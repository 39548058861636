import JSConfetti from 'js-confetti'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'

export const spawnFullPageConfetti = () => {
  const jsConfetti = new JSConfetti()

  const { isMobile } = useIsMobile()

  jsConfetti.addConfetti({
    confettiColors: [
      '#0FBA80', '#3B316E', '#E71C77', '#ff85a1', '#00A2CE'
    ],
    confettiNumber: isMobile.value ? 80 : 200
  })
}
