<script setup>
import BasicPageHeader from '@js/Components/Marketing/BasicPageHeader/BasicPageHeader.vue'
import Container from '@js/Components/Container/Container.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { useMainStore } from '@js/App/Stores/MainStore'
import { doPostRequest } from '@js/App/Api/Infra/ApiClient'
import { ref } from 'vue'
import Icon from '@js/Components/Icon/Icon.vue'

const mainStore = useMainStore()
const errors = ref({})
const showSuccessMessage = ref(false)
const onClickUnsubscribeSearchBuddy = async () => {
  mainStore.startLoading()
  const response = await doPostRequest('/api/user/set-search-buddy', {
    search_buddy_contact_method: 'email',
    search_buddy_contact_address: null
  })
  if (response.hasErrors) {
    errors.value = response.errors
  } else {
    showSuccessMessage.value = true
  }
  mainStore.stopLoading()
}
</script>
<template>
  <div>
    <BasicPageHeader :show-trustpilot-instead-of-login="true" :show-section-divider="false" />

    <Container>
      <div class="pb-[40px] lg:pb-[80px] mt-[40px] lg:mt-[80px]">
        <div v-if="showSuccessMessage" class="lg:max-w-[600px] lg:mx-auto bg-success text-white rounded-t-md px-[20px] lg:px-[40px] py-[8px] text-base">
          <Icon name="check-circle" icon-width="inline w-[20px] h-[20px]" class="mr-[4px]" />
          {{ $trans('UnsubscribeSearchBuddyPage.SuccessMessage') }}
        </div>
        <div
          class="bg-purple-900 lg:max-w-[600px] lg:mx-auto p-[24px] lg:p-[40px] text-white rounded-b-md"
          :class="{
            'rounded-t-md': !showSuccessMessage
          }"
        >
          <h4 class="font-medium text-xl lg:text-4xl mb-[8px]">
            {{ $trans('UnsubscribeSearchBuddyPage.Title') }}
          </h4>
          <p class="text-base lg:text-lg" v-html="$trans('UnsubscribeSearchBuddyPage.Content')" />
          <Button
            :big="true"
            class="mt-[24px]"
            :full-width-on-desktop="true"
            :full-width-on-mobile="true"
            @click="onClickUnsubscribeSearchBuddy"
          >
            {{ $trans('UnsubscribeSearchBuddyPage.NoEmailsButtonLabel') }}
          </Button>
          <Button
            :big="true"
            class="mt-[12px]"
            :full-width-on-desktop="true"
            :full-width-on-mobile="true"
            :outline="true"
            tag="link"
            :href="$getLocalizedUrl('welcome')"
          >
            {{ $trans('UnsubscribeSearchBuddyPage.ChangedMyMind') }}
          </Button>
        </div>
      </div>
    </Container>
  </div>
</template>
<style lang="scss">
.MyRentbird-UnsubscribeSearchBuddyPage {
  background-color: rgb(40,33,75);
}
</style>
