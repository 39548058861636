<script setup>
import { ref } from 'vue'
import Icon from '@js/Components/Icon/Icon.vue'
import Container from '@js/Components/Container/Container.vue'

const show = ref(true)

</script>
<template>
  <div v-if="show" class="bg-success text-white text-base font-medium lg:text-lg">
    <Container class="py-2">
      <div class="flex justify-between">
        <div class="flex">
          <Icon name="check" class="pr-2 lg:relative lg:top-[2px]" />
          <slot />
        </div>
        <Icon
          class="hover:cursor-pointer hover:text-blue-base lg:relative lg:top-[2px]"
          name="cross"
          @click="show = false"
        />
      </div>
    </Container>
  </div>
</template>
