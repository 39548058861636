<script setup>
import TextInput from '@js/Components/Forms/TextInput/TextInput.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { showRentbirdPlusCreateAccountWizard } from '@js/App/Api/RentbirdPlus'
import { storeToRefs } from 'pinia'
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'

defineProps({
  dark: {
    type: Boolean,
    default: true
  }
})

const { searchProfile } = storeToRefs(useSearchProfileStore())
</script>
<template>
  <div class="lg:flex lg:items-end">
    <TextInput
      v-model="searchProfile.rentbirdPlusEmail"
      class="mb-[8px] lg:mb-0 lg:w-[200px] xl:w-[350px] lg:mr-[8px]"
      placeholder="birdie@email.com"
      :dark="dark"
    >
      <template #label>
        {{ $trans('RentbirdPlusEmailInput.Label') }}
      </template>
    </TextInput>

    <Button
      id="request-free-intake"
      :big="true"
      icon-right="arrow-right"
      :full-width-on-mobile="true"
      class="min-w-[236px]"
      @click="showRentbirdPlusCreateAccountWizard()"
    >
      {{ $trans('RentbirdPlusEmailInput.ButtonLabel') }}
    </Button>
  </div>
</template>
