<script setup>
import HeaderWithLogoAndTrustpilot from '@js/Components/HeaderWithLogoAndTrustpilot/HeaderWithLogoAndTrustpilot.vue'
import { usePage } from '@inertiajs/vue3'
import Container from '@js/Components/Container/Container.vue'
import StepResponseLetterWelcome
  from '@js/Components/MyRentbird/Onboarding/MandatoryOnboarding/StepResponseLetterWelcome.vue'
import StepViewResponseLetter
  from '@js/Components/MyRentbird/Onboarding/MandatoryOnboarding/StepViewResponseLetter.vue'
import { computed } from 'vue'
import StepPaymentConfirmed from '@js/Components/MyRentbird/Onboarding/MandatoryOnboarding/StepPaymentConfirmed.vue'
import StepSearchPreferences from '@js/Components/MyRentbird/Onboarding/MandatoryOnboarding/StepSearchPreferences.vue'
import StepPersonalInfo from '@js/Components/MyRentbird/Onboarding/MandatoryOnboarding/StepPersonalInfo.vue'
import StepLivingSituation from '@js/Components/MyRentbird/Onboarding/MandatoryOnboarding/StepLivingSituation.vue'
import StepWhyAreYouFunRenter
  from '@js/Components/MyRentbird/Onboarding/MandatoryOnboarding/StepWhyAreYouFunRenter.vue'
import StepNotificationChannelSelect
  from '@js/Components/MyRentbird/Onboarding/MandatoryOnboarding/StepNotificationChannelSelect.vue'
import StepOnboardingComplete
  from '@js/Components/MyRentbird/Onboarding/MandatoryOnboarding/StepOnboardingComplete.vue'
import StepDownloadApp from '@js/Components/MyRentbird/Onboarding/MandatoryOnboarding/StepDownloadApp.vue'

const page = usePage()
const step = computed(() => parseInt(page.props.step))
</script>
<template>
  <div>
    <HeaderWithLogoAndTrustpilot />

    <Container class="pb-[40px]">
      <StepPaymentConfirmed
        v-if="step === 1"
        class="mt-[16px] lg:mt-[40px]"
      />
      <StepSearchPreferences
        v-if="step === 2"
        class="mt-[16px] lg:mt-[40px]"
      />
      <StepResponseLetterWelcome
        v-if="step === 3"
        class="mt-[16px] lg:mt-[40px]"
      />
      <StepPersonalInfo
        v-if="step === 4"
        class="mt-[16px] lg:mt-[40px]"
      />
      <StepLivingSituation
        v-if="step === 5"
        class="mt-[16px] lg:mt-[40px]"
      />
      <StepWhyAreYouFunRenter
        v-if="step === 6"
        class="mt-[16px] lg:mt-[40px]"
      />
      <StepViewResponseLetter
        v-if="step === 7"
        class="mt-[16px] lg:mt-[40px]"
      />
      <StepNotificationChannelSelect
        v-if="step === 8"
        class="mt-[16px] lg:mt-[40px]"
      />
      <StepDownloadApp
        v-if="step === 9"
        class="mt-[16px] lg:mt-[40px]"
      />
      <StepOnboardingComplete
        v-if="step === 10"
        class="mt-[16px] lg:mt-[40px]"
      />
    </Container>
  </div>
</template>
<style>
.MyRentbird-OnboardingPage {
  background: rgb(234,234,235);
}
</style>
