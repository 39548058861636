<script setup>
import { usePage, Head } from '@inertiajs/vue3'
import BasicPageHeader from '@js/Components/Marketing/BasicPageHeader/BasicPageHeader.vue'
import Container from '@js/Components/Container/Container.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import Image from '@js/Components/Image/Image.vue'
import ImagePowerCordMobile from '@images/power-cord-mobile.png'
import ImagePowerCordDesktop from '@images/power-cord-desktop.png'
import ImageBirdStandingDead from '@images/icons/bird-standing-dead.svg'
const page = usePage()
const status = page.props.status
</script>
<template>
  <Head>
    <title v-if="status === 404">
      {{ $trans('ErrorPage.PageNotFoundSeoTitle') }}
    </title>
    <title v-else>
      {{ $trans('ErrorPage.SeoTitle', { status: 404 }) }}
    </title>
  </Head>
  <div>
    <BasicPageHeader :show-navigation="false" :show-section-divider="false" />

    <Container>
      <h1 class="text-[175px] lg:text-[375px] text-white font-bold text-center mt-[10px] lg:mt-[-40px] relative">
        {{ status }}

        <Image :src="ImageBirdStandingDead" class="absolute top-[32px] left-[50%] w-[24px] lg:w-[50px] lg:top-[72px]" />
      </h1>
      <p class="text-white text-lg -mt-10 text-center lg:text-3xl lg:-mt-[120px]">
        <span v-if="status === 404">{{ $trans('ErrorPage.PageNotFoundSubtitle') }}</span>
        <span v-else>{{ $trans('ErrorPage.GenericErrorSubtite', { status: status }) }}</span>
      </p>
      <div class="lg:flex lg:justify-center">
        <Button
          :big="true"
          tag="link"
          href="/"
          :full-width-on-mobile="true"
          class="mt-[24px] lg:mt-[32px] z-10"
        >
          {{ $trans("ErrorPage.BackToHome") }}
        </Button>
      </div>
    </Container>

    <Image :src="ImagePowerCordMobile" class="w-full mt-[60px] lg:hidden" />
    <Image :src="ImagePowerCordDesktop" class="w-full mt-[80px] hidden lg:block" />
  </div>
</template>
<style lang="scss">
.ErrorPage {
  background: #28214B;
}
</style>
