<script setup>
import PricingCardSimpleButton from '@js/Components/PricingCard/PricingCardSimple/PricingCardSimpleButton.vue'
import { usePaymentStore } from '@js/App/Stores/PaymentStore'
import { storeToRefs } from 'pinia'
import { onMounted } from 'vue'
import { trackSubscriptionSelection } from '@js/App/Tracking/Tracking'

const paymentStore = usePaymentStore()
const { selectedPrice, availablePrices } = storeToRefs(usePaymentStore())

const setSelectedPrice = (price) => {
  paymentStore.removeCoupon()
  selectedPrice.value = price

  trackSubscriptionSelection(selectedPrice.value.plan)
}

onMounted(() => {
  paymentStore.removeCoupon()
})
</script>
<template>
  <div>
    <h2 class="text-purple-900 font-medium text-lg lg:text-2xl mb-2 lg:mb-4">
      {{ $trans('PricingCardSimple.Title') }}
    </h2>
    <PricingCardSimpleButton
      v-for="price of availablePrices"
      :key="price.plan"
      :dusk="`pricing-card-simple-button-${price.plan}`"
      :show-label="price.showLabel"
      class="mb-2 lg:mb-2"
      :checked="selectedPrice?.plan === price.plan"
      @click="setSelectedPrice(price)"
    >
      <template #months>
        {{ $trans(price.numberOfMonths > 1 ? 'PricingCardSimple.Months' : 'PricingCardSimple.MonthsSingle', {
          months: price.numberOfMonths}
        ) }}
      </template>
      <template
        #price
      >
        <div
          :class="{
            'pr-[7px] lg:pr-[4px]': !price.discount
          }"
        >
          {{
            $trans('PricingCardSimple.Price', {
              amount: `${$formatPrice(price.amountPerMonth)}`,
            })
          }}
        </div>
      </template>
      <template #discount>
        <span
          :class="{
            'text-danger': !price.discount
          }"
        >
          {{ $trans('PricingCardSimple.Discount', { discount: parseInt(price.discount || 0) }) }}
        </span>
      </template>
    </PricingCardSimpleButton>
  </div>
</template>
