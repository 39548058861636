<script setup>
import { ref } from 'vue'
import MyRentbirdLayout from '@js/Components/MyRentbird/MyRentbirdLayout/MyRentbirdLayout.vue'
import MyRentbirdBackButton from '@js/Components/MyRentbird/MyRentbirdBackButton/MyRentbirdBackButton.vue'
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import { useUser } from '@js/App/Api/User/User'
import ResponseLetterView from '@js/Components/MyRentbird/ResponseLetter/ResponseLetterView.vue'
import ErrorMessage from '@js/Components/Forms/ErrorMessage/ErrorMessage.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { saveReponseLetters } from '@js/App/Api/User/ResponseLetters'

const { user } = useUser()

const internalResponseLetterTiny = ref(user.value.responseLetterTiny)
const internalResponseLetterLarge = ref(user.value.responseLetterLarge)

const errors = ref({})
const showSavedMessage = ref(false)

const saveResponseLetter = async () => {
  const { errors: apiErrors, hasErrors } = await saveReponseLetters(internalResponseLetterTiny.value, internalResponseLetterLarge.value)
  errors.value = apiErrors

  if (!hasErrors) {
    showSavedMessage.value = true
    setTimeout(() => { showSavedMessage.value = false }, 2000)
  }
}
</script>
<template>
  <MyRentbirdLayout>
    <MyRentbirdBackButton />

    <div class="pb-[40px] lg:pb-[80px]">
      <MyRentbirdCard
        class="mt-[16px]"
        :can-expand-on-mobile="false"
      >
        <template #title>
          {{ $trans('ResponseLetterPage.Title') }}
        </template>
        <template #default>
          <ResponseLetterView
            :response-letter-tiny="internalResponseLetterTiny"
            :response-letter-large="internalResponseLetterLarge"
            @update:response-letter-tiny="newValue => internalResponseLetterTiny = newValue"
            @update:response-letter-large="newValue => internalResponseLetterLarge = newValue"
          />

          <ErrorMessage v-show="errors?.general">
            {{ errors?.general }}
          </ErrorMessage>
          <ErrorMessage v-show="errors?.response_letter_large">
            {{ errors?.response_letter_large }}
          </ErrorMessage>
          <ErrorMessage v-show="errors?.response_letter_tiny">
            {{ errors?.response_letter_tiny }}
          </ErrorMessage>

          <div class="lg:flex">
            <Button
              tag="button"
              :icon-right="showSavedMessage ? 'check' : 'save'"
              :big="true"
              :full-width-on-mobile="true"
              class="mt-[20px] lg:mt-[32px]"
              @click="saveResponseLetter"
            >
              {{ $trans(showSavedMessage ? 'Common.Saved' : 'Common.Save') }}
            </Button>
          </div>
        </template>
      </MyRentbirdCard>
    </div>
  </MyRentbirdLayout>
</template>
<style>
.MyRentbird-ResponseLetterPage {
  background: rgb(234, 234, 235);
}
</style>
