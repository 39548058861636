<script setup>
import Icon from '@js/Components/Icon/Icon.vue'
import RentbirdLogo from '@js/Components/Logos/RentbirdLogo/RentbirdLogo.vue'
import Container from '@js/Components/Container/Container.vue'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'
import { router } from '@inertiajs/vue3'
import Image from '@js/Components/Image/Image.vue'
import HomeDealsLogo from '@images/logos/rentbird-home-deals-logo.png'
import TrustpilotSmall from '@js/Components/Trustpilot/TrustpilotSmall.vue'
import TrustpilotRating from '@js/Components/Trustpilot/TrustpilotRating.vue'

defineProps({
  showHomedealsLogo: {
    type: Boolean,
    default: false
  },
  showBackButton: {
    type: Boolean,
    default: true
  },
  showCloseButton: {
    type: Boolean,
    default: true
  },
  showTrustPilot: {
    type: Boolean,
    default: false
  }
})

const { isMobile } = useIsMobile()

defineEmits(['clicked:back'])
</script>
<template>
  <div class="bg-purple-700">
    <Container>
      <div class="flex items-center justify-between text-white pt-[24px] pb-[24px] lg:pt-[34px] lg:pb-[34px]">
        <div
          v-if="showBackButton"
          role="button"
          class="hover:cursor-pointer hover:text-pink-500 transition-colors"
          @click="$emit('clicked:back')"
        >
          <Icon name="arrow-left" icon-width="w-[30px] h-[30px]" />
        </div>
        <div v-else-if="$slots['top-left'] || showTrustPilot">
          <slot name="top-left" />
          <div v-if="showTrustPilot">
            <RentbirdLogo v-if="!showHomedealsLogo" class="justify-self-start" :href="false" :size="isMobile ? 'xs' : 'md'" />
          </div>
        </div>
        <div v-else class="w-[30px] h-[30px]" />

        <RentbirdLogo v-if="!showHomedealsLogo && !showTrustPilot" class="justify-self-start" :href="false" :size="isMobile ? 'xs' : 'md'" />

        <Image v-if="showHomedealsLogo" :src="HomeDealsLogo" class="w-[112px] lg:w-[150px]" />
        <div v-if="showTrustPilot">
          <TrustpilotSmall v-if="!isMobile" />
          <TrustpilotRating v-else :black="false " />
        </div>
        <div
          v-if="showCloseButton"
          role="button"
          class="hover:cursor-pointer hover:text-pink-500 transition-colors"
          @click="router.visit($getLocalizedUrl('my-rentbird.subscription'))"
        >
          <Icon name="cross" icon-width="w-[30px] h-[30px]" />
        </div>
        <div v-else-if="!showTrustPilot" class="w-[30px] h-[30px]" />
      </div>
    </Container>
    <slot />
  </div>
</template>
