<script setup>
import Icon from '@js/Components/Icon/Icon.vue'

defineProps({
  modelValue: Boolean
})

const id = `checkbox-id-${Math.floor(Math.random() * 10000)}`

defineEmits(['update:modelValue'])

</script>
<template>
  <div
    class="flex hover:cursor-pointer bg-purple-900 p-[8px] text-sm text-white rounded-md"
    @click="$emit('update:modelValue', !modelValue)"
  >
    <div
      class="min-w-[16px] max-w-[16px] max-h-[16px] min-h-[16px] lg:min-w-[20px] lg:max-w-[20px] lg:max-h-[20px] lg:min-h-[20px] rounded flex justify-center items-center relative top-[1px] lg:top-[3px] mr-[8px]"
      :class="{
        'bg-success': modelValue,
        'border border-gray-100': !modelValue
      }"
    >
      <Icon v-if="modelValue" name="check" class="text-white relative left-[0px]" icon-width="w-[14px] h-[14px]" />
    </div>
    <label
      :for="id"
      class="text-white hover:cursor-pointer text-base lg:text-lg"
    >
      <slot /></label>
  </div>
</template>
