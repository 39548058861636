<script setup>
import FiltersInputScreenTopBar from '@js/Components/Forms/FiltersInputAllHousesPage/FiltersInputScreenTopBar.vue'
import { computed } from 'vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import {
  DEFAULT_MIN_BEDROOMS
} from '@js/App/Utils/FiltersInputAllHousesDefaultValues'
import SliderRangeInput from '@js/Components/Forms/SliderRangeInput/SliderRangeInput.vue'
import { formatNumber } from '@js/App/Plugins/Formatting'

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({})
  }
})

const filtersAreDifferentFromDefault = computed(() => {
  return props.modelValue.minBedrooms
})

defineEmits(['update:modelValue', 'click:back', 'click:close'])
</script>
<template>
  <div>
    <FiltersInputScreenTopBar
      :title="$trans('FiltersInput.MinBedrooms')"
      class="mb-[40px]"
      @click:back="$emit('click:back')"
      @click:close="$emit('click:close')"
    />

    <SliderRangeInput
      class="mb-[24px] px-[40px]"
      :min="0"
      :max="6"
      :step="1"
      :format="value => `${formatNumber(value)} ${$trans('FiltersInputMinBedroomsScreen.Bedrooms')}`"
      :model-value="modelValue.minBedrooms || 0"
      @update:model-value="newMinBedrooms => {
        $emit('update:modelValue', {
          ...modelValue,
          minBedrooms: newMinBedrooms
        })
      }"
    />

    <div class="w-full flex justify-end mb-[40px]">
      <a
        class="text-base text-blue-300"
        :class="{
          'hover:underline hover:cursor-pointer': filtersAreDifferentFromDefault,
          'opacity-30': !filtersAreDifferentFromDefault
        }"
        @click.prevent="() => {
          $emit('update:modelValue', {
            ...modelValue,
            minBedrooms: DEFAULT_MIN_BEDROOMS
          })
        }"
      >
        {{ $trans('FilterInputLocationScreen.ResetFilter') }}
      </a>
    </div>

    <Button
      :big="true"
      :full-width-on-mobile="true"
      icon-right="back"
      :outline="true"
      @click="() => {
        $emit('click:back')
      }"
    >
      {{ $trans('FiltersInputLocationScreen.ApplyAndGoBack') }}
    </Button>
  </div>
</template>
