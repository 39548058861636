<script setup>
import NavMenuSupportCardItem from '@js/Components/NavMenu/NavMenuSupportCardItem.vue'
import { COUNTRY_NL, getCountry } from '@js/App/Utils/CountryAndLocale'

const emailToUse = getCountry() === COUNTRY_NL
  ? 'mail@rentbird.nl'
  : 'mail@rentbird.io'
</script>
<template>
  <div>
    <div class="pb-[2px] lg:pb-2 text-sm lg:text-base">
      {{ $trans('NavMenuSupportCardList.title') }}
    </div>
    <NavMenuSupportCardItem
      v-if="getCountry() === COUNTRY_NL"
      left-image="whatsapp"
      :title="$trans('NavMenu.WhatsApp')"
      subtitle="+31 97006520266"
      right-image="speech-bubble"
      link="https://api.whatsapp.com/send?phone=3197006520266"
    />
    <NavMenuSupportCardItem
      left-image="email"
      :title="$trans('NavMenu.Email')"
      :subtitle="emailToUse"
      right-image="submit"
      :link="`mailto:${emailToUse}`"
    />
  </div>
</template>
