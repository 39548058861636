<script setup>
import Select from '@js/Components/Forms/Select/Select.vue'
import { storeToRefs } from 'pinia'
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'

defineProps({
  error: {
    type: String,
    default: ''
  }
})

const { searchProfile } = storeToRefs(useSearchProfileStore())
</script>
<template>
  <Select
    v-model="searchProfile.furnished"
    :error="error"
    dusk="furnished-select"
    class="mt-4"
    :options="[
      { value: 'everything', label: $trans('SearchProfileWizard.DoesNotMatter') },
      { value: 'furnished', label: $trans('SearchProfileWizard.Furnished') },
      { value: 'unfurnished', label: $trans('SearchProfileWizard.Unfurnished') }
    ]"
  >
    <template #label>
      {{ $trans('SearchProfileWizard.Furnished') }}
    </template>
  </Select>
</template>
