<script setup>
</script>
<template>
  <div class="a b c">
    <h6 class="text-3xl font-medium mb-[5px] lg:text-5xl">
      <slot name="title" />
    </h6>
    <p class="text-base lg:text-xl">
      <slot />
    </p>
  </div>
</template>
