<script setup>
import ImageLightBulb from '@images/icons/light.png'
import RentbirdLogo from '@js/Components/Logos/RentbirdLogo/RentbirdLogo.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import WarningTriangle from '@images/icons/warning-triangle.svg'
import SmallLabel from '@js/Components/SmallLabel/SmallLabel.vue'
import CityMap from '@js/Components/SearchProfileWizards/SearchProfileWizard/CityMap.vue'
import { computed, onMounted, ref } from 'vue'
import { getSearchProfileDto, useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import { saveSearchProfileWizardStep } from '@js/App/Api/SearchProfile'
import { isEmpty } from '@js/App/Utils/IsEmpty'
import StepCityAndNeighbourhood from '@js/Components/SearchProfileWizards/SearchProfileWizard/Steps/StepCityAndNeighbourhood.vue'
import StepPriceAndSize from '@js/Components/SearchProfileWizards/SearchProfileWizard/Steps/StepPriceAndSize.vue'
import StepExtraDetails from '@js/Components/SearchProfileWizards/SearchProfileWizard/Steps/StepExtraDetails.vue'
import { useMainStore } from '@js/App/Stores/MainStore'
import { useMatchesPerWeekAndStoreResultsInPinia } from '@js/App/Api/MatchesPerWeek'
import { storeToRefs } from 'pinia'
import WizardNextStepButton from '@js/Components/SearchProfileWizards/SearchProfileWizard/WizardNextStepButton.vue'
import WizardWithSlidingSteps from '@js/Components/Forms/WizardWithSlidingSteps/WizardWithSlidingSteps.vue'
import { CREATE_SEARCH_PROFILE_AND_ACCOUNT_MODAL_ID } from '@js/App/Plugins/Modals'
import { trackClickBackInFunnel, trackFunnelStep } from '@js/App/Tracking/Tracking'
import InfoMessageBox from '@js/Components/InfoMessageBox/InfoMessageBox.vue'
import Image from '@js/Components/Image/Image.vue'
import { useUser } from '@js/App/Api/User/User'
import SearchProfileStickyBottomForCreateAccountWizard
  from '@js/Components/MyRentbird/SearchProfileStickyBottom/SearchProfileStickyBottomForCreateAccountWizard.vue'
import StepChangeValuesBecauseLowNumberOfMatches
  from '@js/Components/SearchProfileWizards/SearchProfileWizard/Steps/StepChangeValuesBecauseLowNumberOfMatches.vue'
import CustomTransitionFade from '@js/Components/Transitions/CustomTransitionFade.vue'
import LowMatchesWarningModal from '@js/Components/SearchProfileWizards/SearchProfileWizard/LowMatchesWarningModal.vue'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import { router } from '@inertiajs/vue3'

defineEmits(['close'])

const mainStore = useMainStore()
const { searchProfile, step } = storeToRefs(useSearchProfileStore())
const { user } = useUser()

const showExtraStepInBetweenBecauseUserHasLowMatches = ref(false)
const showLowMatchesWarningModal = ref(false)

const errors = ref({})

const getSelectCityIdForTracking = () => searchProfile.value?.city?.id || null

onMounted(async () => {
  if (step.value === 2) {
    await trackFunnelStep(step.value - 1, getSelectCityIdForTracking())
  }
  await trackFunnelStep(step.value, getSelectCityIdForTracking())
})

useMatchesPerWeekAndStoreResultsInPinia()

const getIsMatchesPerWeekToLow = () => {
  const { matchesPerWeek } = storeToRefs(useSearchProfileStore())
  if (matchesPerWeek.value.matchesPerWeek < 5) {
    return true
  }
  return false
}

const clickedNextStep = async () => {
  if (mainStore.isLoading) { return }

  mainStore.startLoading()
  try {
    const searchProfileDto = getSearchProfileDto(searchProfile.value)
    const dataToSave = { ...searchProfileDto }

    const { errors: apiErrors } = await saveSearchProfileWizardStep(dataToSave, step.value)
    errors.value = apiErrors

    if (!isEmpty(apiErrors)) { return }

    await trackFunnelStep(step.value + 1, getSelectCityIdForTracking())

    setCorrectNextStepValue()
  } finally {
    mainStore.stopLoading()
  }
}

const setCorrectNextStepValue = () => {
  if (step.value === 1) {
    step.value = 2
    return
  }

  if (step.value === 2) {
    step.value = 3
    return
  }

  if (!showExtraStepInBetweenBecauseUserHasLowMatches.value && getIsMatchesPerWeekToLow()) {
    showExtraStepInBetweenBecauseUserHasLowMatches.value = true
    return
  }

  if (showExtraStepInBetweenBecauseUserHasLowMatches.value) {
    if (!showLowMatchesWarningModal.value && getIsMatchesPerWeekToLow()) {
      showLowMatchesWarningModal.value = true
      return
    }
  }

  showLowMatchesWarningModal.value = false

  router.visit(getLocalizedUrl('create-account', { }, { search_profile: searchProfile.value.serverId }))
}

const clickedPreviousStep = () => {
  trackClickBackInFunnel()

  if (showExtraStepInBetweenBecauseUserHasLowMatches.value) {
    showExtraStepInBetweenBecauseUserHasLowMatches.value = false
    if (step.value === 3) {
      return
    }
  }

  step.value--
}

const showSelectDistanceForMoreMatches = computed(() => {
  if (searchProfile.value.locationSelector === 'distance') {
    return false
  }

  const cityId = searchProfile.value?.city?.id || ''
  if (!cityId) {
    return false
  }
  return cityId !== 'amsterdam' && cityId !== 'rotterdam' && cityId !== 'den-haag' && cityId !== 'utrecht'
})
</script>
<template>
  <div
    dusk="create-search-profile-and-account-wizard"
    class="flex-col flex justify-between min-h-full"
    :class="{'h-full': step === 1}"
  >
    <div class="h-full flex-grow flex flex-col py-4 lg:py-6">
      <div class="flex justify-between items-center px-4 lg:px-6">
        <SmallLabel>
          {{ $trans('SearchProfileWizard.Step', { step: step, total: 3 }) }}
        </SmallLabel>
        <RentbirdLogo :href="false" size="xs" class="pr-12" />
        <Icon name="cross" class="text-purple-700 hover:text-pink-500 transition-colors hover:cursor-pointer" @click="$closeModal(CREATE_SEARCH_PROFILE_AND_ACCOUNT_MODAL_ID)" />
      </div>

      <WizardWithSlidingSteps
        :steps="3"
        :step="step"
        class="flex flex-col flex-grow "
      >
        <template #step-1>
          <div class="flex flex-col h-full lg:flex-row bg-white px-4 lg:px-6 mb-[92px] lg:mb-0">
            <div class="lg:w-1/2 lg:pr-4">
              <StepCityAndNeighbourhood :errors="errors" />

              <InfoMessageBox color="gray" :image="ImageLightBulb" class="mt-[16px] hidden lg:flex">
                <span v-if="!showSelectDistanceForMoreMatches" v-html="$trans('StepCityAndNeighbourhood.WithAPaidAccount')" />

                <span v-else v-html="$trans('StepCityAndNeighbourhood.NotALotOfStuff')" />
              </InfoMessageBox>
            </div>

            <CityMap class="mt-4 mb-0 lg:mb-0 lg:w-1/2 lg:ml-4 min-h-[250px] flex-grow" />

            <InfoMessageBox color="gray" :image="ImageLightBulb" class="mt-[16px] lg:hidden">
              <span v-if="!showSelectDistanceForMoreMatches" v-html="$trans('StepCityAndNeighbourhood.WithAPaidAccount')" />

              <span v-else v-html="$trans('StepCityAndNeighbourhood.NotALotOfStuff')" />
            </InfoMessageBox>
          </div>

          <div class="px-4 lg:px-6">
            <InfoMessageBox v-if="user.hasActiveSubscription" class="mt-4 lg:mt-8 mb-4">
              <Image :src="WarningTriangle" class="mr-2 w-[20px] h-full relative" />
              <span v-html="$trans('CreateSearchProfileAndAccountWizard.HasActiveSubscriptionMessage')" />
            </InfoMessageBox>

            <InfoMessageBox v-else-if="!user.hasActiveSubscription && user.isLoggedIn" class="mt-4 lg:mt-8 mb-4">
              <Image :src="WarningTriangle" class="mr-2 w-[20px] h-full relative" />
              <span v-html="$trans('CreateSearchProfileAndAccountWizard.HasNoActiveSubscriptionMessage')" />
            </InfoMessageBox>

            <WizardNextStepButton
              v-show="!user.hasActiveSubscription && !user.isLoggedIn"
              :show-previous-button="false"
              class="pt-4 lg:pt-8 pb-4 hidden lg:block"
              :error="errors.general"
              @click:next-step="clickedNextStep"
              @click:previous-step="clickedPreviousStep"
            >
              {{ $trans('SearchProfileWizard.NextButtonLabel') }}
            </WizardNextStepButton>
          </div>
        </template>
        <template #step-2>
          <div class="flex-col flex flex-grow justify-between min-h-full bg-white px-4 lg:px-6">
            <div class="mb-[82px] lg:mb-0">
              <StepPriceAndSize :errors="errors" />
            </div>

            <WizardNextStepButton
              class="pt-4 lg:pt-8 pb-4 hidden lg:block"
              :error="errors.general"
              @click:next-step="clickedNextStep"
              @click:previous-step="clickedPreviousStep"
            >
              {{ $trans('SearchProfileWizard.NextButtonLabel') }}
            </WizardNextStepButton>
          </div>
        </template>
        <template #step-3>
          <div class="flex-col flex flex-grow justify-between min-h-full bg-white px-4 lg:px-6">
            <div>
              <CustomTransitionFade :group="1">
                <div v-if="!showExtraStepInBetweenBecauseUserHasLowMatches">
                  <InfoMessageBox :image="ImageLightBulb" color="gray" class="mb-[16px] mt-[16px] lg:hidden">
                    <span v-html="$trans('CreateSearchProfileAndAccountWizard.Step3Hint')" />
                  </InfoMessageBox>
                  <StepExtraDetails :errors="errors" />
                </div>
              </CustomTransitionFade>
              <CustomTransitionFade :group="1">
                <StepChangeValuesBecauseLowNumberOfMatches
                  v-if="showExtraStepInBetweenBecauseUserHasLowMatches"
                  class="mt-[16px] lg:mb-[12px] mb-[32px]"
                  :errors="errors"
                />
              </CustomTransitionFade>
            </div>
            <div class="">
              <InfoMessageBox v-if="!showExtraStepInBetweenBecauseUserHasLowMatches" :image="ImageLightBulb" color="gray" class="lg:mb-[16px] lg:flex hidden">
                <span v-html="$trans('CreateSearchProfileAndAccountWizard.Step3Hint')" />
              </InfoMessageBox>

              <WizardNextStepButton
                class="pb-2 hidden lg:block"
                :error="errors.general"
                @click:next-step="clickedNextStep"
                @click:previous-step="clickedPreviousStep"
              >
                {{ $trans('SearchProfileWizard.NextButtonLabel') }}
              </WizardNextStepButton>
              <div class="text-xs text-gray-600 ">
                {{ $trans('SearchProfileWizard.ExtraStepsBottomLabel') }}
              </div>
            </div>
          </div>

          <LowMatchesWarningModal
            v-show="showLowMatchesWarningModal"
            @click:back="showLowMatchesWarningModal = false"
            @click:continue="clickedNextStep"
          />
        </template>
      </WizardWithSlidingSteps>
      <SearchProfileStickyBottomForCreateAccountWizard
        class="lg:hidden"
        :show-previous-button="step > 1"
        @click:next-step="clickedNextStep"
        @click:previous-step="clickedPreviousStep"
      />
    </div>
  </div>
</template>
<style lang="scss">
.slide-left {
  transform: translateX(-100%);
}
</style>
