<script setup>
import Button from '@js/Components/Forms/Button/Button.vue'
import { useUser } from '@js/App/Api/User/User'
import MainHeroSection from '@js/Components/Marketing/Heros/MainHeroSection/MainHeroSection.vue'

const { user } = useUser()

defineProps({
  showNavigation: {
    type: Boolean,
    default: true
  }
})

</script>
<template>
  <MainHeroSection>
    <template #left>
      <h1
        class="header-text font-medium mb-4 pt-8 lg:text-4xl xl:text-6xl lg:mb-5"
      >
        <slot />
      </h1>

      <div class="lg:flex lg:items-end">
        <Button
          v-if="user.hasActiveSubscription"
          tag="link"
          :href="$getLocalizedUrl('my-rentbird.match-history')"
          :big="true"
          :full-width-on-mobile="true"
          icon-right="arrow-right"
          class="lg:mr-2 mb-2 lg:mb-0"
        >
          <span class="whitespace-nowrap">{{ $trans('HomePageLoggedIn.MatchHistoryButton') }}</span>
        </Button>
        <Button
          v-if="user.hasActiveSubscription"
          tag="link"
          :href="$getLocalizedUrl('my-rentbird.create-search-profile')"
          :big="true"
          :outline="true"
          :full-width-on-mobile="true"
          icon-right="plus"
        >
          <span class="whitespace-nowrap">{{ $trans('HomePageLoggedIn.NewSearchProfileButton') }}</span>
        </Button>
        <Button
          v-if="!user.hasActiveSubscription"
          tag="link"
          :href="$getLocalizedUrl('upgrade')"
          :big="true"
          :full-width-on-mobile="true"
          icon-right="unlocked"
          class="lg:mr-2 mb-2 lg:mb-0"
        >
          <span class="whitespace-nowrap">{{ $trans('HomePageLoggedIn.ChooseSubscription') }}</span>
        </Button>
        <Button
          v-if="!user.hasActiveSubscription"
          tag="link"
          :href="$getLocalizedUrl('pricing')"
          :big="true"
          :outline="true"
          :full-width-on-mobile="true"
          icon-right="arrow-right"
        >
          <span class="whitespace-nowrap">{{ $trans('HomePageLoggedIn.ViewPrices') }}</span>
        </Button>
      </div>
    </template>
  </MainHeroSection>
</template>

<style lang="scss">
.header-text {
  font-size: clamp(26px,8vw,32px);
}
</style>
