<script setup>
</script>

<template>
  <div class="w-full p-4 bg-pink-50 rounded-lg">
    <div class="block lg:flex justify-between">
      <div class="lg:text-lg">
        <slot name="free-account-message" />
      </div>
    </div>
  </div>
</template>
