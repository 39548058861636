<script setup>
import { ref, watch } from 'vue'

const props = defineProps({
  step: {
    type: Number
  },
  steps: {
    type: Number,
    default: 2
  }
})

const stepTransitioningFrom = ref(null)
const stepTransitioningTo = ref(null)
const slideDirection = ref('left')

watch(() => props.step, (newValue, oldValue) => {
  stepTransitioningFrom.value = oldValue
  stepTransitioningTo.value = newValue
  slideDirection.value = newValue > oldValue ? 'left' : 'right'

  setTimeout(() => {
    stepTransitioningTo.value = null
  }, 10)

  setTimeout(() => {
    stepTransitioningFrom.value = null
  }, 300)
})

const shouldShowStep = (index) => {
  if (props.step === index) { return true }
  if (stepTransitioningFrom.value === index) { return true }
  return false
}

</script>
<template>
  <div v-bind="$attrs" class="relative overflow-x-hidden">
    <div
      v-for="i in steps"
      v-show="shouldShowStep(i)"
      v-bind="$attrs"
      :key="i"
      :dusk="`step-${i}`"
      class="overflow-x-hidden transition-transform duration-300"
      :class="{
        'slide-center-to-left': stepTransitioningFrom === i && slideDirection === 'left',
        'slide-center-to-right': stepTransitioningFrom === i && slideDirection === 'right',

        'slide-right-to-center-start': stepTransitioningTo === i && slideDirection === 'left',
        'slide-left-to-center-start': stepTransitioningTo === i && slideDirection === 'right',
      }"
    >
      <slot :name="`step-${i}`" />
    </div>
  </div>
</template>
<style lang="scss">

.slide-center-to-left, .slide-center-to-right, .slide-right-to-center {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 70;
}

.slide-center-to-left {
  transform: translateX(-100%);
}
.slide-center-to-right {
  transform: translateX(100%);
}
.slide-right-to-center-start {
  transform: translateX(100%);
}
.slide-left-to-center-start {
  transform: translateX(-100%);
}
</style>
