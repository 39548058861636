import { doPostRequest } from '@js/App/Api/Infra/ApiClient'
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import { reloadPropsFromServer } from '@js/App/Api/Infra/ServerData'
import { useB2BOrganization } from '@js/App/Api/B2B/B2B'
import { ziggyRoute } from '@js/App/Utils/ZiggyUrls'

/**
 * Calls the API to save a search profile
 * @param searchProfileDto The search profile encoded as DTO (Data transfer object). See searchProfile store for more info.
 * @param step The current step we are in
 * @returns {Promise<{errors: ({}|{general: string})}|{errors: {}}>}
 */
export const saveSearchProfileWizardStep = async (searchProfileDto, step) => {
  const response = await doPostRequest('/api/search-profile', {
    ...searchProfileDto,
    fieldsToValidate: getFieldsToValidate(step)
  }, false)

  if (response.hasErrors) {
    return {
      errors: response.errors
    }
  }

  const apiSearchProfile = response.json?.search_profile

  const searchProfileStore = useSearchProfileStore()
  searchProfileStore.setSearchProfileFromDtoObject(apiSearchProfile)

  reloadPropsFromServer()

  return { errors: {} }
}

export const editSearchProfile = async (searchProfileDto) => {
  const response = await doPostRequest('/api/user/update-search-profile', {
    ...searchProfileDto
  }, false)

  if (response.hasErrors) {
    return {
      errors: response.errors
    }
  }

  const apiSearchProfile = response.json?.search_profile
  const searchProfileStore = useSearchProfileStore()
  searchProfileStore.setSearchProfileFromDtoObject(apiSearchProfile)

  return { errors: {} }
}

export const editB2BSearchProfile = async (searchProfileDto, userRandomId) => {
  const { organization } = useB2BOrganization()

  const response = await doPostRequest(`/b2b/${organization.value}/user/${userRandomId}/search/${searchProfileDto.serverId}`, {
    ...searchProfileDto
  }, false)

  if (response.hasErrors) {
    return {
      errors: response.errors
    }
  }

  const apiSearchProfile = response.json?.search_profile
  const searchProfileStore = useSearchProfileStore()
  searchProfileStore.setSearchProfileFromDtoObject(apiSearchProfile)

  return { errors: {} }
}

export const saveSearchProfile = async (searchProfileDto) => {
  const response = await doPostRequest('/api/user/create-search-profile', {
    ...searchProfileDto
  }, false)

  if (response.hasErrors) {
    return {
      errors: response.errors
    }
  }

  const apiSearchProfile = response.json?.search_profile
  const searchProfileStore = useSearchProfileStore()
  searchProfileStore.setSearchProfileFromDtoObject(apiSearchProfile)

  return { errors: {} }
}

export const saveB2BSearchProfile = async (searchProfileDto, userRandomId) => {
  const { organization } = useB2BOrganization()
  const response = await doPostRequest(
    ziggyRoute('b2b.user.create-or-update-search', {
      organization: organization.value,
      user: userRandomId
    })
    , {
      ...searchProfileDto
    }, false)

  if (response.hasErrors) {
    return {
      errors: response.errors
    }
  }

  const apiSearchProfile = response.json?.search_profile
  const searchProfileStore = useSearchProfileStore()
  searchProfileStore.setSearchProfileFromDtoObject(apiSearchProfile)

  return { errors: {} }
}

export const deleteSearchProfile = async (searchProfileServerId) => {
  await doPostRequest('/api/user/delete-search-profile', {
    random_id: searchProfileServerId
  })
}

export const deleteB2BSearchProfile = async (searchProfileServerId, userRandomId) => {
  const { organization } = useB2BOrganization()
  await doPostRequest(`/b2b/${organization.value}/user/${userRandomId}/delete-search-profile`, {
    random_id: searchProfileServerId
  })
}

const getFieldsToValidate = (step) => {
  if (step === 1) {
    return ['city', 'neighbourhoods', 'distance', 'location_selector']
  }
  if (step === 2) {
    return ['min_rent', 'max_rent']
  }
  if (step === 4) {
    return ['firstname', 'lastname', 'email', 'password']
  }
  return []
}
