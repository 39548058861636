<script setup>
import Icon from '@js/Components/Icon/Icon.vue'
import { computed } from 'vue'

const props = defineProps({
  hours: {
    type: Number,
    default: 0
  }
})

const isOldHome = computed(() => props.hours >= 6)
</script>
<template>
  <div
    class="font-medium text-lg flex items-start"
    :class="{
      'text-success': !isOldHome,
      'text-danger': isOldHome
    }"
  >
    <Icon name="clock" icon-width="w-[24px] h-[24px]" class="mr-2 relative top-[4px] lg:top-0" />
    <p v-if="isOldHome" v-html="$trans('HoursSinceHouseFoundMessage.OldHouse')" />
    <p v-if="!isOldHome" v-html="$trans('HoursSinceHouseFoundMessage.NewHouse')" />
  </div>
</template>
