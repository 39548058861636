<script setup>
import PricingCardAlternativeTabButton
  from '@js/Components/PricingCard/PricingCardAlternative/PricingCardAlternativeTabButton.vue'
import { usePaymentStore } from '@js/App/Stores/PaymentStore'
import { storeToRefs } from 'pinia'
import Button from '@js/Components/Forms/Button/Button.vue'
import PricingCardAlternativeAmount
  from '@js/Components/PricingCard/PricingCardAlternative/PricingCardAlternativeAmount.vue'

const paymentStore = usePaymentStore()
const { selectedPrice, availablePrices } = storeToRefs(usePaymentStore())

const setSelectedPrice = (price) => {
  paymentStore.removeCoupon()
  selectedPrice.value = price
}
</script>
<template>
  <div class="bg-purple-900 text-white px-6 py-4 rounded-md lg:py-6 w-[500px] max-w-full">
    <div class="flex justify-between items-center mb-4">
      <PricingCardAlternativeTabButton
        v-for="price of availablePrices.map(item => item).reverse()"
        :key="price.plan"
        :is-active="selectedPrice?.plan === price.plan"
        @click="setSelectedPrice(price)"
      >
        {{ $trans(price?.numberOfMonths > 1 ? 'PricingCardSimple.Months' : 'PricingCardSimple.MonthsSingle', {
          months: price.numberOfMonths}
        ) }}
      </PricingCardAlternativeTabButton>
    </div>

    <PricingCardAlternativeAmount
      :full-price="selectedPrice?.numberOfMonths > 1 ? 2900 : false"
      :discount="selectedPrice?.discount"
      :price="selectedPrice?.amount / selectedPrice?.numberOfMonths"
    />

    <div class="flex justify-center mt-4">
      <Button
        class="w-full"
        :full-width-on-desktop="true"
        :full-width-on-mobile="true"
        :big="true"
        icon-right="arrow-right"
        tag="link"
        :href="$getLocalizedUrl('my-rentbird.subscription')"
      >
        {{ $trans('PricingCard.ManageSubscription') }}
      </Button>
    </div>
    <div class="text-white text-xs text-center mt-2">
      {{ $trans('UpgradePage.DisclaimerText') }}
    </div>
  </div>
</template>
