<script setup>
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import PersonTextBox from '@js/Components/MyRentbird/Offboarding/PersonTextBox/PersonTextBox.vue'
import StefInCircle from '@images/people/stef-in-circle.png'
import { useUser } from '@js/App/Api/User/User'
import Button from '@js/Components/Forms/Button/Button.vue'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'

const { user } = useUser()
</script>
<template>
  <div>
    <h1 class="text-purple-700 font-bold text-2xl lg:text-4xl">
      {{ $trans('MandatoryOnboardingStep1.Title') }}
    </h1>
    <MyRentbirdCard class="mt-[16px] lg:mt-[20px]" :can-expand-on-mobile="false" :default-is-expanded="true">
      <div class="lg:flex items-end justify-between max-w-full">
        <PersonTextBox
          :light="true"
          :image="StefInCircle"
          max-width-on-desktop="lg:w-full "
        >
          <template #name>
            Stef<br>
            <span class="text-base">{{ $trans('Common.CoFounder') }}</span>
          </template>
          <h4 class="text-[#151227] font-medium text-lg lg:text-xl">
            {{ $trans('MandatoryOnboardingStep1.HiName', { name: user.firstNameFormatted }) }}
          </h4>
          <p v-html="$trans('MandatoryOnboardingStep1.Body')" />
        </PersonTextBox>

        <Button
          tag="link"
          :href="getLocalizedUrl('my-rentbird.onboarding', { step: 2 })"
          icon-right="arrow-right"
          :big="true"
          :full-width-on-mobile="true"
          class="mt-[20px] lg:mt-[32px]"
        >
          {{ $trans('Common.Next') }}
        </Button>
      </div>
    </MyRentbirdCard>
  </div>
</template>
