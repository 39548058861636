<script setup>
import HeaderWithLogoAndTrustpilot from '@js/Components/HeaderWithLogoAndTrustpilot/HeaderWithLogoAndTrustpilot.vue'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'
import Icon from '@js/Components/Icon/Icon.vue'
import BirdWithMessageBox from '@js/Components/BirdWithMessageBox/BirdWithMessageBox.vue'
import { usePage } from '@inertiajs/vue3'
import PricingCardAlternative from '@js/Components/PricingCard/PricingCardAlternative/PricingCardAlternative.vue'
import HouseImage from '@images/icons/house.png'
import BasicHouseCardAlternative from '@js/Components/HouseCards/BasicHouseCard/BasicHouseCardAlternative.vue'
import InfoMessageBox from '@js/Components/InfoMessageBox/InfoMessageBox.vue'
import { useMissedMatchesOfLoggedInUsersLastSearchProfile } from '@js/App/Api/MissedMatches'
import ThreeSmallUsps from '@js/Components/Marketing/ThreeSmallUsps/ThreeSmallUsps.vue'

const page = usePage()
const house = page.props.house

const { missedMatches, isLoading } = useMissedMatchesOfLoggedInUsersLastSearchProfile()
const { isMobile } = useIsMobile()
</script>
<template>
  <HeaderWithLogoAndTrustpilot />

  <div class="container px-4 mx-auto lg:pt-7 pb-[80px]">
    <h1
      class="text-2xl mt-4 font-medium text-purple-700 lg:text-4xl"
    >
      {{ $trans('UpgradeToViewHousePage.Title') }}
    </h1>
    <a
      :href="$getLocalizedUrl('my-rentbird.match-history')"
      class="text-black text-sm hover:cursor-pointer hover:text-pink-500 transition-colors flex mt-4 lg:text-base"
    >
      <Icon name="arrow-left" class="mr-[4px]" icon-width="w-[18px] h-[18px] lg:w-[24px] lg:h-[24px]" />
      <span class="relative top-[-1px] lg:top-[1px]">
        {{ $trans('UpgradeToViewHousePage.BackToAllMatches') }}
      </span>
    </a>

    <div class="lg:flex">
      <div class="lg:w-1/2 lg:pr-3">
        <div class="bg-white px-[16px] py-[16px] lg:px-[32px] lg:py-[32px] rounded-md mt-[16px] lg:mt-[24px]">
          <h4
            class="text-purple-900 font-medium text-xl mb-[4px] lg:text-2xl"
          >
            {{ $trans('UpgradeToViewHouseCard.Title') }}
          </h4>
          <p
            class="text-purple-900 text-base lg:text-lg"
            v-html="$trans('UpgradeToViewHouseCard.Content')"
          />

          <BasicHouseCardAlternative
            class="mt-[16px]"
            :image="house.image"
            :name="house.name"
            :rental-agent="house.rental_agent_name"
            :found-at-formatted="house.created_at_formatted_diff"
          />

          <InfoMessageBox
            :image="HouseImage"
            color="pink"
            class="px-3 lg:px-6 mt-[16px]"
          >
            <div
              v-html="$trans('MissedMatchesMessageBox.Text', {
                matches: isLoading ? '..' : missedMatches
              })"
            />
          </InfoMessageBox>
          <div
            v-if="house.hours_since_created_at <= 3"
            class="text-success font-medium text-lg flex mt-[16px]"
          >
            <Icon name="clock" class="mr-[8px]" />
            <span class="relative top-[-1px]">{{ $trans('UpgradeToViewHouseCard.YouAreFast') }}</span>
          </div>
        </div>
      </div>

      <div class="lg:w-1/2 lg:pl-3">
        <div class="bg-white rounded-md px-[16px] py-[16px] lg:px-[32px] lg:py-[32px] mt-[16px] lg:mt-[24px]">
          <h4
            class="text-purple-900 font-medium text-xl lg:text-2xl mb-[4px]"
          >
            {{ $trans('UpgradeToViewHousePage.NoSubscriptionTitle') }}
          </h4>

          <PricingCardAlternative class="mt-[16px] mb-[16px] lg:mb-[24px] lg:mt-[24px] w-[600px] max-w-full" />

          <ThreeSmallUsps />

          <BirdWithMessageBox
            class="mt-[16px] mb-[-20px] lg:mt-[24px]"
            :bird-location="isMobile ? 'top' : 'right'"
          >
            <div>
              <h4 class="text-lg font-medium mb-[2px]" v-html="$trans('UpgradePage.BirdWithMessageBoxTitle')" />
              <p class="text-base" v-html="$trans('UpgradePage.BirdWithMessageBoxContent')" />
            </div>
          </BirdWithMessageBox>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.UpgradeToViewHousePage {
  background: rgb(234,234,235);
}
</style>
