<script setup>
import NumberOfMatchesBox from '@js/Components/NumberOfMatchesBox/NumberOfMatchesBox.vue'
import { ref } from 'vue'
import { getMatchesPerWeekOfAllSearchProfilesOfCurrentUser } from '@js/App/Api/MatchesPerWeek'

const isLoading = ref(true)
const matchesPerWeek = ref(0)

const recalculateAllMatchesPerWeek = async () => {
  isLoading.value = true
  const response = await getMatchesPerWeekOfAllSearchProfilesOfCurrentUser()
  matchesPerWeek.value = response.matchesPerWeek
  isLoading.value = false
}

recalculateAllMatchesPerWeek()

defineExpose({ recalculateAllMatchesPerWeek })

</script>
<template>
  <NumberOfMatchesBox
    :is-loading="isLoading"
    :is-for-all-search-profiles="true"
    :matches-per-week="matchesPerWeek"
  />
</template>
