<script setup>
import Select from '@js/Components/Forms/Select/Select.vue'
import { doPostRequest } from '@js/App/Api/Infra/ApiClient'
import { getLocale } from '@js/App/Utils/CountryAndLocale'
import { storeToRefs } from 'pinia'
import { useMainStore } from '@js/App/Stores/MainStore'

const { locale } = storeToRefs(useMainStore())

const setLocale = async (newLocale) => {
  await doPostRequest('/api/set-language', { new_locale: newLocale })

  locale.value = newLocale
}

</script>
<template>
  <div>
    <Select
      :model-value="getLocale()"
      :options="[
        {
          label: $trans('Common.Dutch'),
          value: 'nl'
        },
        {
          label: $trans('Common.English'),
          value: 'en'
        },
      ]"
      @update:model-value="newLocale => {
        setLocale(newLocale)
      }"
    >
      <template #label>
        {{ $trans('LanguageSwitcherDropdown.Language') }}
      </template>
    </Select>
  </div>
</template>
