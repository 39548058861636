<script setup>
import CitySelectUsingStore from '@js/Components/Forms/CitySelect/CitySelectUsingStore.vue'
import DistanceSelect from '@js/Components/Forms/DistanceSelect/DistanceSelect.vue'
import NeighbourhoodSelectUsingStore from '@js/Components/Forms/NeighbourhoodSelect/NeighbourhoodSelectUsingStore.vue'
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import { storeToRefs } from 'pinia'
import NumberOfMatchesBoxUseCurrentSearchProfile
  from '@js/Components/NumberOfMatchesBox/NumberOfMatchesBoxCurrentSearchProfile.vue'
import { COUNTRY_US, getCountry } from '@js/App/Utils/CountryAndLocale'

defineProps({
  errors: {
    type: Object,
    default: () => ({})
  }
})

const { searchProfile } = storeToRefs(useSearchProfileStore())
</script>
<template>
  <NumberOfMatchesBoxUseCurrentSearchProfile class="hidden lg:flex mt-5" />
  <CitySelectUsingStore
    dusk="city-select"
    :black="true"
    :text-input-margin-right="false"
    :error="errors.city"
    :disabled="getCountry() === COUNTRY_US"
    class="mt-4"
  />
  <NeighbourhoodSelectUsingStore
    v-show="searchProfile.locationSelector === 'neighbourhoods'"
    class="mt-4"
    :error="errors.neighbourhoods"
    @click:distance="searchProfile.locationSelector = 'distance'"
  />
  <DistanceSelect
    v-if="searchProfile.locationSelector === 'distance'"
    class="mt-4"
    :error="errors.distance"
    @click:neighbourhoods="searchProfile.locationSelector = 'neighbourhoods'"
  />
</template>
