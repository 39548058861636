<script setup>
import { ref } from 'vue'
import { useHasBeenInViewport } from '@js/App/Performance/HasBeenInViewport'
import { Vue3Lottie } from 'vue3-lottie'
import { isSSR } from '@js/App/Utils/Environment'

defineProps({
  url: String,
  lazyLoading: { type: Boolean, default: true }
})

const elementRef = ref(null)
const lottieRef = ref(null)
const { hasBeenInViewport } = useHasBeenInViewport(elementRef)
</script>
<template>
  <div
    v-if="!isSSR()"
    ref="elementRef"
    class="min-w-full min-h-[245px]"
  >
    <Vue3Lottie
      v-if="hasBeenInViewport || !lazyLoading"
      ref="lottieRef"
      :animation-link="url"
      background="transparent"
      :speed="1"
      style="width: 100%;"
      loop
      autoplay
    />
  </div>
</template>
