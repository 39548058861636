import { usePage } from '@inertiajs/vue3'
import { USER_TYPE_COMPANY_ADMIN, USER_TYPE_COMPANY_USER } from '@js/App/Api/User/User'

export const getEnvironment = () => {
  const page = usePage()
  return page?.props?.environment
}

export const isProduction = () => {
  return getEnvironment() === 'production'
}

export const isLocal = () => {
  return getEnvironment() === 'local'
}

export const isTesting = () => {
  return getEnvironment() === 'testing'
}

export const isStaging = () => {
  return getEnvironment() === 'staging'
}

export const isSSR = () => {
  return typeof window === 'undefined'
}

export const isB2BUser = () => {
  const page = usePage()
  const user = page.props.user
  if (!user) {
    return false
  }

  return user.user_type === USER_TYPE_COMPANY_USER || user.user_type === USER_TYPE_COMPANY_ADMIN
}
