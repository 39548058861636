<script setup>
import Icon from '@js/Components/Icon/Icon.vue'
import { ref } from 'vue'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'
import FreeAccountMessageCard from '@js/Components/MyRentbird/FreeAccountMessage/FreeAccountMessageCard.vue'
import CustomTransitionExpand from '@js/Components/Transitions/CustomTransitionExpand.vue'

const props = defineProps({
  canExpandOnMobile: {
    type: Boolean,
    default: true
  },
  canExpandOnDesktop: {
    type: Boolean,
    default: false
  },
  defaultIsExpanded: {
    type: Boolean,
    default: false
  },
  inactiveOnFreeAccount: {
    type: Boolean,
    default: false
  }
})

const isExpanded = ref(props.canExpandOnMobile || props.canExpandOnDesktop ? props.defaultIsExpanded : true)
const { isMobile } = useIsMobile()
</script>

<template>
  <div
    class="relative bg-white rounded px-[16px] py-[16px] lg:px-[32px] lg:py-[32px]"
    :class="{'lg:bg-opacity-20': inactiveOnFreeAccount}"
  >
    <div
      v-show="isExpanded || !isMobile"
      class="absolute z-10 h-full left-0 top-0 px-4 lg:px-8 flex items-center justify-center pointer-events-none"
    >
      <FreeAccountMessageCard
        v-if="inactiveOnFreeAccount"
      >
        <template #free-account-message>
          <slot name="free-account-message" />
        </template>
      </FreeAccountMessageCard>
    </div>

    <div
      class="flex items-center justify-between text-purple-900 transition-colors"
      :class="{
        'hover:cursor-pointer hover:text-pink-500': canExpandOnMobile && !inactiveOnFreeAccount,
        'lg:hover:cursor-auto lg:hover:text-purple-900': !canExpandOnDesktop,
        'lg:text-opacity-20': inactiveOnFreeAccount
      }"
      @click="() => {
        if (canExpandOnMobile && !inactiveOnFreeAccount) {
          isExpanded = !isExpanded
        }
      }"
    >
      <h4 class="text-xl lg:text-3xl font-medium">
        <slot name="title" />
      </h4>
      <Icon
        v-show="canExpandOnMobile && !$slots['title-mobile-right'] && !inactiveOnFreeAccount"
        name="caret-down"
        icon-width="w-[28px] h-[28px]"
        class=""
        :class="{
          'rotate-180': isExpanded,
          'lg:hidden': !canExpandOnDesktop
        }"
      />
      <div v-if="inactiveOnFreeAccount" class="lg:hidden flex text-sm text-gray-300 relative top-[2px]">
        <Icon name="lock" icon-width="w-[16px] h-[16px] mr-[4px]" />
        <div>{{ $trans('MyRentbirdCard.UpgradeRequired') }}</div>
      </div>
      <div v-if="$slots['title-mobile-right']" class="block lg:hidden">
        <slot name="title-mobile-right" />
      </div>
      <div class="lg:block hidden">
        <slot name="title-desktop-right" />
      </div>
    </div>

    <div
      class="lg:pt-[16px]"
      :class="{
        'text-purple-900 text-opacity-20': inactiveOnFreeAccount,
        'pt-[12px]': !inactiveOnFreeAccount
      }"
    >
      <div v-show="!inactiveOnFreeAccount">
        <slot name="body-that-is-always-shown" />
      </div>
      <CustomTransitionExpand>
        <div v-show="isExpanded || !isMobile">
          <slot />
        </div>
      </CustomTransitionExpand>
    </div>
  </div>
</template>
