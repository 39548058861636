<script setup>
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import ProgressBar from '@js/Components/Forms/ProgressBar/ProgressBar.vue'
import NotificationChannelSelect from '@js/Components/Forms/NotificationChannelSelect/NotificationChannelSelect.vue'
import { ref } from 'vue'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import { router } from '@inertiajs/vue3'
import OnboardingNotificationChannelSelect
  from '@js/Components/MyRentbird/Onboarding/MandatoryOnboarding/OnboardingNotifcationChannelSelect/OnboardingNotificationChannelSelect.vue'
import { reloadPropsFromServer } from '@js/App/Api/Infra/ServerData'
import { useUser } from '@js/App/Api/User/User'

const notificationChannelSelectRef = ref(null)

const { user } = useUser()
const internalNotificationChannel = ref(user.value.notificationChannel)

const onClickNext = async () => {
  const isSuccess = await notificationChannelSelectRef.value.internalSaveNotifcationChannel()
  if (!isSuccess) { return }

  const nextStep = internalNotificationChannel.value === 'mobile' ? 9 : 10
  console.dir({ channel: internalNotificationChannel.value })

  router.get(getLocalizedUrl('my-rentbird.onboarding', { step: nextStep }))
}

</script>
<template>
  <div>
    <div class="lg:flex justify-between items-center">
      <h1 class="text-[#151227] font-bold text-2xl lg:text-4xl">
        {{ $trans('MandatoryOnboardingStep7.Title') }}
      </h1>
      <div class="lg:w-[20%]">
        <ProgressBar color="green" :progress="90" :show-percentage="true" />
      </div>
    </div>
    <MyRentbirdCard class="mt-[16px] lg:mt-[20px]" :can-expand-on-mobile="false" :default-is-expanded="true">
      <OnboardingNotificationChannelSelect
        ref="notificationChannelSelectRef"
        @update:notification-channel="newValue => internalNotificationChannel = newValue"
      />

      <div class="lg:flex justify-end">
        <Button
          tag="button"
          icon-right="arrow-right"
          :big="true"
          :full-width-on-mobile="true"
          class="mt-[20px] lg:mt-[32px]"
          @click="onClickNext"
        >
          {{ $trans('Common.Next') }}
        </Button>
      </div>
    </MyRentbirdCard>
  </div>
</template>
