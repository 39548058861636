<script setup>
import KeyImage from '@images/icons/key.png'
import LightningImage from '@images/icons/lightning.svg'
import AfterBlockItem from '@js/Components/Marketing/BeforeAndAfterRentbirdSection/AfterBlockItem.vue'
import Image from '@js/Components/Image/Image.vue'
import RentbirdLogo from '@js/Components/Logos/RentbirdLogo/RentbirdLogo.vue'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'

const { isMobile } = useIsMobile()
</script>
<template>
  <div class=" relative w-full rounded-md border border-purple-700 p-8 bg-purple-700 text-white">
    <h4 class="text-xl font-medium mb-4 lg:mb-[6px] w-full flex flex-wrap  lg:text-3xl">
      <span>
        {{ $trans('AfterBlock.Title') }}
      </span>
      <RentbirdLogo class="pl-3 relative top-[-6px] lg:top-[-7px]" :href="false" :size="isMobile ? 'xs' : 'md'" />
    </h4>
    <AfterBlockItem class="mb-4 lg:mb-6">
      {{ $trans('AfterBlock.Item1') }}
    </AfterBlockItem>
    <AfterBlockItem class="mb-4 lg:mb-6">
      {{ $trans('AfterBlock.Item2') }}
    </AfterBlockItem>
    <AfterBlockItem class="mb-4 lg:mb-6">
      {{ $trans('AfterBlock.Item3') }}
    </AfterBlockItem>
    <AfterBlockItem class="mb-4 lg:mb-6">
      {{ $trans('AfterBlock.Item4') }}
    </AfterBlockItem>
    <AfterBlockItem class="mb-0">
      {{ $trans('AfterBlock.Item5') }}
    </AfterBlockItem>

    <Image
      :src="KeyImage"
      class="key absolute w-[170px] top-[-60px] right-[-50px] lg:w-[270px] lg:top-[-100px] lg:right-[-80px]"
    />
    <Image
      :src="LightningImage"
      class="absolute w-[54px] top-[180px] right-[-10px] lg:w-[80px] lg:top-[220px] lg:right-[-25px]"
    />
  </div>
</template>
<style lang="scss">
.key {
  transform: rotate(174deg);

  @media screen and (min-width: 769px) {
    transform: rotate(174deg);
  }
}
</style>
