<script setup>
import { usePaymentStore } from '@js/App/Stores/PaymentStore'
import { storeToRefs } from 'pinia'
import { onMounted } from 'vue'
import { formatPrice } from '@js/App/Plugins/Formatting'
import Button from '@js/Components/Forms/Button/Button.vue'
import { onClickPricingCardPayButton } from '@js/App/Api/Payments'
import { trackSubscriptionSelection } from '@js/App/Tracking/Tracking'
import PricingCardAlternativeTabButtonBForFooter
  from '@js/Components/PricingCard/PricingCardAlternativeB/PricingCardAlternativeTabButtonBForFooter.vue'

const paymentStore = usePaymentStore()
const { selectedPrice, availablePrices } = storeToRefs(usePaymentStore())

const setSelectedPrice = (price) => {
  paymentStore.removeCoupon()
  selectedPrice.value = price

  trackSubscriptionSelection(selectedPrice.value.plan)
}
onMounted(() => {
  setSelectedPrice(0)
})
</script>
<template>
  <div class="w-full bg-purple-900 text-white p-6 rounded-md">
    <h4 class="text-white text-base lg:text-xl">
      {{ $trans('PricingCardAlternativeB.Title') }}
    </h4>
    <div class="flex-row justify-between items-center mb-4">
      <PricingCardAlternativeTabButtonBForFooter
        v-for="(price,index) of availablePrices.map(item => item)"
        :key="price.plan"
        :index="index"
        :is-active="selectedPrice?.plan === price.plan"
        @click="setSelectedPrice(price)"
      >
        <template #title>
          {{ $trans(price.numberOfMonths > 1 ? 'PricingCardSimple.Months' : 'PricingCardSimple.MonthsSingle', {
            months: price.numberOfMonths}
          ) }}
        </template>

        <template #price>
          {{ formatPrice(availablePrices[index].amountPerMonth) }}
          {{ $trans('PricingCardAlternative.PerMonth') }}
        </template>
        <template #discount>
          <div
            class="text-xs lg:text-base lg:font-medium"
            :class="{
              'text-success': index === 0,
              'text-[#AFC429]': index === 1,
              'text-danger-soft': index === 2,
            }"
          >
            {{ $trans('PricingCardSimple.Discount', { discount: price.discount || 0 }) }}
          </div>
        </template>
      </PricingCardAlternativeTabButtonBForFooter>
    </div>

    <div class="block lg:flex lg:flex-row lg:items-center justify-start">
      <div
        class="flex justify-center lg:min-w-[50%]"
      >
        <Button
          class="w-full mt-2"
          :full-width-on-desktop="true"
          :full-width-on-mobile="true"
          :big="true"
          :disabled="!selectedPrice"
          icon-right="arrow-right"
          @click="onClickPricingCardPayButton"
        >
          {{ $trans('PricingCardAlternative.SelectPlan') }}
        </Button>
      </div>
      <div class="text-white text-xs text-center mx-2 mt-2 lg:text-sm lg:ml-4">
        {{ $trans('PricingCardAlternative.DisclaimerText') }}
      </div>
    </div>
  </div>
</template>
