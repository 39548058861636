<script setup>
import { ref } from 'vue'
import DanielleTextbox from '@js/Components/MyRentbird/Offboarding/PersonTextBox/PersonTextBox.vue'
import TextArea from '@js/Components/Forms/TextArea/TextArea.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import CustomTransitionExpand from '@js/Components/Transitions/CustomTransitionExpand.vue'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'
import Tabs from '@js/Components/Forms/Tabs/Tabs.vue'

const props = defineProps({
  showKim: {
    type: Boolean,
    default: true
  },
  responseLetterTiny: {
    type: String,
    default: ''
  },
  responseLetterLarge: {
    type: String,
    default: ''
  },
  error: {
    type: String,
    default: ''
  }
})

const tipsIsExpanded = ref(false)
const { isMobile } = useIsMobile()

const internalResponseLetterLarge = ref(props.responseLetterLarge.replaceAll('\\n', '\n'))
const internalResponseLetterTiny = ref(props.responseLetterTiny.replaceAll('\\n', '\n'))

defineEmits(['update:response-letter-tiny', 'update:response-letter-large'])
</script>
<template>
  <div>
    <div class="lg:flex lg:flex-row-reverse">
      <DanielleTextbox v-if="showKim" :light="true" class="mb-[24px] lg:ml-[12px]" max-width-on-desktop="lg:w-[400px]">
        <div
          class="flex justify-between items-center hover:cursor-pointer hover:text-pink-500 lg:hover:cursor-auto lg:hover:text-purple-900 mb-[6px]"
          @click="() => {
            if (!isMobile) { return }
            tipsIsExpanded = !tipsIsExpanded
          }"
        >
          <div class="font-medium">
            💡 Tips van Kim
          </div>
          <Icon
            v-if="isMobile"
            name="caret-down"
            class="transition-all"
            :class="{
              'rotate-180': tipsIsExpanded
            }"
            icon-width="w-[24px] h-[24px]"
          />
        </div>
        <CustomTransitionExpand>
          <div v-if="tipsIsExpanded || !isMobile" v-html="$trans('ResponseLetterPage.Tips')" />
        </CustomTransitionExpand>
      </DanielleTextbox>

      <div class="flex-grow lg:mr-[12px]">
        <Tabs
          :tabs="[
            { label: $trans('ResponseLetterView.TabRegular'), value: 'regular'},
            { label: $trans('ResponseLetterView.TabTiny'), value: 'tiny'}
          ]"
        >
          <template #regular>
            <TextArea
              class="!h-auto lg:text-lg"
              :error="error"
              :rows="20"
              :model-value="internalResponseLetterLarge"
              @update:model-value="newValue => $emit('update:response-letter-large', newValue)"
            />
          </template>
          <template #tiny>
            <TextArea
              class="!h-auto lg:text-lg"
              :error="error"
              :rows="20"
              :model-value="internalResponseLetterTiny"
              @update:model-value="newValue => $emit('update:response-letter-tiny', newValue)"
            />
          </template>
        </Tabs>
      </div>
    </div>
  </div>
</template>
