<script setup>
import MyRentbirdNavbar from '@js/Components/MyRentbird/MyRentbirdNavbar/MyRentbirdNavbar.vue'
import BlogPostCard from '@js/Components/MyRentbird/BlogPostCard/BlogPostCard.vue'
import Container from '@js/Components/Container/Container.vue'
import SortButton from '@js/Components/Forms/Button/ButtonSort.vue'
import { computed, ref } from 'vue'
import { getQueryParameter, getQueryParameterBoolean, getQueryParameterInteger } from '@js/App/Utils/ReadQueryParams'
import FiltersInputAllHousesPage from '@js/Components/Forms/FiltersInputAllHousesPage/FiltersInputAllHousesPage.vue'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import { router } from '@inertiajs/vue3'
import {
  DEFAULT_DISTANCE,
  DEFAULT_FURNISHED,
  DEFAULT_MAX_RENT,
  DEFAULT_MIN_BEDROOMS,
  DEFAULT_MIN_SURFACE,
  DEFAULT_SORT_ASCENDING,
  DEFAULT_SORT_BY
} from '@js/App/Utils/FiltersInputAllHousesDefaultValues'
import { isEmpty } from '@js/App/Utils/IsEmpty'
import CitySelect from '@js/Components/Forms/CitySelect/CitySelect.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import AllHousesResults from '@js/Components/AllHouses/AllHousesResults.vue'

const props = defineProps({
  houses: {
    type: Object,
    default: () => ({})
  },
  city: {
    type: Object,
    default: () => ({})
  }
})

const parseCommaSeperatedArrayQueryParameter = (queryParam) => {
  if (!queryParam) {
    return []
  }

  return queryParam.split(',')
}

const filters = ref({
  city: props.city,
  distance: getQueryParameterInteger('distance', DEFAULT_DISTANCE),
  sortBy: getQueryParameter('sort_by', DEFAULT_SORT_BY),
  sortAscending: getQueryParameterBoolean('sort_ascending', DEFAULT_SORT_ASCENDING),
  maxRent: getQueryParameterInteger('max_rent', DEFAULT_MAX_RENT),
  minBedrooms: getQueryParameterInteger('min_bedrooms', DEFAULT_MIN_BEDROOMS),
  minSurface: getQueryParameterInteger('min_surface', DEFAULT_MIN_SURFACE),
  furnished: getQueryParameter('furnished', DEFAULT_FURNISHED),
  extraWishes: parseCommaSeperatedArrayQueryParameter(getQueryParameter('extra_wishes', '')),
  neighbourhoods: parseCommaSeperatedArrayQueryParameter(getQueryParameter('neighbourhoods', ''))
})

const reloadPageWithFilters = () => {
  const url = new URL(
    getLocalizedUrl(!isEmpty(filters.value.city)
      ? 'all-houses-in-city'
      : 'all-houses',
    { city: filters.value.city?.id }
    )
  )
  if (filters.value.distance !== DEFAULT_DISTANCE) {
    url.searchParams.set('distance', `${filters.value.distance}`)
  }
  if (filters.value.sortBy !== DEFAULT_SORT_BY) {
    url.searchParams.set('sort_by', filters.value.sortBy)
  }
  if (filters.value.sortAscending !== DEFAULT_SORT_ASCENDING) {
    url.searchParams.set('sort_ascending', `${filters.value.sortAscending}`)
  }
  if (filters.value.maxRent !== DEFAULT_MAX_RENT) {
    url.searchParams.set('max_rent', `${filters.value.maxRent}`)
  }
  if (filters.value.minBedrooms !== DEFAULT_MIN_BEDROOMS) {
    url.searchParams.set('min_bedrooms', `${filters.value.minBedrooms}`)
  }
  if (filters.value.minSurface !== DEFAULT_MIN_SURFACE) {
    url.searchParams.set('min_surface', `${filters.value.minSurface}`)
  }
  if (filters.value.furnished !== DEFAULT_FURNISHED) {
    url.searchParams.set('furnished', `${filters.value.furnished}`)
  }
  if (filters.value.extraWishes?.length > 0) {
    url.searchParams.set('extra_wishes', filters.value.extraWishes.join(','))
  }
  if (filters.value.neighbourhoods?.length > 0) {
    url.searchParams.set('neighbourhoods', filters.value.neighbourhoods.join(','))
  }

  router.visit(url, {
    preserveState: true,
    preserveScroll: true
  })
}
const onClickSortButton = (field) => {
  if (filters.value.sortBy === field) {
    filters.value.sortAscending = !filters.value.sortAscending
  } else {
    filters.value.sortBy = field
  }
  reloadPageWithFilters()
}

const isCityPage = computed(() => !isEmpty(props.city))

</script>
<template>
  <MyRentbirdNavbar
    :show-account-menu="true"
    :logo-is-clickable="true"
  />

  <Container>
    <BlogPostCard class="mt-[32px] lg:px-[32px]">
      <div class="lg:flex lg:justify-between lg:mb-[32px]">
        <h1 class="text-2xl lg:text-4xl font-medium text-purple-900 mb-[12px] lg:mb-0">
          {{ $trans(isCityPage ? 'AllHousesInCityPage.Title' : 'AllHousesPage.Title', {
            city: city?.name
          }) }}
        </h1>
        <div class="hidden lg:flex">
          <CitySelect
            v-if="!isCityPage"
            :show-label="false"
            :black="true"
            :use-landing-page-styling="false"
            @update:model-value="newCity => {
              filters = {
                ...filters,
                city: newCity,
              }
              reloadPageWithFilters()
            }"
          />

          <Button
            v-if="isCityPage"
            icon-right="back"
            :outline="true"
            :big="true"
            @click="() => {
              filters = {
                ...filters,
                distance: DEFAULT_DISTANCE,
                neighbourhoods: [],
                city: ''
              }
              reloadPageWithFilters()
            }"
          >
            {{ $trans('AllHousesPage.BackToAllHousesButtonLabel') }}
          </Button>
        </div>
      </div>

      <FiltersInputAllHousesPage
        :is-city-page="isCityPage"
        :filters="filters"
        class="mb-[12px] lg:mb-[32px]"
        @update:filters="newFilters => {
          filters = newFilters
        }"
        @should-reload-page="reloadPageWithFilters"
      />

      <div class="flex flex-row pt-3 lg:pt-0 items-center lg:justify-end">
        <div class="hidden lg:block pr-3">
          {{ $trans('AllHousesPage.SortBy') }}
        </div>
        <SortButton
          class="pr-1"
          :icon-right="filters.sortAscending ? 'arrow-up-simple' : 'arrow-down-simple'"
          :filter-active="filters.sortBy === 'created_at'"
          @click="onClickSortButton('created_at')"
        >
          {{ $trans('MatchHistoryPage.SortByLatestLabel') }}
        </SortButton>
        <SortButton
          class="pr-1"
          :icon-right="filters.sortAscending ? 'arrow-up-simple' : 'arrow-down-simple'"
          :filter-active="filters.sortBy === 'rent'"
          @click="onClickSortButton('rent')"
        >
          {{ $trans('MatchHistoryPage.SortByRentLabel') }}
        </SortButton>
        <SortButton
          :filter-actve="false"
          :icon-right="filters.sortAscending ? 'arrow-up-simple' : 'arrow-down-simple'"
          :filter-active="filters.sortBy === 'area'"
          @click="onClickSortButton('area')"
        >
          {{ $trans('MatchHistoryPage.SortBySurfaceLabel') }}
        </SortButton>
      </div>

      <AllHousesResults :houses="props.houses" />
    </BlogPostCard>
  </Container>
</template>
<style lang="scss">
.AllHousesPage {
  background: rgb(234,234,235);
}
</style>
