<script setup>

import { computed, onMounted, ref, watch } from 'vue'
import {
  DEFAULT_DISTANCE,
  DEFAULT_EXTRA_WISHES,
  DEFAULT_FURNISHED,
  DEFAULT_MAX_RENT,
  DEFAULT_MIN_BEDROOMS,
  DEFAULT_MIN_SURFACE, DEFAULT_NEIGHBOURHOODS,
  DEFAULT_SORT_ASCENDING,
  DEFAULT_SORT_BY
} from '@js/App/Utils/FiltersInputAllHousesDefaultValues'
import FiltersInputAllHousesPage from '@js/Components/Forms/FiltersInputAllHousesPage/FiltersInputAllHousesPage.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import CitySelect from '@js/Components/Forms/CitySelect/CitySelect.vue'
import { isEmpty } from '@js/App/Utils/IsEmpty'
import AllHousesResults from '@js/Components/AllHouses/AllHousesResults.vue'
import { doGetRequest } from '@js/App/Api/Infra/ApiClient'
import { getPlaceDetails } from '@js/App/Api/NeighbourhoodsOfCity'

const props = defineProps({
  initialHouses: {
    type: Object,
    default: () => ({})
  },
  cityFromServer: {
    type: Object,
    default: () => ({})
  },
  customTitle: {
    type: String,
    default: ''
  },
  showTitle: {
    type: Boolean,
    default: true
  },
  city: {
    type: String,
    default: ''
  },
  distance: {
    type: Number,
    default: DEFAULT_DISTANCE
  },
  sortBy: {
    type: String,
    default: DEFAULT_SORT_BY
  },
  sortAscending: {
    type: Boolean,
    default: DEFAULT_SORT_ASCENDING
  },
  maxRent: {
    type: Number,
    default: DEFAULT_MAX_RENT
  },
  minBedrooms: {
    type: Number,
    default: DEFAULT_MIN_BEDROOMS
  },
  minSurface: {
    type: Number,
    default: DEFAULT_MIN_SURFACE
  },
  furnished: {
    type: String,
    default: DEFAULT_FURNISHED
  },
  extraWishes: {
    type: Array,
    default: DEFAULT_EXTRA_WISHES
  },
  neighbourhoods: {
    type: Array,
    default: DEFAULT_NEIGHBOURHOODS
  }
})

const setFromCityProp = async (cityId) => {
  if (!cityId) { return '' }
  const result = await getPlaceDetails(cityId)

  return result
}

const filters = ref({
  city: props.cityFromServer ? props.cityFromServer : '',
  page: 1,
  distance: props.distance,
  sortBy: props.sortBy,
  sortAscending: props.sortAscending,
  maxRent: props.maxRent,
  minBedrooms: props.minBedrooms,
  minSurface: props.minSurface,
  furnished: props.furnished,
  extraWishes: props.extraWishes,
  neighbourhoods: props.neighbourhoods
})

const filtersIsTheSameAsInitial = computed(() => {
  return filters.value.city?.id === props.city && filters.value.maxRent === props.maxRent && filters.value.minBedrooms === props.minBedrooms && filters.value.minSurface === props.minSurface &&
   filters.value.furnished === props.furnished && (filters.value.neighbourhoods || []).join('|') === (props.neighbourhoods || []).join('|') && (filters.value.extraWishes || []).join('|') === (props.extraWishes || []).join('|')
})

const isCityPage = computed(() => !isEmpty(filters.value.city))

const houses = ref(isEmpty(props.initialHouses) ? ({ data: [] }) : props.initialHouses)
const fetchHousesFromApi = async () => {
  const result = await doGetRequest('/api/houses', {
    city: filters.value?.city?.id || '',
    distance: filters.value?.distance,
    neighbourhoods: filters.value?.neighbourhoods || [],
    max_rent: filters.value?.maxRent || null,
    min_bedrooms: filters.value?.minBedrooms || 0,
    min_surface: filters.value?.minSurface || null,
    furnished: filters.value?.furnished || 'everything',
    extra_wishes: filters.value?.extraWishes || [],
    sort_by: filters.value?.sortBy || 'created_at',
    sort_ascending: filters.value?.sortAscending === undefined ? 'true' : filters.value.sortAscending,
    page: filters.value.page

  })
  houses.value = result?.json
}

watch(() => filters.value, fetchHousesFromApi)
onMounted(async () => {
  if (!filters.value.city && props.city) {
    filters.value.city = await setFromCityProp(props.city)
    await fetchHousesFromApi()
  }
})
</script>
<template>
  <div>
    <div v-if="showTitle" class="lg:flex lg:justify-between lg:mb-[32px]">
      <h1 v-if="customTitle && filtersIsTheSameAsInitial" class="text-2xl lg:text-4xl font-medium text-purple-900 mb-[12px] lg:mb-0">
        {{ customTitle }}
      </h1>
      <h1 v-else class="text-2xl lg:text-4xl font-medium text-purple-900 mb-[12px] lg:mb-0">
        {{ $trans(isCityPage ? 'AllHousesInCityPage.Title' : 'AllHousesPage.Title', {
          city: filters?.city?.name
        }) }}
      </h1>
      <div class="hidden lg:flex">
        <CitySelect
          v-if="!isCityPage"
          :show-label="false"
          :black="true"
          :use-landing-page-styling="false"
          @update:model-value="newCity => {
            filters = {
              ...filters,
              city: newCity,
            }
          }"
        />

        <Button
          v-if="isCityPage"
          icon-right="back"
          :outline="true"
          :big="true"
          @click="() => {
            filters = {
              ...filters,
              distance: DEFAULT_DISTANCE,
              neighbourhoods: [],
              city: ''
            }
          }"
        >
          {{ $trans('AllHousesPage.BackToAllHousesButtonLabel') }}
        </Button>
      </div>
    </div>

    <FiltersInputAllHousesPage
      :is-city-page="isCityPage"
      :filters="filters"
      class="mb-[12px] lg:mb-[32px]"
      @update:filters="newFilters => {
        filters = newFilters
      }"
    />

    <AllHousesResults
      :should-navigate-on-pagination="false"
      :houses="houses"
      @set-page="newPage => {
        filters = {
          ...filters,
          page: newPage
        }
      }"
    />
  </div>
</template>
