<script setup>
import RentbirdLogo from '@js/Components/Logos/RentbirdLogo/RentbirdLogo.vue'
import TrustpilotSmall from '@js/Components/Trustpilot/TrustpilotSmall.vue'
import LoadingSpinner from '@js/Components/Forms/LoadingSpinner/LoadingSpinner.vue'
import Container from '@js/Components/Container/Container.vue'

const currentYear = new Date().getFullYear()
</script>
<template>
  <footer>
    <div class="bg-white pb-10">
      <Container>
        <div class="pt-12 lg:pt-16 lg:flex lg:w-full lg:justify-between lg:items-center">
          <div>
            <RentbirdLogo :href="false" class="mb-9" />
            <div class="flex text-sm text-gray-600 relative">
              <div>
                &copy; {{ currentYear }} Rentbird
              </div>
              <div class="relative pl-4 top-[-2px]">
                <LoadingSpinner :extra-small="true" :show-text="false" />
              </div>
            </div>
          </div>
          <div class="lg:flex">
            <a :href="$getLocalizedUrl('privacy-policy')" class="block mb-4 text-sm text-gray-600 underline hover:cursor-pointer lg:mr-6">
              {{ $trans('Footer.Privacy') }}
            </a>
            <a :href="$getLocalizedUrl('terms')" class="block mb-7 text-sm text-gray-600 underline hover:cursor-pointer lg:mr-6">
              {{ $trans('Footer.Terms') }}
            </a>
          </div>

          <TrustpilotSmall :black="true" layout="vertical" />
        </div>
      </Container>
    </div>
  </footer>
</template>
