<script setup>

import Container from '@js/Components/Container/Container.vue'
import FeaturesSectionItem from '@js/Components/Marketing/FeaturesSection/FeaturesSectionItem.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { getLocale, LOCALE_NL } from '@js/App/Utils/CountryAndLocale'
import ImagePhoneHowItWorsNL from '@images/phone-how-it-works-nl.png'
import ImagePhoneHowItWorsEN from '@images/phone-how-it-works-en.png'
import Image from '@js/Components/Image/Image.vue'

const locale = getLocale()
</script>

<template>
  <Container class="lg:mt-20">
    <div class="flex flex-col lg:flex-row items-center justify-content-center mt-5 lg:mt-16">
      <div class="lg:px-8 py-4 lg:mr-6 w-3/5 lg:w-1/2 xl:min-w-[404px] xl:max-w-[404px]">
        <Image
          alt="phone image"
          :src="locale === LOCALE_NL ? ImagePhoneHowItWorsNL : ImagePhoneHowItWorsEN"
        />
      </div>
      <div class="pt-4 lg:p-8 flex flex-grow items-center">
        <div class="">
          <h3 class="text-lg lg:text-2xl">
            {{ $trans('FeaturesSection.Subtitle') }}
          </h3>
          <h2
            class="text-3xl lg:text-5xl text-blue-900 font-bold pt-1 lg:pt-2"
          >
            {{ $trans('FeaturesSection.Title') }}
          </h2>

          <div class="py-8">
            <FeaturesSectionItem class="mb-8" icon="bell" :title="$trans('FeaturesSectionItem1.Title')">
              {{ $trans('FeaturesSectionItem1.Subtitle') }}
            </FeaturesSectionItem>
            <FeaturesSectionItem class="mb-8" icon="search-slim" :title="$trans('FeaturesSectionItem2.Title')">
              {{ $trans('FeaturesSectionItem2.Subtitle') }}
            </FeaturesSectionItem>
            <FeaturesSectionItem class="mb-8" icon="search-buddy" :title="$trans('FeaturesSectionItem3.Title')">
              {{ $trans('FeaturesSectionItem3.Subtitle') }}
            </FeaturesSectionItem>
            <FeaturesSectionItem icon="email-fill" :title="$trans('FeaturesSectionItem4.Title')">
              {{ $trans('FeaturesSectionItem4.Subtitle') }}
            </FeaturesSectionItem>
          </div>
          <slot name="button" />
          <Button
            v-if="!$slots.button"
            tag="link"
            :href="$getLocalizedUrl('pricing')"
            :big="true"
            :outline="true"
            :full-width-on-mobile="true"
            icon-right="arrow-right"
          >
            {{ $trans('Footer.Pricing') }}
          </Button>
        </div>
      </div>
    </div>
  </Container>
</template>
