<script setup>
import { ref } from 'vue'
import TextInput from '@js/Components/Forms/TextInput/TextInput.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { useMainStore } from '@js/App/Stores/MainStore'
import { submitCouponCode } from '@js/App/Api/Coupons'
import ErrorMessage from '@js/Components/Forms/ErrorMessage/ErrorMessage.vue'
import { isEmpty } from '@js/App/Utils/IsEmpty'

const isOpen = ref(false)
const couponCode = ref('')

const errors = ref({})
const mainStore = useMainStore()
const submitCoupon = async () => {
  mainStore.startLoading()
  errors.value = {}

  const { errors: apiErrors } = await submitCouponCode(couponCode.value)
  errors.value = apiErrors

  if (isEmpty(errors.value)) {
    isOpen.value = false
  }

  mainStore.stopLoading()
}
</script>
<template>
  <div v-if="!isOpen" class="flex justify-end">
    <div role="button" class="text-sm lg:text-base text-blue-base hover:cursor-pointer hover:underline" @click="isOpen = true">
      {{ $trans('CouponInput.IHaveACouponText') }}
    </div>
  </div>
  <div v-else>
    <div class="flex justify-end mb-1">
      <div role="button" class="text-sm lg:text-base text-blue-base hover:cursor-pointer hover:underline" @click="isOpen = false">
        {{ $trans('CouponInput.CloseCouponText') }}
      </div>
    </div>
    <div class="flex">
      <TextInput
        v-model="couponCode"
        :show-error-border="!!errors.coupon"
        class="mr-1 flex-grow"
        :placeholder="$trans('CouponInput.InputPlaceholder')"
      />
      <Button icon-right="check" :outline="true" :big="true" @click="submitCoupon">
        {{ $trans('CouponInput.ApplyButtonLabel') }}
      </Button>
    </div>
    <ErrorMessage v-if="errors.coupon">
      {{ errors.coupon }}
    </ErrorMessage>
    <ErrorMessage v-if="errors.general">
      {{ errors.general }}
    </ErrorMessage>
  </div>
</template>
