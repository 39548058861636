<script setup>
import { usePaymentStore } from '@js/App/Stores/PaymentStore'
import { storeToRefs } from 'pinia'
import CouponInput from '@js/Components/PricingCard/Checkout/CouponInput.vue'
import CheckoutSingleLine from '@js/Components/PricingCard/Checkout/CheckoutSingleLine.vue'
import {
  onClickConfirmPaymentMethod,
  useStripeCheckoutForm
} from '@js/App/Api/Payments'
import CheckoutButtons from '@js/Components/PricingCard/Checkout/CheckoutButtons.vue'
import { computed, ref } from 'vue'
import ErrorMessage from '@js/Components/Forms/ErrorMessage/ErrorMessage.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import CheckoutLoadingCards from '@js/Components/PricingCard/Checkout/PaymentMethods/CheckoutLoadingCards.vue'
import Container from '@js/Components/Container/Container.vue'
import { getQueryParameter } from '@js/App/Utils/ReadQueryParams'
import { router } from '@inertiajs/vue3'
import CountryOfResidenceSelect from '@js/Components/Forms/CountryOfResidenceSelect/CountryOfResidenceSelect.vue'
import { COUNTRY_NL, getCountry } from '@js/App/Utils/CountryAndLocale'

const paymentStore = usePaymentStore()
const {
  selectedPrice, selectedPriceBeforeCouponWasApplied, isCouponApplied, couponCodeThatWasApplied, totalPriceDifferenceOfCoupon
} = storeToRefs(usePaymentStore())

const stripeElementWrapperRef = ref(null)
const countryOfResidence = ref(
  getCountry() === COUNTRY_NL ? 'NL' : 'US'
)
const { stripe, elements, showStripeElementLoader, error, isSpecialFreeCoupon, paymentIntent } = useStripeCheckoutForm(stripeElementWrapperRef, countryOfResidence)

const onClickBack = () => {
  paymentStore.removeCoupon()
  const onClickBackUrl = getQueryParameter('onClickBackUrl')
  if (onClickBackUrl) {
    router.visit(onClickBackUrl)
  } else {
    history.back()
  }
}

const errors = ref({})

const onClickConfirmPayment = async () => {
  const isFromMobile = getQueryParameter('isFromMobile', 'false') === 'true'
  const { errors: apiErrors } = await onClickConfirmPaymentMethod(stripe, elements, paymentIntent, isSpecialFreeCoupon, isFromMobile)
  if (apiErrors === undefined) {
    errors.value = {}
  } else {
    errors.value = apiErrors
  }
}

const removeCoupon = () => {
  paymentStore.removeCoupon()
}

const shouldShowCouponInput = computed(() => {
  // If number of months < 1 it means we have the special trail deal offer. Then we don't want to give coupon options.
  return selectedPrice.value?.numberOfMonths >= 1
})
</script>
<template>
  <div class="flex flex-col flex-grow justify-between lg:block mt-4 lg:mt-0">
    <Container class="lg:flex">
      <div class="lg:w-1/2 lg:pr-12 lg:pt-10">
        <h1 class="text-purple-700 font-medium text-2xl mb-4 lg:mb-6 lg:text-4xl">
          {{ $trans('Checkout.Title') }}
        </h1>

        <div class="text-gray-400 text-sm mb-2">
          {{ $trans('Checkout.Summary') }}
        </div>

        <!-- Checkout line -->
        <CheckoutSingleLine>
          <template #left>
            <div
              dusk="checkout-change"
              class="flex text-purple-900 hover:cursor-pointer hover:text-pink-500 transition-colors"
              @click="onClickBack"
            >
              <span v-if="selectedPrice?.numberOfMonths > 1">
                {{ $trans('PricingCardSimple.Months', { months: selectedPrice?.numberOfMonths}) }}
              </span>
              <span v-else-if="selectedPrice?.numberOfMonths === 1">
                {{ $trans('PricingCardSimple.MonthsSingle', { months: selectedPrice?.numberOfMonths}) }}
              </span>
              <span v-else>
                {{ $trans('PricingCardSimple.Days', { days: selectedPrice?.numberOfDays}) }}
              </span>
              <Icon name="edit" class="relative left-[8px] top-[2px] lg:top-[3px]" />
              <span class="text-sm relative left-[12px] top-[2px] lg:top-[4px]">{{ $trans('Checkout.Change') }}</span>
            </div>
          </template>
          <template #right>
            <div v-if="selectedPrice?.numberOfMonths >= 1">
              <span
                v-if="selectedPrice?.numberOfMonths > 1"
                class="text-gray-400 line-through pr-2"
              >
                {{ $formatPrice(2900) }}
              </span>
              <span class="text-purple-700">
                {{ $formatPrice(isCouponApplied
                  ? selectedPriceBeforeCouponWasApplied?.amountPerMonth
                  : selectedPrice?.amountPerMonth
                ) }}
              </span>
            </div>
            <div v-else>
              <span class="text-purple-700">
                {{ $formatPrice(selectedPrice?.amount) }}
              </span>
            </div>
          </template>
        </CheckoutSingleLine>

        <div v-if="isCouponApplied">
          <CheckoutSingleLine>
            <template #left>
              {{ $trans('PricingCardSimple.SubtTotal') }}
            </template>
            <template #right>
              <span class="text-purple-700">{{ $formatPrice(selectedPriceBeforeCouponWasApplied?.amount) }}</span>
            </template>
          </CheckoutSingleLine>

          <CheckoutSingleLine>
            <template #left>
              <div class="text-success">
                {{ $trans('PricingCardSimple.CouponCode', { code: couponCodeThatWasApplied }) }}
                <Icon
                  name="trash"
                  class="inline hover:cursor-pointer hover:text-pink-500 transition-colors text-purple-900"
                  icon-width="inline text-purple-900 relative top-[-2px] left-[1px]"
                  @click="removeCoupon"
                />
              </div>
            </template>
            <template #right>
              <span class="text-success&quot;">-{{ $formatPrice(totalPriceDifferenceOfCoupon) }}</span>
            </template>
          </CheckoutSingleLine>
        </div>

        <!-- Total line -->
        <div class="flex justify-between mb-5">
          <div class="text-base lg:text-lg font-medium text-purple-900">
            {{ $trans('Checkout.Total') }}
          </div>
          <div
            class="font-medium text-base lg:text-lg"
            :class="{
              'text-purple-900': selectedPrice?.amount > 0,
              'text-success': selectedPrice?.amount <= 0
            }"
          >
            {{ selectedPrice?.amount <= 0 ? $trans('Checkout.Free') : $formatPrice(selectedPrice?.amount) }}
          </div>
        </div>

        <CouponInput v-if="shouldShowCouponInput && !isCouponApplied" />
      </div>
      <div class="lg:w-1/2 lg:pl-12 lg:pt-14">
        <CountryOfResidenceSelect
          v-model="countryOfResidence"
        />

        <!-- Select payment method -->
        <div class="mt-6">
          <div v-show="!showStripeElementLoader && !isSpecialFreeCoupon" ref="stripeElementWrapperRef" class="mb-4" />
          <div v-show="showStripeElementLoader" class="mb-4">
            <CheckoutLoadingCards />
          </div>

          <ErrorMessage v-show="error" class="mb-8">
            {{ error }}
          </ErrorMessage>
        </div>

        <div class="hidden lg:block lg:mt-8">
          <p v-if="isSpecialFreeCoupon" class="text-base lg:text-lg text-purple-900 mb-2" v-html="$trans('Checkout.SpecialFreePaymentMessage')" />
          <CheckoutButtons
            v-show="(!showStripeElementLoader && !error) || isSpecialFreeCoupon"
            :errors="errors"
            @click:confirm="onClickConfirmPayment"
            @click:back="onClickBack"
          />
        </div>
      </div>
    </Container>
    <Container class="lg:hidden">
      <p v-if="isSpecialFreeCoupon" class="text-base lg:text-lg text-purple-900 mb-2" v-html="$trans('Checkout.SpecialFreePaymentMessage')" />
      <CheckoutButtons
        v-show="(!showStripeElementLoader && !error) || isSpecialFreeCoupon"
        :errors="errors"
        @click:confirm="onClickConfirmPayment"
        @click:back="onClickBack"
      />
    </Container>
  </div>
</template>
<style lang="scss">
.__PrivateStripeElementLoader {
  animation: 2s infinite normal glow;
  animation-timing-function: ease-in-out;
}
@keyframes glow {
  0% { opacity: .4 }
  45% { opacity: 1 }
  55% { opacity: 1 }
  100% { opacity: .4 }
}
</style>
