<script setup>
import NavMenu from '@js/Components/NavMenu/NavMenu.vue'
import TrustpilotSmall from '@js/Components/Trustpilot/TrustpilotSmall.vue'
import RentbirdLogo from '@js/Components/Logos/RentbirdLogo/RentbirdLogo.vue'
import Container from '@js/Components/Container/Container.vue'
import CityMap from '@js/Components/SearchProfileWizards/SearchProfileWizard/CityMap.vue'
import LandingPageNeighbourhoodSelect
  from '@js/Components/Forms/NeighbourhoodSelect/LandingPageNeighbourhoodSelect.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { storeToRefs } from 'pinia'
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import LandingPageDistanceSelect from '@js/Components/Forms/DistanceSelect/LandingPageDistanceSelect.vue'
import { ON_COMPLETE_REDIRECT_TO_UPGRADE_PAGE, showCreateSearchProfileWizard } from '@js/App/SearchProfile'
import { trans } from '@js/App/Plugins/Translations'
import { ref } from 'vue'
import ErrorMessage from '@js/Components/Forms/ErrorMessage/ErrorMessage.vue'
import MobileHeroBar from '@js/Components/Marketing/Heros/MobileHeroBar/MobileHeroBar.vue'

defineProps({
  showNavigation: {
    type: Boolean,
    default: true
  }
})

const error = ref('')

const { searchProfile } = storeToRefs(useSearchProfileStore())

const onClickStartSearch = () => {
  if (searchProfile.value.locationSelector === 'neighbourhoods' && searchProfile.value.neighbourhoods?.length <= 0) {
    error.value = trans('CityHeroSection.SelectNeighbourhoodError')
    return
  }

  error.value = ''

  showCreateSearchProfileWizard(ON_COMPLETE_REDIRECT_TO_UPGRADE_PAGE, 2)
}
</script>
<template>
  <div class="bg-purple-700 text-white mb-[140px]">
    <Container class="mb-[-140px] lg:mb-[-155px] pt-4 lg:pt-8 lg:pb-16">
      <NavMenu v-if="showNavigation" />
      <div v-else class="flex justify-center mb-8">
        <RentbirdLogo :href="false" />
      </div>

      <div
        class="block lg:flex pt-8"
      >
        <div class="lg:pr-8">
          <h6 class="mb-1 lg:mb-3 text-base lg:text-2xl">
            <slot name="subtitle" />
          </h6>
          <h1
            class="text-3xl font-medium mb-4 lg:text-4xl xl:text-6xl lg:mb-3"
          >
            <slot />
          </h1>

          <div class="lg:flex items-end">
            <LandingPageNeighbourhoodSelect
              v-show="searchProfile.locationSelector === 'neighbourhoods'"
              class="flex-grow"
              @click:distance="searchProfile.locationSelector = 'distance'"
            />
            <LandingPageDistanceSelect
              v-show="searchProfile.locationSelector === 'distance'"
              class="flex-grow"
              @click:neighbourhoods="searchProfile.locationSelector = 'neighbourhoods'"
            />

            <Button
              class="mt-2 lg:ml-2"
              :big="true"
              icon-right="arrow-right"
              :full-width-on-mobile="true"
              @click="onClickStartSearch"
            >
              {{ $trans('CityHeroSection.StartYourSearchButtonLabel') }}
            </Button>
          </div>
          <ErrorMessage v-if="!!error">
            {{ error }}
          </ErrorMessage>

          <div class="pt-6 flex justify-start lg:justify-start w-full">
            <TrustpilotSmall class="justify-center" :show-number-of-reviews-on-mobile="true" />
          </div>
        </div>
        <div class="block relative z-20 pt-4 lg:pt-0 lg:min-w-[300px] xl:min-w-[504px] h-[344px] lg:h-[510px]">
          <CityMap class="h-full" :use-landing-page-styling="true" />
        </div>
      </div>
    </Container>
    <MobileHeroBar />
  </div>
</template>
