<script setup>
import B2BLayout from '@js/Components/B2B/B2BLayout.vue'
import { useB2BOrganization } from '@js/App/Api/B2B/B2B'
import B2BCreateSearchProfileAndAccount
  from '@js/Components/B2B/B2BSearchProfileWizard/B2BCreateSearchProfileAndAccount.vue'
import { ziggyRoute } from '@js/App/Utils/ZiggyUrls'

const { organization } = useB2BOrganization()
</script>
<template>
  <B2BLayout
    :bread-crumbs="[
      {
        label: 'Users',
        url: ziggyRoute('b2b.home', { organization: organization })
      },
      {
        label: 'Create new user',
        url: ''
      },
    ]"
  >
    <template #title>
      Create new user
    </template>

    <B2BCreateSearchProfileAndAccount />
  </B2BLayout>
</template>
<style lang="scss">
.B2B-RegisterUserPage {
  background: rgb(234,234,235);
}
</style>
