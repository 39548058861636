<script setup>
import Button from '@js/Components/Forms/Button/Button.vue'
import Container from '@js/Components/Container/Container.vue'
import Image from '@js/Components/Image/Image.vue'
import { isSSR } from '@js/App/Utils/Environment'
import { onMounted, onUnmounted, ref } from 'vue'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'

defineProps({
  image: String
})

const { isMobile } = useIsMobile()

const getImageOffset = (width) => {
  if (isMobile.value) {
    return (width - 375) / 2
  }

  return width / 2 - 400
}

const windowWidth = ref(isSSR() ? 1024 : window.innerWidth)
const imageOffset = ref(getImageOffset(windowWidth.value))

const onResize = (event) => {
  windowWidth.value = window.innerWidth
  imageOffset.value = getImageOffset(windowWidth.value)
}
onMounted(() => {
  addEventListener('resize', onResize)
})

onUnmounted(() => {
  removeEventListener('resize', onResize)
})
</script>
<template>
  <div class="bg-white relative">
    <Container>
      <div class="pb-0 lg:pb-[4px] relative bg-white">
        <div class="pt-12 lg:flex pb-4 lg:pt-24">
          <div class="lg:pr-10">
            <h1
              class="text-3xl text-blue-900 font-bold lg:pt-0 mb-[-40px] lg:text-5xl lg:-mb-[28px] lg:w-[680px] lg:leading-[56px]"
              v-html="$trans('FourExplainerStepsSection.NewTitle')"
            />
          </div>
        </div>
      </div>
    </Container>

    <div class="block lg:flex lg:items-center">
      <div class="relative max-w-full lg:min-w-[50%] overflow-x-hidden ">
        <Image
          :src="image"
          :class="`min-w-[1080px] max-w-[1080px] relative`"
          :style="`right: ${665 - imageOffset}px;`"
        />
      </div>
      <div class="container mx-auto px-4 lg:ml-[40px]">
        <h4 class="text-3xl lg:text-5xl text-blue-900 font-bold -mt-[60px] mb-[24px] lg:w-[300px] xl:w-[500px] lg:max-w-full">
          {{ $trans('FourExplainerStepsSection.NewSubTitle') }}
        </h4>

        <slot name="button" />
        <Button
          v-if="!$slots.button"
          tag="link"
          :href="$getLocalizedUrl('how-it-works')"
          :big="true"
          :outline="true"
          class="mb-[8px] lg:mr-[12px]"
          :full-width-on-mobile="true"
          icon-right="arrow-right"
        >
          {{ $trans('FourExplainerStepsSection.HowItWorksButtonLabel') }}
        </Button>
        <Button
          v-if="!$slots.button"
          tag="link"
          :href="$getLocalizedUrl('pricing')"
          :big="true"
          :outline="true"
          :full-width-on-mobile="true"
          icon-right="arrow-right"
        >
          {{ $trans('FourExplainerStepsSection.ButtonLabel') }}
        </Button>
      </div>
    </div>
  </div>
</template>
