<script setup>
import BasicPageHeader from '@js/Components/Marketing/BasicPageHeader/BasicPageHeader.vue'
import TextInput from '@js/Components/Forms/TextInput/TextInput.vue'
import Container from '@js/Components/Container/Container.vue'
import PasswordInput from '@js/Components/Forms/PasswordInput/PasswordInput.vue'
import { onBeforeMount, reactive } from 'vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { router, usePage } from '@inertiajs/vue3'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import { ON_COMPLETE_REDIRECT_TO_UPGRADE_PAGE, showCreateSearchProfileWizard } from '@js/App/SearchProfile'
import Image from '@js/Components/Image/Image.vue'
import CurlImage from '@images/icons/curl.svg'
import BirdRealisticImage from '@images/icons/bird-realistic.png'
import KeyImage from '@images/icons/key.png'
import { isSSR } from '@js/App/Utils/Environment'

const props = defineProps({ errors: Object, fromMobile: Boolean })

const page = usePage()

const form = reactive({
  csrf: page.props.csrf,
  email: '',
  password: ''
})

onBeforeMount(() => {
  if (props.fromMobile) {
    if (isSSR()) { return }
    window.addEventListener(
      'CookiebotOnDialogInit',
      function (e) {
        if (window.Cookiebot.consent.stamp === 0 && this.top.location !== this.location) {
          window.Cookiebot.submitCustomConsent(!1, !1, !1)
          window.addEventListener(
            'CookiebotOnLoad',
            function (e) {
              window.Cookiebot.deleteConsentCookie()
            },
            false
          )
        }
      },
      false
    )
  }
})

const submit = async () => {
  await router.post('/login', form, {
    onSuccess: () => { window.location.href = getLocalizedUrl('my-rentbird') }
  })
}
</script>
<template>
  <BasicPageHeader :show-trustpilot-instead-of-login="true" :show-section-divider="false" />

  <div class="overflow-x-hidden relative top-[-2px] bg-purple-700">
    <Container class="relative pt-6 lg:pt-14 pb-[200px]">
      <form class="relative block w-[600px] max-w-full mx-auto" method="post" action="/login" @submit.prevent="submit">
        <Image :src="KeyImage" class="absolute left-[-166px] lg:left-[-178px] key-login top-[92px] w-[330px]" />
        <Image :src="BirdRealisticImage" class="absolute right-[-101px] lg:right-[-141px] login-bird top-[242px] w-[280px]" />
        <div class="relative rounded-md bg-purple-900 px-6 py-8  lg:px-10 lg:py-10">
          <Image :src="CurlImage" class="absolute right-[-26px] lg:right-[-10px] top-[-22px] lg:top-[-55px]" />

          <h2 class="text-white font-medium text-3xl mb-2 lg:text-4xl">
            {{ $trans('LoginPage.Title') }}
          </h2>
          <h4 class="text-white font-medium text-base mb-6 lg:text-lg">
            {{ $trans('LoginPage.Subtitle') }}
          </h4>

          <TextInput
            v-model="form.email"
            dusk="email"
            :error="errors.email"
            class="mb-4"
            :placeholder="$trans('LoginPage.EmailPlaceholder')"
          >
            <template #label>
              <span class="text-white">{{ $trans('LoginPage.EmailLabel') }}</span>
            </template>
          </TextInput>

          <PasswordInput
            v-model="form.password"
            dusk="password"
            :error="errors.password"
            class="mb-2"
            :show-create-password-hint="false"
            :placeholder="$trans('LoginPage.PasswordPlaceholder')"
          >
            <template #label>
              <span class="text-white">{{ $trans('LoginPage.PasswordLabel') }}</span>
            </template>
          </PasswordInput>

          <a
            class="block text-base text-blue-300 hover:underline transition-colors hover:cursor-pointer mb-7"
            :href="$getLocalizedUrl('password.request')"
          >
            {{ $trans('LoginPage.ForgotPasswordLink') }}
          </a>

          <Button
            dusk="submit-button"
            type="submit"
            :big="true"
            :full-width-on-mobile="true"
            :full-width-on-desktop="true"
            icon-right="arrow-right"
            class="mb-4"
          >
            {{ $trans('LoginPage.LoginButtonLabel') }}
          </Button>

          <div v-if="!fromMobile" class="text-base text-white text-center">
            {{ $trans('LoginPage.NoAccount') }}
            <span
              class="text-blue-300 hover:underline transition-colors hover:cursor-pointer"
              @click="showCreateSearchProfileWizard(ON_COMPLETE_REDIRECT_TO_UPGRADE_PAGE)"
            >
              {{ $trans('LoginPage.CreateSearchProfile') }}
            </span>
          </div>
        </div>
      </form>
    </Container>
  </div>
</template>
<style lang="scss">
.key-login {
  transform: rotate(42deg);
}
.login-bird {
  transform: scaleX(-1) rotate(-15deg);
}
.Auth-LoginPage {
  background: #28214B;
}
</style>
