<script setup>
import {
  REASON_FOUND_A_HOUSE_THROUGH_ANOTHER_CHANNEL, REASON_FOUND_A_HOUSE_TROUGH_AGENCY, REASON_FOUND_A_HOUSE_TROUGH_WAITING_LIST,
  REASON_FOUND_A_HOUSE_WITH_RENTBIRD, REASON_FOUND_STILL_SEARCHING_BUT_WANT_TO_CANCEL, REASON_OTHER
} from '@js/App/Api/User/Offboarding'
import OffboardingButton from '@js/Components/MyRentbird/Offboarding/OffboardingButton/OffboardingButton.vue'
import { useUser } from '@js/App/Api/User/User'

defineProps({
  userWantsToCancelWithFullRefund: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['clicked:reason'])

const clickedReason = (reason) => {
  emit('clicked:reason', reason)
}

const { user } = useUser()

</script>
<template>
  <div>
    <h4 class="text-white text-base mb-[8px] lg:text-3xl lg:mb-[24px] lg:font-medium">
      {{ $trans('StartPage.Title') }}
    </h4>

    <OffboardingButton
      class="text-left mb-[8px]"
      tag="button"
      @click="clickedReason(REASON_FOUND_A_HOUSE_WITH_RENTBIRD)"
    >
      <span v-html="$trans('StartPage.ButtonFoundAHouseWithRentbird')" />
    </OffboardingButton>
    <OffboardingButton
      class="text-left mb-[8px]"
      tag="button"
      @click="clickedReason(REASON_FOUND_A_HOUSE_TROUGH_AGENCY)"
    >
      <span v-html="$trans('StartPage.ButtonFoundHouseThroughAgency')" />
    </OffboardingButton>
    <OffboardingButton
      class="text-left mb-[8px]"
      tag="button"
      @click="clickedReason(REASON_FOUND_A_HOUSE_TROUGH_WAITING_LIST)"
    >
      <span v-html="$trans('StartPage.ButtonFoundHouseThroughWaitingList')" />
    </OffboardingButton>
    <OffboardingButton
      class="text-left mb-[8px]"
      tag="button"
      @click="clickedReason(REASON_FOUND_A_HOUSE_THROUGH_ANOTHER_CHANNEL)"
    >
      <span v-html="$trans('StartPage.ButtonFoundAHouseThroughAnotherWay')" />
    </OffboardingButton>
    <OffboardingButton
      class="text-left mb-[8px]"
      tag="button"
      @click="clickedReason(REASON_FOUND_STILL_SEARCHING_BUT_WANT_TO_CANCEL)"
    >
      <span
        v-html="$trans(
          user.hasRightToAFullRefund && !userWantsToCancelWithFullRefund
            ? 'StartPage.DontWantToAutoRenew'
            : 'StartPage.ButtonStillSearchingButWantToQuit'
        )"
      />
    </OffboardingButton>
    <OffboardingButton
      class="text-left mb-[8px]"
      tag="button"
      @click="clickedReason(REASON_OTHER)"
    >
      <span v-html="$trans('StartPage.ButtonDifferentReason')" />
    </OffboardingButton>
  </div>
</template>
