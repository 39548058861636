import { usePage } from '@inertiajs/vue3'
import { computed } from 'vue'
import { capitalizeFirstLetter } from '@js/App/Utils/StringFormatting'
import { getPriceFromDto } from '@js/App/Stores/PaymentStore'

export const USER_TYPE_NORMAL = 'normal'
export const USER_TYPE_COMPANY_ADMIN = 'company-admin'
export const USER_TYPE_COMPANY_USER = 'company-user'

/**
 * @returns {{
 *  userPersonalData: ComputedRef<{
 *    current_job: [],
 *    date_of_birth: {
 *        day: number,
 *        month: number,
 *        year: number,
 *    },
 *    gender: string,
 *    is_going_to_live_with: string,
 *    reason_for_moving: string,
 *    total_monthly_income: number,
 *  }>,
 *  user: ComputedRef<{
 *    enabledAutoRenew: boolean,
 *    numberOfSearchProfiles: (*|null),
 *    notificationChannel: (string|*),
 *    completedOnboardingSteps: (*|*[]),
 *    serverId: (*|boolean),
 *    firstNameFormatted: *,
 *    maxNumberOfSearchProfiles: (*|number),
 *    phone: (*|string),
 *    stripeId: boolean,
 *    avatarImage: (*|string),
 *    hasMadeAtLeastOnePayment: (*|boolean),
 *    isLoggedIn: boolean,
 *    lastNameFormatted: *,
 *    hasActiveSubscription: (*|boolean),
 *    email: (*|string),
 *    showInbetweenPage: boolean,
 *    isSubscribedToMarketingEmails: boolean,
 *    isSubscribedToSearchProfileEmails: boolean,
 *   }>
 * }}'
 */
export const useUser = () => {
  const page = usePage()

  const user = computed(() => getUserFromDto(page.props.user))

  const userPersonalData = computed(
    () => getUserPersonalDataFromDto(page.props.userPersonalData)
  )

  const userActivePayment = computed(
    () => {
      const payment = page.props.userActivePayment
      if (payment && payment.price) {
        payment.priceDto = getPriceFromDto(payment.price)
      }
      return payment
    }
  )

  return { user, userPersonalData, userActivePayment }
}

const getUserFromDto = userDto => ({
  serverId: userDto?.random_id || false,
  isLoggedIn: !!userDto,
  stripeId: !!userDto?.stripe_id,
  firstNameFormatted: capitalizeFirstLetter(userDto?.firstname || ''),
  lastNameFormatted: capitalizeFirstLetter(userDto?.lastname || ''),
  email: userDto?.email || '',
  phone: userDto?.phone || '',
  notificationChannel: userDto?.notification_channel || '',
  enabledAutoRenew: !!userDto?.auto_renew || false,
  numberOfSearchProfiles: userDto?.number_of_search_profiles || null,
  hasMadeAtLeastOnePayment: userDto?.has_made_at_least_one_payment || false,
  hasActiveSubscription: userDto?.has_active_subscription || false,
  searchBuddyContactAddress: userDto?.search_buddy_contact_address || null,
  avatarImage: userDto?.picture_url || '',
  maxNumberOfSearchProfiles: userDto?.max_search_profiles || 1,
  responseLetterTiny: userDto?.response_letters?.[0] || '',
  responseLetterLarge: userDto?.response_letters?.[1] || '',
  hasCreatedResponseLetter: userDto?.has_created_response_letter || false,
  showHouseDetailPage: userDto?.show_signup_letter_page || false,
  hasAppliedForHomedeals: userDto?.has_applied_for_homedeals || false,
  hasLeftFeedbackInTheLastMonth: userDto?.has_left_feedback_in_the_last_month || false,
  hasRightToAFullRefund: userDto?.has_right_to_a_full_refund || false,
  daysSinceFirstPayment: userDto?.days_since_first_payment,
  shouldShowAddOurWhatsappNumberMessage: userDto?.should_show_add_our_whatsapp_number_message || false,
  firstPaymentCreatedAt: userDto?.first_payment_created_at || null,
  hasMadeFirstPaymentToday: userDto?.has_made_first_payment_today || false,
  hasMobilePhoneLinked: userDto?.has_mobile_phone_linked || false,
  isSubscribedToMarketingEmails: userDto?.is_subscribed_to_marketing_emails,
  isSubscribedToHouseMatchEmails: userDto?.is_subscribed_to_house_match_emails,
  lastPaymentIsAppleInApp: userDto?.last_payment_is_apple_in_app,
  verhuisgids_completed_steps: userDto?.verhuisgids_completed_steps
})

const getUserPersonalDataFromDto = (userPersonalDataDto) => {
  const userPersonalData = { ...(userPersonalDataDto || {}) }

  if (!Array.isArray(userPersonalData.current_job)) {
    userPersonalData.current_job = []
  }

  if (userPersonalData.date_of_birth_day) {
    userPersonalData.date_of_birth = {
      day: userPersonalData.date_of_birth_day,
      month: userPersonalData.date_of_birth_month,
      year: userPersonalData.date_of_birth_year
    }
  }

  return userPersonalData
}
