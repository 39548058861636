<script setup>
import { storeToRefs } from 'pinia'
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import TextInput from '@js/Components/Forms/TextInput/TextInput.vue'
import PhoneNumberInput from '@js/Components/Forms/PhoneNumberInput/PhoneNumberInput.vue'
import CountryOfResidenceSelect from '@js/Components/Forms/CountryOfResidenceSelect/CountryOfResidenceSelect.vue'

defineProps({
  errors: {
    type: Object,
    default: () => ({})
  }
})

const { searchProfile } = storeToRefs(useSearchProfileStore())

</script>
<template>
  <div>
    <div class="lg:flex">
      <div class="lg:w-1/2 lg:pr-2">
        <TextInput
          v-model="searchProfile.rentbirdPlusFirstname"
          class="mb-[8px] lg:mb-0"
          :placeholder="$trans('SearchProfileWizard.FirstnamePlaceholder')"
          :error="errors.firstname"
        >
          <template #label>
            {{ $trans('SearchProfileWizard.Firstname') }}
          </template>
        </TextInput>
      </div>
      <div class="lg:w-1/2 lg:pl-2">
        <TextInput
          v-model="searchProfile.rentbirdPlusLastname"
          :placeholder="$trans('SearchProfileWizard.LastnamePlaceholder')"
          :error="errors.lastname"
        >
          <template #label>
            {{ $trans('SearchProfileWizard.Lastname') }}
          </template>
        </TextInput>
      </div>
    </div>

    <div class="block">
      <PhoneNumberInput
        v-model="searchProfile.rentbirdPlusPhone"
        :error="errors.phone"
        class="mt-[8px] lg:mt-[16px]"
        :require-validation="false"
      />
    </div>

    <div class="lg:flex lg:mt-[16px] mt-[8px]">
      <div class="lg:w-1/2 lg:pr-2">
        <TextInput
          v-model="searchProfile.rentbirdPlusCity"
          class="mb-[8px] lg:mb-0"
          :placeholder="$trans('StepPersonalDetail.CurrentCityPlaceholder')"
          :error="errors.city_of_residence"
        >
          <template #label>
            {{ $trans('StepPersonalDetail.CurrentCityLabel') }}
          </template>
        </TextInput>
      </div>
      <div class="lg:w-1/2 lg:pl-2">
        <CountryOfResidenceSelect
          v-model="searchProfile.rentbirdPlusCountry"
          :error="errors.country_of_residence"
        />
      </div>
    </div>

    <div class="block mt-[8px] lg:mt-[16px]">
      <TextInput
        v-model="searchProfile.rentbirdPlusHowToReachMe"
        :placeholder="$trans('StepPersonalDetail.HowToReachMeLabel')"
        :error="errors.how_to_reach_me"
      >
        <template #label>
          {{ $trans('StepPersonalDetails.HowToReachMePlaceholder') }}
        </template>
      </TextInput>
    </div>
  </div>
</template>
