import { doGetRequest } from '@js/App/Api/Infra/ApiClient'
import { onMounted, ref, watch } from 'vue'
import { isProduction } from '@js/App/Utils/Environment'

/**
 * Gets the available neighbourhoods in a city from the API.
 * @param city {Ref} The Vue ref city object.
 * @returns {{
 *   isLoading: Ref<UnwrapRef<boolean>>,
 *   hasErrors: Ref<UnwrapRef<boolean>>,
 *   neighbourhoods: Ref<UnwrapRef<{neighbourhoods: *[], city: null}>>,
 *   errors: Ref<UnwrapRef<*[]>>
 * }}
 */
export const useNeighbourhoodsInCity = (city) => {
  const isLoading = ref(true)
  const hasErrors = ref(false)
  const errors = ref([])
  const neighbourhoods = ref({ city: null, neighbourhoods: [] })
  const getNeighbourhoodsInCityFromApi = async () => {
    isLoading.value = true
    const response = await doGetRequest(`/api/places/${city.value.id}/neighbourhoods`, {
      include_coordinates: false
    }, isProduction())

    hasErrors.value = response.hasErrors || false
    errors.value = response.errors || []
    neighbourhoods.value = {
      cityId: city.value.id,
      neighbourhoods: response.json?.neighbourhoods || []
    }

    isLoading.value = false
  }

  onMounted(async () => {
    await getNeighbourhoodsInCityFromApi()
  })

  watch(city, async () => {
    await getNeighbourhoodsInCityFromApi()
  })

  return {
    isLoading, hasErrors, errors, neighbourhoods
  }
}

/**
 * Returns the coordinates of all the neighbourhoods from the API.
 * @param city The city to search for.
 * @returns {Promise<*[]>} All th eneighbourhoods with coordinates
 */
export const getNeighbourhoodsWithCoordinatesInCity = async (city) => {
  const cityId = (city.value || city).id

  const response = await doGetRequest(`/api/places/${cityId}/neighbourhoods`, {
    include_coordinates: true
  }, false)

  return response.json.neighbourhoods || []
}

export const getPlaceDetails = async (city) => {
  const cityId = typeof city === 'string' ? city : (city.value || city).id

  const response = await doGetRequest(`/api/places/${cityId}/neighbourhoods`, {
    include_coordinates: true
  }, false)

  return response.json?.place
}
