<script setup>
import { onUnmounted, onMounted, ref, watch } from 'vue'
import StepLabel from '@js/Components/Forms/StepLabel/StepLabel.vue'
import ThreeStepsExplainerStep from '@js/Components/Marketing/ThreeStepsAnimation/ThreeStepsExplainerStep.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import LottieAnimation from '@js/Components/LottieAnimation/LottieAnimation.vue'
import { useHasBeenInViewport } from '@js/App/Performance/HasBeenInViewport'
import { getLocale } from '@js/App/Utils/CountryAndLocale'
import Container from '@js/Components/Container/Container.vue'
import { getFullUrl } from '@js/App/Utils/getFullUrl'

const step = ref(1)
const elementRef = ref(null)

const locale = getLocale()
const { hasBeenInViewport } = useHasBeenInViewport(elementRef)

let stepInterval = null
onMounted(() => {
  watch(hasBeenInViewport, () => {
    stepInterval = setInterval(() => {
      step.value += 1
      if (step.value > 3) {
        step.value = 1
      }
    }, 7000)
  })
})

onUnmounted(() => {
  clearInterval(stepInterval)
})

const setStep = (newStep) => {
  step.value = newStep
  // After use interaction we disable the auto slide.
  clearInterval(stepInterval)
}

</script>
<template>
  <Container class="pb-20 lg:pb-[104px]">
    <div ref="elementRef" class="pt-16 lg:pt-[135px]">
      <div class="py-6 px-4 rounded-lg lg:py-10 lg:px-6" style="background-color: #F0F6FB;">
        <h5 class="color-purple-700 text-sm text-center lg:text-xl lg:mb-1">
          {{ $trans('ThreeStepsAnimation.Subtitle') }}
        </h5>
        <h3 class="color-purple-700 text-3xl font-medium text-center mb-4 lg:text-5xl">
          {{ $trans('ThreeStepsAnimation.Title') }}
        </h3>

        <div class="block lg:flex">
          <!-- 1, 2, 3 Steps label -->
          <div class="flex lg:hidden min-w-full lg:min-w-0 items-center justify-center mb-4">
            <StepLabel :active="step === 1" class="mr-4" @click="setStep(1)">
              1
            </StepLabel>
            <StepLabel :active="step === 2" class="mr-4" @click="setStep(2)">
              2
            </StepLabel>
            <StepLabel :active="step === 3" @click="setStep(3)">
              3
            </StepLabel>
          </div>

          <div class="lg:w-1/2 lg:min-w-1/2 lg:max-w-1/2 lg:pr-10 relative">
            <LottieAnimation
              class="top-0 left-0"
              :class="{'opacity-0': step !== 1, 'opacity-100': step === 1}"
              :url="getFullUrl(`/img/lottie-animations/how-it-works-step-1-${locale}.json`)"
            />
            <LottieAnimation
              class="absolute top-0 left-0"
              :class="{'opacity-0': step !== 2, 'opacity-100': step === 2}"
              :url="getFullUrl(`/img/lottie-animations/how-it-works-step-2-${locale}.json`)"
            />
            <LottieAnimation
              class="absolute top-0 left-0"
              :class="{'opacity-0': step !== 3, 'opacity-100': step === 3}"
              :url="getFullUrl(`/img/lottie-animations/how-it-works-step-3-${locale}.json`)"
            />
          </div>

          <div class="lg:w-1/2 lg:min-w-1/2 lg:max-w-1/2 lg:pl-10 lg:pt-10">
            <!-- 1, 2, 3 Steps label -->
            <div class="hidden lg:flex min-w-full lg:min-w-0 items-center mb-10">
              <StepLabel :active="step === 1" class="mr-4" @click="step = 1">
                1
              </StepLabel>
              <StepLabel :active="step === 2" class="mr-4" @click="step = 2">
                2
              </StepLabel>
              <StepLabel :active="step === 3" @click="step = 3">
                3
              </StepLabel>
            </div>

            <div>
              <ThreeStepsExplainerStep
                v-show="step === 1"
                :title="$trans('ThreeExplainerSteps.Step1Title')"
              >
                {{ $trans('ThreeExplainerSteps.Step1Text') }}
              </ThreeStepsExplainerStep>
              <ThreeStepsExplainerStep
                v-show="step === 2"
                :title="$trans('ThreeExplainerSteps.Step2Title')"
              >
                {{ $trans('ThreeExplainerSteps.Step2Text') }}
              </ThreeStepsExplainerStep>
              <ThreeStepsExplainerStep
                v-show="step === 3"
                :title="$trans('ThreeExplainerSteps.Step3Title')"
              >
                {{ $trans('ThreeExplainerSteps.Step3Text') }}
              </ThreeStepsExplainerStep>

              <slot name="button" />
              <Button
                v-if="!$slots.button"
                :big="true"
                :outline="true"
                :full-width-on-mobile="true"
                tag="link"
                :href="$getLocalizedUrl('how-it-works')"
                icon-right="arrow-right"
              >
                {{ $trans('ThreeExplainerSteps.ButtonLabel') }}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>
