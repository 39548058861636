<script setup>
import HeaderWithLogoAndTrustpilot from '@js/Components/HeaderWithLogoAndTrustpilot/HeaderWithLogoAndTrustpilot.vue'
import MissedMatchesMessageBox from '@js/Components/MissedMatchesMessageBox/MissedMatchesMessageBox.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { ref } from 'vue'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'
import BirdWithMessageBox from '@js/Components/BirdWithMessageBox/BirdWithMessageBox.vue'
import { usePage } from '@inertiajs/vue3'
import { getSearchProfileDto, getSearchProfileFromDto, useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import StepCreateAccount from '@js/Components/SearchProfileWizards/SearchProfileWizard/Steps/StepCreateAccount.vue'
import { useMainStore } from '@js/App/Stores/MainStore'
import { saveSearchProfileWizardStep } from '@js/App/Api/SearchProfile'
import { isEmpty } from '@js/App/Utils/IsEmpty'
import { trackCompleteRegistration } from '@js/App/Tracking/Tracking'
import { onCompletedSearchProfile } from '@js/App/SearchProfile'
import { storeToRefs } from 'pinia'

const { isMobile } = useIsMobile()

const page = usePage()
const searchProfile = page.props.searchProfile ? getSearchProfileFromDto(page.props.searchProfile) : null

const errors = ref({})
const firstname = ref('')
const lastname = ref('')
const email = ref('')
const password = ref('')

const mainStore = useMainStore()
const { actionAfterCompletion } = storeToRefs(useSearchProfileStore())

const clickedSubmit = async () => {
  if (mainStore.isLoading) { return }

  mainStore.startLoading()
  try {
    const searchProfileDto = getSearchProfileDto(searchProfile)
    const dataToSave = { ...searchProfileDto }
    dataToSave.create_account = true
    dataToSave.firstname = firstname.value
    dataToSave.lastname = lastname.value
    dataToSave.email = email.value
    dataToSave.password = password.value

    const { errors: apiErrors } = await saveSearchProfileWizardStep(dataToSave, 4)
    errors.value = apiErrors

    if (!isEmpty(apiErrors)) { return }

    await trackCompleteRegistration(email.value)
    await onCompletedSearchProfile(actionAfterCompletion.value)
  } finally {
    mainStore.stopLoading()
  }
}
</script>
<template>
  <HeaderWithLogoAndTrustpilot />

  <div class="container px-4 mx-auto lg:pt-7">
    <div class="flex items-center">
      <h1
        class="text-2xl mt-3 font-medium text-purple-700 lg:text-4xl lg:mb-5"
        :class="{
          'mb-3': searchProfile
        }"
      >
        {{ $trans('CreateAccountPage.TitleNotLoggedIn') }}
      </h1>
    </div>
    <div class="block lg:flex lg:mb-14">
      <div
        class="lg:w-1/2 lg:pr-3"
        :class="{
          'lg:hidden': !searchProfile
        }"
      >
        <MissedMatchesMessageBox
          v-if="searchProfile"
          :search-profile="searchProfile"
        />

        <div v-if="isMobile" class="bg-white px-4 lg:px-6 py-4 lg:py-6 mt-4 lg:mt-0  rounded-md lg:mb-10">
          <StepCreateAccount
            :errors="errors"
            :model-value="{
              firstname: firstname,
              lastname: lastname,
              email: email,
              password: password,
            }"
            @update:model-value="newValue => {
              firstname = newValue.firstname
              lastname = newValue.lastname
              email = newValue.email
              password = newValue.password
            }"
            @submit="clickedSubmit"
          />

          <Button
            class="mt-[10px]"
            :big="true"
            :full-width-on-mobile="true"
            :full-width-on-desktop="true"
            icon-right="check"
            @click="clickedSubmit"
          >
            {{ $trans('CreateAccountPage.CreateAccount') }}
          </Button>
          <div class="text-xs text-gray-600 text-center mt-[12px]">
            {{ $trans('SearchProfileWizard.CreateAccountBottomLabel') }}
          </div>

          <BirdWithMessageBox class="mt-[16px] mb-[-20px]">
            <div>
              <h4 class="text-lg font-medium mb-[2px]" v-html="$trans('UpgradePage.BirdWithMessageBoxTitle')" />
              <p class="text-base" v-html="$trans('UpgradePage.BirdWithMessageBoxContent')" />
            </div>
          </BirdWithMessageBox>
        </div>
      </div>

      <div
        class="lg:w-1/2 lg:block hidden"
        :class="{
          'lg:pl-3': searchProfile
        }"
      >
        <div class="bg-white rounded p-[32px] mb-[120px]">
          <StepCreateAccount
            :errors="errors"
            :model-value="{
              firstname: firstname,
              lastname: lastname,
              email: email,
              password: password,
            }"
            @update:model-value="newValue => {
              firstname = newValue.firstname
              lastname = newValue.lastname
              email = newValue.email
              password = newValue.password
            }"
            @submit="clickedSubmit"
          />

          <Button
            class="mt-[10px]"
            :big="true"
            :full-width-on-mobile="true"
            :full-width-on-desktop="true"
            icon-right="check"
            @click="clickedSubmit"
          >
            {{ $trans('CreateAccountPage.CreateAccount') }}
          </Button>
          <div class="text-xs text-gray-600 text-center mt-[12px]">
            {{ $trans('SearchProfileWizard.CreateAccountBottomLabel') }}
          </div>

          <BirdWithMessageBox
            bird-location="right"
            class="mt-[24px] mb-[-12px]"
          >
            <div>
              <h4 class="text-lg font-medium mb-[2px]" v-html="$trans('UpgradePage.BirdWithMessageBoxTitle')" />
              <p class="text-base" v-html="$trans('UpgradePage.BirdWithMessageBoxContent')" />
            </div>
          </BirdWithMessageBox>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.CreateAccountPage {
  background: rgb(234,234,235);
}
</style>
