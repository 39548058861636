<script setup>
import DarkPurpleThinModal from '@js/Components/Modals/DarkPurpleThinModal.vue'
import ButtonAppDownload from '@js/Components/Forms/Button/ButtonAppDownload.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import { isAndroidAppEnabled, isIphoneAppEnabled } from '@js/App/FeatureFlags'

const emit = defineEmits(['close'])

const close = () => {
  emit('close')
}
</script>
<template>
  <DarkPurpleThinModal
    :is-open="true"
    :show-top-bar="false"
    :padding="false"
    class="lg:w-[513px]"
  >
    <div class="bg-success px-6 lg:px-2.5 py-2 text-sm flex lg:text-base">
      <Icon name="check-circle" icon-width="w-[18px] mr-1 relative top-0.5 lg:w-5 lg:top-1" />
      <p>{{ $trans('ModallInstallOurApp.TopBar') }}</p>
    </div>
    <div class="py-6 px-6 lg:px-10">
      <h4 class="text-xl lg:text-3xl font-medium mb-1">
        {{ $trans('ModallInstallOurApp.Title') }}
      </h4>
      <p class="text-base lg:text-lg mb-4">
        {{ $trans('ModalInstallOurApp.Subtitle') }}
      </p>

      <div class="flex justify-between">
        <ButtonAppDownload
          v-if="isAndroidAppEnabled()"
          :is-android="true"
          class="mr-4"
          target="_blank"
          href="https://play.google.com/store/apps/details?id=io.rentbird&hl=nl&pli=1"
        />
        <ButtonAppDownload
          v-if="isIphoneAppEnabled()"
          :is-android="false"
          target="_blank"
          href="https://apps.apple.com/us/app/rentbird-find-rental-houses/id6468817766"
        />
      </div>
      <div class="flex justify-center">
        <button
          class="text-base text-blue-base hover:cursor-pointer hover:underline transition-all mt-6"
          @click="close"
        >
          {{ $trans('ModalInstallOurApp.ButtonLabel') }}
        </button>
      </div>
    </div>
  </DarkPurpleThinModal>
</template>
