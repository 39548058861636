<script setup>

import { computed, reactive, ref } from 'vue'
import { router, usePage } from '@inertiajs/vue3'
import { useB2BOrganization } from '@js/App/Api/B2B/B2B'
import TextInput from '@js/Components/Forms/TextInput/TextInput.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { ziggyRoute } from '@js/App/Utils/ZiggyUrls'

const page = usePage()
const user = computed(() => page.props.b2b_user)
const errors = computed(() => page.props.errors)

const form = reactive({
  firstname: user.value.firstname,
  lastname: user.value.lastname,
  email: user.value.email
})

const { organization } = useB2BOrganization()
const showSavedMessage = ref(false)
const submit = () => {
  router.post(
    ziggyRoute('b2b.user.details', {
      organization: organization.value,
      user: user.value.random_id
    }),
    form,
    {
      preserveScroll: true,
      preserveState: true,
      onSuccess: () => {
        showSavedMessage.value = true
        setTimeout(() => {
          showSavedMessage.value = false
        }, 2000)
      }
    }
  )
}
</script>
<template>
  <form @submit.prevent="submit">
    <div class="flex space-x-6 mb-4">
      <TextInput
        v-model="form.firstname"
        :error="errors.firstname"
        class="w-1/2"
        placeholder="Firstname"
      >
        <template #label>
          <span class="text-purple-900">Firstname</span>
        </template>
      </TextInput>
      <TextInput
        v-model="form.lastname"
        :error="errors.lastname"
        class="w-1/2"
        placeholder="Lastname"
      >
        <template #label>
          <span class="text-purple-900">Lastname</span>
        </template>
      </TextInput>
    </div>

    <TextInput
      v-model="form.email"
      :error="errors.email"
      placeholder="Email"
      class="mb-4"
    >
      <template #label>
        <span class="text-purple-900">Email</span>
      </template>
    </TextInput>

    <Button
      :big="true"
      :outline="true"
      :icon-right="showSavedMessage ? 'check' : 'save'"
      @click="submit"
    >
      <span v-if="showSavedMessage">Saved</span>
      <span v-else>Save changes</span>
    </Button>
  </form>
</template>
