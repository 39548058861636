<script setup>
import { Link } from '@inertiajs/vue3'
import { computed, ref } from 'vue'
import Icon from '@js/Components/Icon/Icon.vue'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'

const props = defineProps({
  isOpenByDefault: {
    type: Boolean,
    default: false
  },
  showAllLinks: {
    type: Boolean,
    default: false
  },
  links: {
    type: Array,
    default: () => ([])
  }
})

const partitionedLinks = computed(() => {
  const firstHalf = props.links.slice(0, props.links.length / 2)
  const secondHalf = props.links.slice(props.links.length / 2, props.links.length)
  return { firstHalf, secondHalf }
})

const isOpen = ref(props.isOpenByDefault)
const { isMobile } = useIsMobile()
</script>
<template>
  <div>
    <h6
      class="text-black font-medium text-base flex justify-between hover:cursor-pointer hover:text-blue-base lg:hover:text-black lg:hover:cursor-auto transition-colors mb-2"
      @click="isOpen = !isOpen"
    >
      <span>
        <slot name="title" />
      </span>
      <Icon
        v-if="isMobile"
        name="caret-down"
        class="relative top-[-4px] transition-transform"
        icon-width="w-[30px] h-[30px]"
        :class="{
          'rotate-180': isOpen,
        }
        "
      />
    </h6>
    <div
      v-show="isOpen || !isMobile"
      :class="{
        'mt-8': !(!!$slots.title)
      }"
    >
      <div v-for="link of partitionedLinks.firstHalf" :key="link.text">
        <component
          :is="link.tag === 'a' ? 'a' : Link"
          class="text-black text-base hover:cursor-pointer hover:text-blue-base transition-colors pb-3 block"
          :href="link.href"
          :target="link.target"
        >
          {{ link.text }}
        </component>
      </div>
      <div v-for="link of partitionedLinks.secondHalf" v-show="showAllLinks" :key="link.text">
        <component
          :is="link.tag === 'a' ? 'a' : Link"
          class="text-black text-base hover:cursor-pointer hover:text-blue-base transition-colors pb-3 block"
          :href="link.href"
          :target="link.target"
        >
          {{ link.text }}
        </component>
      </div>

      <div>
        <span
          class="
          text-black
          text-base
          hover:cursor-pointer
          hover:text-blue-base
          transition-colors
          pb-3
          block"
        >
          <slot name="showallcities" />
        </span>
      </div>
    </div>
  </div>
</template>
