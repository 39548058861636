<script setup>
import { ref } from 'vue'
import { Carousel, Slide } from 'vue3-carousel'
import { useHousesInCity } from '@js/App/Api/HousesInCity'
import ErrorMessage from '@js/Components/Forms/ErrorMessage/ErrorMessage.vue'
import HousesInCitySelect from '@js/Components/Marketing/TheRentalHackSection/HousesInCityCarousel/HousesInCitySelect.vue'
import BasicHouseCard from '@js/Components/HouseCards/BasicHouseCard/BasicHouseCard.vue'
import LoadingSpinner from '@js/Components/Forms/LoadingSpinner/LoadingSpinner.vue'
import Container from '@js/Components/Container/Container.vue'

const props = defineProps({
  selectedCity: {
    type: Object,
    default: () => ({
      id: 'amsterdam',
      name: 'Amsterdam'
    })
  },
  showCityDropdown: {
    type: Boolean,
    default: true
  }
})

const city = ref({
  ...props.selectedCity
})
const elementRef = ref(null)

const { isLoading, hasErrors, errors, houses, numberOfHouses, availablePlaces } = useHousesInCity(elementRef, city)

const breakpoints = {
  0: {
    itemsToShow: 1.2
  },
  400: {
    itemsToShow: 1.3
  },
  460: {
    itemsToShow: 1.5
  },
  540: {
    itemsToShow: 1.8
  },
  680: {
    itemsToShow: 2.1
  },
  740: {
    itemsToShow: 2.3
  },
  840: {
    itemsToShow: 2.6
  },
  1000: {
    itemsToShow: 3.1
  },
  1200: {
    itemsToShow: 3.8
  },
  1400: {
    itemsToShow: 4.7
  },
  1800: {
    itemsToShow: 5.6
  },
  2000: {
    itemsToShow: 6.1
  }
}

</script>
<template>
  <div ref="elementRef">
    <div v-if="hasErrors" class="flex justify-center">
      <Container>
        <ErrorMessage :big="true">
          {{ errors.general }}
        </ErrorMessage>
      </Container>
    </div>
    <div v-else>
      <Container>
        <h3 class="text-3xl lg:text-5xl text-purple-700 font-medium text-center mb-10">
          <span class="pr-3" v-html="$trans('HousesInCityCarousel.Title', { numberOfHouses: numberOfHouses })" />
          <HousesInCitySelect
            v-if="showCityDropdown"
            v-model="city"
            :options="availablePlaces"
          />
          <span v-else>{{ city.name }}</span>
        </h3>
      </Container>

      <Carousel
        v-if="!isLoading"
        :autoplay="2000"
        :wrap-around="true"
        :breakpoints="breakpoints"
        :pause-autoplay-on-hover="true"
      >
        <Slide v-for="house of houses" :key="house.id">
          <BasicHouseCard
            :name="house.name"
            :area="house.area"
            :bedrooms="house.bedrooms"
            :rental-price="house.rent"
            :image="house.image"
            :rental-agent="house.rental_agent_name"
          />
        </Slide>
      </Carousel>

      <div v-if="isLoading" class="h-[254px] flex justify-center items-center">
        <LoadingSpinner />
      </div>
    </div>
  </div>
</template>
