<script setup>
import ThreeExplainerStepsItem from '@js/Components/Marketing/ThreeExplainerStepsSection/ThreeExplainerStepsItem.vue'
import { ref, onMounted } from 'vue'
import Container from '@js/Components/Container/Container.vue'
import LottieAnimation from '@js/Components/LottieAnimation/LottieAnimation.vue'
import { getLocale } from '@js/App/Utils/CountryAndLocale'
import { getFullUrl } from '@js/App/Utils/getFullUrl'
import { isSSR } from '@js/App/Utils/Environment'

const step3ButtonRef = ref(null)
const dashedLineRef = ref(null)
const clipMargin = ref(null)
const locale = getLocale()

const handleDashedLineSize = () => {
  setInterval(() => {
    if (!step3ButtonRef.value) {
      return
    }
    const step3ButtonPosition = Math.round(step3ButtonRef.value.getBoundingClientRect().top)
    const dashedLinePosition = Math.round(dashedLineRef.value.getBoundingClientRect().bottom)
    clipMargin.value = (step3ButtonPosition - dashedLinePosition) + 'px'
  }, 200)
}

if (!isSSR()) {
  onMounted(() => {
    handleDashedLineSize()
  })
}
</script>

<template>
  <Container>
    <div class="relative lg:top-[-290px] mt-[150px] lg:mt-0 lg:mb-[-270px]">
      <div class="lg:flex lg:flex-row mb-4 lg:mb-16">
        <div class="text-3xl self-center text-center lg:right-8 mb-4 lg:m-16 order-1">
          <div
            ref="dashedLineRef"
            :style="{overflowClipMargin: clipMargin}"
            class="hidden lg:block mx-auto h-[326px] w-[4px] overflow-clip"
          >
            <svg
              width="
          4"
              height="1486"
              viewBox="0 0 4 1486"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="2"
                y1="-8.74228e-08"
                x2="2.00006"
                y2="1486"
                stroke="url(#paint0_linear_517_9816)"
                stroke-width="4"
                stroke-dasharray="4 4"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_517_9816"
                  x1="1.24578e-05"
                  y1="1486"
                  x2="0.0198113"
                  y2="2.63958e-07"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#E71C77" />
                  <stop offset="0.0001" stop-color="#BC145F" />
                  <stop offset="0.484375" stop-color="#F178AE" />
                  <stop offset="1" stop-color="#6F61B9" stop-opacity="0.1" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div class="relative">
            <div class="lg:hidden mx-auto top-[-270px] left-[49.5%] absolute">
              <svg width="4" height="301" viewBox="0 0 4 301" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line
                  x1="2"
                  y1="0.382812"
                  x2="2.00001"
                  y2="301.01"
                  stroke="url(#paint0_linear_517_9875)"
                  stroke-width="4"
                  stroke-dasharray="4 4"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_517_9875"
                    x1="-3.91217e-05"
                    y1="301.01"
                    x2="0.00076095"
                    y2="0.382813"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#D5A4CF" />
                    <stop offset="1" stop-color="#6F61B9" stop-opacity="0.1" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <button class="w-[3rem] h-[3rem] rounded-full text-white bg-[#D5A4CF] relative z-10">
              1
            </button>
          </div>
        </div>
        <div class="w-full flex justify-center lg:mt-[340px] order-2">
          <ThreeExplainerStepsItem :title="$trans('ThreeExplainerStepsSection.Title1')">
            {{ $trans('ThreeExplainerSteps.Content1') }}
          </ThreeExplainerStepsItem>
        </div>
        <div class="w-full h-full justify-center flex px-8 lg:mt-[340px] relative z-10 pb-16 pt-12 ">
          <LottieAnimation
            :lazy-loading="false"
            class="top-0 left-0"
            :url="getFullUrl(`/img/lottie-animations/how-it-works-step-1-${locale}.json`)"
          />
        </div>
      </div>

      <div class="lg:flex lg:flex-row mb-4 lg:mb-16">
        <div class="text-3xl self-center text-center lg:right-8 mb-4 lg:m-16 order-1">
          <div class="relative">
            <div class="lg:hidden mx-auto top-[-300px] left-[49.5%] absolute">
              <svg width="4" height="320" viewBox="0 0 4 320" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line
                  x1="2"
                  y1="1.04907e-07"
                  x2="1.99998"
                  y2="320"
                  stroke="url(#paint0_linear_517_9880)"
                  stroke-width="4"
                  stroke-dasharray="4 4"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_517_9880"
                    x1="-6.92825e-05"
                    y1="320"
                    x2="0.000868642"
                    y2="2.54595e-09"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#E4609B" />
                    <stop offset="1" stop-color="#D5A4CF" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <button class="w-[3rem] h-[3rem] relative rounded-full text-white bg-[#E4609B]">
              2
            </button>
          </div>
        </div>
        <div class="w-full flex justify-center order-2 lg:pt-8">
          <ThreeExplainerStepsItem :title="$trans('ThreeExplainerStepsSection.Title2')">
            {{ $trans('ThreeExplainerStepsSection.Content2') }}
          </ThreeExplainerStepsItem>
        </div>
        <div class="w-full h-full justify-center flex px-8 relative z-10 pb-16 pt-12 lg:pt-8 ">
          <LottieAnimation
            :lazy-loading="false"
            class="top-0 left-0"
            :url="getFullUrl(`/img/lottie-animations/how-it-works-step-2-${locale}.json`)"
          />
        </div>
      </div>

      <div class="lg:flex lg:flex-row mb-4 lg:mb-16">
        <div class="text-3xl self-center text-center lg:right-8 mb-4 lg:m-16 order-1">
          <div class="relative">
            <div class="lg:hidden mx-auto top-[-250px] left-[49.5%] absolute">
              <svg width="4" height="250" viewBox="0 0 4 307" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line
                  x1="1.90973"
                  y1="-8.34768e-08"
                  x2="1.90974"
                  y2="307"
                  stroke="url(#paint0_linear_517_9885)"
                  stroke-width="3.81945"
                  stroke-dasharray="3.82 3.82"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_517_9885"
                    x1="-3.9078e-05"
                    y1="307"
                    x2="0.00079532"
                    y2="2.16161e-09"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#BF1963" />
                    <stop offset="1" stop-color="#E4609B" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <button ref="step3ButtonRef" class="w-[3rem] h-[3rem] relative rounded-full text-white bg-[#BF1963]">
              3
            </button>
          </div>
        </div>
        <div class="w-full flex justify-center order-2 lg:pt-8">
          <ThreeExplainerStepsItem :title="$trans('ThreeExplainerStepsSection.Title3')">
            {{ $trans('ThreeExplainerStepsSection.Content3') }}
          </ThreeExplainerStepsItem>
        </div>
        <div class="w-full h-full justify-center flex px-8 relative pb-8 pt-12 lg:pt-8 z-10">
          <LottieAnimation
            :lazy-loading="false"
            class="top-0 left-0"
            :url="getFullUrl(`/img/lottie-animations/how-it-works-step-3-${locale}.json`)"
          />
        </div>
      </div>
    </div>
  </Container>
</template>
