<script setup>
import HeaderWithLogoAndTrustpilot from '@js/Components/HeaderWithLogoAndTrustpilot/HeaderWithLogoAndTrustpilot.vue'
import { usePage } from '@inertiajs/vue3'
import PricingCardStartTrial from '@js/Components/PricingCard/PricingCardStartTrial/PricingCardStartTrial.vue'
import UserReviewCard from '@js/Components/Marketing/UserReviewsSection/UserReviewCard.vue'
import { randomElement } from '@js/App/Utils/Arrays'
import ThreeSmallUsps from '@js/Components/Marketing/ThreeSmallUsps/ThreeSmallUsps.vue'
const page = usePage()

const userReviews = page?.props?.trustpilot?.reviews || []
const reviewToShow = randomElement(userReviews)

</script>
<template>
  <HeaderWithLogoAndTrustpilot />

  <div class="container px-4 mx-auto lg:pt-7">
    <h1
      class="text-2xl mt-6 font-medium text-purple-700 lg:text-4xl lg:mb-5"
    >
      {{ $trans('UpgradeStartTrial.Title') }}
    </h1>

    <div class="lg:flex lg:mt-[24px] pb-[100px]">
      <div class="lg:w-1/2 lg:pr-3">
        <div class="bg-white rounded px-[16px] py-[16px] mt-[16px] lg:mt-0 lg:px-[32px] lg:py-[32px]">
          <h4 class="text-lg text-purple-900 font-medium mb-[16px] lg:mb-[24px] lg:text-2xl">
            {{ $trans('UpgradeStartTrial.Subtitle') }}
          </h4>

          <PricingCardStartTrial />
        </div>
      </div>
      <div class="lg:w-1/2 lg:pl-3">
        <div class="bg-white rounded p-[16px] mt-[16px] lg:mt-0 lg:p-[32px]">
          <h4 class="text-lg text-purple-900 font-medium mb-[16px] lg:text-2xl">
            {{ $trans('UpgradeStartTrialPage.WhyRentbirdTitle') }}
          </h4>

          <ThreeSmallUsps />

          <div class="bg-white rounded p-[16px] mt-[16px] lg:mt-[24px] lg:p-[32px]">
            <div class="w-full flex justify-center">
              <UserReviewCard
                :name="reviewToShow.name"
                :image="reviewToShow.image"
                :trustpilot-url="`https://www.trustpilot.com/reviews/${reviewToShow.trustpilot_id}`"
                :rating="reviewToShow.rating"
              >
                <span v-html="reviewToShow.formatted_review_text" />
              </UserReviewCard>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.UpgradeStartTrialPage {
  background: rgb(234,234,235);
}
</style>
