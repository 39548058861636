import { usePage } from '@inertiajs/vue3'
import { useMainStore } from '@js/App/Stores/MainStore'
import { storeToRefs } from 'pinia'

export const LOCALE_NL = 'nl'
export const LOCALE_EN = 'en'

export const COUNTRY_NL = 'nl'

export const COUNTRY_US = 'us'

export const getLocale = () => {
  const mainStore = useMainStore()
  return mainStore.locale
}

export const useLocale = () => {
  const { locale } = storeToRefs(useMainStore())
  return { locale }
}

export const getReverseLocale = () => {
  const locale = getLocale()
  if (locale === LOCALE_NL) {
    return LOCALE_EN
  }
  return LOCALE_NL
}

export const getCountry = () => {
  const page = usePage()
  return page.props.country
}

export const getCurrency = () => {
  const page = usePage()
  return page.props.country === 'us' ? '$' : '€'
}

export const getDecimalSeperator = () => {
  const page = usePage()
  return page.props.country === 'us' ? '.' : ','
}
