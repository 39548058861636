<script setup>
import KarstenImage from '@images/team/karsten.jpg'
import StefImage from '@images/team/stef.jpg'
import DirkImage from '@images/team/dirk.jpg'
import QuintenImage from '@images/team/quinten.jpg'
import ThomasImage from '@images/team/thomas.jpg'

import TeamMemberCard from '@js/Components/Marketing/OurTeamCarousel/TeamMemberCard.vue'
import { Carousel, Slide } from 'vue3-carousel'
import { ref } from 'vue'
import { trans } from '@js/App/Plugins/Translations'
import { shuffle } from '@js/App/Utils/Arrays'
import Icon from '@js/Components/Icon/Icon.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import Container from '@js/Components/Container/Container.vue'
import BecomeABirdieCard from '@js/Components/Marketing/OurTeamCarousel/BecomeABirdieCard.vue'
const hasInteracted = ref(false)
const myCarousel = ref(null)

const breakpoints = {
  0: {
    itemsToShow: 1.07
  },
  400: {
    itemsToShow: 1.12
  },
  410: {
    itemsToShow: 1.16
  },
  420: {
    itemsToShow: 1.18
  },
  430: {
    itemsToShow: 1.22
  },
  440: {
    itemsToShow: 1.24
  },
  450: {
    itemsToShow: 1.26
  },
  460: {
    itemsToShow: 1.28
  },
  480: {
    itemsToShow: 1.34
  },
  500: {
    itemsToShow: 1.39
  },
  520: {
    itemsToShow: 1.43
  },
  530: {
    itemsToShow: 1.46
  },
  540: {
    itemsToShow: 1.48
  },
  560: {
    itemsToShow: 1.54
  },
  600: {
    itemsToShow: 1.64
  },
  640: {
    itemsToShow: 1.74
  },
  680: {
    itemsToShow: 1.88
  },
  720: {
    itemsToShow: 1.98
  },
  750: {
    itemsToShow: 2.07
  }
}

const teamMembers = shuffle([
  {
    image: KarstenImage,
    name: 'Karsten',
    role: trans('OurTeamCarousel.KarstenRole'),
    funFacts: [
      trans('OurTeamCarousel.KarstenFunFact1'),
      trans('OurTeamCarousel.KarstenFunFact2'),
      trans('OurTeamCarousel.KarstenFunFact3')
    ].filter(item => !!item)
  },
  {
    image: StefImage,
    name: 'Stef',
    role: trans('OurTeamCarousel.StefRole'),
    funFacts: [
      trans('OurTeamCarousel.StefFunFact1'),
      trans('OurTeamCarousel.StefFunFact2'),
      trans('OurTeamCarousel.StefFunFact3')
    ].filter(item => !!item)
  },
  {
    image: DirkImage,
    name: 'Dirk',
    role: trans('OurTeamCarousel.DirkRole'),
    funFacts: [
      trans('OurTeamCarousel.DirkFunFact1'),
      trans('OurTeamCarousel.DirkFunFact2'),
      trans('OurTeamCarousel.DirkFunFact3')
    ].filter(item => !!item)
  },
  {
    image: QuintenImage,
    name: 'Quinten',
    role: trans('OurTeamCarousel.QuintenRole'),
    funFacts: [
      trans('OurTeamCarousel.QuintenFunFact1'),
      trans('OurTeamCarousel.QuintenFunFact2'),
      trans('OurTeamCarousel.QuintenFunFact3')
    ].filter(item => !!item)
  },
  {
    image: ThomasImage,
    name: 'Thomas',
    role: trans('OurTeamCarousel.ThomasRole'),
    funFacts: [
      trans('OurTeamCarousel.ThomasFunFact1'),
      trans('OurTeamCarousel.ThomasFunFact2'),
      trans('OurTeamCarousel.ThomasFunFact3')
    ].filter(item => !!item)
  }
])

const previousSlide = () => {
  hasInteracted.value = true
  myCarousel.value.prev()
}
const nextSlide = () => {
  hasInteracted.value = true
  myCarousel.value.next()
}
</script>
<template>
  <div class="lg:hidden">
    <Carousel
      ref="myCarousel"

      :autoplay="hasInteracted ? 0 : 3000000"
      :wrap-around="true"
      :breakpoints="breakpoints"
      :pause-autoplay-on-hover="true"
      snap-align="center"
    >
      <Slide v-for="teamMember of teamMembers" :key="teamMember.name">
        <TeamMemberCard
          :image="teamMember.image"
          :name="teamMember.name"
          :role="teamMember.role"
          :fun-facts="teamMember.funFacts"
        />
      </Slide>
      <Slide key="become-a-birdie">
        <BecomeABirdieCard />
      </Slide>
    </Carousel>

    <Container class="mt-8">
      <div class="w-full flex justify-end">
        <Button
          class="mr-2"
          :outline="true"
          :big="true"
          tag="button"
          @click="previousSlide"
        >
          <Icon name="arrow-outline-left" />
        </Button>
        <Button
          :outline="true"
          :big="true"
          tag="button"
          @click="nextSlide"
        >
          <Icon name="arrow-outline-right" />
        </Button>
      </div>
    </Container>
  </div>
  <Container class="hidden lg:block">
    <div class="flex flex-wrap justify-center">
      <div v-for="teamMember of teamMembers" :key="teamMember.name">
        <TeamMemberCard
          class="mb-6 mx-3"
          :image="teamMember.image"
          :name="teamMember.name"
          :role="teamMember.role"
          :fun-facts="teamMember.funFacts"
        />
      </div>
      <div>
        <BecomeABirdieCard class="mb-6 mx-3" />
      </div>
    </div>
  </Container>
</template>
