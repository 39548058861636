import { isSSR } from '@js/App/Utils/Environment'

export const onReachBottomOfPage = (callback) => {
  if (isSSR()) { return }

  window.onscroll = (ev) => {
    if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight - 500) {
      callback()
    }
  }
}
