<script setup>
import ImageLight from '@images/icons/light.png'
import { storeToRefs } from 'pinia'
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import { computed } from 'vue'
import InfoMessageBox from '@js/Components/InfoMessageBox/InfoMessageBox.vue'
import Checkbox from '@js/Components/Forms/Checkbox/Checkbox.vue'
import CurrentJobSingleSelect from '@js/Components/Forms/LivingRequirementsInput/CurrentJobSingleSelect.vue'
import MoneyInput from '@js/Components/Forms/MoneyInput/MoneyInput.vue'
import { trans } from '@js/App/Plugins/Translations'

defineProps({
  errors: {
    type: Object,
    default: () => ({})
  }
})

const { searchProfile } = storeToRefs(useSearchProfileStore())

const showPartnerFields = computed(() => ['with_partner', 'with_partner_and_children'].includes(searchProfile.value.rentbirdPlusGoingToLiveWith))

</script>
<template>
  <div>
    <div>
      <CurrentJobSingleSelect
        class="mb-[16px]"
        :model-value="searchProfile?.rentbirdPlusCurrentJob"
        :error="errors.current_job"
        @update:model-value="newValue => {
          searchProfile.rentbirdPlusCurrentJob = newValue
        }"
      />
      <InfoMessageBox
        v-if="searchProfile.rentbirdPlusCurrentJob === 'loondienst' || searchProfile.rentbirdPlusCurrentJob === 'expat'"
        :image="ImageLight"
        color="gray"
      >
        <span v-html="$trans('StepIncome.LoondienstInfo')" />
      </InfoMessageBox>
      <InfoMessageBox
        v-else-if="searchProfile.rentbirdPlusCurrentJob === 'ondernemer'"
        :image="ImageLight"
        color="gray"
      >
        <span v-html="$trans('StepIncome.OndernemerInfo')" />
      </InfoMessageBox>
      <InfoMessageBox
        v-else-if="searchProfile.rentbirdPlusCurrentJob === 'student'"
        :image="ImageLight"
        color="gray"
      >
        <span v-html="$trans('StepIncome.StudentInfo')" />
      </InfoMessageBox>

      <MoneyInput
        v-model="searchProfile.rentbirdPlusIncome"
        :error="errors.income"
        class="mt-[16px] mb-[16px]"
        :placeholder="trans('StepIncom.IncomePlaceholder')"
      >
        <template #label>
          {{ $trans('StepIncome.IncomeLabel') }}
        </template>
      </MoneyInput>
    </div>
    <div v-if="showPartnerFields">
      <CurrentJobSingleSelect
        class="mb-[16px]"
        :model-value="searchProfile?.rentbirdPlusPartnerCurrentJob"
        :error="errors.partner_current_job"
        @update:model-value="newValue => {
          searchProfile.rentbirdPlusPartnerCurrentJob = newValue
        }"
      >
        <template #label>
          {{ $trans('StepIncome.PartnerCurrentJobLabel') }}
        </template>
      </CurrentJobSingleSelect>
      <InfoMessageBox
        v-if="searchProfile.rentbirdPlusPartnerCurrentJob === 'loondienst' || searchProfile.rentbirdPlusPartnerCurrentJob === 'expat'"
        :image="ImageLight"
        color="gray"
      >
        <span v-html="$trans('StepIncome.LoondienstInfo')" />
      </InfoMessageBox>
      <InfoMessageBox
        v-else-if="searchProfile.rentbirdPlusPartnerCurrentJob === 'ondernemer'"
        :image="ImageLight"
        color="gray"
      >
        <span v-html="$trans('StepIncome.OndernemerInfo')" />
      </InfoMessageBox>
      <InfoMessageBox
        v-else-if="searchProfile.rentbirdPlusPartnerCurrentJob === 'student'"
        :image="ImageLight"
        color="gray"
      >
        <span v-html="$trans('StepIncome.StudentInfo')" />
      </InfoMessageBox>

      <MoneyInput
        v-model="searchProfile.rentbirdPlusPartnerIncome"
        :error="errors.partner_income"
        class="mt-[16px] mb-[16px]"
        :placeholder="trans('StepIncom.IncomePlaceholder')"
      >
        <template #label>
          {{ $trans('StepIncome.PartnerIncomeLabel') }}
        </template>
      </MoneyInput>
    </div>

    <Checkbox
      class="mt-6"
      :model-value="searchProfile.rentbirdPlusGarantsteller"
      @update:model-value="newValue => {
        searchProfile.rentbirdPlusGarantsteller = newValue
      }"
    >
      {{ $trans('StepIncome.Garantsteller') }}
    </Checkbox>

    <Checkbox
      class="mt-6"
      :model-value="searchProfile.rentbirdPlusIHaveMoreThan50K"
      @update:model-value="newValue => {
        searchProfile.rentbirdPlusIHaveMoreThan50K = newValue
      }"
    >
      {{ $trans('StepIncome.IHaveMoreThan50K') }}
    </Checkbox>
  </div>
</template>
