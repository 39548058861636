<script setup>
import {
  RATING_BAD, RATING_GOOD, RATING_NEUTRAL
} from '@js/App/Api/User/Offboarding'
import OffboardingButton from '@js/Components/MyRentbird/Offboarding/OffboardingButton/OffboardingButton.vue'

const emit = defineEmits(['clicked:rating'])

const clickedRating = (rating) => {
  emit('clicked:rating', rating)
}
</script>
<template>
  <div>
    <h4 class="text-white text-base mb-[8px] lg:text-3xl lg:font-medium lg:mb-[24px]">
      {{ $trans('OffboardingRatingInput.Title') }}
    </h4>

    <div class="lg:flex">
      <OffboardingButton
        class="text-left mb-[8px] lg:mb-0 lg:mr-[4px]"
        tag="button"
        @click="clickedRating(RATING_BAD)"
      >
        <div class="text-center flex justify-center flex-wrap">
          <svg
            class="inline"
            xmlns="http://www.w3.org/2000/svg"
            width="101"
            height="100"
            viewBox="0 0 101 100"
            fill="none"
          >
            <path d="M50.4987 91.6673C73.5106 91.6673 92.1654 73.0125 92.1654 50.0007C92.1654 26.9888 73.5106 8.33398 50.4987 8.33398C27.4868 8.33398 8.83203 26.9888 8.83203 50.0007C8.83203 73.0125 27.4868 91.6673 50.4987 91.6673Z" stroke="#FF0000" stroke-width="8.33333" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M67.1654 66.6673C67.1654 66.6673 60.9154 58.334 50.4987 58.334C40.082 58.334 33.832 66.6673 33.832 66.6673" stroke="#FF0000" stroke-width="8.33333" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M38 37.5H38.0417" stroke="#FF0000" stroke-width="8.33333" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M63 37.5H63.0417" stroke="#FF0000" stroke-width="8.33333" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <span class="min-w-full" v-html="$trans('OffboardingRatingInput.Bad')" />
        </div>
      </OffboardingButton>
      <OffboardingButton
        class="text-left mb-[8px] lg:mb-0  lg:mx-[4px]"
        tag="button"
        @click="clickedRating(RATING_NEUTRAL)"
      >
        <div class="text-center flex justify-center flex-wrap">
          <svg xmlns="http://www.w3.org/2000/svg" width="101" height="100" viewBox="0 0 101 100" fill="none">
            <path d="M50.4987 91.6673C73.5106 91.6673 92.1654 73.0125 92.1654 50.0007C92.1654 26.9888 73.5106 8.33398 50.4987 8.33398C27.4868 8.33398 8.83203 26.9888 8.83203 50.0007C8.83203 73.0125 27.4868 91.6673 50.4987 91.6673Z" stroke="#FFAD33" stroke-width="8.33333" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M33.832 62.5H67.1654" stroke="#FFAD33" stroke-width="8.33333" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M38 37.5H38.0417" stroke="#FFAD33" stroke-width="8.33333" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M63 37.5H63.0417" stroke="#FFAD33" stroke-width="8.33333" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <span class="min-w-full" v-html="$trans('OffboardingRatingInput.Neutral')" />
        </div>
      </OffboardingButton>
      <OffboardingButton
        class="text-left  lg:ml-[4px]"
        tag="button"
        @click="clickedRating(RATING_GOOD)"
      >
        <div class="text-center flex justify-center flex-wrap">
          <svg xmlns="http://www.w3.org/2000/svg" width="101" height="100" viewBox="0 0 101 100" fill="none">
            <path d="M50.4987 91.6673C73.5106 91.6673 92.1654 73.0125 92.1654 50.0007C92.1654 26.9888 73.5106 8.33398 50.4987 8.33398C27.4868 8.33398 8.83203 26.9888 8.83203 50.0007C8.83203 73.0125 27.4868 91.6673 50.4987 91.6673Z" stroke="#0FBA80" stroke-width="8.33333" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M33.832 58.334C33.832 58.334 40.082 66.6673 50.4987 66.6673C60.9154 66.6673 67.1654 58.334 67.1654 58.334" stroke="#0FBA80" stroke-width="8.33333" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M38 37.5H38.0417" stroke="#0FBA80" stroke-width="8.33333" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M63 37.5H63.0417" stroke="#0FBA80" stroke-width="8.33333" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <span class="min-w-full" v-html="$trans('OffboardingRatingInput.Good')" />
        </div>
      </OffboardingButton>
    </div>
  </div>
</template>
