import { getCountry, getLocale } from '@js/App/Utils/CountryAndLocale'
import { getFromCache, isInCache, putInCache } from '@js/App/Performance/LocalCache'
import { usePage } from '@inertiajs/vue3'
import { trans } from '@js/App/Plugins/Translations'
import { isSSR } from '@js/App/Utils/Environment'

export const doGetRequest = async (url, params = {}, cacheResponse = false) => {
  if (!isSSR() && cacheResponse && isInCache({ method: 'GET', url, params })) {
    return getFromCache({ method: 'GET', url, params })
  }

  const response = await fetch(url + '?' + (new URLSearchParams(params)), {
    headers: {
      Accept: 'application/json',
      ...getDefaultHeadersToSend()
    }
  })

  if (response.status >= 400) {
    return {
      hasErrors: true,
      errors: await getApiErrorMessageFromResponse(response)
    }
  }

  const asJson = await response.json()

  const responseObject = {
    hasErrors: false,
    json: asJson
  }

  if (!isSSR() && cacheResponse) {
    putInCache({ method: 'GET', url, params }, responseObject)
  }

  return responseObject
}

export const doPostRequest = async (url, postData, cacheResponse = false) => {
  if (!isSSR() && cacheResponse && isInCache({ method: 'POST', url, postData })) {
    return getFromCache({ method: 'POST', url, postData })
  }

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(postData),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...getDefaultHeadersToSend()
    }
  })

  if (response.status >= 400) {
    return {
      hasErrors: true,
      errors: await getApiErrorMessageFromResponse(response)
    }
  }

  const asJson = await response.json()

  const responseObject = {
    hasErrors: false,
    json: asJson
  }

  if (!isSSR() && cacheResponse) {
    putInCache({ method: 'POST', url, postData }, responseObject)
  }

  return responseObject
}

/**
 * Returns the default headers to be added to every request
 * @returns {{"X-Locale": String, "X-Country": String}}
 */
export const getDefaultHeadersToSend = () => {
  const page = usePage()
  return {
    'X-Locale': getLocale(),
    'X-Country': getCountry(),
    'X-CSRF-TOKEN': page.props.csrf
  }
}

/**
 * Converts an API exception to a translated error object.
 * @param response The fetch response object
 * @returns {{}|{general: string}} An object containing the error values. For example:
 * {
 *     "email": "Please provide a valid email",
 *     "password": "The password must be 8 characters."
 * }
 */
export const getApiErrorMessageFromResponse = async (response) => {
  const errorObject = {}

  const asJson = await response.json()

  const errors = asJson?.errors
  if (!errors) {
    return {
      general: trans('Errors.General')
    }
  }

  for (const key of Object.keys(errors)) {
    errorObject[key] = errors[key][0]
  }

  return errorObject
}
