<script setup>
import TrustpilotStars from '@js/Components/Trustpilot/TrustpilotStars.vue'
import { usePage } from '@inertiajs/vue3'
import Image from '@js/Components/Image/Image.vue'
import TrustpilotImage from '@images/trustpilot-logo.svg'
import TrustpilotImageInverse from '@images/trustpilot-logo-white.svg'

defineProps({
  inverse: {
    type: Boolean,
    default: false
  }
})

const page = usePage()

const trustPilotRating = page.props?.trustpilot?.rating
const numberOfReviews = page.props?.trustpilot?.numberOfReviews
</script>
<template>
  <div
    class="border p-4 rounded-lg lg:px-10 lg:py-4 trustpilot"
    :class="{
      'border-purple-400': !inverse,
      'border-[rgb(136,133,154)]': inverse,
    }"
  >
    <div class="flex items-center justify-center mb-3 lg:flex-wrap lg:mb-4">
      <Image class="w-[97px] mr-4 lg:w-[200px] lg:mr-0" :src="inverse ? TrustpilotImageInverse : TrustpilotImage" />
      <TrustpilotStars class="w-[131px] mr-4 mt-1 lg:w-[176px] lg:mt-4" :rating="trustPilotRating" />
      <span
        class="text-xs font-medium relative top-[3px] lg:text-base lg:relative lg:top-[8px]"
        :class="{
          'text-purple-800 ': !inverse,
          'text-white': inverse
        }"
      >{{ $formatNumber(trustPilotRating, 1) }}</span>
    </div>

    <div
      class="text-center text-sm"
      :class="{
        'text-purple-800': !inverse,
        'text-white': inverse
      }"
      v-html="$trans('TrustpilotBox.BasedOn', {
        url: 'https://nl.trustpilot.com/review/rentbird.nl',
        number: numberOfReviews
      })"
    />
  </div>
</template>
