<script setup>
import BasicPageHeader from '@js/Components/Marketing/BasicPageHeader/BasicPageHeader.vue'
import Container from '@js/Components/Container/Container.vue'
import { reactive, ref } from 'vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { router, usePage } from '@inertiajs/vue3'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import Image from '@js/Components/Image/Image.vue'
import CurlImage from '@images/icons/curl.svg'
import BirdRealisticImage from '@images/icons/bird-realistic.png'
import KeyImage from '@images/icons/key.png'
import Icon from '@js/Components/Icon/Icon.vue'

const page = usePage()

const form = reactive({
  csrf: page.props.csrf,
  email: '',
  password: ''
})

const showSuccessMessage = ref(false)

const submit = async () => {
  await router.post('/password/email', form, {
    onSuccess: () => {
      window.location.href = getLocalizedUrl('my-rentbird')
      showSuccessMessage.value = true
    }
  })
}
</script>
<template>
  <BasicPageHeader :show-trustpilot-instead-of-login="true" :show-section-divider="false" />
  <div class="overflow-clip relative">
    <Container class="relative pt-8 lg:pt-12 pb-[200px] lg:pb-[300px]">
      <form class="relative block w-[600px] max-w-full mx-auto" method="post" @submit.prevent="submit">
        <Image :src="KeyImage" class="absolute left-[-166px] lg:left-[-178px] key-login top-[92px] w-[330px]" />
        <Image :src="BirdRealisticImage" class="absolute right-[-101px] lg:right-[-141px] login-bird top-[242px] w-[280px]" />
        <div class="relative rounded-md bg-purple-900 h-[500px] lg:h-[524px] px-6 py-8  lg:px-10 lg:py-10">
          <Image :src="CurlImage" class="absolute right-[-40px] lg:right-[-10px] top-[-30px] lg:top-[-40px]" />

          <h2 class="flex text-white font-medium text-4xl mb-2 lg:text-4xl">
            <Icon name="check-circle-white" class="pr-3" />
            {{ $trans('PasswordRecovery.ResetSuccessTitle') }}
          </h2>
          <h4 class="text-white text-base mb-6 lg:text-lg">
            <span v-html="$trans('PasswordRecovery.ResetSuccessMessage')" />
          </h4>

          <a :href="$getLocalizedUrl('my-rentbird.home')">
            <Button
              tag="tag"
              :big="true"
              :full-width-on-mobile="true"
              :full-width-on-desktop="true"
              icon-right="arrow-right"
              class="mb-6"
            >
              {{ $trans('PasswordRecovery.ProceedToMyRentbird') }}
            </Button>
          </a>
        </div>
      </form>
    </Container>
  </div>
</template>
<style lang="scss">
.Auth-PasswordResetSuccessPage {
  background: #28214B;
}
.key-login {
  transform: rotate(42deg);
}
.login-bird {
  transform: scaleX(-1) rotate(-15deg);
}
.Auth-LoginPage {
  background: #28214B;
}
</style>
