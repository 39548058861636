<script setup>
import { onClickPricingCardPayButton } from '@js/App/Api/Payments'
import { getCurrency } from '@js/App/Utils/CountryAndLocale'
import Button from '@js/Components/Forms/Button/Button.vue'
import { onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { usePaymentStore } from '@js/App/Stores/PaymentStore'

onMounted(() => {
  const paymentStore = usePaymentStore()
  const { trialPrice, selectedPrice } = storeToRefs(paymentStore)

  if (trialPrice.value) {
    selectedPrice.value = trialPrice.value
  }
})
</script>
<template>
  <div class="bg-purple-900 text-white px-[40px] py-[40px] rounded-md w-[500px] max-w-full">
    <div class="text-base text-white text-center mb-[12px]">
      {{ $trans('PricingCardOneDollarTrialUS.StartTrialFor') }}
    </div>
    <div class="flex justify-center">
      <div class="flex items-center">
        <div
          class="text-9xl text-[120px] font-bold"
        >
          1
        </div>
        <div class="text-4xl text-[40px] font-bold flex flex-col h-full justify-end pl-[6px] relative top-[-15px]">
          <div class="relative top-[-4px]">
            {{ getCurrency() }}
          </div>
        </div>
      </div>
    </div>

    <div class="text-gray-400 text-center">
      {{ $trans('PricingCardOneDollarTrialUS.Disclaimer') }}
    </div>

    <div class="flex justify-center mt-[32px]">
      <Button
        class="w-full"
        :full-width-on-desktop="true"
        :full-width-on-mobile="true"
        :big="true"
        icon-right="arrow-right"
        @click="onClickPricingCardPayButton"
      >
        {{ $trans("PricingCardOneDollarTrialUS.ButtonLabel") }}
      </Button>
    </div>
  </div>
</template>
