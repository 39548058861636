<script setup>

import Icon from '@js/Components/Icon/Icon.vue'
import TextInput from '@js/Components/Forms/TextInput/TextInput.vue'
import Pagination from '@js/Components/Pagination/Pagination.vue'
import { computed } from 'vue'

defineProps({
  headers: {
    type: Array,
    default: () => ([
      {
        label: 'The label',
        value: 'the_value'
      }
    ])
  },
  searchQuery: {
    type: [String, null],
    default: null
  },
  sortBy: {
    type: [String, null],
    default: null
  },
  sortDirection: {
    type: [String, null],
    default: null
  },
  pagination: {
    type: Object,
    default: () => ({})
  }
})

defineEmits(['clicked:sortBy', 'update:searchQuery', 'update:sortDirection', 'update:sortBy'])

const baseUrl = computed(() => window.location.href.replaceAll(/(&|\?)page=\d+/gm, ''))
</script>
<template>
  <div>
    <div v-if="searchQuery !== null" class="flex mb-4">
      <TextInput
        :model-value="searchQuery"
        placeholder="Search..."
        @update:model-value="newSearchQuery => {
          $emit('update:searchQuery', newSearchQuery)
        }"
      >
        <template #icon>
          <Icon name="search" />
        </template>
      </TextInput>
    </div>
    <!-- Table header -->
    <table class="data-table text-base font-medium text-purple-900 w-full">
      <tr>
        <th
          v-for="(header, index) of headers"
          :key="header.value"
          class="text-left font-medium px-[16px] py-[16px] transition-colors"
          :class="{
            'bg-gray-50 bg-opacity-20': true,
            'rounded-tl-md': index === 0,
            'rounded-tr-md': index === headers.length - 1,
            'hover:cursor-pointer hover:text-pink-500': sortBy !== null && header.isSortable !== false,
            'text-pink-500': sortBy === header.value
          }"
          @click="() => {
            if (header.isSortable === false) {
              return
            }

            if (sortBy === header.value) {
              $emit('update:sortDirection', sortDirection === 'asc' ? 'desc' : 'asc')
            } else {
              $emit('update:sortBy', header.value)
              $emit('update:sortDirection', 'desc')
            }
          }"
        >
          <div class="flex items-center">
            {{ header.label }}
            <Icon
              v-if="sortBy === header.value"
              name="caret-down"
              icon-width="w-[18px] ml-1"
              class="transition-transform"
              :class="{
                'rotate-180 relative left-[3px]': sortDirection === 'asc'
              }"
            />
          </div>
        </th>
      </tr>

      <!-- Table body -->
      <slot />
    </table>

    <Pagination
      v-if="pagination?.last_page > 1"
      class="mt-8"
      :base-url="baseUrl"
      :current-page="pagination.current_page"
      :last-page="pagination.last_page"
    />
  </div>
</template>
<style lang="scss">
.data-table td {
  padding: 16px 16px;
  font-size: 16px;
  font-weight: normal;
  //background-color: rgba(234, 234, 235, .5);
}

.data-table tr {
  background-color: rgba(234, 234, 235, .3);
}
.data-table tr:nth-child(2n) {
  background-color: rgba(234, 234, 235, 0);
}
</style>
