<script setup>
import { ref } from 'vue'
import { saveUserPersonalData } from '@js/App/Api/User/UserPersonalData'
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import LivingRequirementsInput from '@js/Components/Forms/LivingRequirementsInput/LivingRequirementsInput.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { useUser } from '@js/App/Api/User/User'
import { reloadPropsFromServer } from '@js/App/Api/Infra/ServerData'

const { userPersonalData } = useUser()
const internalPersonalData = ref({ ...userPersonalData.value })

const errors = ref({})

const showSavedMessage = ref(false)

const onSaveDetails = async () => {
  errors.value = {}

  const { errors: apiErrors, hasErrors } = await saveUserPersonalData(internalPersonalData.value, [
    'current_job', 'is_going_to_live_with', 'total_monthly_income', 'reason_for_moving'
  ])
  if (hasErrors) {
    errors.value = apiErrors
    return
  }

  showSavedMessage.value = true
  reloadPropsFromServer()
}

</script>
<template>
  <MyRentbirdCard :can-expand-on-mobile="false">
    <template #title>
      {{ $trans('EditLivingRequirementsCard.Title') }}
    </template>
    <p class="text-base text-purple-900 mb-[24px]">
      {{ $trans('EditLivingRequirementsCard.Subtitle') }}
    </p>
    <LivingRequirementsInput v-if="internalPersonalData" v-model="internalPersonalData" :errors="errors" />

    <Button class="mt-[16px]" :icon-right="showSavedMessage ? 'check' : 'save'" :full-width-on-mobile="true" @click="onSaveDetails">
      <span v-if="!showSavedMessage">{{ $trans('Common.Save') }}</span>
      <span v-else>{{ $trans('Common.Saved') }}</span>
    </Button>
  </MyRentbirdCard>
</template>
