import { doPostRequest } from '@js/App/Api/Infra/ApiClient'
import { reloadPropsFromServer } from '@js/App/Api/Infra/ServerData'
import { useMainStore } from '@js/App/Stores/MainStore'
import { useUser } from '@js/App/Api/User/User'

export const saveNotificationChannel = async (errors, internalNotificationChannel) => {
  const mainStore = useMainStore()
  mainStore.startLoading()

  const { user } = useUser()
  try {
    errors.value = {}
    if (internalNotificationChannel.value === user.value.notificationChannel) {
      return true
    }

    const notificationResponse = await doPostRequest('/api/user/update-notification-channel', {
      notification_channel: internalNotificationChannel.value
    })

    if (notificationResponse.hasErrors) {
      errors.value = notificationResponse.errors
      return false
    }

    reloadPropsFromServer()

    return true
  } finally {
    mainStore.stopLoading()
  }
}
