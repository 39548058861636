<script setup>
import PricingHeroSection from '@js/Components/Marketing/Heros/PricingHeroSection/PricingHeroSection.vue'
import BeforeAndAfterRentbirdSection
  from '@js/Components/Marketing/BeforeAndAfterRentbirdSection/BeforeAndAfterRentbirdSection.vue'
import Footer from '@js/Components/Marketing/Footer/Footer.vue'
import CallToActionSection from '@js/Components/Marketing/CallToActionSection/CallToActionSection.vue'
import SectionDivider from '@js/Components/Marketing/SectionDivider/SectionDivider.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import FaqQuestion from '@js/Components/FaqQuestion/FaqQuestion.vue'
import Container from '@js/Components/Container/Container.vue'
import CallToActionSectionLoggedIn from '@js/Components/Marketing/CallToActionSection/CallToActionSectionLoggedIn.vue'

import { useUser } from '@js/App/Api/User/User'
import UserReviewsSectionAlternative
  from '@js/Components/Marketing/UserReviewsSectionAlternative/UserReviewsSectionAlternative.vue'

const { user } = useUser()

</script>
<template>
  <PricingHeroSection />

  <BeforeAndAfterRentbirdSection class="mt-[218px] lg:mt-[92px] mb-[80px] lg:mb-[104px]" />

  <UserReviewsSectionAlternative :use-hardcoded-reviews="true">
    <template #button>
      <Button
        class="mr-4"
        :outline="true"
        :big="true"
        icon-right="arrow-right"
        tag="link"
        :href="$getLocalizedUrl('reviews')"
      >
        {{ $trans('UserReviewsCarousel.ButtonLabel') }}
      </Button>
    </template>
  </UserReviewsSectionAlternative>

  <div class="pt-[60px] lg:pt-[105px] pb-[40px]">
    <Container>
      <p class="text-lg lg:text-2xl text-purple-500 lg:mb-1">
        {{ $trans('PricingPage.FaqSubtitle') }}
      </p>
      <h4 class="text-3xl text-[28px] lg:text-5xl font-medium text-purple-900 mb-3 lg:mb-8">
        {{ $trans('PricingPage.FaqTitle') }}
      </h4>
      <FaqQuestion class="mb-2 lg:mb-4">
        <template #title>
          {{ $trans('FaqQuestion.CommissionTitle') }}
        </template>
        <template #default>
          <span v-html="$trans('FaqQuestion.CommissionContent')" />
        </template>
      </FaqQuestion>

      <FaqQuestion class="mb-2 lg:mb-4">
        <template #title>
          {{ $trans('FaqQuestion.HowDoIQuitTitle') }}
        </template>
        <template #default>
          <span v-html="$trans('FaqQuestion.HowDoIQuitTitleContent')" />
        </template>
      </FaqQuestion>

      <FaqQuestion class="lg:mb-4">
        <template #title>
          {{ $trans('FaqQuestion.WhyWouldIPayTitle') }}
        </template>
        <template #default>
          <span v-html="$trans('FaqQuestion.WhyWouldIPayContent')" />
        </template>
      </FaqQuestion>

      <Button
        class="mt-4 lg:mt-6"
        :big="true"
        :outline="true"
        :full-width-on-mobile="true"
        tag="link"
        :href="$getLocalizedUrl('faq')"
        icon-right="arrow-right"
      >
        {{ $trans('PricingPage.FaqButtonLabel') }}
      </Button>
    </Container>
  </div>

  <SectionDivider :reversed="true" />
  <CallToActionSectionLoggedIn v-if="user.isLoggedIn" />
  <CallToActionSection v-else />

  <Footer />
</template>
