<script setup>
import { ref } from 'vue'
import { useHasBeenInViewport } from '@js/App/Performance/HasBeenInViewport'
import { isSSR } from '@js/App/Utils/Environment'

defineOptions({
  // This makes sure that the CSS class="" doesn't get put on the imageRef div, but only on the <img> tag.
  inheritAttrs: false
})

defineProps({
  src: {
    type: String
  },
  lazy: {
    type: Boolean,
    default: true
  }

})

const imageRef = ref(null)
const { hasBeenInViewport } = useHasBeenInViewport(imageRef, 750)
</script>
<template>
  <div v-if="isSSR() || !hasBeenInViewport" ref="imageRef" />

  <img
    v-if="!lazy || (hasBeenInViewport && !isSSR())"
    v-bind="$attrs"
    :src="src"
  >
</template>
