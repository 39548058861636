<script setup>
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import { ref } from 'vue'
import ProgressBar from '@js/Components/Forms/ProgressBar/ProgressBar.vue'
import { useUser } from '@js/App/Api/User/User'
import { router } from '@inertiajs/vue3'
import ResponseLetterView from '@js/Components/MyRentbird/ResponseLetter/ResponseLetterView.vue'
import { saveReponseLetters } from '@js/App/Api/User/ResponseLetters'
import ErrorMessage from '@js/Components/Forms/ErrorMessage/ErrorMessage.vue'
import InfoMessageBox from '@js/Components/InfoMessageBox/InfoMessageBox.vue'
import ImageLightBulb from '@images/icons/light.png'
import Icon from '@js/Components/Icon/Icon.vue'

const errors = ref({})

const { user } = useUser()

const internalResponseLetterTiny = ref(user.value.responseLetterTiny)
const internalResponseLetterLarge = ref(user.value.responseLetterLarge)

const onClickNext = async () => {
  const { errors: apiErrors } = await saveReponseLetters(internalResponseLetterTiny.value, internalResponseLetterLarge.value)
  errors.value = apiErrors

  router.get(getLocalizedUrl('my-rentbird.onboarding', { step: 8 }))
}

</script>
<template>
  <div>
    <div class="lg:flex justify-between items-center">
      <h1 class="text-[#151227] font-bold text-2xl lg:text-4xl">
        {{ $trans('MandatoryOnboardingStep6.Title') }}
      </h1>
      <div class="lg:w-[20%]">
        <ProgressBar color="green" :progress="70" :show-percentage="true" />
      </div>
    </div>
    <MyRentbirdCard class="mt-[16px] lg:mt-[20px]" :can-expand-on-mobile="false" :default-is-expanded="true">
      <InfoMessageBox color="gray" :image="ImageLightBulb" class="mb-[24px]">
        {{ $trans('MandatoryOnboardingStep6.Tip') }}
      </InfoMessageBox>
      <ResponseLetterView
        :show-kim="false"
        :response-letter-tiny="internalResponseLetterTiny"
        :response-letter-large="internalResponseLetterLarge"
        @update:response-letter-tiny="newValue => internalResponseLetterTiny = newValue"
        @update:response-letter-large="newValue => internalResponseLetterLarge = newValue"
      />

      <p class="text-[#A2A3A4] mt-1 text-center">
        {{ $trans('MandatoryOnboardingStep6.YouCanEditLater') }}
      </p>

      <ErrorMessage v-show="errors?.general">
        {{ errors?.general }}
      </ErrorMessage>
      <ErrorMessage v-show="errors?.response_letter_large">
        {{ errors?.response_letter_large }}
      </ErrorMessage>
      <ErrorMessage v-show="errors?.response_letter_tiny">
        {{ errors?.response_letter_tiny }}
      </ErrorMessage>

      <div class="flex flex-row w-full justify-end pt-[20px] lg:pt-[32px]">
        <Button
          tag="link"
          class=" lg:mb-0 mr-2"
          :big="true"
          :outline="true"
          :href="getLocalizedUrl('my-rentbird.onboarding', { step: 6 })"
        >
          <Icon name="arrow-left" />
        </Button>
        <Button
          tag="button"
          icon-right="arrow-right"
          :big="true"
          :full-width-on-mobile="true"
          class="flex-grow lg:flex-grow-0"
          @click="onClickNext"
        >
          {{ $trans('MandatoryOnboardingStep6.NextButtonLabel') }}
        </Button>
      </div>
    </MyRentbirdCard>
  </div>
</template>
