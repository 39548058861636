<script setup>
import HeaderWithLogoAndTrustpilot from '@js/Components/HeaderWithLogoAndTrustpilot/HeaderWithLogoAndTrustpilot.vue'
import MissedMatchesMessageBox from '@js/Components/MissedMatchesMessageBox/MissedMatchesMessageBox.vue'
import PricingCardSimple from '@js/Components/PricingCard/PricingCardSimple/PricingCardSimple.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { ref } from 'vue'
import ErrorMessage from '@js/Components/Forms/ErrorMessage/ErrorMessage.vue'
import { onClickPricingCardPayButton } from '@js/App/Api/Payments'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'
import Icon from '@js/Components/Icon/Icon.vue'
import BirdWithMessageBox from '@js/Components/BirdWithMessageBox/BirdWithMessageBox.vue'
import { usePage } from '@inertiajs/vue3'
import { getSearchProfileFromDto } from '@js/App/Stores/SearchProfileStore'
import { useUser } from '@js/App/Api/User/User'
import ThreeSmallUsps from '@js/Components/Marketing/ThreeSmallUsps/ThreeSmallUsps.vue'
import { hasNormalSubscriptionPricesAvailable, onlyHasTrialPriceAvailable } from '@js/App/Prices'
import PricingCardStartTrial from '@js/Components/PricingCard/PricingCardStartTrial/PricingCardStartTrial.vue'

const { user } = useUser()

const showSelectPriceError = ref(false)

const { isMobile } = useIsMobile()

const page = usePage()
const searchProfile = page.props.searchProfile ? getSearchProfileFromDto(page.props.searchProfile) : null
</script>
<template>
  <HeaderWithLogoAndTrustpilot />

  <div class="container px-4 mx-auto lg:pt-7">
    <div class="flex justify-between items-center">
      <h1
        class="text-2xl mt-3 font-medium text-purple-700 lg:text-4xl lg:mb-5"
        :class="{
          'mb-3': searchProfile
        }"
      >
        <span v-if="user.isLoggedIn">
          {{ $trans('UpgradePage.Title', { name: user.firstNameFormatted }) }}
        </span>
        <span v-else>
          {{ $trans('UpgradePage.TitleNotLoggedIn') }}
        </span>
      </h1>
      <a :href="$getLocalizedUrl('my-rentbird.home')" class="hidden hover:cursor-pointer lg:flex text-gray-600 text-base font-medium">
        {{ $trans('UpgradePage.FreeRentbirdButtonLabel') }}
        <Icon name="arrow-right" class="ml-[8px]" />
      </a>
    </div>
    <div class="block lg:flex lg:mb-14">
      <div
        class="lg:w-1/2 lg:pr-3"
        :class="{
          'lg:hidden': !searchProfile
        }"
      >
        <MissedMatchesMessageBox
          v-if="searchProfile && user.isLoggedIn"
          :search-profile="searchProfile"
        />

        <div v-if="isMobile" class="bg-white px-4 lg:px-6 py-4 lg:py-6 mt-4 lg:mt-0  rounded-md lg:mb-10">
          <PricingCardSimple v-if="hasNormalSubscriptionPricesAvailable()" class="lg:mb-3" />
          <PricingCardStartTrial v-if="onlyHasTrialPriceAvailable()" class="lg:mb-3" />

          <ErrorMessage v-if="showSelectPriceError">
            {{ $trans('UpgradePage.ErrorSelectPrice') }}
          </ErrorMessage>
          <Button
            v-if="hasNormalSubscriptionPricesAvailable()"
            dusk="submit-button"
            class="mb-4"
            :big="true"
            :full-width-on-desktop="true"
            :full-width-on-mobile="true"
            icon-right="arrow-right"
            @click="() => {
              if (!onClickPricingCardPayButton()) {
                showSelectPriceError = true
              }
            }"
          >
            {{ $trans('UpgradePage.PayButtonLabel') }}
          </Button>

          <ThreeSmallUsps />

          <BirdWithMessageBox class="mt-[16px] mb-[-20px]">
            <div>
              <h4 class="text-lg font-medium mb-[2px]" v-html="$trans('UpgradePage.BirdWithMessageBoxTitle')" />
              <p class="text-base" v-html="$trans('UpgradePage.BirdWithMessageBoxContent')" />
            </div>
          </BirdWithMessageBox>
        </div>

        <a
          v-if="isMobile"
          :href="$getLocalizedUrl('my-rentbird.home')"
          class="text-gray-600 font-medium hover:cursor-pointer flex justify-center mt-[42px] pb-[60px]"
        >
          {{ $trans('UpgradePage.FreeRentbirdButtonLabel') }}
          <Icon name="arrow-right" class="ml-[8px]" />
        </a>
      </div>

      <div
        class="lg:w-1/2 lg:block hidden"
        :class="{
          'lg:pl-3': searchProfile
        }"
      >
        <div class="bg-white rounded p-[32px] mb-[120px]">
          <PricingCardSimple v-if="hasNormalSubscriptionPricesAvailable()" class="lg:mb-3" />
          <PricingCardStartTrial v-if="onlyHasTrialPriceAvailable()" class="lg:mb-3" />

          <ErrorMessage v-if="showSelectPriceError">
            {{ $trans('UpgradePage.ErrorSelectPrice') }}
          </ErrorMessage>
          <Button
            v-if="hasNormalSubscriptionPricesAvailable()"
            dusk="submit-button"
            class="mb-4"
            :big="true"
            :full-width-on-desktop="true"
            :full-width-on-mobile="true"
            icon-right="arrow-right"
            @click="() => {
              if (!onClickPricingCardPayButton()) {
                showSelectPriceError = true
              }
            }"
          >
            {{ $trans('UpgradePage.PayButtonLabel') }}
          </Button>

          <ThreeSmallUsps />

          <BirdWithMessageBox
            bird-location="right"
            class="mt-[24px] mb-[-12px]"
          >
            <div>
              <h4 class="text-lg font-medium mb-[2px]" v-html="$trans('UpgradePage.BirdWithMessageBoxTitle')" />
              <p class="text-base" v-html="$trans('UpgradePage.BirdWithMessageBoxContent')" />
            </div>
          </BirdWithMessageBox>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.UpgradePage {
  background: rgb(234,234,235);
}
</style>
