<script setup>
import ErrorMessage from '@js/Components/Forms/ErrorMessage/ErrorMessage.vue'
import { computed, ref } from 'vue'
import Icon from '@js/Components/Icon/Icon.vue'
import ProgressBar from '@js/Components/Forms/ProgressBar/ProgressBar.vue'

const props = defineProps({
  modelValue: String,
  placeholder: String,

  useLandingPageStyling: {
    type: Boolean,
    default: false
  },
  error: {
    type: String,
    default: ''
  },
  id: {
    type: String,
    default: ''
  },
  autocomplete: {
    type: String,
    default: ''
  },
  showCreatePasswordHint: {
    type: Boolean,
    default: true
  },
  textWhite: {
    type: Boolean,
    default: false
  },

  // Tag used by Dusk browser tests, added directly to the input element
  dusk: {
    type: [String, null],
    default: null
  }
})

defineEmits(['update:modelValue', 'focus', 'blur', 'keyup.enter'])

const inputRef = ref(null)
const type = ref('password')

const focus = () => {
  inputRef.value.focus()
}

const progressBarWidth = computed(() => {
  const length = props.modelValue.length
  let p = length / 8 * 100
  if (p > 100) { p = 100 }
  return p
})

defineExpose({
  focus
})

</script>
<template>
  <div>
    <label
      v-if="$slots.label"
      class="block font-medium text-base mb-1 text-purple-700"
      :class="{'text-white': textWhite}"
    >
      <slot name="label" />
    </label>
    <div class="relative">
      <input
        :id="id"
        ref="inputRef"
        :dusk="dusk"
        :autocomplete="autocomplete"
        :type="type"
        :value="modelValue"
        :placeholder="placeholder"
        class="block outline-0 w-full p-[15px] rounded placeholder-gray-400 text-base pr-12"
        :class="{
          'border-none text-gray-900': useLandingPageStyling,
          'text-purple-700': !useLandingPageStyling,
          'border border-gray-200': !useLandingPageStyling && !error,
          'border border-danger': !useLandingPageStyling && error
        }"
        :style="useLandingPageStyling && 'box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);'"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        @input="(e) => { $emit('update:modelValue', e.target.value) }"
        @keyup.enter="$emit('keyup.enter')"
      >
      <div
        class="text-black absolute top-[19px] right-[16px] hover:text-pink-500 hover:cursor-pointer transition-all p-[16px] m-[-16px]"
        @click="type = type === 'password' ? 'text' : 'password'"
      >
        <Icon
          v-if="type === 'password'"
          name="eye"
        />
        <Icon
          v-if="type === 'text'"
          name="eye-off"
        />
      </div>
    </div>
    <ProgressBar v-if="showCreatePasswordHint" class="mt-2" :progress="progressBarWidth" />
    <div v-if="showCreatePasswordHint" class="text-sm text-purple-700 mt-1" :class="{'text-white': textWhite}">
      <span v-show="modelValue.length < 8" v-html="$trans('PasswordInput.RequirementsTextInvalid')" />
      <span v-show="modelValue.length >= 8" v-html="$trans('PasswordInput.RequirementsTextValid')" />
    </div>

    <ErrorMessage
      v-if="error"
      :dusk="`${dusk}-error`"
      class="mt-[2px]"
    >
      {{ error }}
    </ErrorMessage>
  </div>
</template>
<style lang="scss">
.password-label-invalid {
  color: #EA1717;
}
.password-label-valid {
  color: #0FBA80;
}
</style>
