<script setup>
import FiltersInputScreenTopBar from '@js/Components/Forms/FiltersInputAllHousesPage/FiltersInputScreenTopBar.vue'
import { computed, onMounted, ref } from 'vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { DEFAULT_MAX_RENT } from '@js/App/Utils/FiltersInputAllHousesDefaultValues'
import SliderRangeInput from '@js/Components/Forms/SliderRangeInput/SliderRangeInput.vue'
import { getCurrency } from '@js/App/Utils/CountryAndLocale'
import { formatNumber } from '@js/App/Plugins/Formatting'

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({})
  }
})

const filtersAreDifferentFromDefault = computed(() => {
  return props.modelValue.maxRent
})

const hasReset = ref(false)

onMounted(() => {
  hasReset.value = false
})

defineEmits(['update:modelValue', 'click:back', 'click:close'])
</script>
<template>
  <div>
    <FiltersInputScreenTopBar
      :title="$trans('FiltersInput.MaxRent')"
      class="mb-[40px]"
      @click:back="$emit('click:back')"
      @click:close="$emit('click:close')"
    />

    <SliderRangeInput
      class="mb-[24px] px-[16px]"
      :min="750"
      :max="5000"
      :step="250"
      :format="value => `${getCurrency()}${formatNumber(value, 0)}`"
      :model-value="modelValue.maxRent || 2500"
      @update:model-value="newMaxRent => {
        hasReset = false

        $emit('update:modelValue', {
          ...modelValue,
          maxRent: newMaxRent
        })
      }"
    />

    <div class="w-full flex justify-end mb-[40px]">
      <a
        class="text-base text-blue-300"
        :class="{
          'hover:underline hover:cursor-pointer': filtersAreDifferentFromDefault,
          'opacity-30': !filtersAreDifferentFromDefault
        }"
        @click.prevent="() => {
          hasReset = true

          $emit('update:modelValue', {
            ...modelValue,
            maxRent: DEFAULT_MAX_RENT
          })
        }"
      >
        {{ $trans('FilterInputLocationScreen.ResetFilter') }}
      </a>
    </div>

    <Button
      :big="true"
      :outline="true"
      :full-width-on-mobile="true"
      icon-right="back"
      @click="() => {
        if (hasReset) {
          $emit('update:modelValue', {
            ...modelValue,
            maxRent: DEFAULT_MAX_RENT
          })
        } else if (!modelValue.maxRent) {
          $emit('update:modelValue', {
            ...modelValue,
            maxRent: 2500
          })
        }
        $emit('click:back')

      }"
    >
      {{ $trans('FiltersInputLocationScreen.ApplyAndGoBack') }}
    </Button>
  </div>
</template>
