import { defineStore } from 'pinia'
import { usePage } from '@inertiajs/vue3'
import {
  PLAN_1_MONTHLY,
  PLAN_1_MONTHLY_FREE_WHATSAPPP, PLAN_1_MONTHLY_HIGH, PLAN_1_MONTHLY_SUPER_HIGH,
  PLAN_1_WEEK_TRIAL,
  PLAN_2_MONTHLY,
  PLAN_2_MONTHLY_FREE_WHATSAPPP, PLAN_2_MONTHLY_HIGH, PLAN_2_MONTHLY_SUPER_HIGH,
  PLAN_3_MONTHLY,
  PLAN_3_MONTHLY_FREE_WHATSAPPP, PLAN_3_MONTHLY_HIGH, PLAN_3_MONTHLY_SUPER_HIGH,
  US_PLAN_1_MONTHLY,
  US_PLAN_1_MONTHLY_HIGH,
  US_PLAN_2_MONTHLY, US_PLAN_2_MONTHLY_HIGH,
  US_PLAN_3_MONTHLY, US_PLAN_3_MONTHLY_HIGH,
  US_PLAN_TRIAL
} from '@js/App/Prices'
import { getQueryParameter } from '@js/App/Utils/ReadQueryParams'
import { isEmpty } from '@js/App/Utils/IsEmpty'

const page = usePage()

// Note: this store is NOT CLEARED and is persistent across inertia page loads!
export const usePaymentStore = defineStore('payment', {
  state: () => {
    const availablePrices = getAvailablePricesFromDto(page.props.prices)
    const trialPrice = getTrialPriceFromDto(page.props.prices)

    return {
      // We also store the prices before a coupon code was applied. This way we can show fancy things such as:
      // x amount and percentage of discount!
      pricesBeforeCouponCodeWasApplied: [],
      availablePrices,
      trialPrice,

      selectedPriceBeforeCouponWasApplied: null,
      selectedPrice: getSelectedPriceFromQueryParamOrSetDefault([...availablePrices, trialPrice]),

      couponCodeThatWasApplied: null
    }
  },

  actions: {
    applyNewCouponPrices (newPrices, couponCode) {
      // First we store the "current" normal prices so that we can show cool things like "x amount of discount applied."
      this.pricesBeforeCouponCodeWasApplied = [...this.availablePrices]

      // Then update the prices with the new coupon prices
      this.availablePrices = getAvailablePricesFromDto(newPrices)

      // Store a "copy" of the old selected price
      this.selectedPriceBeforeCouponWasApplied = this.selectedPrice

      // Then we update the selected price to reflect the new coupon change
      this.selectedPrice = this.availablePrices.find((item) => {
        return item.numberOfMonths === this.selectedPrice.numberOfMonths &&
            item.numberOfDays === this.selectedPrice.numberOfDays
      })

      this.couponCodeThatWasApplied = couponCode
    },

    removeCoupon () {
      if (!isEmpty(this.pricesBeforeCouponCodeWasApplied)) {
        this.availablePrices = [...this.pricesBeforeCouponCodeWasApplied]
      }
      if (this.selectedPriceBeforeCouponWasApplied) {
        this.selectedPrice = this.selectedPriceBeforeCouponWasApplied
      }

      this.couponCodeThatWasApplied = null
    }
  },

  getters: {
    isCouponApplied: state => state.couponCodeThatWasApplied !== null,
    totalPriceDifferenceOfCoupon: state => !state.selectedPriceBeforeCouponWasApplied
      ? null
      : state.selectedPriceBeforeCouponWasApplied.amount - state.selectedPrice.amount
  }
})

export const getAvailablePricesFromDto = items => items
  .filter(price => [
    PLAN_1_MONTHLY, PLAN_2_MONTHLY, PLAN_3_MONTHLY, PLAN_1_MONTHLY_FREE_WHATSAPPP,
    PLAN_2_MONTHLY_FREE_WHATSAPPP, PLAN_3_MONTHLY_FREE_WHATSAPPP,
    PLAN_1_MONTHLY_HIGH, PLAN_2_MONTHLY_HIGH, PLAN_3_MONTHLY_HIGH,
    US_PLAN_1_MONTHLY, US_PLAN_2_MONTHLY, US_PLAN_3_MONTHLY,
    US_PLAN_1_MONTHLY_HIGH, US_PLAN_2_MONTHLY_HIGH, US_PLAN_3_MONTHLY_HIGH,
    PLAN_1_MONTHLY_SUPER_HIGH, PLAN_2_MONTHLY_SUPER_HIGH, PLAN_3_MONTHLY_SUPER_HIGH
  ].includes(price.plan))
  .map(item => getPriceFromDto(item))
  .reverse()

export const getTrialPriceFromDto = items => items
  .filter(price => [
    PLAN_1_WEEK_TRIAL, US_PLAN_TRIAL
  ].includes(price.plan))
  .map(item => getPriceFromDto(item))[0]

export const getPriceFromDto = item => ({
  plan: item.plan,
  renewalPlan: item.renewal_plan,
  amount: item.amount,
  amountPerMonth: Math.floor(item.amount / item.number_of_months),
  numberOfMonths: item.number_of_months,
  numberOfDays: item.number_of_days,
  isSubscriptionPlan: !!item.is_subscription_plan,
  isWhatsappIncludedForFree: true,
  version: item.version,
  country: item.country,
  currency: item.currency,
  discount: item.number_of_months > 1 && (
    item.number_of_months === 2 ? 32 : 44
  ),
  showLabel: item.number_of_months === 2,
  fullAmountIfPaidPerMonth: 2900 * item.number_of_months
})

const getSelectedPriceFromQueryParamOrSetDefault = (availablePrices) => {
  const selectedPlan = getQueryParameter('plan')
  if (!selectedPlan) {
    return availablePrices.find(
      item =>
        item.plan === PLAN_2_MONTHLY || item.plan === US_PLAN_2_MONTHLY || item.plan === US_PLAN_2_MONTHLY_HIGH ||
          item.plan === US_PLAN_TRIAL || item.plan === PLAN_1_WEEK_TRIAL || item.plan === PLAN_2_MONTHLY_HIGH ||
          item.plan === PLAN_2_MONTHLY_SUPER_HIGH
    )
  }

  return availablePrices.find(item => item.plan === selectedPlan)
}
