<script setup>
import Icon from '@js/Components/Icon/Icon.vue'
import { onMounted, ref } from 'vue'

defineProps({
  video: String
})

const justGotMounted = ref(true)
onMounted(() => {
  justGotMounted.value = true
  setTimeout(() => { justGotMounted.value = false }, 1)
})

defineEmits(['close'])

</script>
<template>
  <div
    class=" z-70"
    @click="$emit('close')"
  >
    <!-- This is the dark background on desktop -->
    <div
      class="fixed left-0 top-0 right-0 bottom-0 bg-black bg-opacity-50 transition-all duration-200"
      :class="{
        'bg-opacity-0': justGotMounted,
      }"
    />

    <div class="fixed inset-0 flex justify-center items-center py-10">
      <div
        v-bind="$attrs"
        class="overflow-y-auto h-full lg:h-auto lg:max-h-full lg:max-w-full lg:rounded-md transition-transform duration-500"
        :class="{
          'translate-y-vscreen': justGotMounted,
        }"
      >
        <div class="flex justify-end pb-2">
          <Icon name="cross" class="hover:text-pink-500 transition-colors hover:cursor-pointer text-white" @click="$emit('close')" />
        </div>

        <video
          class="object-fill max-w-full max-h-[600px] xl:max-h-[800px]"
          controls
          autoplay
          @click.stop="() => {}"
        >
          <source src="/videos/user-review-noah.mov" type="video/mp4">
        </video>
      </div>
    </div>
  </div>
</template>
