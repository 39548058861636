<script setup>
import OffboardingDarkCard from '@js/Components/MyRentbird/Offboarding/OffboardingDarkCard/OffboardingDarkCard.vue'
import { useUser } from '@js/App/Api/User/User'
import AboutYourSearchCard from '@js/Components/MyRentbird/Offboarding/AboutYourSearchCard/AboutYourSearchCard.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import CheckboxAlternative from '@js/Components/Forms/Checkbox/CheckboxAlternative.vue'
import { ref } from 'vue'
import InfoMessageBox from '@js/Components/InfoMessageBox/InfoMessageBox.vue'
import LightImage from '@images/icons/light.png'
import ErrorMessage from '@js/Components/Forms/ErrorMessage/ErrorMessage.vue'

defineProps({
  userWantsToCancelWithFullRefund: {
    type: Boolean,
    default: false
  },
  userHasAppleInAppPayment: {
    type: Boolean,
    default: false
  },
  errors: {
    type: Object,
    default: () => ({})
  }
})

const { userActivePayment } = useUser()

const deleteAllSearchProfiles = ref(false)

defineEmits(['clicked:cancelSubscription', 'clicked:goBack'])
</script>
<template>
  <div>
    <div class="lg:flex">
      <div class="lg:w-1/2 lg:mr-[16px]">
        <p class="text-white text-base mb-[8px] lg:text-lg">
          {{ $trans('ConfirmCancel.SubscriptionTitle') }}
        </p>
        <OffboardingDarkCard class="mb-[12px]">
          <div class="flex justify-between mb-[8px]">
            <div class="text-base font-medium  lg:text-lg">
              {{ $trans(userActivePayment.priceDto.numberOfMonths > 1 ? 'PricingCardSimple.Months' : 'PricingCardSimple.MonthsSingle', {
                months: userActivePayment.priceDto.numberOfMonths
              }) }}
            </div>
            <div class="text-base lg:text-lg">
              {{ $formatPrice(userActivePayment.priceDto.amount / userActivePayment.priceDto.numberOfMonths) }} {{ $trans('PricingCardAlternative.PerMonth') }}
            </div>
          </div>
          <div class="text-sm text-gray-50 lg:text-lg">
            {{ $trans('ConfirmCancel.Date', {
              date: userActivePayment.subscription_ends_at_formatted
            }) }}
          </div>
        </OffboardingDarkCard>

        <InfoMessageBox
          v-if="userWantsToCancelWithFullRefund"
          :image="LightImage"
          color="bg-purple-500"
          class="mb-[12px]"
        >
          {{ $trans('ConfirmCancel.MoneyBackMessage') }}
        </InfoMessageBox>

        <CheckboxAlternative
          v-if="!userWantsToCancelWithFullRefund"
          v-model="deleteAllSearchProfiles"
          class="mb-[40px]"
        >
          <span v-html="$trans('ConfirmCancel.IWantToKeepReceivingMatches')" />
        </CheckboxAlternative>
      </div>

      <div class="lg:w-1/2 lg:ml-[16px]">
        <AboutYourSearchCard class="mb-[40px]" />

        <div v-if="userWantsToCancelWithFullRefund && !userHasAppleInAppPayment" class="mb-[40px]">
          <div class="text-sm text-gray-200 mb-[4px]">
            {{ $trans('Common.Summary') }}
          </div>
          <div class="flex w-full justify-between text-base font-medium text-white mb-[8px]">
            <div>{{ $trans('ConfirmCancel.Refund') }}</div>
            <div>{{ $formatPrice(userActivePayment.amount) }}</div>
          </div>
          <div class="w-full h-[1px] bg-white opacity-10 my-[8px]" />
          <div class="text-sm text-white">
            {{ $trans('ConfirmCancel.WeWillTransferIn7Days') }}
          </div>
        </div>
        <div v-if="userWantsToCancelWithFullRefund && userHasAppleInAppPayment" class="mb-[40px]">
          <div class="text-sm text-danger font-medium">
            {{ $trans('ConfirmCancel.RequestARefundInApplePlease') }}
          </div>
        </div>
      </div>
    </div>

    <ErrorMessage v-if="errors.refund" class="mb-[4px]">
      {{ errors.refund }}
    </ErrorMessage>
    <div class="lg:flex lg:flex-row-reverse lg:justify-between">
      <Button
        class="mb-[8px]"
        :big="true"
        :full-width-on-mobile="true"
        icon-right="check"
        @click="() => {
          $emit('clicked:cancelSubscription', deleteAllSearchProfiles)
        }"
      >
        {{ $trans('ConfirmCancel.YesIQuit') }}
      </Button>

      <Button
        :big="true"
        :outline="true"
        :full-width-on-mobile="true"
        icon-right="cross"
        @click="$emit('clicked:goBack')"
      >
        {{ $trans('ConfirmCancel.GoBack') }}
      </Button>
    </div>
  </div>
</template>
