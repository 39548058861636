<script setup>
import B2BLayout from '@js/Components/B2B/B2BLayout.vue'
import { computed, ref, watch } from 'vue'
import { router, usePage } from '@inertiajs/vue3'
import B2BUpdateUserForm from '@js/Components/B2B/B2BUpdateUserForm.vue'
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import B2BNumberOfMatchesBoxAllSearchProfiles
  from '@js/Components/B2B/NumberOfMatchesBox/B2BNumberOfMatchesBoxAllSearchProfiles.vue'
import SearchProfileCard from '@js/Components/SearchProfileCard/SearchProfileCard.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { useB2BOrganization } from '@js/App/Api/B2B/B2B'
import Icon from '@js/Components/Icon/Icon.vue'
import DarkPurpleThinModal from '@js/Components/Modals/DarkPurpleThinModal.vue'
import { doPostRequest } from '@js/App/Api/Infra/ApiClient'
import DataTable from '@js/Components/Tables/DataTable.vue'
import { getQueryParameter } from '@js/App/Utils/ReadQueryParams'
import HouseStatusDropdown from '@js/Components/B2B/HouseStatusDropdown.vue'
import CustomTransitionFade from '@js/Components/Transitions/CustomTransitionFade.vue'
import { ziggyRoute } from '@js/App/Utils/ZiggyUrls'

const page = usePage()
const user = computed(() => page.props.b2b_user)
const searchProfiles = computed(() => page.props.search_profiles)
const houses = computed(() => page.props.houses)

const canCreateNewSearchProfile = computed(() => searchProfiles.value.length < 10)

const { organization } = useB2BOrganization()

const showDeleteModal = ref(false)

const onClickedDelete = async () => {
  await doPostRequest(`/b2b/${organization.value}/user/${user.value.random_id}/delete`, {}, false)
  router.visit(`/b2b/${organization.value}`)
}

const searchQuery = ref(getQueryParameter('search_query', ''))
const sortBy = ref(getQueryParameter('sort_by', 'created_at'))
const sortDirection = ref(getQueryParameter('sort_direction', 'desc'))

const refetchTableWithSearchAndSortParameters = () => {
  let url = `/b2b/${organization.value}/user/${user.value.random_id}`
  url += `?search_query=${encodeURIComponent(searchQuery.value)}`
  url += `&sort_by=${encodeURIComponent(sortBy.value)}`
  url += `&sort_direction=${sortDirection.value}`

  router.visit(url, {
    preserveState: true,
    preserveScroll: true
  })
}

watch(searchQuery, () => {
  const oldValue = searchQuery.value
  setTimeout(() => {
    if (oldValue !== searchQuery.value) { return }

    refetchTableWithSearchAndSortParameters()
  }, 500)
})
watch(sortBy, refetchTableWithSearchAndSortParameters)
watch(sortDirection, refetchTableWithSearchAndSortParameters)
</script>
<template>
  <B2BLayout
    :bread-crumbs="[
      {
        label: 'Users',
        url: ziggyRoute('b2b.home', { organization: organization})
      },
      {
        label: `${user.firstname} ${user.lastname}`,
        url: ziggyRoute('b2b.user.details', { organization: organization, user: user.random_id})
      }
    ]"
  >
    <template #title>
      <div class="w-full flex justify-between">
        <div>
          {{ user.firstname }} {{ user.lastname }}
        </div>
        <div>
          <Button :outline="true" :big="true" icon-right="trash" @click="showDeleteModal = true">
            Delete user
          </Button>
        </div>
      </div>
    </template>

    <CustomTransitionFade>
      <DarkPurpleThinModal v-show="showDeleteModal" :show-top-bar="false">
        <h4 class="text-white text-xl font-medium">
          Delete user
        </h4>
        <p class="mt-[6px] text-base">
          This will delete the user. The user will not receive any matches anymore. <strong>This cannot be undone.</strong>
        </p>
        <Button
          :full-width-on-mobile="true"
          :full-width-on-desktop="true"
          icon-right="trash"
          class="mt-[32px]"
          @click="onClickedDelete"
        >
          Delete user
        </Button>
        <Button
          :full-width-on-mobile="true"
          :full-width-on-desktop="true"
          icon-right="back"
          class="mt-[8px]"
          :outline="true"
          @click="showDeleteModal = false"
        >
          Cancel and go back
        </Button>
      </DarkPurpleThinModal>
    </CustomTransitionFade>

    <div class="flex lg:space-x-4">
      <MyRentbirdCard class="lg:w-1/2" :can-expand-on-mobile="false">
        <template #title>
          User details
        </template>

        <div class="flex items-center mb-4">
          <Icon class="pr-2 relative top-[-1px]" :name="searchProfiles.length > 0 ? 'dot-green' : 'dot-red'" />
          <span v-if="searchProfiles.length > 0">User is receiving matches</span>
          <span v-else>User is not receiving matches</span>
        </div>

        <B2BUpdateUserForm />
      </MyRentbirdCard>

      <MyRentbirdCard class="lg:w-1/2" :can-expand-on-mobile="false">
        <template #title>
          Search profiles
        </template>
        <B2BNumberOfMatchesBoxAllSearchProfiles
          v-if="searchProfiles.length > 0"
        />
        <div class="lg:flex lg:flex-wrap lg:mt-[8px] lg:ml-[-4px] lg:mr-[-4px]">
          <div
            v-for="searchProfile of searchProfiles"
            :key="searchProfile.serverId"
            class="lg:w-1/2 lg:pr-[4px] lg:pl-[4px]"
          >
            <SearchProfileCard
              class="mt-[8px]"
              :search-profile="searchProfile"
              :show-edit-button="true"
              :rounded-bottom="true"
              :edit-url="ziggyRoute('b2b.user.search.details', {
                organization: organization,
                user: user.random_id,
                searchProfile: searchProfile.random_id
              })"
            />
          </div>
          <div
            v-show="canCreateNewSearchProfile"
            class="hidden lg:flex w-1/2 items-center justify-center  min-h-[140px]"
          >
            <Button
              :href="ziggyRoute('b2b.user.new-search', { organization: organization, user: user.random_id})"
              tag="link"
              icon-right="plus"
            >
              Create new search
            </Button>
          </div>
        </div>
      </MyRentbirdCard>
    </div>

    <MyRentbirdCard class="mt-6" :can-expand-on-mobile="false">
      <template #title>
        Houses sent to user
      </template>

      <DataTable
        :search-query="searchQuery"
        :sort-by="sortBy"
        :sort-direction="sortDirection"
        :pagination="houses"
        :headers="[
          {
            label: 'Name',
            value: 'name',
            isSortable: false,
          },
          {
            label: 'Email sent at',
            value: 'created_at',
          },
          {
            label: 'Details',
            value: 'details',
            isSortable: false,
          },
          {
            label: 'Status',
            value: 'status',
            isSortable: false,
          },
        ]"
        @update:search-query="newSearchQuery => { searchQuery = newSearchQuery }"
        @update:sort-by="newSortBy => { sortBy = newSortBy }"
        @update:sort-direction="newSortDirection => { sortDirection = newSortDirection }"
      >
        <template
          v-for="item of houses.data"
          :key="item?.random_id"
        >
          <tr class="row">
            <td>
              <a
                class="text-blue-base hover:cursor-pointer hover:underline flex items-center"
                :href="item.url"
                target="_blank"
              >
                <img alt="house-image" :src="item.image_url" class="w-[64px] mr-3">
                <div>
                  {{ item.name }}, {{ item.city }}
                </div>
              </a>
            </td>
            <td>
              {{ item.created_at_formatted }}
            </td>
            <td>
              &euro;{{ item.rent }} • {{ item.area }}m<sup>2</sup> <span v-if="item.bedrooms > 0">• {{ item.bedrooms }}
                <small>bedrooms</small></span>
            </td>
            <td>
              <HouseStatusDropdown
                :house-random-id="item.random_id"
                :house-status="item.house_status"
              />
            </td>
          </tr>
        </template>
      </DataTable>
    </MyRentbirdCard>
  </B2BLayout>
</template>
<style lang="scss">
.B2B-UserDetailPage {
  background: rgb(234,234,235);
}
</style>
