<script setup>
import ErrorMessage from '@js/Components/Forms/ErrorMessage/ErrorMessage.vue'
import { onMounted, ref } from 'vue'
import { isSafari, useIsMobile } from '@js/App/Utils/BrowserDetection'

const props = defineProps({
  modelValue: String,
  placeholder: String,

  type: {
    type: String,
    default: 'text'
  },

  error: {
    type: String,
    default: ''
  },
  autocomplete: {
    type: String,
    default: ''
  },
  delayFocus: {
    type: Boolean,
    default: false
  },
  delayFocusTimeInMs: {
    type: Number,
    default: 500
  },
  disableBorder: {
    type: Boolean,
    default: false
  },
  id: {
    type: String,
    default: ''
  },
  showErrorBorder: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue', 'focus', 'blur', 'keyup.enter'])

const inputRef = ref(null)

const focus = () => {
  inputRef.value.focus()
}

defineExpose({
  focus
})

const onMouseDown = (e) => {
  const { isMobile } = useIsMobile()
  if (!props.delayFocus) { return }
  // we cannot delay for mobile safari. If we do this then the keyboard doesn't show up anymore when clicking input elements that have a delay.
  if (isMobile && isSafari()) { return }

  e.preventDefault()

  setTimeout(() => {
    inputRef.value.focus()
  }, props.delayFocusTimeInMs)
}

const onFocus = (e) => {
  // We have to do this hacky thing to disable safari auto selecting the input element when the modal pops up.
  // If you remove this, open safari on a desktop and click on "start search" on a main landing page you will
  // see that the city input is opened. Which sucks.
  const timeSinceMounted = performance.now() - mountedAt
  if (isSafari() && timeSinceMounted <= 700) {
    return
  }

  emit('focus')
}

let mountedAt = null
onMounted(() => {
  mountedAt = performance.now()
})
</script>
<template>
  <div>
    <label
      v-if="$slots.label"
      class="block font-medium text-base mb-1 text-white"
    >
      <slot name="label" />
    </label>
    <div
      class="relative"
    >
      <div
        v-if="$slots.icon"
        class="text-black absolute top-[15px] left-[16px]"
      >
        <slot name="icon" />
      </div>
      <input
        :id="id || null"
        ref="inputRef"
        :type="type"
        :value="modelValue"
        :autocomplete="autocomplete || null"
        :placeholder="placeholder"
        class="block p-[16px] outline-0 ring-0 rounded focus:outline-0 focus-visible:outline-0 focus:ring-0 focus-visible:ring-0 active:outline-0 active:ring-0 w-full placeholder-gray-400 text-base bg-purple-900"
        :class="{

          'border border-danger':error || showErrorBorder,
        }"
        @focus="(e) => onFocus(e)"
        @blur="$emit('blur')"
        @input="(e) => { $emit('update:modelValue', e.target.value) }"
        @keyup.enter="$emit('keyup.enter')"
        @mousedown="(e) => onMouseDown(e)"
      >
    </div>
    <ErrorMessage
      v-if="error"
      class="mt-[2px]"
    >
      {{ error }}
    </ErrorMessage>
  </div>
</template>
