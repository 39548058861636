<script setup>
import BasicPageHeader from '@js/Components/Marketing/BasicPageHeader/BasicPageHeader.vue'
import Container from '@js/Components/Container/Container.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { useMainStore } from '@js/App/Stores/MainStore'
import { doPostRequest } from '@js/App/Api/Infra/ApiClient'
import { ref } from 'vue'
import Icon from '@js/Components/Icon/Icon.vue'
import UnHappyStefImage from '@images/people/unhappy-stef.png'
import Image from '@js/Components/Image/Image.vue'
import Checkbox from '@js/Components/Forms/Checkbox/Checkbox.vue'
import { usePage } from '@inertiajs/vue3'

const mainStore = useMainStore()
const errors = ref({})
const showSuccessMessage = ref(false)

const page = usePage()

const wantsToReceiveMarketingEmails = ref(page.props.isSubscribedToMarketingEmails)
const wantsToReceiveHouseMatchEmails = ref(page.props.isSubscribedToHouseMatchEmails)

const onClickUnsubscribe = async () => {
  mainStore.startLoading()
  const response = await doPostRequest('/api/marketing-emails/unsubscribe', {
    email: page.props.unsubscribeEmail,
    isSubscribedToMarketingEmails: wantsToReceiveMarketingEmails.value,
    isSubscribedToHouseMatchEmails: wantsToReceiveHouseMatchEmails.value
  })
  if (response.hasErrors) {
    errors.value = response.errors
  } else {
    showSuccessMessage.value = true
  }
  mainStore.stopLoading()
}
</script>
<template>
  <div>
    <BasicPageHeader :show-trustpilot-instead-of-login="true" :show-section-divider="false" />

    <Container>
      <div class="pb-[40px] lg:pb-[80px] mt-[40px] lg:mt-[80px]">
        <div v-if="showSuccessMessage" class="lg:max-w-[600px] lg:mx-auto bg-success text-white rounded-t-md px-[20px] lg:px-[40px] py-[8px] text-base">
          <Icon name="check-circle" icon-width="inline w-[20px] h-[20px]" class="mr-[4px]" />
          {{ $trans('UnsubscribePage.SuccessMessage') }}
        </div>
        <div
          class="bg-purple-900 lg:max-w-[600px] lg:mx-auto p-[24px] lg:p-[40px] text-white rounded-b-md"
          :class="{
            'rounded-t-md': !showSuccessMessage
          }"
        >
          <h4 class="font-medium text-xl lg:text-4xl mb-[8px]">
            {{ $trans('UnsubscribePage.Title') }}
          </h4>
          <p class="text-base lg:text-lg" v-html="$trans('UnsubscribePage.Content')" />

          <Image :src="UnHappyStefImage" class="rounded-md mt-[12px]" />
          <p class="text-sm text-white text-opacity-70 text-center mt-[8px]">
            {{ $trans('UnsubscribePage.PunishStef') }}
          </p>

          <Checkbox v-model="wantsToReceiveMarketingEmails" class="mb-2 mt-2 lg:mt-8 lg:mb-4">
            <span class="text-white" v-html="$trans('UnsubscribePage.SubscribedToMarketingEmails')" />
          </Checkbox>
          <Checkbox v-model="wantsToReceiveHouseMatchEmails" class="mb-2 lg:mb-8">
            <span class="text-white" v-html="$trans('UnsubscribePage.SubscribedToHouseMatchEmails')" />
          </Checkbox>

          <Button
            :big="true"
            class="mt-[24px]"
            :full-width-on-desktop="true"
            :full-width-on-mobile="true"
            :icon-right="showSuccessMessage ? 'check' : ''"
            @click="onClickUnsubscribe"
          >
            {{ $trans(showSuccessMessage ? 'UnsubscribePage.Done' : 'UnsubscribePage.NoEmailsButtonLabel') }}
          </Button>
          <Button
            :big="true"
            class="mt-[12px]"
            :full-width-on-desktop="true"
            :full-width-on-mobile="true"
            :outline="true"
            tag="link"
            :href="$getLocalizedUrl('my-rentbird.home')"
          >
            {{ $trans('UnsubscribePage.ChangedMyMind') }}
          </Button>
        </div>
      </div>
    </Container>
  </div>
</template>
<style lang="scss">
.MyRentbird-UnsubscribePage {
  background-color: rgb(40,33,75);
}
</style>
