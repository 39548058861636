<script setup>
import UserAvatarImage from '@js/Components/MyRentbird/UserAvatarImage/UserAvatarImage.vue'
import { ref } from 'vue'
import Icon from '@js/Components/Icon/Icon.vue'
import MyRentbirdNavbarLinkItem from '@js/Components/MyRentbird/MyRentbirdNavbar/MyRentbirdNavbarLinkItem.vue'
import UserPaymentStatusLabel from '@js/Components/MyRentbird/UserPaymentStatusLabel/UserPaymentStatusLabel.vue'
import { useUser } from '@js/App/Api/User/User'
import CustomTransitionFade from '@js/Components/Transitions/CustomTransitionFade.vue'

const { user } = useUser()

const isOpen = ref(false)
</script>
<template>
  <div class="relative">
    <div
      class="flex items-center hover:cursor-pointer text-white hover:text-pink-500 transition-colors"
      @click="isOpen = !isOpen"
    >
      <UserAvatarImage />
      <Icon name="caret-down" class=" pl-[2px]" icon-width="w-[19px] h-[19px] lg:w-[24px] lg:h-[24px]" />
    </div>

    <CustomTransitionFade>
      <div
        v-show="isOpen"
        class="fixed z-20 inset-0 bg-black lg:bg-opacity-0 bg-opacity-50 transition-all duration-200 lg:absolute lg:top-[60px] lg:bottom-auto lg:right-0 lg:left-auto"
      >
        <div class="ml-[40px] lg:ml-0 bg-white h-full px-[24px] py-[24px] flex flex-col lg:flex-col-reverse justify-end lg:w-[352px] lg:rounded-md lg:border lg:border-gray-200">
          <div class="flex flex-col lg:flex-col-reverse">
            <div>
              <MyRentbirdNavbarLinkItem
                class="mb-[24px] lg:mb-0 hidden lg:flex"
                :text="$trans('MyRentbirdNavbarDropdown.Logout')"
                icon="logout"
                :url="$getLocalizedUrl('logout')"
              />
            </div>
            <div class="flex flex-col lg:flex-col-reverse">
              <div class="w-full border border-[#eaeaeb] mb-[12px] lg:block hidden" />

              <MyRentbirdNavbarLinkItem
                class="mb-[12px]"
                :url="$getLocalizedUrl('my-rentbird.faq')"
                :text="$trans('MyRentbirdNavbarDropdown.Faq')"
                icon="question-mark"
              />

              <MyRentbirdNavbarLinkItem
                :disabled="!user.hasActiveSubscription"
                class="mb-[12px]"
                :url="$getLocalizedUrl('my-rentbird.response-letter')"
                :text="$trans('MyRentbirdNavbarDropdown.ResponseLetter')"
                icon="email"
              />

              <MyRentbirdNavbarLinkItem
                class="mb-[12px]"
                :url="$getLocalizedUrl('my-rentbird.onboarding-tips')"
                :text="$trans('MyRentbirdNavbarDropdown.Tips')"
                icon="light"
              />

              <MyRentbirdNavbarLinkItem
                class="mb-[12px]"
                :url="$getLocalizedUrl('my-rentbird.match-history')"
                :text="$trans('MyRentbirdNavbarDropdown.MatchHistory')"
                icon="heart-light"
              />

              <div class="w-full border border-[#eaeaeb] mb-[12px] lg:hidden block" />
            </div>
            <div class="flex flex-col lg:flex-col-reverse">
              <div class="w-full border border-[#eaeaeb] mb-[12px] lg:block hidden" />
              <MyRentbirdNavbarLinkItem
                class="mb-[12px]"
                :url="$getLocalizedUrl('my-rentbird.subscription')"
                :text="$trans('MyRentbirdNavbarDropdown.Subscription')"
                icon="creditcard"
              />

              <MyRentbirdNavbarLinkItem
                class="mb-[12px]"
                :url="$getLocalizedUrl('my-rentbird.preferences')"
                :text="$trans('MyRentbirdNavbarDropdown.Preferences')"
                icon="sliders"
              />

              <MyRentbirdNavbarLinkItem
                class="mb-[12px]"
                :url="$getLocalizedUrl('my-rentbird.my-account')"
                :text="$trans('MyRentbirdNavbarDropdown.Account')"
                icon="user"
              />

              <MyRentbirdNavbarLinkItem
                class="mb-[12px]"
                :url="$getLocalizedUrl('my-rentbird.home')"
                :text="$trans('MyRentbirdNavbarDropdown.MyRentbird')"
                icon="house-light"
              />

              <div class="w-full border border-[#eaeaeb] mb-[12px] lg:hidden" />
            </div>

            <MyRentbirdNavbarLinkItem
              class="mb-[24px] lg:mb-0 block lg:hidden"
              :text="$trans('MyRentbirdNavbarDropdown.Logout')"
              icon="logout"
              :url="$getLocalizedUrl('logout')"
            />
          </div>

          <div class=" lg:mb-[15px]">
            <div class="flex justify-between text-purple-900 font-medium text-lg mb-[8px] lg:mb-[2px]">
              <span>
                {{ user.firstNameFormatted }} {{ user.lastNameFormatted }}
              </span>
              <Icon
                name="cross"
                icon-width="w-[24px] h-[24px]"
                class="text-purple-900 hover:text-pink-500 hover:cursor-pointer transition-colors"
                @click="isOpen = false"
              />
            </div>

            <UserPaymentStatusLabel />
          </div>
        </div>
      </div>
    </CustomTransitionFade>
  </div>
</template>
