<script setup>
import Icon from '@js/Components/Icon/Icon.vue'
import { ref } from 'vue'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'

const props = defineProps({
  modelValue: Array,
  options: {
    type: Array,
    default: () => ([
      { value: 'the_value', label: 'I Am option' }
    ])
  },
  initialIsExpanded: {
    type: Boolean,
    default: false
  },
  canCollapse: {
    type: Boolean,
    default: true
  },
  checkboxLocation: {
    type: String,
    default: 'right'
  },
  dusk: {
    type: [String, null],
    default: null
  },
  classLabel: {
    type: String,
    default: ''
  }
})

const isExpanded = ref(props.initialIsExpanded)
const { isMobile } = useIsMobile()

const setIsExpanded = (_isExpanded) => {
  isExpanded.value = _isExpanded
}

defineExpose({
  setIsExpanded
})

defineEmits(['update:modelValue', 'update:isExpanded'])
</script>
<template>
  <div
    v-bind="$attrs"
    class="flex justify-between border-b pb-2 text-purple-700 transition-colors lg:hover:cursor-auto lg:hover:text-purple-700"
    :class="{
      'hover:cursor-pointer hover:text-blue-base': canCollapse
    }"
    style="border-color: rgba(162,163,164,.1);"
    :dusk="dusk"
    @click="() => {
      if (!canCollapse) { return; }
      isExpanded = !isExpanded
      $emit('update:isExpanded', isExpanded)
    }"
  >
    <div :class="`text-base font-medium ${classLabel} w-full`">
      <slot />
    </div>
    <Icon
      v-if="isMobile && canCollapse"
      name="caret-down"
      icon-width="w-[24px] relative"
      class="transition-transform"
      :class="{
        'rotate-180': isExpanded
      }"
    />
  </div>
  <div
    v-if="isExpanded || !isMobile || !canCollapse"
    class="pt-2"
  >
    <div
      v-for="option of options"
      :key="option.label"
      :dusk="`option-${option.value}`"
      @click="() => {
        $emit('update:modelValue', modelValue.includes(option.value)
          ? modelValue.filter(item => item !== option.value)
          : [...modelValue, option.value]
        )
      }"
    >
      <div
        v-tippy="option.tooltip ? {
          content: option.tooltip,
          allowHTML: true,
          maxWidth: isMobile ? 275 : 350
        } : null"
        class="px-[6px] rounded flex items-center text-gray-900 text-base py-[8px] transition-colors hover:cursor-pointer hover:text-white hover:bg-blue-base"
        :class="{
          'flex-row-reverse justify-end': checkboxLocation === 'left',
          'justify-between': checkboxLocation === 'right',
        }"
      >
        <span
          class="relative top-[2px]"
          :class="{
            'pr-[6px]': checkboxLocation === 'right',
            'pl-[8px]': checkboxLocation === 'left',
          }"
        >
          {{ option.label }}
          <Icon
            v-if="option.tooltip"
            name="info"
            icon-width="inline"
            class="relative top-[-1px] left-[2px]"
          />
        </span>
        <Icon
          v-if="!modelValue.includes(option.value)"
          name="box"
        />
        <Icon
          v-if="modelValue.includes(option.value)"
          name="box-checked"
        />
      </div>
    </div>
  </div>
</template>
