<script setup>
import Icon from '@js/Components/Icon/Icon.vue'
import { Link } from '@inertiajs/vue3'
import { useB2BOrganization } from '@js/App/Api/B2B/B2B'
import { ziggyRoute } from '@js/App/Utils/ZiggyUrls'

defineProps({
  breadCrumbs: {
    type: Array,
    default: () => ([])
  }
})

const { organization } = useB2BOrganization()
</script>
<template>
  <div class="flex h-full items-stretch min-h-screen">
    <div class="w-[250px] min-w-[250px] max-w-[250px] bg-purple-700">
      <div class="text-xl text-white text-center font-medium mt-10 mb-4">
        Rentbird B2B
      </div>
      <div class="text-center">
        <Link
          :href="ziggyRoute('b2b.home', { organization: organization })"
          class="text-white hover:cursor-pointer hover:underline py-4 hover:bg-black hover:bg-opacity-20 transition-colors flex items-center justify-center"
        >
          <Icon name="user" icon-width="w-[18px] mr-2 relative top-[-2px]" />
          <span>Users</span>
        </Link>

        <Link
          :href="ziggyRoute('b2b.houses', { organization: organization })"
          class="text-white hover:cursor-pointer hover:underline py-4 hover:bg-black hover:bg-opacity-20 transition-colors flex items-center justify-center"
        >
          <Icon name="house" icon-width="w-[18px] mr-2 relative top-[-2px]" />
          <span>Houses</span>
        </Link>

        <a
          href="/logout"
          class="text-white hover:cursor-pointer hover:underline py-4 hover:bg-black hover:bg-opacity-20 transition-colors flex items-center justify-center"
        >
          <Icon name="logout" icon-width="w-[18px] mr-2 relative top-[-2px]" />
          <span>Logout</span>
        </a>
      </div>
    </div>
    <div class="flex-grow px-[48px] py-[48px]">
      <div v-if="breadCrumbs.length > 0" class="flex mb-2">
        <div v-for="item of breadCrumbs" :key="item.label">
          <Link v-if="item.url" :href="item.url" class="text-blue-base hover:cursor-pointer hover:underline">
            {{ item.label }}
          </Link>
          <span v-else>
            {{ item.label }}
          </span>
          <span>&nbsp;/&nbsp;</span>
        </div>
      </div>

      <h1 class="text-purple-900 font-medium text-4xl pb-4 border-b border-gray-100 mb-4">
        <slot name="title" />
      </h1>
      <slot />
    </div>
  </div>
</template>
