<script setup>
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import { usePage, Link } from '@inertiajs/vue3'
import HouseCardMatchHistory from '@js/Components/HouseCards/HouseCardMatchHistory/HouseCardMatchHistory.vue'
import InfoMessageBox from '@js/Components/InfoMessageBox/InfoMessageBox.vue'

const page = usePage()
const house = page.props.latestHouseSentToUser
</script>
<template>
  <MyRentbirdCard :default-is-expanded="true">
    <template #title>
      {{ $trans('MatchHistoryCard.Title') }}
    </template>
    <template #title-desktop-right>
      <Link
        class="text-base text-blue-base hover:cursor-pointer hover:underline transition-all relative top-[2px]"
        :href="$getLocalizedUrl('my-rentbird.match-history')"
      >
        {{ $trans('MatchHistoryCard.MatchHistoryLink') }}
      </Link>
    </template>
    <template #default>
      <HouseCardMatchHistory
        v-if="house"
        :image="house.image"
        :name="house.name"
        :found-at-formatted="house.created_at_formatted_diff"
        :rental-agent="house.rental_agent_name"
        :city="house.city"
        :bedrooms="house.bedrooms"
        :area="house.area"
        :rent="house.rent"
        :url="house.click_track_url"
      />
      <InfoMessageBox v-else color="blue">
        {{ $trans('MatchHistoryOverviewCard.NoHouseReceivedYetMessage') }}
      </InfoMessageBox>

      <div class="lg:hidden flex justify-end pt-[16px]">
        <a
          class="text-base text-blue-base hover:cursor-pointer hover:underline transition-all relative top-[6px]"
          :href="$getLocalizedUrl('my-rentbird.match-history')"
        >{{ $trans('MatchHistoryCard.MatchHistoryLink') }}</a>
      </div>
    </template>
  </MyRentbirdCard>
</template>
