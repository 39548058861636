import { getQueryParameter } from '@js/App/Utils/ReadQueryParams'

const QUERY_PARAMETER_SEARCH_PROFILE_VERSION = 'cspv'

export const getCreatSearchProfileAndAccountWizardVersionToUse = () => {
  if (isNewVersionOfSearchProfileAndAccountWizardEnabled()) {
    return 'B'
  }

  let version = localStorage.getItem('searchProfileVersion')
  if (!version) {
    version = getQueryParameter(QUERY_PARAMETER_SEARCH_PROFILE_VERSION, 'A').toUpperCase()
    if (version !== 'A' && version !== 'B') {
      version = 'A'
    }

    localStorage.setItem('searchProfileVersion', version)
  }

  return version
}

export const isNewVersionOfSearchProfileAndAccountWizardEnabled = () => true
export const isAndroidAppEnabled = () => true
export const isIphoneAppEnabled = () => true
export const isProductHuntBadgeEnabled = () => false
