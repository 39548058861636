<script setup>
import { ref } from 'vue'
import { Link } from '@inertiajs/vue3'
import RentbirdLogo from '@js/Components/Logos/RentbirdLogo/RentbirdLogo.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import TrustpilotSmall from '@js/Components/Trustpilot/TrustpilotSmall.vue'
import { useUser } from '@js/App/Api/User/User'
import NavMenuFoldoutUS from '@js/Components/NavMenu/US/NavMenuFoldoutUS.vue'

const isMobileMenuOpen = ref(false)

const { user } = useUser()

defineProps({
  showTrustpilotInsteadOfLogin: {
    type: Boolean,
    default: false
  }
})

</script>
<template>
  <div class="flex justify-between items-center ">
    <RentbirdLogo :href="$getLocalizedUrl('welcome')" />
    <div class="flex items-center">
      <div class="hidden lg:block lg:mr-6 hover:text-pink-500 p-4 text-white text-base rounded-md transition-colors">
        <div v-if="!showTrustpilotInsteadOfLogin">
          <Link v-if="!user.isLoggedIn" :href="$getLocalizedUrl('login')" class="flex">
            <Icon name="user" class="relative mr-2" />
            <span>{{ $trans('NavMenu.Login') }}</span>
          </Link>
          <Link v-else :href="$getLocalizedUrl('my-rentbird.home')" class="flex">
            <span>{{ $trans('NavMenuFoldout.MyRentbird') }}</span>
            <Icon name="trending-up" class="relative ml-2" />
          </Link>
        </div>
        <div v-else>
          <TrustpilotSmall class="justify-center" />
        </div>
      </div>
      <div class=" flex hover:text-pink-500 hover:cursor-pointer bg-purple-900 p-4 text-white text-base rounded-md transition-colors" @click="isMobileMenuOpen = true">
        <Icon name="menu" class="relative mr-2" />

        <span>{{ $trans('NavMenu.Menu') }}</span>
      </div>
      <NavMenuFoldoutUS :is-open="isMobileMenuOpen" @close="() => isMobileMenuOpen = false" />
    </div>
  </div>
</template>
