<script setup>
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import { ref } from 'vue'
import ProgressBar from '@js/Components/Forms/ProgressBar/ProgressBar.vue'
import { useUser } from '@js/App/Api/User/User'
import { saveUserPersonalData } from '@js/App/Api/User/UserPersonalData'
import { reloadPropsFromServer } from '@js/App/Api/Infra/ServerData'
import { Link, router } from '@inertiajs/vue3'
import LivingRequirementsInput from '@js/Components/Forms/LivingRequirementsInput/LivingRequirementsInput.vue'
import { useMainStore } from '@js/App/Stores/MainStore'
import { generateResponseLetters } from '@js/App/Api/User/ResponseLetters'
import Icon from '@js/Components/Icon/Icon.vue'
const { userPersonalData } = useUser()
const internalPersonalData = ref({ ...userPersonalData.value })

const errors = ref({})

const onClickNext = async () => {
  const mainStore = useMainStore()
  try {
    mainStore.startLoading()
    errors.value = {}

    const { errors: apiErrors, hasErrors } = await saveUserPersonalData(internalPersonalData.value, [
      'current_job', 'is_going_to_live_with', 'total_monthly_income', 'reason_for_moving', 'pets'
    ])
    errors.value = apiErrors

    if (hasErrors) { return }

    reloadPropsFromServer()
  } finally {
    mainStore.stopLoading()
  }
  router.get(getLocalizedUrl('my-rentbird.onboarding', { step: 6 }))
}
</script>
<template>
  <div>
    <div class="lg:flex justify-between items-center">
      <h1 class="text-[#151227] font-bold text-2xl lg:text-4xl">
        {{ $trans('MandatoryOnboardingStep5.Title') }}
      </h1>
      <div class="lg:w-[20%]">
        <ProgressBar color="green" :progress="60" :show-percentage="true" />
      </div>
    </div>
    <MyRentbirdCard class="mt-[16px] lg:mt-[20px]" :can-expand-on-mobile="false" :default-is-expanded="true">
      <LivingRequirementsInput v-if="internalPersonalData" v-model="internalPersonalData" :errors="errors" />

      <div class="flex flex-row w-full justify-end pt-[20px] lg:pt-[32px]">
        <Button
          tag="link"
          class=" lg:mb-0 mr-2"
          :big="true"
          :outline="true"
          :href="getLocalizedUrl('my-rentbird.onboarding', { step: 4 })"
        >
          <Icon name="arrow-left" />
        </Button>
        <Button
          tag="button"
          icon-right="arrow-right"
          :big="true"
          :full-width-on-mobile="true"
          class="flex-grow lg:flex-grow-0"
          @click="onClickNext"
        >
          {{ $trans('Common.Next') }}
        </Button>
      </div>
    </MyRentbirdCard>
  </div>
</template>
