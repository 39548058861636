<script setup>
import { ref, watch } from 'vue'
import Icon from '@js/Components/Icon/Icon.vue'
import TextInputWithSuggestionsDropdown
  from '@js/Components/Forms/TextInputWithSuggestionsDropdown/TextInputWithSuggestionsDropdown.vue'
import { usePlacesSearch } from '@js/App/Api/PlacesSearch'
import { getNeighbourhoodsWithCoordinatesInCity } from '@js/App/Api/NeighbourhoodsOfCity'

const props = defineProps({
  modelValue: Object,
  black: {
    type: Boolean,
    default: false
  },
  textInputMarginRight: {
    type: Boolean,
    default: true
  },
  useLandingPageStyling: {
    type: Boolean,
    default: false
  },
  error: {
    type: String,
    default: ''
  },
  showErrorBorder: {
    type: Boolean,
    default: false
  },
  blackBorder: {
    type: Boolean,
    default: false
  },
  showLabel: {
    type: Boolean,
    default: true
  },

  dusk: {
    type: [String, null],
    default: null
  }
})

const emit = defineEmits(['update:modelValue'])

const setCity = (city) => {
  getNeighbourhoodsWithCoordinatesInCity(city)
    .then((neighbourhoods) => {
      emit('update:modelValue', {
        ...city,
        neighbourhoods
      })
    })
}

const textValue = ref(props.modelValue?.name || '')
const shouldCallSearchApi = ref(false)

watch(() => props.modelValue?.city, () => {
  textValue.value = props.modelValue?.city?.name || ''
})

const { places, hasMoreResults } = usePlacesSearch(textValue, shouldCallSearchApi)

const debounceCallSearchApi = (currentValue) => {
  setTimeout(() => {
    if (textValue.value !== currentValue) { return }
    shouldCallSearchApi.value = true
  }, 300)
}

</script>
<template>
  <div class="lg:flex-grow">
    <label
      v-if="showLabel"
      class="block font-medium text-base mb-1"
      :class="{
        'text-white': !black && !useLandingPageStyling,
        'text-black': black && !useLandingPageStyling,
        'text-purple-700': useLandingPageStyling
      }"
    >
      {{ $trans('CitySelect.Label') }}
    </label>

    <div class="lg:flex lg:w-full">
      <TextInputWithSuggestionsDropdown
        class="lg:flex-grow"
        :dusk="dusk"
        :class="{
          'lg:mr-2': textInputMarginRight
        }"
        :black-border="blackBorder"
        :use-landing-page-styling="useLandingPageStyling"
        :model-value="props.modelValue"
        :text-value="textValue"
        :placeholder="$trans('CitySelect.Placeholder')"
        :error="error"
        :show-error-border="showErrorBorder"
        :should-scroll-into-view-on-mobile="useLandingPageStyling"
        :options="places.map(place => ({
          ...place,
          label: place._highlightResult?.name?.value || place.name,
        }))"
        :show-has-more-results-text="hasMoreResults"
        @update:model-value=" newValue => {
          setCity({
            id: newValue.id,
            name: newValue.name,
            coordinates: newValue.coordinates,
            hasNeighbourhoods: !!newValue.has_neighbourhoods
          })
          textValue = newValue.name
        }"
        @update:clicked-option="newValue => {
          setCity({
            id: newValue.id,
            name: newValue.name,
            coordinates: newValue.coordinates,
            hasNeighbourhoods: !!newValue.has_neighbourhoods
          })
          textValue = newValue.name
          places = []
        }"
        @update:text-value="newValue => {
          textValue = newValue
          debounceCallSearchApi(newValue)

        }"
        @click:text-input="() => {
          textValue = ''
          shouldCallSearchApi = true
        }"
        @click:outside="() => {
          if (textValue !== modelValue?.name) {
            textValue = modelValue?.name
          }
          places = []
        }"
      >
        <template #icon>
          <Icon name="search" />
        </template>
      </TextInputWithSuggestionsDropdown>
    </div>
  </div>
</template>
