import { getCurrency, getLocale } from '@js/App/Utils/CountryAndLocale'

const DEFAULT_FORMATTING = {
  nl: {
    decimalPoint: ',',
    thousandsSeparator: '.'
  },
  en: {
    decimalPoint: '.',
    thousandsSeparator: ','
  },
  us: {
    decimalPoint: '.',
    thousandsSeparator: ','
  }
}

export const formatNumber = (number, decimals, decimalPoint = null, thousandsSeparator = null) => {
  if (decimalPoint === null || thousandsSeparator === null) {
    const locale = getLocale()
    const formattingRules = DEFAULT_FORMATTING[locale]
    decimalPoint = formattingRules.decimalPoint
    thousandsSeparator = formattingRules.thousandsSeparator
  }

  number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
  const n = !isFinite(+number) ? 0 : +number
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
  const sep = thousandsSeparator
  const dec = decimalPoint
  let s = ''
  const toFixedFix = function (n, prec) {
    const k = Math.pow(10, prec)
    return '' + Math.round(n * k) / k
  }

  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
  }

  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }

  return s.join(dec)
}

export const formatPrice = (amountInCents) => {
  let amount = `${getCurrency()}${formatNumber(
      amountInCents / 100,
      2)}`
  if (amount.endsWith(',00')) {
    amount = amount.substring(0, amount.length - 3) + ',-'
  }
  if (amount.endsWith('.00')) {
    amount = amount.substring(0, amount.length - 3) + '.-'
  }
  return amount
}

export const getPriceBeforeCommaAndAfterCommaAsArray = (amountInCents) => {
  let cents = Math.round(amountInCents % 100)
  if (cents === 0) {
    cents = '00'
  }
  return [
    Math.floor(amountInCents / 100),
    cents
  ]
}

export default {
  install: (app, options) => {
    // This globally injects a formatNumber() method for translations
    app.config.globalProperties.$formatNumber = (number, decimals, decimalPoint, thousandsSeparator) => {
      return formatNumber(number, decimals, decimalPoint, thousandsSeparator)
    }

    // This globally injects a formatPrice() method for translations
    app.config.globalProperties.$formatPrice = (amountInCents) => {
      return formatPrice(amountInCents)
    }
  }
}
