<script setup>
import { computed } from 'vue'

const props = defineProps({
  modelValue: String,
  options: {
    type: Array,
    default: () => ([
      {
        value: 1,
        label: 'het getal 1'
      }
    ])
  },
  grayOnFalse: {
    type: Boolean,
    default: false
  }
})

const firstOption = computed(() => props.options?.[0] || {})
const secondOption = computed(() => props.options?.[1] || {})

defineEmits(['update:modelValue'])
</script>
<template>
  <div class="flex">
    <div
      class="text-purple-900 hover:cursor-pointer"
      :class="{
        'font-medium': modelValue === firstOption.value,
        'opacity-30': modelValue !== firstOption.value,
      }"
      @click="() => $emit('update:modelValue', firstOption.value)"
    >
      {{ firstOption.label }}
    </div>
    <div
      class="w-[46px] h-[24px] rounded-full mx-[8px] p-[1px] relative transition-colors hover:cursor-pointer"
      :class="{
        'bg-pink-500': modelValue === secondOption.value || !grayOnFalse,
        'bg-gray-50': modelValue === firstOption.value && grayOnFalse
      }"
      @click="$emit('update:modelValue', modelValue === secondOption.value ? firstOption.value : secondOption.value)"
    >
      <div
        class="w-[20px] h-[20px] bg-white rounded-full relative top-[1px] left-[1px] transition-transform"
        :class="{
          'translate-x-[22px]': modelValue === secondOption.value,
          'translate-x-[0px]': modelValue !== secondOption.value,
        }"
      />
    </div>
    <div
      class="text-purple-900 hover:cursor-pointer"
      :class="{
        'font-medium': modelValue === secondOption.value,
        'opacity-30': modelValue !== secondOption.value,
      }"
      @click="() => $emit('update:modelValue', secondOption.value)"
    >
      {{ secondOption.label }}
    </div>
  </div>
</template>
