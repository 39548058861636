<script setup>
import { usePage } from '@inertiajs/vue3'
import Container from '@js/Components/Container/Container.vue'
import UserReviewsCarouselAlternative from '@js/Components/Marketing/UserReviewsSectionAlternative/UserReviewsCarouselAlternative.vue'
import UserReviewsTrustpilotAlternative
  from '@js/Components/Marketing/UserReviewsSectionAlternative/UserReviewsTrustpilotAlternative.vue'
import TrustpilotBox from '@js/Components/Trustpilot/TrustpilotBox.vue'
import { getHardcodedReviews } from '@js/App/UserReviews'
import Button from '@js/Components/Forms/Button/Button.vue'

const props = defineProps({
  useHardcodedReviews: {
    type: Boolean,
    default: false
  }
})

const page = usePage()
const userReviews = props.useHardcodedReviews
  ? getHardcodedReviews()
  : (page?.props?.trustpilot?.reviews || [])

</script>
<template>
  <div class="bg-[#F3F1FF] py-[64px]">
    <Container :large="true">
      <div class="lg:flex justify-between items-center">
        <div>
          <h6 class="text-lg text-purple-900 lg:text-2xl lg:mb-[8px]">
            {{ $trans('UserReviewsSectionAlternative.Subtitle') }}
          </h6>
          <h2 class="text-3xl text-purple-700 font-medium lg:text-5xl">
            {{ $trans('UserReviewsSectionAlternative.Title') }}
          </h2>
        </div>
        <div class="hidden lg:block">
          <UserReviewsTrustpilotAlternative />
        </div>
      </div>
    </Container>

    <UserReviewsCarouselAlternative
      class="mt-[24px] lg:mt-[105px]"
      :reviews="userReviews"
    >
      <template #button>
        <slot v-if="$slots.button" name="button" />

        <Button
          v-else
          class="mr-4"
          :outline="true"
          :big="true"
          icon-right="arrow-right"
          tag="link"
          :href="$getLocalizedUrl('reviews')"
        >
          {{ $trans('UserReviewsCarousel.ButtonLabel') }}
        </Button>
      </template>
    </UserReviewsCarouselAlternative>

    <Container :large="true">
      <div class="lg:hidden pt-[24px] mt-[40px] border-t border-[#524498] border-opacity-30">
        <div class="flex justify-center w-full">
          <TrustpilotBox :black="true" :show-number-of-reviews-on-mobile="true" class="border-opacity-0" />
        </div>
      </div>
    </Container>
  </div>
</template>
