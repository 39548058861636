<script setup>
import { useUser } from '@js/App/Api/User/User'
import { trans } from '@js/App/Plugins/Translations'

const { user } = useUser()

defineProps({
  freeMessage: {
    type: String,
    default: () => trans('UserPaymentStatusLabel.Free')
  },
  paidMessage: {
    type: String,
    default: () => trans('UserPaymentStatusLabel.Paid')
  }
})

</script>
<template>
  <div class="flex">
    <div v-if="user.hasActiveSubscription" class="text-success border border-success rounded text-sm px-[8px] py-[3px]">
      {{ paidMessage }}
    </div>
    <div v-else class="text-danger border border-danger rounded text-sm px-[8px] py-[3px]">
      {{ freeMessage }}
    </div>
  </div>
</template>
