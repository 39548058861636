<script setup>
import InlineCheckboxGroup from '@js/Components/Forms/InlineCheckboxGroup/InlineCheckboxGroup.vue'
import { storeToRefs } from 'pinia'
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import { ref } from 'vue'
import Checkbox from '@js/Components/Forms/Checkbox/Checkbox.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import { trans } from '@js/App/Plugins/Translations'

defineProps({
  initialIsExpanded: {
    type: Boolean,
    default: false
  },
  canCollapse: {
    type: Boolean,
    default: true
  },
  options: {
    type: Array,
    default: () => ([
      { label: trans('SearchProfileWizard.Garden'), value: 'has_garden' },
      { label: trans('SearchProfileWizard.Balcony'), value: 'has_balcony' },
      { label: trans('SearchProfileWizard.Bath'), value: 'has_bath' },
      { label: trans('SearchProfileWizard.EnergyLabelCOrBetter'), value: 'has_energy_label_c_or_better' }
    ])
  }
})

const child = ref(null)

defineExpose({
  setIsExpanded: exp => child.value?.setIsExpanded(exp)
})

defineEmits(['update:isExpanded'])

const { searchProfile } = storeToRefs(useSearchProfileStore())
</script>
<template>
  <InlineCheckboxGroup
    ref="child"
    dusk="living-requirements"
    :can-collapse="canCollapse"
    :initial-is-expanded="initialIsExpanded"
    :model-value="[
      searchProfile.hasGarden ? 'has_garden' : null,
      searchProfile.hasBalcony ? 'has_balcony' : null,
      searchProfile.hasBath ? 'has_bath' : null,
      searchProfile.hasEnergyLabelCOrBetter ? 'has_energy_label_c_or_better' : null,
    ].filter(v => v)"
    :options="options"
    @update:model-value="newValue => {
      searchProfile.hasGarden = newValue.includes('has_garden')
      searchProfile.hasBalcony = newValue.includes('has_balcony')
      searchProfile.hasBath = newValue.includes('has_bath')
      searchProfile.hasEnergyLabelCOrBetter = newValue.includes('has_energy_label_c_or_better')
    }"
  >
    <div class="lg:flex lg:justify-between w-full">
      <div>{{ $trans('SearchProfileWizard.ExtraLivingWishes') }}</div>
      <div class="mt-[4px] lg:mt-0">
        <Checkbox
          v-tippy="{
            allowHTML: true,
            content: $trans('LivingRequirementsCheckboxGroup.ExplainTooltip')
          }"
          :model-value="searchProfile.houseRequirementsOperator === 'and'"
          :small="true"
          @update:model-value="newValue => {
            if (newValue) {
              searchProfile.houseRequirementsOperator = 'and'
            } else {
              searchProfile.houseRequirementsOperator = 'or'
            }
          }"
        >
          <div class="flex">
            <span class="font-normal text-base" v-html="$trans('LivingRequirementsCheckboxGroup.AndOrLabel')" />
            <Icon name="info" class="ml-[4px] mt-[2px]" />
          </div>
        </Checkbox>
      </div>
    </div>
  </InlineCheckboxGroup>
</template>
