export const getMapboxTokenAndStyleUrl = () => {
  const TOKENS = [
    {
      token: 'pk.eyJ1IjoiZGlya3JlbnRiaXJkIiwiYSI6ImNsazgyejlydzBkdnEzZnByemtiaXB2dngifQ.o519c_nRA4YXlTXoq4f4_g',
      style: 'mapbox://styles/dirkrentbird/clk8313k500mm01pdhgcpdqgj'
    },
    {
      token: 'pk.eyJ1IjoicmVudGJpcmQiLCJhIjoiY2xrYXc2YnlmMDh4czNjbzBnanY0bDVvMSJ9.PuCSbXPGL9caL-oWT2yRCA',
      style: 'mapbox://styles/rentbird/clkawa44s00rx01qy5op370sw'
    }
  ]

  const tokenSavedInUsersBrowserAsString = localStorage.getItem('mapboxToken')
  if (tokenSavedInUsersBrowserAsString) {
    return JSON.parse(tokenSavedInUsersBrowserAsString)
  }

  const token = TOKENS[Math.floor(Math.random() * TOKENS.length)]

  localStorage.setItem('mapboxToken', JSON.stringify(token))

  return token
}
