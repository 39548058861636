<script setup>
import { storeToRefs } from 'pinia'
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import InlineCheckboxGroup from '@js/Components/Forms/InlineCheckboxGroup/InlineCheckboxGroup.vue'

const { searchProfile } = storeToRefs(useSearchProfileStore())

</script>
<template>
  <InlineCheckboxGroup
    ref="child"
    dusk="extra-wishes"
    :initial-is-expanded="true"
    :can-collapse="false"
    :model-value="[
      searchProfile.includeRooms ? 'include_rooms' : null,
      searchProfile.sharing3OrMore ? 'sharing_3_or_more' : null,
      searchProfile.isForPets ? 'is_for_pets' : null,
      searchProfile.includeWoningcorporaties ? 'include_woningcorporaties' : null,
    ].filter(v => v)"
    :options="[
      searchProfile.minBedrooms <= 1 ? { label: $trans('SearchProfileWizard.AlsoSearchForRooms'), value: 'include_rooms'} : null,
      { label: $trans('SearchProfileWizard.Sharing3OrMore'), value: 'sharing_3_or_more'},
      { label: 'Geschikt voor huisdieren', value: 'is_for_pets' },
      { label: 'Search for \'free sector\' listings from housing associations (you might have to pay to sign up and view a listing)', value: 'include_woningcorporaties' },
    ].filter(v => v)"
    @update:model-value="newValue => {
      searchProfile.includeRooms= newValue.includes('include_rooms')
      searchProfile.sharing3OrMore= newValue.includes('sharing_3_or_more')
      searchProfile.isForPets = newValue.includes('is_for_pets')
      searchProfile.includeWoningcorporaties = newValue.includes('include_woningcorporaties')
    }"
  >
    Extra wishes
  </InlineCheckboxGroup>
</template>
