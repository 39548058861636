<script setup>
import DanielleTextbox from '@js/Components/MyRentbird/Offboarding/PersonTextBox/PersonTextBox.vue'
import MediaUpload from '@js/Components/Forms/MediaUpload/MediaUpload.vue'
import { ref } from 'vue'
import CheckboxAlternative from '@js/Components/Forms/Checkbox/CheckboxAlternative.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import SelfieInstructions from '@images/selfie-instructions.png'
import Image from '@js/Components/Image/Image.vue'

const mediaUploadRef = ref(null)
const hasAgreed = ref(false)

const hasSelectedAtLeastOneFile = ref(false)

defineEmits(['next-step'])
</script>
<template>
  <div>
    <DanielleTextbox>
      <template #title />
      <span v-html="$trans('UploadHappySelfie.Text')" />
    </DanielleTextbox>

    <MediaUpload
      ref="mediaUploadRef"
      class="mt-[24px] mb-[12px]"
      @selected-a-file="hasSelectedAtLeastOneFile = true"
      @images-uploaded="images => $emit('next-step', images)"
    />

    <div class="lg:hidden bg-purple-900 p-4 mb-6">
      <div class="text-white text-lg font-bold mb-1">
        {{ $trans('UploadSelfieStep.InstructionsTitle') }}
      </div>
      <Image
        class="w-full mb-2"
        :src="SelfieInstructions"
      />
      <div v-html="$trans('UploadSelfieStep.InstructionsText')" />
    </div>

    <CheckboxAlternative v-model="hasAgreed">
      {{ $trans('UploadSelfieStep.Checkbox') }}
    </CheckboxAlternative>

    <Button
      :disabled="!hasSelectedAtLeastOneFile || !hasAgreed"
      :big="true"
      icon-right="check"
      :full-width-on-mobile="true"
      class="mt-3"
      @click="() => {
        if (!hasSelectedAtLeastOneFile || !hasAgreed) { return }

        mediaUploadRef.submitFiles()
      }"
    >
      {{ $trans('UploadSelfieStep.UploadPhotosVideos') }}
    </Button>
  </div>
</template>
