<script setup>

import { computed, reactive } from 'vue'
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import TextInput from '@js/Components/Forms/TextInput/TextInput.vue'
import { router, usePage } from '@inertiajs/vue3'
import Button from '@js/Components/Forms/Button/Button.vue'
import { useB2BOrganization } from '@js/App/Api/B2B/B2B'
import { useMainStore } from '@js/App/Stores/MainStore'
import { ziggyRoute } from '@js/App/Utils/ZiggyUrls'

const errors = computed(() => usePage().props.errors)

const form = reactive({
  firstname: '',
  lastname: '',
  email: ''
})

const { organization } = useB2BOrganization()

const mainStore = useMainStore()
const submitForm = async () => {
  mainStore.startLoading()
  await router.post(
    ziggyRoute('b2b.user.register', { organization: organization.value }),
    form, {
      onFinish: () => {
        mainStore.stopLoading()
      }
    })
}
</script>
<template>
  <MyRentbirdCard class="w-[900px] max-w-full">
    <div class="mb-4 text-base text-purple-900">
      First create the user, then you can create the first search job for this user.
    </div>
    <form class="lg:flex" method="post" @submit.prevent="submitForm">
      <TextInput
        id="firstName"
        v-model="form.firstname"
        dusk="firstname"
        class="mt-4 lg:w-1/2 lg:pr-4"
        placeholder="Firstname"
        :error="errors.firstname"
        autocomplete="given-name"
      >
        <template #label>
          Firstname
        </template>
      </TextInput>

      <TextInput
        id="lastName"
        v-model="form.lastname"
        dusk="lastname"
        class="mt-4 lg:w-1/2 lg:pl-4"
        placeholder="Lastname"
        :error="errors.lastname"
        autocomplete="family-name"
      >
        <template #label>
          Lastname
        </template>
      </TextInput>
    </form>

    <TextInput
      id="email"
      v-model="form.email"
      dusk="email"
      type="email"
      class="mt-4"
      placeholder="email"
      :error="errors.email"
      autocomplete="email"
    >
      <template #label>
        Email
      </template>
    </TextInput>

    <Button :big="true" class="mt-4" @click="submitForm">
      Create user
    </Button>
  </MyRentbirdCard>
</template>
<style lang="scss">

</style>
