<script setup>
import Icon from '@js/Components/Icon/Icon.vue'
import { ref } from 'vue'
import CustomTransitionExpand from '@js/Components/Transitions/CustomTransitionExpand.vue'

const isOpen = ref(false)
</script>
<template>
  <div
    class="p-4 lg:p-6 bg-[#FAFAFA] border border-gray-100 rounded-md text-gray-900 "
  >
    <div
      class="w-full flex justify-between hover:text-blue-base items-center transition-all hover:cursor-pointer"
      @click="isOpen = !isOpen"
    >
      <span class="text-base lg:text-xl font-normal"><slot name="title" /></span>
      <span class="pl-2">
        <Icon v-show="!isOpen" name="plus" />
        <Icon v-show="isOpen" name="minus" />
      </span>
    </div>

    <CustomTransitionExpand>
      <div v-show="isOpen" class="text-left font-normal text-base mt-4">
        <slot />
      </div>
    </CustomTransitionExpand>
  </div>
</template>
