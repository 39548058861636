<script setup>
import { ref } from 'vue'
import Icon from '@js/Components/Icon/Icon.vue'
import useClickedOutside from '@js/App/Utils/ClickedOutside'

defineProps({
  modelValue: Object,

  options: {
    type: Array,
    default: () => ([])
  }
})

const isOpen = ref(false)
const selectRef = ref(null)

useClickedOutside(selectRef, () => {
  if (isOpen.value) {
    isOpen.value = false
  }
})

defineEmits(['update:modelValue'])

</script>
<template>
  <div ref="selectRef" class="relative inline">
    <div
      class="text-3xl lg:text-5xl text-purple-700 font-medium px-4 py-3 border border-purple-700 inline-block rounded-md hover:cursor-pointer mt-[4px] lg:mt-[8px]"
      :class="{
        [`rounded-b-none`]: isOpen
      }"
      @click="isOpen = !isOpen"
    >
      <div class="flex">
        <span class="flex-grow-1 pr-2">{{ modelValue?.name }}</span>
        <Icon
          class="relative lg:mt-3 mt-[3px] transition-transform"
          :class="{'rotate-180 top-[-4px]': isOpen}"
          name="caret-down"
        />
      </div>
    </div>
    <div
      v-if="isOpen"
      class="absolute right-0 z-10 mt-0 top-[40px] w-full origin-top-left border-t-0 bg-white border border-purple-700 rounded-b-md max-h-[280px] overflow-y-auto"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabindex="-1"
    >
      <div class="py-1" role="none">
        <div
          v-for="option of options"
          :key="option.name"
          class="text-purple-700 block px-4 py-2 text-lg hover:text-white cursor-pointer hover:bg-blue-base transition-colors"
          @click="() => {
            $emit('update:modelValue', option);
            isOpen = false
          }"
        >
          {{ option.name }}
        </div>
      </div>
    </div>
  </div>
</template>
