<script setup>
import FiltersInputScreenTopBar from '@js/Components/Forms/FiltersInputAllHousesPage/FiltersInputScreenTopBar.vue'
import { computed } from 'vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import InlineCheckboxGroup from '@js/Components/Forms/InlineCheckboxGroup/InlineCheckboxGroup.vue'
import { includesAny } from '@js/App/Utils/Arrays'

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({})
  }
})

const canResetExtraWishesFilter = computed(() => {
  return includesAny(props.modelValue?.extraWishes || [], ['garden', 'balcony', 'bath'])
})

const canResetSuitableForFilter = computed(() => {
  return includesAny(props.modelValue?.extraWishes || [], ['for_students', 'for_pets', 'for_sharers'])
})

defineEmits(['update:modelValue', 'click:back', 'click:close'])
</script>
<template>
  <div>
    <FiltersInputScreenTopBar
      :title="$trans('FiltersInput.ExtraWishes')"
      class="mb-[0px]"
      @click:back="$emit('click:back')"
      @click:close="$emit('click:close')"
    />

    <InlineCheckboxGroup
      :model-value="modelValue.extraWishes || []"
      :initial-is-expanded="true"
      :can-collapse="false"
      :options="[
        {
          label: $trans('FiltersInputFurnishedScreen.Garden'),
          value: 'garden',
        },
        {
          label: $trans('FiltersInputFurnishedScreen.Balcony'),
          value: 'balcony',
        },
        {
          label: $trans('FiltersInputFurnishedScreen.Bath'),
          value: 'bath',
        }
      ]"
      @update:model-value="newValue => $emit('update:modelValue', {
        ...modelValue,
        extraWishes: newValue
      })"
    >
      {{ $trans('FitlersInputExtraWishesScreen.SpecificLivingWishes') }}
    </InlineCheckboxGroup>

    <div class="w-full flex justify-end mb-[16px] mt-[24px]">
      <a
        class="text-base text-blue-300"
        :class="{
          'hover:underline hover:cursor-pointer': canResetExtraWishesFilter,
          'opacity-30': !canResetExtraWishesFilter
        }"
        @click.prevent="() => {
          $emit('update:modelValue', {
            ...modelValue,
            extraWishes: (modelValue.extraWishes || []).filter(item => item !== 'garden' && item !== 'bath' && item !== 'balcony')
          })
        }"
      >
        {{ $trans('FilterInputLocationScreen.ResetFilter') }}
      </a>
    </div>

    <InlineCheckboxGroup
      :model-value="modelValue.extraWishes || []"
      class=""
      :initial-is-expanded="true"
      :can-collapse="false"
      :options="[
        {
          label: $trans('FiltersInputExtraWishesScreen.Students'),
          value: 'for_students',
        },
        {
          label: $trans('FiltersInputExtraWishesScreen.Pets'),
          value: 'for_pets',
        },
        {
          label: $trans('FiltersInputExtraWishesScreen.Sharers'),
          value: 'for_sharers',
        }
      ]"
      @update:model-value="newValue => $emit('update:modelValue', {
        ...modelValue,
        extraWishes: newValue
      })"
    >
      {{ $trans('FitlersInputExtraWishesScreen.SuitableFor') }}
    </InlineCheckboxGroup>

    <div class="w-full flex justify-end mb-[24px] mt-[24px]">
      <a
        class="text-base text-blue-300"
        :class="{
          'hover:underline hover:cursor-pointer': canResetSuitableForFilter,
          'opacity-30': !canResetSuitableForFilter
        }"
        @click.prevent="() => {
          $emit('update:modelValue', {
            ...modelValue,
            extraWishes: (modelValue.extraWishes || []).filter(item => item !== 'for_students' && item !== 'for_pets' && item !== 'for_sharers')
          })
        }"
      >
        {{ $trans('FilterInputLocationScreen.ResetFilter') }}
      </a>
    </div>

    <Button
      :big="true"
      :full-width-on-mobile="true"
      icon-right="back"
      :outline="true"
      @click="() => {
        $emit('click:back')
      }"
    >
      {{ $trans('FiltersInputLocationScreen.ApplyAndGoBack') }}
    </Button>
  </div>
</template>
