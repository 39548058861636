<script setup>
import Icon from '@js/Components/Icon/Icon.vue'
import { computed, ref } from 'vue'
import useClickedOutside from '@js/App/Utils/ClickedOutside'
import Button from '@js/Components/Forms/Button/Button.vue'
import ToggleSelectAll from '@js/Components/Forms/CheckboxDropdown/ToggleSelectAll.vue'
import { useMainStore } from '@js/App/Stores/MainStore'
import ErrorMessage from '@js/Components/Forms/ErrorMessage/ErrorMessage.vue'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'
import { cutoffIfTooLong } from '@js/App/Utils/StringFormatting'

const props = defineProps({
  modelValue: [Array],
  options: {
    type: Array,
    default: () => ([
      { label: 'This is the label', value: 'this_is_the_value' }
    ])
  },
  isAllOptionsSelected: {
    type: [Boolean, null],
    default: null
  },
  error: {
    type: String,
    default: ''
  },
  useLandingPageStyling: {
    type: Boolean,
    default: false
  },
  maxLengthDesktop: {
    type: Number,
    default: 80
  },
  placeholder: {
    type: String,
    default: ''
  },
  dusk: {
    type: [String, null],
    default: null
  }
})

defineEmits([
  'update:modelValue'
])

const mainStore = useMainStore()

const isOpen = ref(false)
const iJustGotOpened = ref(false)
const iJustGotClosed = ref(false)
const dropdownRef = ref(null)

const isEnabled = computed(() => (props.options || []).length > 0)

const { isMobile } = useIsMobile()

useClickedOutside(dropdownRef, () => {
  isOpen.value = false
})

const setDropdownIsOpen = (dropdownIsOpen) => {
  const { isMobile } = useIsMobile()

  if (!isMobile.value) {
    isOpen.value = dropdownIsOpen
    return
  }

  iJustGotOpened.value = dropdownIsOpen
  iJustGotClosed.value = !dropdownIsOpen

  if (dropdownIsOpen) {
    isOpen.value = dropdownIsOpen
  } else {
    setTimeout(() => {
      isOpen.value = false
    }, 200)
  }
  mainStore.setIsFullScreenInputActive(dropdownIsOpen)

  setTimeout(() => {
    iJustGotOpened.value = false
  }, 1)
}
</script>
<template>
  <div
    ref="dropdownRef"
    class="relative"
  >
    <div
      :dusk="dusk"
      class="flex items-center justify-between outline-0 w-full rounded placeholder-gray-400 text-base text-gray-900 border hover:cursor-pointer"
      :class="{
        'bg-gray-200 bg-opacity-20': !isEnabled && !useLandingPageStyling,
        'border-gray-200': !error && !useLandingPageStyling,
        'border-[rgb(152,153,154)] bg-white': useLandingPageStyling,
        'border-danger': error,
      }"
    >
      <span v-if="isOpen" class="flex flex-grow">
        <ToggleSelectAll
          class="p-4"
          :is-all-options-selected="isAllOptionsSelected !== null
            ? isAllOptionsSelected // In this case we explicitly got a prop telling us if everything is selected.
            : modelValue.length === options.length"
          @click:select-all="$emit('update:modelValue', [...options.map(item => item.value)])"
          @click:deselect-all="$emit('update:modelValue', [])"
        />
        <span class="flex-grow" @click="setDropdownIsOpen(!isOpen)">&nbsp;</span>
      </span>
      <span
        v-else
        class="flex-grow px-4 py-[15px]"
        :class="{
          'text-purple-700': !useLandingPageStyling,
          'text-gray-900': useLandingPageStyling,
        }"
        @click="() => { if (isEnabled) { setDropdownIsOpen(true) } }"
      >
        <span v-if="$slots.itemsSelectedMessage"><slot name="itemsSelectedMessage" /></span>
        <span v-else>
          <span v-if="modelValue.length <= 0" class="text-gray-400">{{ placeholder ? placeholder : $trans('CheckboxDropdown.Placeholder') }}</span>
          <span v-else>
            {{ cutoffIfTooLong(
              options.filter(item => modelValue.includes(item.value)).map(item => item.label).join(', '),
              isMobile ? 30 : maxLengthDesktop
            ) }}
          </span>
        </span>
      </span>

      <Icon
        name="caret-down"
        :class="{
          'pr-4': !isOpen,
          'rotate-180 pl-4': isOpen
        }"
        icon-width="w-[26px]"
        @click="() => {
          if (isEnabled) { setDropdownIsOpen(!isOpen) }
        }"
      />
    </div>
    <ErrorMessage v-if="error" class="mt-[2px]">
      {{ error }}
    </ErrorMessage>

    <!-- Dropdown -->
    <div
      v-if="isOpen || iJustGotOpened || iJustGotClosed"
      :class="{
        'fixed top-0 left-0 right-0 bottom-0 bg-white text-gray-900 z-20 overflow-y-auto lg:absolute lg:w-full lg:max-h-[250px] lg:h-auto lg:rounded-b lg:border lg:border-t-0 lg:top-[53px] lg:left-auto lg:bottom-auto transition-transform z-200': true,
        'lg:border-gray-200': !error,
        'lg:border-danger': error,
        'translate-y-vscreen': iJustGotOpened,
        'scroll-down': iJustGotClosed
      }"
      style="box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.075);"
    >
      <div
        class="flex justify-between lg:hidden mt-4 border-b pb-3 mb-4 border-dashed border-gray-200 px-4 lg:px-0"
      >
        <ToggleSelectAll
          :is-all-options-selected="isAllOptionsSelected !== null
            ? isAllOptionsSelected // In this case we explicitly got a prop telling us if everything is selected.
            : modelValue.length !== options.length"
          @click:select-all="$emit('update:modelValue', [...options.map(item => item.value)])"
          @click:deselect-all="$emit('update:modelValue', [])"
        />
        <Icon class="text-gray-900" name="cross" @click="setDropdownIsOpen(false)" />
      </div>
      <slot name="options" />
      <div v-if="!$slots.options">
        <div
          v-for="item of options"
          :key="item.value"
          class="flex justify-between px-4 py-3 text-base transition-colors hover:cursor-pointer"
          @click="() => {
            $emit('update:modelValue', modelValue.includes(item.value)
              ? [...modelValue.filter(item2 => item2 !== item.value)]
              : [...modelValue, item.value]
            )
          }"
        >
          <span>{{ item.label }}</span>
          <Icon v-if="!modelValue.includes(item.value)" name="box" />
          <Icon v-else name="box-checked" />
        </div>
      </div>
      <div class="block lg:hidden px-4">
        <Button :outline="false" :full-width-on-mobile="true" class="mt-4 mb-4" @click="setDropdownIsOpen(false)">
          {{ $trans('CheckboxDropdown.SaveAndCloseButtonLabel') }}
        </Button>
      </div>
    </div>
  </div>
</template>
