<script setup>
import Select from '@js/Components/Forms/Select/Select.vue'
import { storeToRefs } from 'pinia'
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import { COUNTRY_NL, getCountry, getCurrency } from '@js/App/Utils/CountryAndLocale'
import { computed } from 'vue'
import Checkbox from '@js/Components/Forms/Checkbox/Checkbox.vue'

defineProps({
  showSendMoreExpensiveHousesCheckbox: {
    type: Boolean,
    default: false
  },
  error: {
    type: String,
    default: ''
  }
})

const { searchProfile } = storeToRefs(useSearchProfileStore())

const options = computed(() => {
  if (getCountry() === COUNTRY_NL) {
    return [750, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000, 2250, 2500, 2750, 3000, 3250, 3500, 4000, 4500, 5000]
  }

  return [1000, 1250, 1500, 1750, 2000, 2500, 3000, 3500, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 12500, 15000, 17500, 20000]
})
</script>
<template>
  <div>
    <Select
      v-model="searchProfile.maxRent"
      dusk="max-rent"
      :error="error"
      :plus-and-minus-buttons="true"
      :options="options.map(item => ({
        value: item,
        label: `${getCurrency()}${$formatNumber(item, 0)}`
      }))"
    >
      <template #label>
        <div
          :class="{
            'flex justify-between w-full': showSendMoreExpensiveHousesCheckbox
          }"
        >
          <div>{{ $trans('SearchProfileWizard.MaxRent') }}</div>
          <Checkbox
            v-if="showSendMoreExpensiveHousesCheckbox"
            :small="true"
            class="hidden lg:flex"
            :model-value="searchProfile.sendMoreExpensiveHouses"
            @update:model-value="newValue => {
              searchProfile.sendMoreExpensiveHouses = !searchProfile.sendMoreExpensiveHouses
            }"
          >
            <span class="text-base font-normal">{{ $trans('MaxRentSelect.CheckboxLabel') }}</span>
          </Checkbox>
        </div>
      </template>
    </Select>
    <Checkbox
      v-if="showSendMoreExpensiveHousesCheckbox"
      :small="true"
      class="lg:hidden mt-[2px]"
      :model-value="searchProfile.sendMoreExpensiveHouses"
      @update:model-value="newValue => {
        searchProfile.sendMoreExpensiveHouses = !searchProfile.sendMoreExpensiveHouses
      }"
    >
      <span class="text-base font-normal">{{ $trans('MaxRentSelect.CheckboxLabel') }}</span>
    </Checkbox>
  </div>
</template>
