<script setup>
import { useUser } from '@js/App/Api/User/User'

const { user } = useUser()
</script>
<template>
  <div class="w-[40px] h-[40px] lg:w-[50px] lg:h-[50px] rounded-full bg-pink-200 flex justify-center items-center hover:bg-pink-250 transition-colors">
    <span class="text-black text-base font-normal lg:text-xl">
      {{ user.firstNameFormatted.charAt(0) }}{{ user.lastNameFormatted.charAt(0) }}
    </span>
  </div>
</template>
