<script setup>
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import { storeToRefs } from 'pinia'
import NumberOfMatchesBox from '@js/Components/NumberOfMatchesBox/NumberOfMatchesBox.vue'

const { matchesPerWeek } = storeToRefs(useSearchProfileStore())

</script>
<template>
  <NumberOfMatchesBox :is-loading="matchesPerWeek?.isLoading" :matches-per-week="matchesPerWeek?.matchesPerWeek" />
</template>
