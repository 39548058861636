<script setup>
import DistanceSelect from '@js/Components/Forms/DistanceSelect/DistanceSelect.vue'
import NeighbourhoodSelectUsingStore from '@js/Components/Forms/NeighbourhoodSelect/NeighbourhoodSelectUsingStore.vue'
import { storeToRefs } from 'pinia'
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import MaxRentSelect from '@js/Components/Forms/MaxRentSelect/MaxRentSelect.vue'
import MinSurfaceSelect from '@js/Components/Forms/MinSurfaceSelect/MinSurfaceSelect.vue'
import LivingRequirementsCheckboxGroup
  from '@js/Components/Forms/LivingRequirementsCheckboxGroup/LivingRequirementsCheckboxGroup.vue'
import { trans } from '@js/App/Plugins/Translations'
import { onMounted, ref } from 'vue'
import HouseRequirementsNotClearCheckbox
  from '@js/Components/Forms/EnergyLabelCheckbox/HouseRequirementsNotClearCheckbox.vue'
import NumberOfMatchesBoxUseCurrentSearchProfile
  from '@js/Components/NumberOfMatchesBox/NumberOfMatchesBoxCurrentSearchProfile.vue'

const { searchProfile } = storeToRefs(useSearchProfileStore())

defineProps({
  errors: {
    type: Object,
    default: () => ({})
  }
})

const livingRequirementOptions = ref([
  { label: trans('SearchProfileWizard.Garden'), value: 'has_garden' },
  { label: trans('SearchProfileWizard.Balcony'), value: 'has_balcony' },
  { label: trans('SearchProfileWizard.Bath'), value: 'has_bath' },
  { label: trans('SearchProfileWizard.EnergyLabelCOrBetter'), value: 'has_energy_label_c_or_better' }
])

const showRequirementsNotClearCheckbox = ref(false)

onMounted(() => {
  livingRequirementOptions.value = livingRequirementOptions.value.filter((item) => {
    if (searchProfile.value.hasGarden && item.value === 'has_garden') { return true }
    if (searchProfile.value.hasBalcony && item.value === 'has_balcony') { return true }
    if (searchProfile.value.hasBath && item.value === 'has_bath') { return true }
    if (searchProfile.value.hasEnergyLabelCOrBetter && item.value === 'has_energy_label_c_or_better') { return true }

    return false
  })

  if (livingRequirementOptions.value.length > 0 && !searchProfile.value.notifyWhenRequirementsNotClear) {
    showRequirementsNotClearCheckbox.value = true
  }
})
</script>
<template>
  <div>
    <h4 class="text-lg text-purple-900 font-medium mb-[2px] lg:text-xl">
      {{ $trans('StepChangeValuesBecauseLowNumberOfMatches.Title') }}
    </h4>
    <p
      class="text-base text-purple-900"
      v-html="$trans('StepChangeValuesBecauseLowNumberOfMatches.Content')"
    />

    <div class="lg:flex lg:space-x-4 lg:mt-[16px]">
      <div class="lg:w-1/3">
        <NeighbourhoodSelectUsingStore
          v-show="searchProfile.locationSelector === 'neighbourhoods'"
          class="mt-4 lg:mt-0"
          :error="errors.neighbourhoods"
          @click:distance="searchProfile.locationSelector = 'distance'"
        />
        <DistanceSelect
          v-if="searchProfile.locationSelector === 'distance'"
          class="mt-4 lg:mt-0"
          :error="errors.distance"
          @click:neighbourhoods="searchProfile.locationSelector = 'neighbourhoods'"
        />

        <p class="text-sm text-gray-400 mt-[2px]">
          {{ searchProfile.city?.name }}
        </p>
      </div>

      <MaxRentSelect
        class="mt-[16px] lg:mt-0 lg:w-1/3"
        :show-send-more-expensive-houses-checkbox="false"
        :error="errors.max_rent"
      />

      <MinSurfaceSelect class="mt-[16px] lg:mt-0 lg:w-1/3" :error="errors.min_surface" />
    </div>

    <div class="lg:min-h-[150px]">
      <LivingRequirementsCheckboxGroup
        v-if="livingRequirementOptions.length > 0"
        class="mt-[16px]"
        :initial-is-expanded="true"
        :can-collapse="false"
        :options="livingRequirementOptions"
      />

      <HouseRequirementsNotClearCheckbox
        v-if="showRequirementsNotClearCheckbox"
        class="mt-[16px]"
        :can-collapse="false"
      />
    </div>

    <NumberOfMatchesBoxUseCurrentSearchProfile class="hidden lg:flex lg:mt-[22px]" />
  </div>
</template>
