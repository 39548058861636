<script setup>
import MyRentbirdBackButton from '@js/Components/MyRentbird/MyRentbirdBackButton/MyRentbirdBackButton.vue'
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import { usePage, Link } from '@inertiajs/vue3'
import HouseCardMatchHistory from '@js/Components/HouseCards/HouseCardMatchHistory/HouseCardMatchHistory.vue'
import HoursSinceHouseFoundMessage
  from '@js/Components/MyRentbird/HoursSinceHouseFoundMessage/HoursSinceHouseFoundMessage.vue'
import InfoMessageBox from '@js/Components/InfoMessageBox/InfoMessageBox.vue'
import WarningTriangle from '@images/icons/warning-triangle.svg'
import Button from '@js/Components/Forms/Button/Button.vue'
import TextArea from '@js/Components/Forms/TextArea/TextArea.vue'
import Checkbox from '@js/Components/Forms/Checkbox/Checkbox.vue'
import { ref, watch } from 'vue'
import { doPostRequest } from '@js/App/Api/Infra/ApiClient'
import { copyTextToClipboard } from '@js/App/Utils/Clipboard'
import MyRentbirdLayout from '@js/Components/MyRentbird/MyRentbirdLayout/MyRentbirdLayout.vue'
import MobileAppBottomModal from '@js/Components/Modals/MobileAppBottomModal.vue'

const page = usePage()
const house = page.props.house
const rentalAgentProfile = page.props.rental_agent_profile
const responseLetter = page.props.response_letter?.replaceAll('\\n', '\n')

const showHousePage = ref(!!page.props.show_house_page)

watch(showHousePage, () => {
  doPostRequest('/api/user/show-house-detail-page', {
    show_house_detail_page: !!showHousePage.value
  })
})

const showCopySuccessMessage = ref(false)
const onClickCopyLetter = async () => {
  await copyTextToClipboard(responseLetter)

  showCopySuccessMessage.value = true
}

</script>
<template>
  <MyRentbirdLayout>
    <MyRentbirdBackButton />

    <MyRentbirdCard
      class="mt-[16px]"
      :can-expand-on-mobile="false"
    >
      <template #title>
        {{ $trans('HousePage.Title') }}
      </template>
      <template #title-desktop-right>
        <HoursSinceHouseFoundMessage
          class="relative top-[8px]"
          :hours="house.hours_since_house_found"
        />
      </template>
      <template #default>
        <p class="text-purple-900 lg:text-lg" v-html="$trans('HousePage.Subtitle')" />

        <div class="lg:flex mt-[24px]">
          <HouseCardMatchHistory
            class="lg:min-w-[464px] lg:mr-[12px]"
            :bedrooms="house.bedrooms"
            :found-at-formatted="house.created_at_formatted_diff"
            :city="house.city"
            :name="house.name"
            :area="house.area"
            :rent="house.rent"
            :image="house.image_url"
            :rental-agent="house.rental_agent_name"
            :url="house.detail_url"
          />

          <div class="hidden lg:block w-full min-h-full lg:ml-[12px]">
            <TextArea
              class="lg:h-[350px]"
              :model-value="responseLetter"
              :disabled="true"
            />

            <div class="flex w-full justify-end mt-[4px]">
              <Link :href="$getLocalizedUrl('my-rentbird.response-letter')" class="text-blue-base hover:cursor-pointer hover:underline">
                {{ $trans('MyRentbird.EditResponseLetter') }}
              </Link>
            </div>
          </div>
        </div>

        <HoursSinceHouseFoundMessage
          class="pt-[24px] lg:hidden"
          :hours="house.hours_since_house_found"
        />

        <InfoMessageBox
          v-if="rentalAgentProfile?.requires_payment_amount > 0"
          class="mt-[24px]"
          color="pink"
          :image="WarningTriangle"
        >
          <div
            v-html="$trans('HousePage.PaidWebsiteInfoBox', {
              url: $getLocalizedUrl('my-rentbird.preferences')
            })"
          />
        </InfoMessageBox>

        <InfoMessageBox
          v-if="rentalAgentProfile?.individuals_place_houses"
          class="mt-[24px]"
          color="pink"
          :image="WarningTriangle"
        >
          <div
            v-html="$trans('HousePage.IndividualsPlaceHousesInfoBox')"
          />
        </InfoMessageBox>

        <Button
          tag="a"
          :href="house.detail_url"
          :full-width-on-mobile="true"
          icon-right="external"
          class="mt-[24px] lg:mr-[8px]"
        >
          {{ $trans('HousePage.ViewHouse') }}
        </Button>

        <Button
          :outline="true"
          :full-width-on-mobile="true"
          :icon-right="!showCopySuccessMessage ? 'copy' : 'check'"
          class="mt-[8px]"
          @click="onClickCopyLetter"
        >
          <span v-if="!showCopySuccessMessage">{{ $trans('HousePage.CopyLetter') }}</span>
          <span v-else>{{ $trans('Common.Copied') }}</span>
        </Button>

        <Checkbox
          :model-value="!showHousePage"
          class="mt-[24px]"
          @update:model-value="newValue => showHousePage = !newValue"
        >
          {{ $trans('HousePage.CheckboxLabel') }}
        </Checkbox>

        <TextArea
          class="mt-[24px] lg:hidden"
          :model-value="responseLetter?.replaceAll('\\n', '\n')"
          :disabled="true"
        />

        <div class="lg:hidden flex w-full justify-end">
          <Link :href="$getLocalizedUrl('my-rentbird.response-letter')" class="text-blue-base hover:cursor-pointer hover:underline">
            {{ $trans('MyRentbird.EditResponseLetter') }}
          </Link>
        </div>
      </template>
    </MyRentbirdCard>
  </MyRentbirdLayout>
  <MobileAppBottomModal />
</template>
<style lang="scss">
.MyRentbird-HousePage {
  background: rgb(234,234,235);
}
</style>
