<script setup>
import ImageAndroidDownload from '@images/button-android-download.svg'
import ImageIosDownload from '@images/button-ios-download.svg'

import ImageAndroidDownloadBlack from '@images/button-android-download-black.svg'
import ImageIosDownloadBlack from '@images/button-ios-download-black.svg'

import ImageAndroidDownloadOutline from '@images/button-android-download-outline.svg'
import ImageIosDownloadOutline from '@images/button-ios-download-outline.svg'

import Image from '@js/Components/Image/Image.vue'

function getImage (isAndroid, isBlack, isOutline) {
  if (isAndroid) {
    if (isBlack) {
      return ImageAndroidDownloadBlack
    } else if (isOutline) {
      return ImageAndroidDownloadOutline
    } else {
      return ImageAndroidDownload
    }
  }

  if (isBlack) {
    return ImageIosDownloadBlack
  } else if (isOutline) {
    return ImageIosDownloadOutline
  } else {
    return ImageIosDownload
  }
}

defineProps({
  isAndroid: {
    type: Boolean,
    default: false
  },
  href: {
    type: String,
    default: '#'
  },
  target: {
    type: String,
    default: '#'
  },
  isBlack: {
    type: Boolean,
    default: false
  },
  isOutline: {
    type: Boolean,
    default: false
  },
  wOnImage: {
    type: Boolean,
    default: true
  }
})
</script>
<template>
  <a
    :href="href"
    class="hover:cursor-pointer group"
    :target="target"
  >
    <Image
      :class="{
        'w-[137px] lg:w-[205px]': wOnImage
      }"
      class="group-hover:opacity-90 transition-all "
      :src="getImage(isAndroid, isBlack, isOutline)"
    />
  </a>
</template>
