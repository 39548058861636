<script setup>
import { ref } from 'vue'
import TinyStepLabel from '@js/Components/Marketing/TheRentalHackSection/StefAndDirk/TinyStepLabel.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import Image from '@js/Components/Image/Image.vue'
import StefAndDirkImage from '@images/people/stef-and-dirk.webp'
import StefAndDirkImageSmall from '@images/people/stef-and-dirk-360x350.webp'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'
import Container from '@js/Components/Container/Container.vue'

const isExpanded = ref(false)

const { isMobile } = useIsMobile()

</script>
<template>
  <Container>
    <div class="block lg:flex">
      <div class="flex justify-center">
        <div>
          <Image v-if="!isMobile" class="block z-10 relative w-[350px] lg:min-w-[350px] xl:min-w-[500px] max-w-full" :lazy="true" :src="StefAndDirkImage" />
          <Image v-if="isMobile" class="block z-10 relative w-[350px] lg:min-w-[350px] xl:min-w-[500px] max-w-full" :lazy="true" :src="StefAndDirkImageSmall" />
        </div>
      </div>

      <div class="p-8 relative top-[-20px] lg:left-[-70px] rounded-md lg:p-10 lg:max-w-full" style="background-color: #F0F6FB;">
        <h4 class="text-3xl text-purple-700 font-medium mb-2 lg:text-5xl lg:mb-4">
          {{ $trans('StefAndDirk.Title') }}
        </h4>

        <div class="bg-blue-100 rounded-md p-2 text-base font-medium text-purple-700 lg:text-2xl lg:font-normal">
          {{ $trans('StefAndDirk.Quote') }}
        </div>

        <p class="text-base text-purple-700 mt-6 lg:text-xl">
          {{ $trans('StefAndDirk.Intro') }}
        </p>

        <div
          :class="{
            'hidden': isExpanded,
            'block': !isExpanded
          }"
          class="mt-8 mb-10 text-center lg:hidden"
        >
          <span class="text-base font-medium text-purple-700 underline hover:cursor-pointer lg:text-xl" @click="() => { isExpanded = true }">
            {{ $trans('StefAndDirk.ClickToReadMore') }}
          </span>
        </div>
        <div
          :class="{
            'hidden': !isExpanded,
            'block': isExpanded
          }"
          class="mt-8 mb-10 lg:block"
        >
          <div class="flex mb-4 mt-5 lg:mb-5 ">
            <TinyStepLabel class="min-w-[24px]" step="1" />
            <p class="text-base text-purple-700 pl-2 lg:text-xl lg:pl-4" v-html="$trans('StefAndDirk.Item1')" />
          </div>

          <div class="flex mb-4 lg:mb-5">
            <TinyStepLabel class="min-w-[24px]" step="2" />
            <p class="text-base text-purple-700 pl-2 lg:text-xl lg:pl-4" v-html="$trans('StefAndDirk.Item2')" />
          </div>

          <div class="flex mb-4 lg:mb-5">
            <TinyStepLabel class="min-w-[24px]" step="3" />
            <p class="text-base text-purple-700 pl-2 lg:text-xl lg:pl-4" v-html="$trans('StefAndDirk.Item3')" />
          </div>

          <p class="text-left text-base text-purple-700 mt-5 lg:text-xl lg:mt-6" v-html="$trans('StefAndDirk.Outro')" />

          <p class="text-left text-base text-purple-700 mt-5 lg:text-xl" v-html="$trans('StefAndDirk.Conclusion')" />
        </div>

        <slot name="button" />

        <Button
          v-if="!$slots.button"
          tag="link"
          :href="$getLocalizedUrl('faq')"
          :big="true"
          :outline="true"
          :full-width-on-mobile="true"
          icon-right="arrow-right"
          class="lg:mr-4"
        >
          {{ $trans('StefAndDirk.ButtonLabelFaq') }}
        </Button>

        <Button
          v-if="!$slots.button"
          tag="link"
          :href="$getLocalizedUrl('about-us')"
          :big="true"
          :outline="true"
          :full-width-on-mobile="true"
          icon-right="arrow-right"
          class="mt-4 lg:mt-0"
        >
          {{ $trans('StefAndDirk.ButtonLabelAboutUs') }}
        </Button>
      </div>
    </div>
  </Container>
</template>
