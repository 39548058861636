<script setup>
import Container from '@js/Components/Container/Container.vue'
import FeaturesAlternativeCard from '@js/Components/Marketing/FeaturesAlternativeSection/FeaturesAlternativeCard.vue'
import ImageNewYorkIphoneMobile from '@images/new-york-iphone-mobile.png'
import ImageNewYorkIphoneDesktop from '@images/new-york-iphone-desktop.png'
import Image from '@js/Components/Image/Image.vue'
import ImageManhattanSearchProfile from '@images/manhattan-search-profile.png'
import ImageManhattanSearchProfileDesktop from '@images/manhattan-search-profile-desktop.png'
import ImageNewYorkResponseLetter from '@images/new-york-response-letter.png'

import ImageNewYorkResponseLetterDesktop from '@images/new-york-response-letter-desktop.png'
import ImageNewYorkFamilyMobile from '@images/new-york-family-mobile.png'
import ImageNewYorkFamilyDesktop from '@images/new-york-family-desktop.png'
import ImageBirdsFlyingLetter from '@images/birds-flying-letter.png'
import FeaturesAlternativeSingleValueCard
  from '@js/Components/Marketing/FeaturesAlternativeSection/FeaturesAlternativeSingleValueCard.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { showCreateSearchProfileWizard } from '@js/App/SearchProfile'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'
import { onlyHasTrialPriceAvailable } from '@js/App/Prices'

const { isMobile } = useIsMobile()
</script>
<template>
  <Container>
    <h6 class="text-lg lg:text-2xl text-purple-900 mb-[4px]">
      {{ $trans('FeaturesAlternativeSection.Subtitle') }}
    </h6>
    <h2 class="text-3xl lg:text-5xl text-purple-700 font-medium mb-[24px] lg:mb-[48px]">
      {{ $trans('FeaturesAlternativeSection.Title') }}
    </h2>

    <div class="xl:flex xl:space-x-[24px]">
      <div class="basis-[462px]">
        <FeaturesAlternativeCard :padding="false" class="mb-[24px]">
          <template #title>
            <div class="px-[32px] pt-[32px] pb-[8px] lg:pb-[12px]">
              {{ $trans('FeaturesAlternativeSection.Feature1Title') }}
            </div>
          </template>
          <div class="px-[32px] pb-[26px]" v-html="$trans('FeaturesAlternativeSection.Feature1Content')" />

          <div class="flex w-full justify-center">
            <Image :src="isMobile ? ImageNewYorkIphoneMobile : ImageNewYorkIphoneDesktop" class="w-[340px] lg:w-full lg:relative lg:top-[15px]" />
          </div>
        </FeaturesAlternativeCard>

        <FeaturesAlternativeCard class="mb-[24px] pb-0" :padding="false">
          <template #title>
            <div class="px-[32px] pt-[32px] pb-[8px] lg:pb-[12px]">
              {{ $trans('FeaturesAlternativeSection.Feature2Title') }}
            </div>
          </template>

          <div class="px-[32px] pb-[26px]" v-html="$trans('FeaturesAlternativeSection.Feature2Content')" />

          <div class="flex w-full justify-center">
            <Image :src="isMobile ? ImageManhattanSearchProfile : ImageManhattanSearchProfileDesktop" class="w-[315px] lg:w-full" />
          </div>
        </FeaturesAlternativeCard>

        <FeaturesAlternativeCard class="mb-[24px] pb-0" :padding="false">
          <template #title>
            <div class="px-[32px] pt-[32px] pb-[8px] lg:pb-[12px]">
              {{ $trans('FeaturesAlternativeSection.Feature3Title') }}
            </div>
          </template>

          <div class="px-[32px] pb-[26px]" v-html="$trans('FeaturesAlternativeSection.Feature3Content')" />

          <div class="flex w-full justify-center">
            <Image :src="isMobile ? ImageNewYorkResponseLetter : ImageNewYorkResponseLetterDesktop" class="w-[320px] xl:w-[378px]" />
          </div>
        </FeaturesAlternativeCard>
      </div>

      <div class="basis-[492px]">
        <FeaturesAlternativeCard class="mb-[24px] pb-0" :padding="false">
          <div class="flex w-full justify-center">
            <Image :src="isMobile ? ImageNewYorkFamilyMobile : ImageNewYorkFamilyDesktop" class="w-full rounded-t-md" />
          </div>

          <h4 class="text-xl lg:text-3xl font-medium text-purple-900 mt-[24px] px-[32px] pb-[8px]">
            {{ $trans('FeaturesAlternativeSection.Feature4Title') }}
          </h4>
          <div class="px-[32px] pb-[26px]" v-html="$trans('FeaturesAlternativeSection.Feature4Content')" />
        </FeaturesAlternativeCard>

        <FeaturesAlternativeCard class="mb-[24px] pb-0" :padding="false">
          <template #title>
            <div class="px-[32px] pt-[32px] pb-[8px]">
              {{ $trans('FeaturesAlternativeSection.Feature5Title') }}
            </div>
          </template>

          <div class="px-[32px] pb-[26px]" v-html="$trans('FeaturesAlternativeSection.Feature5Content')" />

          <div class="flex w-full justify-center">
            <Image :src="ImageBirdsFlyingLetter" class="w-[359px] relative left-[-32px]" />
          </div>
        </FeaturesAlternativeCard>
      </div>

      <div class="basis-[288px]">
        <FeaturesAlternativeSingleValueCard
          class="mb-[24px]"
          :top-title="$trans('FeaturesAlternativeSection.SingleValue1TopTitle')"
          :title="$trans('FeaturesAlternativeSection.SingleValue1Title')"
          :bottom-title="$trans('FeaturesAlternativeSection.SingleValue1BottomTitle')"
        />

        <FeaturesAlternativeSingleValueCard
          class="mb-[24px]"
          :top-title="$trans('FeaturesAlternativeSection.SingleValue2TopTitle')"
          :title="$trans('FeaturesAlternativeSection.SingleValue2Title')"
          :bottom-title="$trans('FeaturesAlternativeSection.SingleValue2BottomTitle')"
        />

        <FeaturesAlternativeSingleValueCard
          class="mb-[24px]"
          :top-title="$trans('FeaturesAlternativeSection.SingleValue3TopTitle')"
          :title="$trans('FeaturesAlternativeSection.SingleValue3Title')"
          :bottom-title="$trans('FeaturesAlternativeSection.SingleValue3BottomTitle')"
        />

        <FeaturesAlternativeSingleValueCard
          v-if="onlyHasTrialPriceAvailable()"
          class="mb-[24px]"
          :top-title="$trans('FeaturesAlternativeSection.TrialTopTitle')"
          :title="$trans('FeaturesAlternativeSection.TrialTitle')"
          :bottom-title="$trans('FeaturesAlternativeSection.TrialBottomTitle')"
        />
        <FeaturesAlternativeSingleValueCard
          v-else
          class="mb-[24px]"
          :top-title="$trans('FeaturesAlternativeSection.NormalPriceTopTitle')"
          :title="$trans('FeaturesAlternativeSection.NormalPriceTitle')"
          :bottom-title="$trans('FeaturesAlternativeSection.NormalPriceBottomTitle')"
        />

        <FeaturesAlternativeCard>
          <div class="flex justify-center">
            <Button
              :big="true"
              icon-right="arrow-right"
              @click="showCreateSearchProfileWizard()"
            >
              {{ $trans('FeaturesAlternativeSection.ButtonLabel') }}
            </Button>
          </div>
        </FeaturesAlternativeCard>
      </div>
    </div>
  </Container>
</template>
