<script setup>
import { useUser } from '@js/App/Api/User/User'
import TextInputAlternative from '@js/Components/Forms/TextInput/TextInputAlternative.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { computed, ref } from 'vue'
import { useMainStore } from '@js/App/Stores/MainStore'
import { doPostRequest } from '@js/App/Api/Infra/ApiClient'
import StreetAddressInput from '@js/Components/Forms/StreetAddressInput/StreetAddressInput.vue'

const props = defineProps({
  modelValue: Object
})

const emit = defineEmits(['update:modelValue', 'clicked:next'])

const { user } = useUser()

const buttonIsDisabled = computed(() =>
  !(props.modelValue.postcode && props.modelValue.housenumber && props.modelValue.phone && props.modelValue.email)
)

const showPostcodeInput = ref(true)

const mainStore = useMainStore()
const errors = ref({})
const onClickNext = async () => {
  mainStore.startLoading()
  errors.value = {}

  try {
    const response = await doPostRequest('/api/offboarding/homedeals/validate/contact-info', {
      postcode: props.modelValue.postcode,
      housenumber: props.modelValue.housenumber,
      phone: props.modelValue.phone,
      email: props.modelValue.email
    })

    if (response.hasErrors) {
      errors.value = response.errors
      return
    }

    emit('clicked:next')
  } finally {
    mainStore.stopLoading()
  }
}
</script>
<template>
  <div>
    <h4 class="text-xl text-white font-medium mb-[4px] lg:text-3xl lg:mb-[8px]">
      {{ $trans('HomeDealsContactInfo.Title') }}
    </h4>
    <p class="text-base text-white mb-[24px] lg:text-xl lg:mb-[32px]" v-html="$trans('HomeDealsContactInfo.Text')" />

    <div class="lg:flex">
      <div class="w-[343px] mr-[8px]">
        <h6 class="text-base font-medium text-white">
          {{ $trans('Common.Name') }}
        </h6>
        <p class="text-base text-white p-[16px] mb-[16px]">
          {{ user.firstNameFormatted }} {{ user.lastNameFormatted }}
        </p>
      </div>
      <TextInputAlternative
        :error="errors.email"
        :model-value="modelValue.email"
        class="mb-[16px] lg:mb-[24px] hidden lg:block flex-grow"
        @update:model-value="newValue => $emit('update:modelValue', {
          ...modelValue,
          email: newValue
        })"
      >
        <template #label>
          {{ $trans('Common.Email') }}
        </template>
      </TextInputAlternative>
    </div>

    <div class="flex">
      <TextInputAlternative
        v-if="showPostcodeInput"
        :error="errors.postcode"
        :model-value="modelValue.postcode"
        placeholder="0000 AA"
        class="mr-[4px] flex-grow"
        @update:model-value="newValue => $emit('update:modelValue', {
          ...modelValue,
          postcode: newValue
        })"
      >
        <template #label>
          <div class="flex">
            {{ $trans('HomeDealsContactInfo.NewPostcode') }}
          </div>
        </template>
      </TextInputAlternative>
      <TextInputAlternative
        v-if="showPostcodeInput"
        :error="errors.housenumber"
        class="ml-[4px]"
        :model-value="modelValue.housenumber"
        :placeholder="$trans('HomeDealsContactInfo.NewHouseNumberPlaceholder')"
        @update:model-value="newValue => $emit('update:modelValue', {
          ...modelValue,
          housenumber: newValue
        })"
      >
        <template #label>
          {{ $trans('HomeDealsContactInfo.NewHouseNumber') }}
        </template>
      </TextInputAlternative>
      <StreetAddressInput
        v-if="!showPostcodeInput"
        :error="errors.postcode"
        class="mr-[4px] flex-grow min-w-[200px]"
        @update:postcode="newValue => {
          showPostcodeInput = true

          $emit('update:modelValue', {
            ...modelValue,
            postcode: newValue.postcode,
            housenumber: newValue.housenumber,
          })
        }"
      >
        <template #label>
          {{ $trans('HomeDealsContactInfo.NewAddress') }}
        </template>
      </StreetAddressInput>
    </div>
    <div v-if="showPostcodeInput" class="text-blue-base underline hover:cursor-pointer text-sm mt-1 font-normal" @click="showPostcodeInput = false">
      {{ $trans('HomeDealsContactInfo.IDontKnowMyPostcode') }}
    </div>

    <TextInputAlternative
      :error="errors.phone"
      :model-value="modelValue.phone"
      class="mt-[16px] lg:mt-[24px] mb-[16px] lg:mb-[24px]"
      @update:model-value="newValue => $emit('update:modelValue', {
        ...modelValue,
        phone: newValue?.startsWith('06') ? `+316${newValue.substring(2)}` : newValue
      })"
    >
      <template #label>
        {{ $trans('Common.Phone') }}
      </template>
    </TextInputAlternative>

    <TextInputAlternative
      :error="errors.email"
      :model-value="modelValue.email"
      class="mb-[16px] lg:hidden lg:mb-[24px]"
      @update:model-value="newValue => $emit('update:modelValue', {
        ...modelValue,
        email: newValue
      })"
    >
      <template #label>
        {{ $trans('Common.Email') }}
      </template>
    </TextInputAlternative>

    <div class="lg:flex lg:w-full lg:justify-end">
      <Button
        :disabled="buttonIsDisabled"
        :big="true"
        icon-right="arrow-right"
        :full-width-on-mobile="true"
        class="mt-[16px]"
        @click="() => {
          if (!buttonIsDisabled) {
            onClickNext()
          }
        }"
      >
        {{ $trans('Common.Next') }}
      </Button>
    </div>
  </div>
</template>
