<script setup>
import RentbirdLogo from '@js/Components/Logos/RentbirdLogo/RentbirdLogo.vue'
import TrustpilotSmall from '@js/Components/Trustpilot/TrustpilotSmall.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import SectionDivider from '@js/Components/Marketing/SectionDivider/SectionDivider.vue'
import Container from '@js/Components/Container/Container.vue'
import { useUser } from '@js/App/Api/User/User'

const { user } = useUser()

defineProps({
  sectionDividerColor: {
    type: String,
    default: 'white'
  }
})

</script>
<template>
  <div class="bg-purple-700">
    <SectionDivider :color="sectionDividerColor" :reversed="true" />
    <Container>
      <div class="text-white pb-12 lg:pb-20 pt-12 lg:pt-10">
        <div class="flex justify-center mb-4 lg:mb-8">
          <RentbirdLogo :href="false" size="md" />
        </div>

        <h4 class="text-3xl lg:text-5xl font-medium text-center mb-6 lg:mb-9" v-html="$trans('CallToActionSection.Title')" />
        <div class="block mb-6 lg:mb-9 lg:flex justify-center">
          <Button
            v-if="user.hasActiveSubscription"
            tag="link"
            :href="$getLocalizedUrl('my-rentbird.match-history')"
            :big="true"
            :full-width-on-mobile="true"
            icon-right="arrow-right"
            class="lg:mr-2 mb-2 lg:mb-0"
          >
            <span class="whitespace-nowrap">{{ $trans('HomePageLoggedIn.MatchHistoryButton') }}</span>
          </Button>
          <Button
            v-if="user.hasActiveSubscription"
            tag="link"
            :href="$getLocalizedUrl('my-rentbird.create-search-profile')"
            :big="true"
            :outline="true"
            :full-width-on-mobile="true"
            icon-right="plus"
          >
            <span class="whitespace-nowrap">{{ $trans('HomePageLoggedIn.NewSearchProfileButton') }}</span>
          </Button>
          <Button
            v-if="!user.hasActiveSubscription"
            tag="link"
            :href="$getLocalizedUrl('upgrade')"
            :big="true"
            :full-width-on-mobile="true"
            icon-right="unlocked"
            class="lg:mr-2 mb-2 lg:mb-0"
          >
            <span class="whitespace-nowrap">{{ $trans('HomePageLoggedIn.ChooseSubscription') }}</span>
          </Button>
          <Button
            v-if="!user.hasActiveSubscription"
            tag="link"
            :href="$getLocalizedUrl('pricing')"
            :big="true"
            :outline="true"
            :full-width-on-mobile="true"
            icon-right="arrow-right"
          >
            <span class="whitespace-nowrap">{{ $trans('HomePageLoggedIn.ViewPrices') }}</span>
          </Button>
        </div>
        <div class="w-full">
          <TrustpilotSmall class="lg:justify-center" />
        </div>
      </div>
    </Container>
  </div>
</template>
