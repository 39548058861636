<script setup>
import Container from '@js/Components/Container/Container.vue'
import FeaturesAlternativeCard from '@js/Components/Marketing/FeaturesAlternativeSection/FeaturesAlternativeCard.vue'
import Image from '@js/Components/Image/Image.vue'
import ImageRentbirdGoldTeamMobile from '@images/rentbird-gold-team-mobile.png'
import ImageRentbirdGoldTeamDesktop from '@images/rentbird-gold-team-desktop.png'
import ImageRentbirdGoldNegotiationsMobile from '@images/rentbird-gold-negotiations-mobile.png'
import ImageRentbirdGoldNegotiationsDesktop from '@images/rentbird-gold-negotiations-desktop.png'
import ImageEatAKeyMobile from '@images/eat-a-key-mobile.png'
import ImageEatAKeyDesktop from '@images/eat-a-key-desktop.png'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'
import ImageCrown from '@images/icons/crown.png'
import ImageCurl from '@images/icons/curl.svg'
import ImageLightningDouble from '@images/flash-double.png'

const { isMobile } = useIsMobile()
</script>
<template>
  <Container :large="true">
    <h2 class="text-3xl lg:text-5xl text-purple-700 font-medium mb-[24px] lg:mb-[80px] lg:max-w-[937px] lg:mx-auto lg:text-center lg:text-[#151227]">
      {{ $trans('FeaturesAlternativeRentbirdGoldSection.Title') }}
    </h2>

    <div class="xl:flex xl:space-x-[32px]">
      <div class="xl:w-1/3">
        <FeaturesAlternativeCard :padding="false" class="mb-[24px] lg:mb-[32px]">
          <template #title>
            <div class="px-[32px] pt-[32px] pb-[8px] lg:pb-[16px]">
              {{ $trans('FeaturesAlternativeRentbirdGoldSection.Feature1Title') }}
            </div>
          </template>
          <div class="px-[32px] pb-[26px] lg:pb-[32px]" v-html="$trans('FeaturesAlternativeRentbirdGoldSection.Feature1Content')" />

          <Image :src="ImageLightningDouble" class="absolute top-[-30px] right-[0px]" />
        </FeaturesAlternativeCard>

        <FeaturesAlternativeCard v-if="isMobile" class="lg:hidden mb-[24px] lg:mb-[32px] pb-0" :padding="false">
          <template #title>
            <div class="px-[32px] pt-[32px] pb-[8px] lg:pb-[16px]">
              {{ $trans('FeaturesAlternativeRentbirdGoldSection.Feature2Title') }}
            </div>
          </template>

          <div class="px-[32px] pb-[26px] lg:pb-[32px]" v-html="$trans('FeaturesAlternativeRentbirdGoldSection.Feature2Content')" />

          <div class="flex w-full justify-center">
            <Image :src="isMobile ? ImageRentbirdGoldTeamMobile : ImageRentbirdGoldTeamDesktop" class="w-full rounded-b-md" />
          </div>
        </FeaturesAlternativeCard>

        <FeaturesAlternativeCard v-if="isMobile" class="mb-[24px] lg:mb-[32px] pb-0" :padding="false">
          <template #title>
            <div class="px-[32px] pt-[32px] pb-[8px] lg:pb-[12px]">
              {{ $trans('FeaturesAlternativeRentbirdGoldSection.Feature3Title') }}
            </div>
          </template>

          <div class="px-[32px] pb-[26px] lg:pb-[32px] lg:pb-[32px]" v-html="$trans('FeaturesAlternativeRentbirdGoldSection.Feature3Content')" />

          <Image :src="ImageCrown" class="absolute top-[-20px] right-[0px] w-[53px]" />
        </FeaturesAlternativeCard>

        <FeaturesAlternativeCard v-if="!isMobile" class="mb-[24px] lg:mb-[32px] pb-0" :padding="false">
          <template #title>
            <div class="px-[32px] pt-[32px] pb-[8px] lg:pb-[12px]">
              {{ $trans('FeaturesAlternativeRentbirdGoldSection.Feature4Title') }}
            </div>
          </template>
          <div class="px-[32px] pb-[26px] lg:pb-[32px]" v-html="$trans('FeaturesAlternativeRentbirdGoldSection.Feature4Content')" />

          <div class="flex w-full justify-center">
            <Image :src="isMobile ? ImageRentbirdGoldNegotiationsMobile : ImageRentbirdGoldNegotiationsDesktop" class="w-full rounded-b-md" />
          </div>
        </FeaturesAlternativeCard>
      </div>

      <div class="xl:w-1/3">
        <FeaturesAlternativeCard v-if="isMobile" class="mb-[24px] lg:mb-[32px] pb-0" :padding="false">
          <template #title>
            <div class="px-[32px] pt-[32px] pb-[8px] lg:pb-[16px]">
              {{ $trans('FeaturesAlternativeRentbirdGoldSection.Feature4Title') }}
            </div>
          </template>
          <div class="px-[32px] pb-[26px] lg:pb-[32px]" v-html="$trans('FeaturesAlternativeRentbirdGoldSection.Feature4Content')" />

          <div class="flex w-full justify-center">
            <Image :src="isMobile ? ImageRentbirdGoldNegotiationsMobile : ImageRentbirdGoldNegotiationsDesktop" class="w-full rounded-b-md" />
          </div>
        </FeaturesAlternativeCard>

        <FeaturesAlternativeCard v-if="!isMobile" class="mb-[24px] lg:mb-[32px] pb-0" :padding="false">
          <template #title>
            <div class="px-[32px] pt-[32px] pb-[8px] lg:pb-[16px]">
              {{ $trans('FeaturesAlternativeRentbirdGoldSection.Feature2Title') }}
            </div>
          </template>

          <div class="px-[32px] pb-[26px] lg:pb-[32px]" v-html="$trans('FeaturesAlternativeRentbirdGoldSection.Feature2Content')" />

          <div class="flex w-full justify-center">
            <Image :src="isMobile ? ImageRentbirdGoldTeamMobile : ImageRentbirdGoldTeamDesktop" class="w-full rounded-b-md" />
          </div>
        </FeaturesAlternativeCard>

        <FeaturesAlternativeCard class="mb-[24px] lg:mb-[32px] pb-0" :padding="false">
          <template #title>
            <div class="px-[32px] pt-[32px] pb-[8px] lg:pb-[16px]">
              {{ $trans('FeaturesAlternativeRentbirdGoldSection.Feature5Title') }}
            </div>
          </template>

          <div class="px-[32px] pb-[26px] lg:pb-[32px]" v-html="$trans('FeaturesAlternativeRentbirdGoldSection.Feature5Content')" />

          <Image :src="ImageCurl" class="absolute top-[-12px] right-[-4px] w-[60px] lg:w-[70px] lg:top-auto lg:bottom-[-20px]" />
        </FeaturesAlternativeCard>
      </div>

      <div class="xl:w-1/3">
        <FeaturesAlternativeCard v-if="!isMobile" class="mb-[24px] lg:mb-[32px] pb-0" :padding="false">
          <template #title>
            <div class="px-[32px] pt-[32px] pb-[8px] lg:pb-[16px]">
              {{ $trans('FeaturesAlternativeRentbirdGoldSection.Feature3Title') }}
            </div>
          </template>

          <div class="px-[32px] pb-[26px] lg:pb-[32px]" v-html="$trans('FeaturesAlternativeRentbirdGoldSection.Feature3Content')" />

          <Image :src="ImageCrown" class="absolute top-[-35px] right-[0px] w-[90px]" />
        </FeaturesAlternativeCard>

        <FeaturesAlternativeCard class="mb-[24px] lg:mb-[32px] pb-0" :padding="false">
          <template #title>
            <div class="px-[32px] pt-[32px] pb-[8px] lg:pb-[16px]">
              {{ $trans('FeaturesAlternativeRentbirdGoldSection.Feature6Title') }}
            </div>
          </template>
          <div class="px-[32px] pb-[26px] lg:pb-[32px]" v-html="$trans('FeaturesAlternativeRentbirdGoldSection.Feature6Content')" />

          <div class="flex w-full justify-center">
            <Image :src="isMobile ? ImageEatAKeyMobile : ImageEatAKeyDesktop" class="w-full rounded-b-md" />
          </div>
        </FeaturesAlternativeCard>
      </div>
    </div>
  </Container>
</template>
