<script setup>
import { computed, ref } from 'vue'
import ButtonFilter from '@js/Components/Forms/Button/ButtonFilter.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import FiltersInputItem from '@js/Components/Forms/FiltersInputAllHousesPage/FiltersInputItem.vue'
import FiltersInputLocationScreen from '@js/Components/Forms/FiltersInputAllHousesPage/Screens/FiltersInputLocationScreen.vue'
import FiltersInputMaxRentScreen from '@js/Components/Forms/FiltersInputAllHousesPage/Screens/FiltersInputMaxRentScreen.vue'
import { formatNumber } from '@js/App/Plugins/Formatting'
import FiltersInputMinBedroomsScreen
  from '@js/Components/Forms/FiltersInputAllHousesPage/Screens/FiltersInputMinBedroomsScreen.vue'
import FiltersInputMinSurfaceScreen
  from '@js/Components/Forms/FiltersInputAllHousesPage/Screens/FiltersInputMinSurfaceScreen.vue'
import {
  DEFAULT_CITY,
  DEFAULT_DISTANCE,
  DEFAULT_EXTRA_WISHES,
  DEFAULT_FURNISHED, DEFAULT_MAX_RENT, DEFAULT_MIN_BEDROOMS, DEFAULT_MIN_SURFACE, DEFAULT_NEIGHBOURHOODS
} from '@js/App/Utils/FiltersInputAllHousesDefaultValues'
import FiltersInputFurnishedScreen
  from '@js/Components/Forms/FiltersInputAllHousesPage/Screens/FiltersInputFurnishedScreen.vue'
import FiltersInputExtraWishesScreen
  from '@js/Components/Forms/FiltersInputAllHousesPage/Screens/FiltersInputExtraWishesScreen.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import FiltersInputLabel from '@js/Components/Forms/FiltersInputAllHousesPage/FiltersInputLabel.vue'
import { isEmpty } from '@js/App/Utils/IsEmpty'
import { getCurrency } from '@js/App/Utils/CountryAndLocale'
import { trans } from '@js/App/Plugins/Translations'
import Select from '@js/Components/Forms/Select/Select.vue'
import CheckboxDropdown from '@js/Components/Forms/CheckboxDropdown/CheckboxDropdown.vue'
import NeighbourhoodSelect from '@js/Components/Forms/NeighbourhoodSelect/NeighbourhoodSelect.vue'

const props = defineProps({
  filters: {
    type: Object,
    default: () => ({})
  },
  isCityPage: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:filters', 'shouldReloadPage'])

const SCREEN_ALL_FILTERS = 'all-filters'
const SCREEN_LOCATION = 'location'
const SCREEN_MAX_RENT = 'max-rent'
const SCREEN_MIN_BEDROOMS = 'min-bedrooms'
const SCREEN_MIN_SURFACE = 'min-surface'
const SCREEN_FURNISHED = 'furnished'
const SCREEN_EXTRA_WISHES = 'extra-wishes'

const isExpanded = ref(false)
const currentScreen = ref(SCREEN_ALL_FILTERS)

const canResetFilters = computed(() => {
  if (props.filters.city !== DEFAULT_CITY) { return true }
  if (props.filters.distance !== DEFAULT_DISTANCE) { return true }
  if (props.filters.maxRent !== DEFAULT_MAX_RENT) { return true }
  if (props.filters.furnished !== DEFAULT_FURNISHED) { return true }
  if (props.filters.minBedrooms !== DEFAULT_MIN_BEDROOMS) { return true }
  if (props.filters.minSurface !== DEFAULT_MIN_SURFACE) { return true }
  if (props.filters.extraWishes?.length > 0) { return true }
  if (props.filters.neighbourhoods?.length > 0) { return true }

  return false
})

const resetFilters = () => {
  emit('update:filters', {
    ...props.filters,
    city: DEFAULT_CITY,
    distance: DEFAULT_DISTANCE,
    furnished: DEFAULT_FURNISHED,
    maxRent: DEFAULT_MAX_RENT,
    minBedrooms: DEFAULT_MIN_BEDROOMS,
    minSurface: DEFAULT_MIN_SURFACE,
    extraWishes: DEFAULT_EXTRA_WISHES,
    neighbourhoods: DEFAULT_NEIGHBOURHOODS
  })
  console.log('reset filters, should reload page')
  emit('shouldReloadPage')
}

const labelsToShow = computed(() => {
  const labels = []

  if (!isEmpty(props.filters.city)) {
    labels.push({ label: props.filters.city?.name, value: 'city' })
  }
  if (props.filters.distance !== DEFAULT_DISTANCE) {
    labels.push({ label: `<${props.filters.distance}km`, value: 'distance' })
  }
  if (props.filters.maxRent !== DEFAULT_MAX_RENT) {
    labels.push({ label: `<${getCurrency()}${formatNumber(props.filters.maxRent, 0)}`, value: 'maxRent' })
  }
  if (props.filters.furnished !== DEFAULT_FURNISHED) {
    labels.push({ label: trans(props.filters.furnished), value: 'furnished' })
  }
  if (props.filters.minBedrooms !== DEFAULT_MIN_BEDROOMS) {
    labels.push({ label: `${props.filters.minBedrooms}+ ${trans('FiltersInputMinBedroomsScreen.Bedrooms')}`, value: 'minBedrooms' })
  }
  if (props.filters.minSurface !== DEFAULT_MIN_SURFACE) {
    labels.push({ label: `${props.filters.minSurface}m2`, value: 'minSurface' })
  }
  if (props.filters.extraWishes?.length > 0) {
    labels.push({
      label: props.filters.extraWishes?.map(item => trans(item)).join(', '),
      value: 'extraWishes'
    })
  }

  return labels
})

const onClickDeleteFilter = (filter) => {
  if (filter === 'city') {
    emit('update:filters', {
      ...props.filters,
      city: DEFAULT_CITY
    })
    emit('shouldReloadPage')
    return
  }

  if (filter === 'distance') {
    emit('update:filters', {
      ...props.filters,
      distance: DEFAULT_DISTANCE
    })
    emit('shouldReloadPage')
    return
  }

  if (filter === 'maxRent') {
    emit('update:filters', {
      ...props.filters,
      maxRent: DEFAULT_MAX_RENT
    })
    emit('shouldReloadPage')
  }

  if (filter === 'minBedrooms') {
    emit('update:filters', {
      ...props.filters,
      minBedrooms: DEFAULT_MIN_BEDROOMS
    })
    emit('shouldReloadPage')
  }

  if (filter === 'minSurface') {
    emit('update:filters', {
      ...props.filters,
      minSurface: DEFAULT_MIN_SURFACE
    })
    emit('shouldReloadPage')
  }

  if (filter === 'furnished') {
    emit('update:filters', {
      ...props.filters,
      furnished: DEFAULT_FURNISHED
    })
    emit('shouldReloadPage')
  }

  if (filter === 'extraWishes') {
    emit('update:filters', {
      ...props.filters,
      extraWishes: DEFAULT_EXTRA_WISHES
    })
    emit('shouldReloadPage')
  }
}

</script>
<template>
  <div>
    <div class="flex justify-between items-center lg:hidden">
      <ButtonFilter @click="isExpanded = true" />
      <div v-show="canResetFilters" class="text-purple-900 underline hover:cursor-pointer" @click="resetFilters">
        {{ $trans("FilterInputLocationScreen.ResetFilters") }}
      </div>
    </div>

    <div class="hidden lg:flex lg:flex-wrap justify-between items-center">
      <div class="flex items-center flex-wrap space-x-[4px] -ml-[4px] space-y-[4px] -mt-[4px]">
        <Select
          v-if="isCityPage && !filters.city?.neighbourhoods?.length"
          class="min-w-[120px] mt-[4px]"
          :model-value="filters.distance"
          :options="[2,3,5,8,10,15,20,30,50].map(item => ({
            label: `<${item}km`,
            value: item,
          }))"
          :plus-and-minus-buttons="false"
          @update:model-value="newDistance => {
            $emit('update:filters', {
              ...filters,
              distance: newDistance
            })
            console.log('new distance filter')
            $emit('shouldReloadPage')
          }"
        />
        <NeighbourhoodSelect
          v-if="isCityPage && filters.city?.neighbourhoods?.length"
          :placeholder="$trans('FiltersInputAllHousesPage.Neighbourhoods')"
          :model-value="filters.neighbourhoods || []"
          :all-neighbourhoods-of-city="filters.city?.neighbourhoods || []"
          class="min-w-[220px] max-w-[220px] ml-[4px] mt-[4px]"
          :show-top-bar="false"
          @update:model-value="newNeighbourhoods => {
            if (newNeighbourhoods?.length === 0 && filters.neighbourhoods?.length === 0) {
              return
            }
            $emit('update:filters', {
              ...filters,
              neighbourhoods: newNeighbourhoods
            })
            $emit('shouldReloadPage')
          }"
        />
        <Select
          class="min-w-[120px] max-w-[120px] mt-[4px]"
          :model-value="filters.maxRent"
          :placeholder="$trans('Common.MaxRent')"
          :options="[750,1000,1250,1500,1750,2000,2250,2500,2750,3000,3250,3500,3750,4000,4250,4500,4750,5000].map(item => ({
            label: `${getCurrency()}${formatNumber(item, 0)}`,
            value: item
          }))"
          @update:model-value="newValue => {
            console.log('maxrent')
            $emit('update:filters', {
              ...filters,
              maxRent: newValue
            })
            $emit('shouldReloadPage')
          }"
        />
        <Select
          class="min-w-[165px] max-w-[165px]"
          :model-value="filters.minBedrooms || null"
          :placeholder="$trans('Common.MinBedrooms')"
          :options="[1,2,3,4,5].map(item => ({
            label: `${formatNumber(item, 0)}+ ${$trans('FiltersInputMinBedroomsScreen.Bedrooms')}`,
            value: item
          }))"
          @update:model-value="newValue => {
            $emit('update:filters', {
              ...filters,
              minBedrooms: newValue
            })
            $emit('shouldReloadPage')
          }"
        />
        <Select
          :model-value="filters.minSurface || null"
          :placeholder="$trans('Common.MinSurface')"
          class="min-w-[165px] max-w-[165px]"
          :options="[10,20,30,40,50,60,70,80,90,100,120,140,160,180,200].map(item => ({
            label: `${formatNumber(item, 0)}m2`,
            value: item
          }))"
          @update:model-value="newValue => {
            $emit('update:filters', {
              ...filters,
              minSurface: newValue
            })
            $emit('shouldReloadPage')
          }"
        />
        <Select
          class="min-w-[170px] max-w-[170px]"
          :model-value="filters.furnished"
          :placeholder="$trans('Common.Furnished')"
          :options="[
            {
              value: 'everything',
              label: $trans('everything')
            },
            {
              value: 'furnished',
              label: $trans('furnished')
            },
            {
              value: 'unfurnished',
              label: $trans('unfurnished')
            }
          ]"
          @update:model-value="newValue => {
            $emit('update:filters', {
              ...filters,
              furnished: newValue
            })
            $emit('shouldReloadPage')
          }"
        />
        <CheckboxDropdown
          :max-length-desktop="12"
          :placeholder="$trans('FiltersInputExtraWishesScreen.ExtraWishes')"
          :model-value="(filters.extraWishes || [])"
          class="max-w-[185px] min-w-[185px]"
          :options="[
            {
              label: $trans('FiltersInputFurnishedScreen.Garden'),
              value: 'garden',
            },
            {
              label: $trans('FiltersInputFurnishedScreen.Balcony'),
              value: 'balcony',
            },
            {
              label: $trans('FiltersInputFurnishedScreen.Bath'),
              value: 'bath',
            },
            {
              label: $trans('FiltersInputExtraWishesScreen.Students'),
              value: 'for_students',
            },
            {
              label: $trans('FiltersInputExtraWishesScreen.Pets'),
              value: 'for_pets',
            },
            {
              label: $trans('FiltersInputExtraWishesScreen.Sharers'),
              value: 'for_sharers',
            }
          ]"
          @update:model-value="newValue => {
            $emit('update:filters', {
              ...filters,
              extraWishes: newValue
            })
            $emit('shouldReloadPage')
          }"
        />
        <div v-show="canResetFilters" class="text-purple-900 underline hover:cursor-pointer" @click="resetFilters">
          {{ $trans("FilterInputLocationScreen.ResetFilters") }}
        </div>
      </div>
    </div>

    <div
      v-show="labelsToShow.length > 0"
      class="flex flex-wrap mt-[12px] lg:mt-[16px] space-x-1 space-y-0.5"
    >
      <FiltersInputLabel
        v-for="label of labelsToShow"
        :key="label.value"
        @click:delete="() => onClickDeleteFilter(label.value)"
      >
        {{ label.label }}
      </FiltersInputLabel>
    </div>

    <div v-if="isExpanded" class="fixed lg:hidden inset-0 bg-white z-10 px-[16px] py-[16px]">
      <div v-show="currentScreen === SCREEN_ALL_FILTERS">
        <div class="flex justify-between mb-[42px]">
          <div class="flex items-center">
            <Icon name="filter" class="mr-[12px]" />
            <h4 class="text-xl font-medium">
              {{ $trans('FiltersInput.Filters') }}
            </h4>
          </div>

          <Icon
            class="hover:cursor-pointer hover:text-pink-500 transition-colors"
            name="cross"
            @click="isExpanded = false"
          />
        </div>

        <FiltersInputItem
          :label="$trans('FiltersInput.Location')"
          :text-value="filters.city?.name
            ? `${filters.city?.name}, ${
              filters.neighbourhoods?.length
                ? $trans('FiltersInput.NumberOfNeighbourhoods', { number: filters.neighbourhoods?.length})
                :`<${filters.distance}km`
            }`
            : $trans('FiltersInput.NoPreference')"
          class="mb-[24px]"
          @click="currentScreen = SCREEN_LOCATION"
        />

        <FiltersInputItem
          :label="$trans('FiltersInput.MaxRent')"
          :text-value="filters.maxRent
            ? `€${formatNumber(filters.maxRent, 0)}`
            : $trans('FiltersInput.NoPreference')"
          class="mb-[24px]"
          @click="currentScreen = SCREEN_MAX_RENT"
        />

        <FiltersInputItem
          :label="$trans('FiltersInput.MinBedrooms')"
          :text-value="filters.minBedrooms
            ? `${filters.minBedrooms} ${$trans('FiltersInputMinBedroomsScreen.Bedrooms')}`
            : $trans('FiltersInput.NoPreference')"
          class="mb-[24px]"
          @click="currentScreen = SCREEN_MIN_BEDROOMS"
        />

        <FiltersInputItem
          :label="$trans('FiltersInput.MinSurface')"
          :text-value="filters.minSurface
            ? `${filters.minSurface}m2`
            : $trans('FiltersInput.NoPreference')"
          class="mb-[24px]"
          @click="currentScreen = SCREEN_MIN_SURFACE"
        />

        <FiltersInputItem
          :label="$trans('FiltersInput.Furnished')"
          :text-value="filters.furnished !== DEFAULT_FURNISHED
            ? $trans(filters.furnished)
            : $trans('FiltersInput.NoPreference')"
          class="mb-[24px]"
          @click="currentScreen = SCREEN_FURNISHED"
        />

        <FiltersInputItem
          :label="$trans('FiltersInput.ExtraWishes')"
          :text-value="filters.extraWishes?.length > 0
            ? filters.extraWishes.map(item => $trans(item)).join(', ')
            : $trans('FiltersInput.NoPreference')"
          class="mb-[24px]"
          @click="currentScreen = SCREEN_EXTRA_WISHES"
        />

        <div class="w-full flex justify-end mb-[16px] mt-[24px] ">
          <a
            class="text-base text-blue-300"
            :class="{
              'hover:underline hover:cursor-pointer': canResetFilters,
              'opacity-30': !canResetFilters
            }"
            @click.prevent="resetFilters"
          >
            {{ $trans('FilterInputLocationScreen.ResetFilters') }}
          </a>
        </div>

        <Button
          class="mt-[40px]"
          :big="true"
          :full-width-on-mobile="true"
          icon-right="check"
          @click="() => {
            isExpanded=false
          }"
        >
          {{ $trans('FiltersInputLocationScreen.ApplyAndClose') }}
        </Button>
      </div>

      <FiltersInputLocationScreen
        v-if="currentScreen === SCREEN_LOCATION"
        :is-city-page="isCityPage"
        :model-value="{
          city: filters.city,
          neighbourhoods: filters.neighbourhoods,
          distance: filters.distance
        }"
        @click:back="() => {
          currentScreen = SCREEN_ALL_FILTERS
          $emit('shouldReloadPage')
        }"
        @click:close="currentScreen = SCREEN_ALL_FILTERS"
        @update:model-value="newValue => {
          $emit('update:filters', {
            ...filters,
            city: newValue.city,
            distance: newValue.distance,
            neighbourhoods: newValue.neighbourhoods
          })
          $emit('shouldReloadPage')
        }"
      />

      <FiltersInputMaxRentScreen
        v-if="currentScreen === SCREEN_MAX_RENT"
        :model-value="{
          maxRent: filters.maxRent
        }"
        @click:back="() => {
          currentScreen = SCREEN_ALL_FILTERS
          $emit('shouldReloadPage')
        }"
        @click:close="currentScreen = SCREEN_ALL_FILTERS"
        @update:model-value="newValue => {
          $emit('update:filters', {
            ...filters,
            maxRent: newValue.maxRent
          })
        }"
      />

      <FiltersInputMinBedroomsScreen
        v-if="currentScreen === SCREEN_MIN_BEDROOMS"
        :model-value="{
          minBedrooms: filters.minBedrooms
        }"
        @click:back="() => {
          currentScreen = SCREEN_ALL_FILTERS
          $emit('shouldReloadPage')
        }"
        @click:close="currentScreen = SCREEN_ALL_FILTERS"
        @update:model-value="newValue => {
          $emit('update:filters', {
            ...filters,
            minBedrooms: newValue.minBedrooms
          })
        }"
      />

      <FiltersInputMinSurfaceScreen
        v-if="currentScreen === SCREEN_MIN_SURFACE"
        :model-value="{
          minSurface: filters.minSurface
        }"
        @click:back="() => {
          currentScreen = SCREEN_ALL_FILTERS
          $emit('shouldReloadPage')
        }"
        @click:close="currentScreen = SCREEN_ALL_FILTERS"
        @update:model-value="newValue => {
          $emit('update:filters', {
            ...filters,
            minSurface: newValue.minSurface
          })
        }"
      />

      <FiltersInputFurnishedScreen
        v-if="currentScreen === SCREEN_FURNISHED"
        :model-value="{
          furnished: filters.furnished
        }"
        @click:back="() => {
          currentScreen = SCREEN_ALL_FILTERS
          $emit('shouldReloadPage')
        }"
        @click:close="currentScreen = SCREEN_ALL_FILTERS"
        @update:model-value="newValue => {
          $emit('update:filters', {
            ...filters,
            furnished: newValue.furnished
          })
        }"
      />

      <FiltersInputExtraWishesScreen
        v-if="currentScreen === SCREEN_EXTRA_WISHES"
        :model-value="{
          extraWishes: filters.extraWishes
        }"
        @click:back="() => {
          currentScreen = SCREEN_ALL_FILTERS
          $emit('shouldReloadPage')
        }"
        @click:close="currentScreen = SCREEN_ALL_FILTERS"
        @update:model-value="newValue => {
          $emit('update:filters', {
            ...filters,
            extraWishes: newValue.extraWishes
          })
        }"
      />
    </div>
  </div>
</template>
