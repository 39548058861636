<script setup>
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import NumberOfMatchesBoxAllSearchProfiles
  from '@js/Components/NumberOfMatchesBox/NumberOfMatchesBoxAllSearchProfiles.vue'
import { ref } from 'vue'
import ProgressBar from '@js/Components/Forms/ProgressBar/ProgressBar.vue'
import InfoMessageBox from '@js/Components/InfoMessageBox/InfoMessageBox.vue'
import ImageLight from '@images/icons/light.png'
import SetUserAlsoSearchForAccountSettings
  from '@js/Components/MyRentbird/Onboarding/MandatoryOnboarding/StepSearchProfileAlsoSearchFor/SetUserAlsoSearchForAccountSettings.vue'
import SetUserSuitableForAccountSettings
  from '@js/Components/MyRentbird/Onboarding/MandatoryOnboarding/StepSuitableFor/SetUserSuitableForAccountSettings.vue'
const numberOfMatchesBoxRef = ref(null)

</script>
<template>
  <div>
    <div class="lg:flex justify-between items-center">
      <h1 class="text-[#151227] font-bold text-2xl lg:text-4xl">
        {{ $trans('MandatoryOnboardingStep2.Title') }}
      </h1>
      <div class="lg:w-[20%]">
        <ProgressBar color="green" :progress="20" :show-percentage="true" />
      </div>
    </div>
    <MyRentbirdCard class="mt-[16px] lg:mt-[20px]" :can-expand-on-mobile="false" :default-is-expanded="true">
      <InfoMessageBox color="gray" :image="ImageLight" class="mb-[24px]">
        {{ $trans('MandatoryOnboardingStep2.Hint') }}
      </InfoMessageBox>

      <div class="block lg:flex justify-start">
        <div class="lg:mr-8 lg:w-1/2">
          <SetUserAlsoSearchForAccountSettings
            title-location="top"
            checkbox-location="left"
            class-label="lg:text-lg text-purple-900"
            @account-settings-updated="() => {
              numberOfMatchesBoxRef.recalculateAllMatchesPerWeek()
            }"
          />
        </div>
        <div class="mt-4 lg:mt-0 mb-4 lg:mb-0 lg:w-1/2">
          <SetUserSuitableForAccountSettings
            checkbox-location="left"
            class-label="lg:text-lg text-purple-900"
            @account-settings-updated="() => {
              numberOfMatchesBoxRef.recalculateAllMatchesPerWeek()
            }"
          />
        </div>
      </div>

      <NumberOfMatchesBoxAllSearchProfiles
        ref="numberOfMatchesBoxRef"
        class="mt-4 lg:mt-6"
      />

      <div class="flex w-full justify-end">
        <Button
          tag="link"
          :href="getLocalizedUrl('my-rentbird.onboarding', { step: 3 })"
          icon-right="arrow-right"
          :big="true"
          :full-width-on-mobile="true"
          class="mt-[20px] lg:mt-[32px]"
        >
          {{ $trans('Common.Next') }}
        </Button>
      </div>
    </MyRentbirdCard>
  </div>
</template>
