<script setup>
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import { computed, onMounted, watch } from 'vue'
import CheckboxDropdown from '@js/Components/Forms/CheckboxDropdown/CheckboxDropdown.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import { storeToRefs } from 'pinia'

defineEmits(['click:distance'])

defineProps({
  error: {
    type: String,
    default: ''
  },
  showTopBar: {
    type: Boolean,
    default: true
  }
})

const searchProfileStore = useSearchProfileStore()
const { searchProfile, allNeighbourhoodsOfCity } = storeToRefs(searchProfileStore)

const handleHover = (item) => {
  searchProfile.value.hoveredNeighbourhood = item.ids
}
const handleUnHover = (item) => {
  searchProfile.value.hoveredNeighbourhood = []
}

const isAllNeighbourhoodsSelected = computed(() => {
  for (const item of allNeighbourhoodsOfCity.value) {
    if (!isNeighbourhoodSelected(item)) {
      return false
    }
  }
  return true
})

watch(allNeighbourhoodsOfCity, (newValue, oldValue) => {
  if (JSON.stringify(newValue) === JSON.stringify(oldValue)) {
    return
  }
  setSelectedNeighbourhoods()
})

const setSelectedNeighbourhoods = () => {
  if ((allNeighbourhoodsOfCity.value || []).length <= 0) {
    searchProfile.value.neighbourhoods = []
  }

  searchProfile.value.neighbourhoods = [
    ...new Set(
      allNeighbourhoodsOfCity.value.map(item => item.ids).flat()
    )
  ]
}

const isNeighbourhoodSelected = (item) => {
  if ((item?.ids || []).length <= 0) { return false }

  for (const id of item.ids) {
    if (!searchProfile.value?.neighbourhoods.includes(id)) {
      return false
    }
  }

  return true
}

onMounted(() => {
  // There were already neighbourhoods set. Don't reset them on mount
  if (searchProfile.value.neighbourhoods?.length > 0) { return }

  setSelectedNeighbourhoods()
})
</script>
<template>
  <div>
    <div v-if="showTopBar" class="flex justify-between">
      <label class="block font-medium text-base text-purple-700 mb-1">
        {{ $trans('NeighbourhoodSelect.Label') }}
      </label>
      <div dusk="neighbourhood-select-switch" class="text-blue-base hover:cursor-pointer hover:underline transition-all" @click="$emit('click:distance')">
        {{ $trans('NeighbourhoodSelect.SwitchToDistance') }}
      </div>
    </div>
    <CheckboxDropdown
      dusk="neighbourhood-select"
      :model-value="searchProfile.neighbourhoods"
      :is-all-options-selected="isAllNeighbourhoodsSelected"
      :error="error"
      :options="allNeighbourhoodsOfCity?.map(item => ({
        label: item.name,
        value: item.ids
      }))"
      @update:model-value="newValue => {
        searchProfile.neighbourhoods = [...new Set(newValue.flat())]
      }"
    >
      <template #itemsSelectedMessage>
        <span v-if="searchProfile.neighbourhoods.length > 0 && !isAllNeighbourhoodsSelected">{{ $trans('NeighbourhoodSelect.ItemsSelectedMessage', { number: searchProfile.neighbourhoods.length}) }}</span>
        <span v-else-if="searchProfile.neighbourhoods.length === 0">{{ $trans('NeighbourhoodSelect.NoItemsSelectedMessage') }}</span>
        <span v-else>{{ $trans('NeighbourhoodSelect.AllItemsSelectedMessage') }}</span>
      </template>
      <template #options>
        <div
          v-for="item of allNeighbourhoodsOfCity"
          :key="item.name"
          class="flex justify-between py-3 px-4 my-[2px] text-base text-purple-700 hover:cursor-pointer hover:text-white hover:bg-blue-base transition-all"
          :class="{
            'pl-4': !item.group,
            'pl-4 ml-4 border-l border-gray-201 border-dashed': item.group
          }"
          :dusk="`neighbourhood-select-option-${item.name}`"

          @click="() => {
            if (isNeighbourhoodSelected(item)) {
              searchProfile.neighbourhoods = searchProfile.neighbourhoods.filter(neighbourhoodItem => !item.ids.includes(neighbourhoodItem))
            } else {
              searchProfile.neighbourhoods = [...new Set([...searchProfile.neighbourhoods, ...item.ids].flat())]
            }
          }"
          @mouseenter="handleHover(item)"
          @mouseleave="handleUnHover(item)"
        >
          <span>{{ item.name }}</span>
          <Icon v-if="!isNeighbourhoodSelected(item)" name="box" />
          <Icon v-else name="box-checked" />
        </div>
      </template>
    </CheckboxDropdown>
  </div>
</template>
