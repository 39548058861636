<script setup>
import NavMenu from '@js/Components/NavMenu/NavMenu.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import TrustpilotLogo from '@images/trustpilot-logo-white.svg'
import TrustpilotStars from '@js/Components/Trustpilot/TrustpilotStars.vue'
import { usePage } from '@inertiajs/vue3'
import Image from '@js/Components/Image/Image.vue'
import SectionDivider from '@js/Components/Marketing/SectionDivider/SectionDivider.vue'
import Container from '@js/Components/Container/Container.vue'
import PricingCardAlternativeB from '@js/Components/PricingCard/PricingCardAlternativeB/PricingCardAlternativeB.vue'

const page = usePage()
</script>
<template>
  <div class="bg-purple-700 text-white mb-[160px] lg:mb-0">
    <Container class="pb-8 pt-4 lg:pt-8 lg:pb-16">
      <NavMenu />
    </Container>

    <Container>
      <div class="mt-1 pb-[140px] lg:pb-[92px]">
        <div class="block lg:flex">
          <div class="w-full lg:w-1/2 lg:mr-3 mb-6 lg:mb-0">
            <div class="flex flex-col lg:block">
              <h4 class="text-white text-base lg:text-2xl lg:mb-3">
                {{ $trans('PricingHeroSection.SubTitle') }}
              </h4>
              <h1 class="text-white text-3xl font-medium lg:text-6xl lg:text-[64px]" v-html="$trans('PricingHeroSection.Title')" />

              <div class="flex mt-4 lg:mt-8">
                <Icon name="check-circle" class="mr-2 lg:mr-3 text-success" icon-width="lg:w-[24px] lg:h-[24px]" />
                <p class="text-base relative top-[-3px] lg:top-0 lg:text-xl">
                  {{ $trans('PricingHeroSection.Item1') }}
                </p>
              </div>

              <div class="flex mt-3 lg:mt-4">
                <Icon name="check-circle" class="mr-2 lg:mr-3 text-success" icon-width="lg:w-[24px] lg:h-[24px]" />
                <p class="text-base relative top-[-3px] lg:top-0 lg:text-xl">
                  {{ $trans('PricingHeroSection.Item2') }}
                </p>
              </div>

              <div class="flex mt-3 lg:mt-4">
                <Icon name="check-circle" class="mr-2 lg:mr-3 text-success" icon-width="lg:w-[24px] lg:h-[24px]" />
                <p class="text-base relative top-[-3px] lg:top-0 lg:text-xl">
                  {{ $trans('PricingHeroSection.Item3') }}
                </p>
              </div>

              <div class="flex mt-3 lg:mt-4">
                <Icon name="check-circle" class="mr-2 lg:mr-3 text-success" icon-width="lg:w-[24px] lg:h-[24px]" />
                <p class="text-base relative top-[-3px] lg:top-0 leading-9 lg:text-xl">
                  <span class="pr-2">{{ $trans('PricingHeroSection.Item4') }}</span>
                  <TrustpilotStars class="w-[132px] inline relative top-[-2px]" :rating="page.props.trustpilot.rating" />
                  <span class="font-medium pl-2">{{ $formatNumber(page.props.trustpilot.rating, 1) }}</span>
                  <span class="pl-2 pr-2">{{ $trans('Common.On') }}</span>
                  <Image class="inline relative top-[-2px]" :src="TrustpilotLogo" />
                </p>
              </div>
            </div>
          </div>

          <div class="relative mx-auto lg:w-1/2 flex justify-center lg:justify-end lg:ml-3">
            <PricingCardAlternativeB class="absolute z-30" />
          </div>
        </div>
      </div>
    </Container>
    <SectionDivider class="mb-24" />
  </div>
</template>
