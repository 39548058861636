<script setup>
import B2BLayout from '@js/Components/B2B/B2BLayout.vue'
import { usePage, router, Link } from '@inertiajs/vue3'
import { useB2BOrganization } from '@js/App/Api/B2B/B2B'
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import { computed, ref, watch } from 'vue'
import { getQueryParameter } from '@js/App/Utils/ReadQueryParams'
import DataTable from '@js/Components/Tables/DataTable.vue'
import HouseStatusDropdown from '@js/Components/B2B/HouseStatusDropdown.vue'
import { ziggyRoute } from '@js/App/Utils/ZiggyUrls'

const page = usePage()

const houses = computed(() => (page.props.houses))

const { organization } = useB2BOrganization()

const searchQuery = ref(getQueryParameter('search_query', ''))
const sortBy = ref(getQueryParameter('sort_by', 'created_at'))
const sortDirection = ref(getQueryParameter('sort_direction', 'desc'))

const refetchTableWithSearchAndSortParameters = () => {
  let url = `/b2b/${organization.value}/houses`
  url += `?search_query=${encodeURIComponent(searchQuery.value)}`
  url += `&sort_by=${encodeURIComponent(sortBy.value)}`
  url += `&sort_direction=${sortDirection.value}`

  router.visit(url, {
    preserveState: true,
    preserveScroll: true
  })
}

watch(searchQuery, () => {
  const oldValue = searchQuery.value
  setTimeout(() => {
    if (oldValue !== searchQuery.value) { return }

    refetchTableWithSearchAndSortParameters()
  }, 500)
})
watch(sortBy, refetchTableWithSearchAndSortParameters)
watch(sortDirection, refetchTableWithSearchAndSortParameters)

</script>
<template>
  <B2BLayout
    :bread-crumbs="[
      {
        label: 'Houses',
        url: ziggyRoute('b2b.houses', { organization: organization})
      },
    ]"
  >
    <template #title>
      <div class="flex items-center">
        <Icon name="house" icon-width="w-[40px] mr-2 relative top-[-2px]" />
        <span>Houses</span>
      </div>
    </template>

    <MyRentbirdCard class="mt-6" :can-expand-on-mobile="false">
      <template #title>
        Houses
      </template>
      <DataTable
        :search-query="searchQuery"
        :sort-by="sortBy"
        :sort-direction="sortDirection"
        :pagination="houses"
        :headers="[
          {
            label: 'Name',
            value: 'name',
            isSortable: false,
          },
          {
            label: 'Email sent at',
            value: 'created_at',
          },
          {
            label: 'Details',
            value: 'details',
            isSortable: false,
          },
          {
            label: 'Status',
            value: 'status',
            isSortable: false,
          },
        ]"
        @update:search-query="newSearchQuery => { searchQuery = newSearchQuery }"
        @update:sort-by="newSortBy => { sortBy = newSortBy }"
        @update:sort-direction="newSortAscending => { sortDirection = newSortAscending }"
      >
        <template
          v-for="item of houses.data"
          :key="item?.random_id"
        >
          <tr class="row">
            <td>
              <Link
                class="text-blue-base hover:cursor-pointer hover:underline flex items-center"
                :href="ziggyRoute('b2b.house.details', { organization: organization, house: item.random_id})"
                target="_blank"
              >
                <img alt="house-image" :src="item.image_url" class="w-[64px] mr-3">
                <div>
                  {{ item.name }}, {{ item.city }}
                </div>
              </Link>
            </td>
            <td>
              {{ item.created_at_formatted }}
            </td>
            <td>
              &euro;{{ item.rent }} • {{ item.area }}m<sup>2</sup> <span v-if="item.bedrooms > 0">• {{ item.bedrooms }}
                <small>bedrooms</small></span>
            </td>
            <td>
              <HouseStatusDropdown
                :house-random-id="item.random_id"
                :house-status="item.house_status"
              />
            </td>
          </tr>
        </template>
      </DataTable>
    </MyRentbirdCard>
  </B2BLayout>
</template>
<style lang="scss">
.B2B-HousesPage {
  background: rgb(234,234,235);
}
</style>
