<script setup>
import Footer from '@js/Components/Marketing/Footer/Footer.vue'
import Container from '@js/Components/Container/Container.vue'
import BasicPageHeader from '@js/Components/Marketing/BasicPageHeader/BasicPageHeader.vue'
import { getLocale } from '@js/App/Utils/CountryAndLocale'
import { isSSR } from '@js/App/Utils/Environment'

if (!isSSR()) {
  setInterval(() => {
    const el = document.getElementById('CybotCookiebotDialog')
    if (!el) {
      return
    }

    el.parentNode.removeChild(el)
  }, 50)
}
</script>
<template>
  <BasicPageHeader>
    {{ $trans('PrivacyPage.Title') }}
  </BasicPageHeader>
  <Container>
    <div class="privacy-page text-base leading-7 max-w-full w-[800px] py-10">
      <div v-if="getLocale() === 'nl'">
        <p class="text-4xl font-medium !mb-6">
          <strong>Privacyverklaring Rentbird</strong>
        </p>
        <p>
          <strong>Artikel 1. Wettelijke kaders voor de verwerking van persoonsgegevens</strong>
        </p>
        <p>
          Rentbird is een onderneming gericht op het ontwikkelen, produceren en uitgeven van software. Met betrekking
          tot de bedrijfsactiviteiten van Rentbird gelden de volgende wettelijke kaders:
        </p>
        <ul>
          <li>
            <em>De Algemene verordening Gegevensbescherming (hierna te noemen: AVG);</em>
          </li>
          <li><em>De Uitvoeringswet Algemene verordening Gegevensbescherming (hierna te noemen: UAVG).</em></li>
        </ul>

        <p><strong>Artikel 2. Omschrijving persoonsgegevens</strong></p>
        <p>
          Rentbird kan persoonsgegevens over u verwerken, doordat u gebruik maakt van de diensten van Rentbird en/of
          omdat u uw persoonsgegevens zelf aan Rentbird heeft verstrekt. Rentbird kan de volgende persoonsgegevens
          verwerken: <em>uw voor- en achternaam, uw adresgegevens, uw bedrijfsnaam, uw bedrijfsadres, uw KvK-nummer
            bedrijf, uw geboortedatum, uw geslacht, uw telefoonnummer, uw e-mailadres, uw betalingsgegevens, uw IP-adres,
            maximum huurprijs, eventuele woonwensen en de opleverstaat van de gezochte woning.</em>
        </p>
        <p>
          <strong>Artikel 3. Doeleinden van de verwerking van persoonsgegevens</strong>
        </p>
        <p>
          Uw persoonsgegevens worden alleen met een rechtvaardige grondslag verwerkt en alleen voor welbepaalde,
          uitdrukkelijk omschreven en gerechtvaardigde doelen. Uw persoonsgegevens kunnen door Rentbird worden verwerkt
          voor: <em>het aangaan en uitvoeren van een overeenkomst met u, de betaling te laten plaatsvinden, u op de
            hoogte te houden van de voortgang van de overeenkomst, om de diensten/bedrijfsactiviteiten van Rentbird te
            optimaliseren en om de relatie met u (als klant) te onderhouden</em>.
        </p>
        <p>
          <strong>Artikel 4. Bewaartermijn persoonsgegevens</strong>
        </p>
        <p>
          Rentbird bewaart uw persoonsgegevens niet langer dan strikt noodzakelijk is om de doelen, waarvoor uw
          persoonsgegevens in eerste instantie zijn verzameld, te realiseren (zie artikel 3). Uw persoonsgegevens zullen
          in ieder geval niet langer dan 10 jaar worden bewaard (afgaande op de termijn die de Belastingdienst stelt op
          het bewaren van administratie).
        </p>
        <p>
          <strong>Artikel 5. Verwerking persoonsgegevens met derden</strong>
        </p>
        <p>
          Rentbird verstrekt uitsluitend persoonsgegevens aan derden als dit nodig is voor de uitvoering van onze
          overeenkomst met u of om te voldoen aan een wettelijke verplichting. Rentbird kan uw persoonsgegevens
          verstrekken aan een betalingsverwerker om de betaling, voor de gekochte diensten, te laten plaatsvinden of aan
          marketingplatforms dan wel andere samenwerkingspartners voor het uitvoeren van de Overeenkomst met u.
        </p>
        <p>
          <strong>Artikel 6. Marketingactiviteiten</strong>
        </p>
        <p>
          Rentbird houdt, in het kader van haar marketingactiviteiten de algemene bezoekgegevens van de bezoekers van
          haar bezoekers bij. In dit kader kan met name het IP-adres van uw computer en het tijdstip van de opvraging
          van gegevens, die de browser van een bezoeker meestuurt, worden geregistreerd. Deze gegevens kunnen worden
          gebruikt voor statistische analyses van bezoek- en klikgedrag op de website en worden zo veel mogelijk
          geanonimiseerd.
        </p>
        <p>
          <strong>Artikel 7. Verwerking persoonsgegevens van minderjarigen</strong>
        </p>
        <p>
          Rentbird verwerkt enkel en alleen persoonsgegevens van minderjarigen (personen jonger dan 16 jaar) indien
          daarvoor schriftelijke toestemming is gegeven door de ouder, verzorger of wettelijke vertegenwoordiger.
          Rentbird kan echter niet controleren of een bezoeker 17 jaar of ouder is. Rentbird raadt ouders, verzorgers of
          wettelijke vertegenwoordigers dan ook aan om betrokken te zijn bij de onlineactiviteiten van hun kinderen, om
          te voorkomen dat de persoonsgegevens van hun kinderen verzameld worden zonder hun ouderlijke toestemming.
        </p>
        <p>
          <strong>Artikel 8. Beveiliging persoonsgegevens</strong>
        </p>
        <p>
          Rentbird gaat zorgvuldig om met uw persoonsgegevens en behandelt deze vertrouwelijk: uw persoonsgegevens
          worden alleen verwerkt door personen met een geheimhoudingsplicht en alleen voor het doel waarvoor de
          persoonsgegevens zijn verzameld. Daarbij draagt Rentbird zorg voor een passende beveiliging van de
          persoonsgegevens. Rentbird beschermt de aan zijn toevertrouwde persoonsgegevens met behulp van technische en
          administratieve veiligheidsmaatregelen om het risico van verlies, misbruik, onbevoegde toegang, openbaarmaking
          en wijziging van de persoonsgegevens zo klein mogelijk te maken. Hierbij kunt u denken aan firewalls,
          versleuteling van gegevens en fysieke en administratieve toegangscontroles tot aan de data en servers. Indien
          u toch denkt dat er misbruik is gemaakt van data, dan kunt u contact opnemen met Rentbird (zie artikel 13 voor
          de contactgegevens).
        </p>
        <p>
          <strong>Artikel 9. Rechten van betrokkenen</strong>
        </p>
        <p>
          Rentbird honoreert alle rechten van u als betrokkene. De betrokkene is de natuurlijke persoon op wie de
          verwerkte of de te verwerken persoonsgegevens betrekking hebben. De AVG heeft mede als doelstelling om de
          betrokkenen zo goed mogelijk te beschermen bij de verwerking van hun persoonsgegevens. Om deze bescherming en
          een eerlijke verwerking van de persoonsgegevens te waarborgen, biedt de AVG de betrokkene een aantal rechten
          die de betrokkene tegen de verwerkingsverantwoordelijke (in dit geval Rentbird), kan uitoefenen, dan wel
          inroepen.
        </p>
        <p>
          De betrokkene heeft het recht op informatie over de verwerking van zijn/haar persoonsgegevens, het recht op
          inzage in zijn/haar persoonsgegevens, het recht op correctie,
          rectificatie of aanvulling van eventuele onjuiste persoonsgegevens, het recht op verwijdering van de
          persoonsgegevens
          (“het recht om vergeten te worden”), het recht op beperking van de verwerking van zijn/haar persoonsgegevens,
          het recht op verzet
          tegen de verwerking van zijn/haar persoonsgegevens, het recht op overdracht van zijn/haar persoonsgegevens
          (“dataportabiliteit”), het
          recht om niet onderworpen te worden aan een geautomatiseerde besluitvorming en het recht om bezwaar te maken
          tegen de verwerking van
          persoonsgegevens.
        </p>
        <p>
          U kunt een verzoek om één van de hierboven genoemde rechten geldend te maken indienen door een e-mail te
          sturen
          naar: mail@rentbird.nl. Rentbird zal zo spoedig mogelijk, maar in ieder geval binnen 4 weken, op uw verzoek
          reageren.
        </p>
        <p><strong>Artikel 10. Cookies</strong></p>
        <p>
          Rentbird maakt gebruik van cookies. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze
          website wordt opgeslagen in de browser van uw computer, tablet of smartphone. Rentbird gebruikt cookies met
          een
          puur technische functionaliteit. Bij uw eerste bezoek aan de website heeft Rentbird u al geïnformeerd over het
          gebruik
          van deze cookies en heeft Rentbird uw toestemming gevraagd voor het plaatsen van de cookies.
        </p>
        <p><em>10.1 Cookies van derde partijen</em></p>
        <p>
          Het is mogelijk dat derde partijen, zoals Google, op de website van Rentbird adverteren of dat zij gebruik
          maken van een andere dienst. Daarvoor plaatsen deze derde partijen in sommige gevallen cookies. Deze cookies
          zijn niet door Rentbird te beïnvloeden.
        </p>
        <p><em>10.2 Analytics cookies</em></p>
        <p>
          Voor het verzamelen van statistieken over het gebruik en bezoek van de website, maakt Rentbird gebruik van
          analyticsdiensten. Deze gegevens worden door de aanbieder van deze analyticsdiensten geanalyseerd en de
          resultaten hiervan worden aan Rentbird verstrekt. Rentbird krijgt op deze manier inzicht in hoe de website
          wordt gebruikt en kan aan de hand van die gegevens de website en/of haar producten zo nodig aanpassen. Als dat
          wettelijk verplicht is, kan de analyticsdienst deze informatie aan derden verstrekken.
        </p>
        <p><em>10.3 Tracking cookies</em></p>
        <p>
          Rentbird maakt op haar website gebruik van cookies van derde partijen die u op basis van uw surfgedrag
          gepersonaliseerde aanbiedingen kunnen doen. Via de website wordt een cookie op uw apparaat geplaatst met een
          uniek nummer. De cookies maken het mogelijk om uw apparaat te herkennen als u na een bezoek aan de website van
          Rentbird naar een andere website surft, die onderdeel uitmaakt van hetzelfde advertentienetwerk. Dit maakt het
          voor Rentbird mogelijk om u op die andere websiteadvertenties te tonen. Het daardoor opgebouwde profiel is
          niet gekoppeld aan uw naam, adres, e-mailadres en dergelijke, maar dient alleen om advertenties af te stemmen
          op uw profiel, zodat deze zo relevant mogelijk zijn voor u.
        </p>
        <p><em>10.4 Functionele cookies</em></p>
        <p>
          De functionele cookies zorgen ervoor dat de website naar behoren werkt en dat bijvoorbeeld de
          voorkeursinstellingen worden onthouden (bijvoorbeeld de taal waarin een bezoeker de website wil lezen). De
          functionele cookies worden ook gebruikt om de website goed te laten werken en deze te kunnen
          optimaliseren.
        </p>
        <p><em>10.5 Afmelden cookies</em></p>
        <p>
          U kunt u afmelden voor de cookies door uw internetbrowser zo in te stellen dat deze geen cookies meer
          opslaat. Daarnaast kunt u ook alle informatie die eerder is opgeslagen via de instellingen van uw browser
          verwijderen. U dient er wel rekening mee te houden dat het verwijderen van cookies tot gevolg kan hebben dat
          bepaalde onderdelen van de website niet of niet goed meer werken. Het weigeren en verwijderen van cookies
          heeft alleen gevolgen voor de computer en de browser waarop u deze handeling uitvoert. Als u gebruik maakt van
          meerdere computers en/of browsers dient u de bovengenoemde handeling op ieder device en/of iedere browser
          uitvoeren.
        </p>
        <p><strong>Artikel 11. Wijziging Privacyverklaring</strong></p>
        <p>
          Rentbird behoudt het recht om deze Privacyverklaring te wijzigen. Wijzigingen zullen op de website van
          Rentbird worden gepubliceerd: https://rentbird.nl.
        </p>
        <p>
          <strong>Artikel 12. Vragen en/of klachten</strong>
        </p>
        <p>
          Mocht u een vraag en/of een klacht hebben over de verwerking van uw persoonsgegevens, kunt u hierover direct
          contact opnemen met Rentbird. Naast het recht om een klacht in te dienen bij Rentbird, heeft u ook altijd het
          recht om een klacht in te dienen bij de toezichthoudende autoriteit op het gebied van privacybescherming: de
          Autoriteit Persoonsgegevens, Postbus 93374, 2509 AJ te Den Haag, 070-888 5000.
        </p>
        <p><strong>Artikel 13. Contactgegevens Rentbird</strong></p>
        <p>Bedrijfsnaam: RB Global (handelsnaam: Rentbird)</p>
        <p>KvK-nummer: 82844569</p>
        <p>Adres: Solostraat 73</p>
        <p>E-mail: mail@rentbird.nl</p>
        <p>Postcode en plaats: 1095GV Amsterdam</p>
        <p>Website: https://rentbird.nl</p>
        <p>Privacyverklaring Rentbird - 2 oktober 2023</p>
      </div>
      <div v-if="getLocale() === 'en'">
        <p class="text-4xl font-medium !mb-6">
          <strong>Privacy disclaimer for Rentbird</strong>
        </p>
        <p><strong>Article 1. Legal Framework for the Processing of Personal Data</strong></p>
        <p>
          Rentbird is a company focused on developing, producing, and distributing software. The following legal
          frameworks apply to Rentbird's business activities:
        </p>
        <ul>
          <li><em>The General Data Protection Regulation (referred to as GDPR);</em></li>
          <li>
            <em>The Dutch Implementation Act for the General Data Protection Regulation (referred to as UAVG).</em>
          </li>
        </ul>
        <p><strong>Article 2. Description of Personal Data</strong></p>
        <p>
          Rentbird may process your personal data because you use Rentbird's services and/or because you have provided
          your personal data to Rentbird. Rentbird may process the following personal data: <em>your first and last
            name, your address details, your company name, your company address, your company registration number (KvK),
            your date of birth, your gender, your telephone number, your email address, your payment details, your IP
            address, maximum rent price, any housing preferences, and the condition of the desired property</em>.
        </p>
        <p><strong>Article 3. Purposes of Processing Personal Data</strong></p>
        <p>
          Your personal data will only be processed with a lawful basis and for specific, explicit, and legitimate
          purposes. Rentbird may process your personal data for the following purposes: <em>entering into and performing
            an agreement with you, facilitating payments, keeping you informed about the progress of the agreement,
            optimizing Rentbird's services/business activities, and maintaining the relationship with you (as a
            customer)</em>.
        </p>
        <p><strong>Article 4. Retention Period of Personal Data</strong></p>
        <p>
          Rentbird will not retain your personal data for longer than strictly necessary to achieve the purposes for
          which your personal data were initially collected (see Article 3). Your personal data will not be retained for
          more than 10 years (based on the period specified by the Tax and Customs Administration for retaining
          records).
        </p>
        <p><strong>Article 5. Processing Personal Data with Third Parties</strong></p>
        <p>
          Rentbird will only provide personal data to third parties when it is necessary for the performance of our
          agreement with you or to comply with a legal obligation. Rentbird may provide your personal data to a payment
          processor to facilitate payment for the purchased services or to marketing platforms or other partners for the
          execution of the
          agreement with you.
        </p>
        <p><strong>Article 6. Marketing Activities</strong></p>
        <p>
          As part of its marketing activities, Rentbird keeps general visitor data of its website visitors. In this
          context, the IP address of your computer and the time of data retrieval, sent by a visitor's browser, may be
          recorded. These data can be used for statistical analysis of website visits and click behavior and are
          anonymized as much as possible.
        </p>
        <p><strong>Article 7. Processing Personal Data of Minors</strong></p>
        <p>
          Rentbird processes personal data of minors (individuals under the age of 16) only if written consent has been
          provided by a parent, guardian, or legal representative. However, Rentbird cannot verify whether a visitor is
          17 years or older. Rentbird therefore advises parents, guardians, or legal representatives to be involved in
          their children's online activities to prevent the collection of their children's personal data without
          parental consent.
        </p>
        <p><strong>Article 8. Security of Personal Data</strong></p>
        <p>
          Rentbird handles your personal data with care and treats it confidentially: your personal data are only
          processed by individuals with a confidentiality obligation and only for the purpose for which the personal
          data were collected. Rentbird also ensures appropriate security measures to protect personal data. Rentbird
          protects the entrusted personal data using technical and administrative security measures to minimize the risk
          of loss, misuse, unauthorized access, disclosure, and alteration of personal data. This includes firewalls,
          data encryption, and physical and administrative access controls to data and servers. If you believe that data
          has been misused, you can contact Rentbird (see Article 13 for contact information).
        </p>
        <p><strong>Article 9. Rights of Data Subjects</strong></p>
        <p>
          Rentbird respects all your rights as a data subject. The data subject is the natural person to whom the
          processed or to be processed personal data relates. The GDPR aims to provide the best possible protection to
          data subjects in the processing of their personal data. To ensure this protection and fair processing of
          personal data, the GDPR grants the data subject several rights that they can exercise or invoke against the
          data controller (in this case, Rentbird).
        </p>
        <p>
          The data subject has the right to information about the processing of their personal data, the right to
          access their personal data, the right to correction, rectification, or completion of any incorrect personal
          data, the right to erasure of personal data ("the right to be forgotten"), the right to restriction of the
          processing of their personal data, the right to object to the processing of their personal data, the right to
          data portability, the right not to be subject to automated decision-making, and the right to object to the
          processing of personal data.
        </p>
        <p>
          You can make a request to exercise any of the above-mentioned rights by sending an email to:
          mail@rentbird.nl. Rentbird will respond to your request as soon as possible, but in any case within 4
          weeks.
        </p>
        <p><strong>Article 10. Cookies</strong></p>
        <p>
          Rentbird uses cookies. A cookie is a small text file that is stored in the browser of your computer, tablet,
          or smartphone during your first visit to this website. Rentbird uses cookies solely for technical
          functionality. During your initial visit to the website, Rentbird informed you about the use of these cookies
          and requested your consent to place them.
        </p>
        <p><em>10.1 Third-Party Cookies</em></p>
        <p>
          Third parties, such as Google, may advertise on Rentbird's website or use other services, and in some cases,
          these third parties may place cookies. Rentbird cannot influence these cookies.
        </p>
        <p><em>10.2 Analytics Cookies</em></p>
        <p>
          To collect statistics on the use and visits to the website, Rentbird uses analytics services. These data are
          analyzed by the provider of these analytics services, and the results are provided to Rentbird. This allows
          Rentbird to gain insights into how the website is used and, if necessary, make adjustments to the website
          and/or its products. If required by law, the analytics service may provide this information to third
          parties.
        </p>
        <p><em>10.3 Tracking Cookies</em></p>
        <p>
          Rentbird's website uses third-party cookies based on your browsing behavior to offer personalized offers. A
          cookie with a unique number is placed on your device via the website. These cookies allow your device to be
          recognized when you visit another website within the same advertising network after visiting Rentbird's
          website. This enables Rentbird to display advertisements on that other website. The resulting profile is not
          linked to your name, address, email address, etc., but is used solely to tailor ads to your profile to make
          them as relevant as possible to you.
        </p>
        <p><em>10.4 Functional Cookies</em></p>
        <p>
          Functional cookies ensure that the website functions properly and that user preferences are remembered (e.g.,
          the language in which a visitor wants to read the website). Functional cookies are also used to ensure the
          proper functioning of the website and to optimize it.
        </p>
        <p><em>10.5 Opting Out of Cookies</em></p>
        <p>
          You can opt out of cookies by configuring your internet browser not to store cookies. Additionally, you can
          delete all previously stored information through your browser's settings. Please note that refusing and
          deleting cookies may result in certain parts of the website not functioning properly. Refusing and deleting
          cookies only affect the computer and browser on which you perform these actions. If you use multiple devices
          and/or browsers, you should perform the aforementioned actions on each device and/or browser.
        </p>
        <p><strong>Article 11. Amendment of Privacy Disclaimer</strong></p>
        <p>
          Rentbird reserves the right to amend this Privacy Disclaimer. Changes will be published on Rentbird's
          website: https://rentbird.nl.
        </p>
        <p><strong>Article 12. Questions and/or Complaints</strong></p>
        <p>
          If you have any questions and/or complaints about the processing of your personal data, you can contact
          Rentbird directly. In addition to the right to lodge a complaint with Rentbird, you also have the right to
          lodge a complaint with the supervisory authority for privacy protection: the Dutch Data Protection Authority
          (Autoriteit Persoonsgegevens), P.O. Box 93374, 2509 AJ The Hague, Netherlands, +31 (0)70-888 5000.
        </p>
        <p><strong>Article 13. Contact Details for Rentbird</strong></p>
        <p>Company Name: RB Global (trading as Rentbird) Chamber of Commerce Number (KvK): 82844569</p>
        <p>Adress: Solostraat 73 Email: mail@rentbird.nl</p>
        <p>Postal Code and City: 1095GV Amsterdam Website: https://rentbird.nl</p>
        <p>Privacyverklaring Rentbird - 2 oktober 2023</p>
      </div>
    </div>
  </Container>
  <Footer />
</template>
<style lang="scss">
.privacy-page {
  p {
    margin-bottom: 8px;

  }

  em {
    font-weight: 500;
  }

  ul {
    margin-bottom: 16px;
    list-style-type: disc;
    margin-left: 24px;

    li {
      margin-bottom: 4px;
    }
  }
}
</style>
