<template>
  <div
    class="flex justify-between border-b pb-2 mb-2"
    style="border-color: rgb(233,233,233);"
  >
    <div class="text-base lg:text-lg text-purple-900">
      <slot name="left" />
    </div>
    <div class="text-base lg:text-lg ">
      <slot name="right" />
    </div>
  </div>
</template>
