<script setup>
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import { ref } from 'vue'
import ProgressBar from '@js/Components/Forms/ProgressBar/ProgressBar.vue'
import { useUser } from '@js/App/Api/User/User'
import { saveUserPersonalData } from '@js/App/Api/User/UserPersonalData'
import { reloadPropsFromServer } from '@js/App/Api/Infra/ServerData'
import { Link, router } from '@inertiajs/vue3'
import LivingRequirementsInput from '@js/Components/Forms/LivingRequirementsInput/LivingRequirementsInput.vue'
import { useMainStore } from '@js/App/Stores/MainStore'
import { generateResponseLetters } from '@js/App/Api/User/ResponseLetters'
import Tabs from '@js/Components/Forms/Tabs/Tabs.vue'
import DanielleTextbox from '@js/Components/MyRentbird/Offboarding/PersonTextBox/PersonTextBox.vue'
import CustomTransitionExpand from '@js/Components/Transitions/CustomTransitionExpand.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import TextArea from '@js/Components/Forms/TextArea/TextArea.vue'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'
const { userPersonalData } = useUser()
const internalPersonalData = ref({ ...userPersonalData.value })

const errors = ref({})

const { isMobile } = useIsMobile()
const tipsIsExpanded = ref(false)

const onClickNext = async () => {
  const mainStore = useMainStore()
  try {
    mainStore.startLoading()
    errors.value = {}

    const { errors: apiErrors, hasErrors } = await saveUserPersonalData(internalPersonalData.value, [
      'why_i_am_a_good_renter', 'fun_fact_about_me'
    ])
    errors.value = apiErrors

    if (hasErrors) {
      return
    }

    const { errors: apiErrorsResponseLetter, hasErrorsResponseLetter } = await generateResponseLetters()
    errors.value = apiErrorsResponseLetter

    if (hasErrorsResponseLetter) {
      return
    }

    reloadPropsFromServer()
  } finally {
    mainStore.stopLoading()
  }
  router.get(getLocalizedUrl('my-rentbird.onboarding', { step: 7 }))
}
</script>
<template>
  <div>
    <div class="lg:flex justify-between items-center">
      <h1 class="text-[#151227] font-bold text-2xl lg:text-4xl">
        {{ $trans('MandatoryOnboardingStep5Point5.Title') }}
      </h1>
      <div class="lg:w-[20%]">
        <ProgressBar color="green" :progress="65" :show-percentage="true" />
      </div>
    </div>
    <MyRentbirdCard class="mt-[16px] lg:mt-[20px]" :can-expand-on-mobile="false" :default-is-expanded="true">
      <div class="lg:flex lg:flex-row-reverse">
        <DanielleTextbox :light="true" class="mb-[24px] lg:ml-[12px]" max-width-on-desktop="lg:w-[400px]">
          <div
            class="flex justify-between items-center hover:cursor-pointer hover:text-pink-500 lg:hover:cursor-auto lg:hover:text-purple-900 mb-[6px]"
            @click="() => {
              if (!isMobile) { return }
              tipsIsExpanded = !tipsIsExpanded
            }"
          >
            <div class="font-medium">
              💡 Tips van Kim
            </div>
            <Icon
              v-if="isMobile"
              name="caret-down"
              class="transition-all"
              :class="{
                'rotate-180': tipsIsExpanded
              }"
              icon-width="w-[24px] h-[24px]"
            />
          </div>
          <CustomTransitionExpand>
            <div v-if="tipsIsExpanded || !isMobile" v-html="$trans('ResponseLetterPage.Tips')" />
          </CustomTransitionExpand>
        </DanielleTextbox>

        <div class="flex-grow lg:mr-[12px]">
          <TextArea
            :hfull="false"
            class="!h-auto lg:text-lg"
            :error="errors.why_i_am_a_good_renter"
            :rows="7"
            :model-value="internalPersonalData.why_i_am_a_good_renter"
            :placeholder="$trans('MandatoryOnboardingStep5Point5WhyAreYouAFunRenter.Placeholder')"
            @update:model-value="newValue => internalPersonalData.why_i_am_a_good_renter = newValue"
          >
            <template #label>
              {{ $trans('Label.WhyAreYouAGoodRenter') }}
            </template>
          </TextArea>

          <TextArea
            :hfull="false"
            class="!h-auto lg:text-lg"
            :error="errors.fun_fact_about_me"
            :rows="7"
            :model-value="internalPersonalData.fun_fact_about_me"
            :placeholder="$trans('MandatoryOnboardingStep5Point5WhyAreYouAFunRenter.FunFactAboutMePlaceholder')"
            @update:model-value="newValue => internalPersonalData.fun_fact_about_me = newValue"
          >
            <template #label>
              {{ $trans('MandatoryOnboardingStep5Point5WhyAreYouAFunRenter.FunFactAboutMeLabel') }}
            </template>
          </TextArea>
        </div>
      </div>

      <div class="flex flex-row w-full justify-end pt-[20px] lg:pt-[32px]">
        <Button
          tag="link"
          class=" lg:mb-0 mr-2"
          :big="true"
          :outline="true"
          :href="getLocalizedUrl('my-rentbird.onboarding', { step: 5 })"
        >
          <Icon name="arrow-left" />
        </Button>
        <Button
          tag="button"
          icon-right="arrow-right"
          :big="true"
          :full-width-on-mobile="true"
          class="flex-grow lg:flex-grow-0"
          @click="onClickNext"
        >
          {{ $trans('Common.Next') }}
        </Button>
      </div>
    </MyRentbirdCard>
  </div>
</template>
