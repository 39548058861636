<script setup>
import Icon from '@js/Components/Icon/Icon.vue'

defineProps({
  modelValue: Boolean,

  small: {
    type: Boolean,
    default: false
  }
})

const id = `checkbox-id-${Math.floor(Math.random() * 10000)}`

defineEmits(['update:modelValue'])

</script>
<template>
  <div
    class="flex flex-nowrap hover:cursor-pointer"
    @click="$emit('update:modelValue', !modelValue)"
  >
    <div
      class="rounded flex justify-center items-center relative mr-[8px]"
      :class="{
        'min-w-[22px] max-w-[22px] max-h-[22px] min-h-[22px] lg:min-w-[26px] lg:max-w-[26px] lg:max-h-[26px] lg:min-h-[26px] top-[0px] lg:top-0': !small,
        'min-w-[20px] max-w-[20px] max-h-[20px] min-h-[20px] lg:min-w-[20px] lg:max-w-[20px] lg:max-h-[20px] lg:min-h-[20px] top-[2px] lg:top-[3px]': small,
        'bg-success': modelValue,
        'bg-white border border-gray-100': !modelValue
      }"
    >
      <Icon
        v-if="modelValue"
        name="check"
        class="text-white relative left-[-1px] lg:left-0"
        :icon-width="`${small ? 'w-[19px] h-[19px] lg:w-[18px] lg:h-[18px]' : 'w-[19px] h-[19px] lg:w-[22px] lg:h-[22px]'}`"
      />
    </div>
    <label
      :for="id"
      class="text-gray-900 text-base hover:cursor-pointer"
      :class="{
        'lg:text-lg ': !small,

      }"
    >
      <slot /></label>
  </div>
</template>
