<script setup>
import Select from '@js/Components/Forms/Select/Select.vue'
import ErrorMessage from '@js/Components/Forms/ErrorMessage/ErrorMessage.vue'
import GoingToLiveWithSelect from '@js/Components/Forms/LivingRequirementsInput/GoingToLiveWithSelect.vue'
import CurrentJobDropdown from '@js/Components/Forms/LivingRequirementsInput/CurrentJobDropdown.vue'
import TextInput from '@js/Components/Forms/TextInput/TextInput.vue'
import { computed } from 'vue'
import { trans } from '@js/App/Plugins/Translations'

const props = defineProps({
  modelValue: Object,
  errors: {
    type: Object,
    default: () => ({})
  }
})

const frontendIncomeError = computed(() => {
  const value = props.modelValue?.total_monthly_income
  if (!value) {
    return ''
  }
  if (isNaN(value)) {
    return trans('Common.EnterAValidNumber')
  }

  const asFloat = parseFloat(value)
  if (asFloat < 0) {
    return trans('Common.EnterAPositiveNumber')
  }
  if (asFloat > 15000) {
    return trans('Common.MakeSureItIsAMonthlyIncome')
  }
  return ''
})

defineEmits(['update:modelValue'])
</script>
<template>
  <div>
    <div class="mb-[16px]">
      <GoingToLiveWithSelect
        :model-value="modelValue?.is_going_to_live_with"
        :error="errors.is_going_to_live_with"
        @update:model-value="newValue => {
          $emit('update:modelValue', {
            ...modelValue,
            is_going_to_live_with: newValue
          })
        }"
      />
    </div>

    <div class="lg:flex lg:mb-[16px] mb-[16px]">
      <div class="lg:w-1/2 lg:pr-[8px] mb-[16px] lg:mb-0">
        <CurrentJobDropdown
          :model-value="modelValue?.current_job"
          :error="errors.current_job"
          @update:model-value="newValue => {
            $emit('update:modelValue', {
              ...modelValue,
              current_job: [...new Set(newValue.flat())]
            })
          }"
        />
      </div>

      <Select
        :model-value="modelValue?.reason_for_moving"
        class="lg:mt-0 lg:w-1/2 lg:pl-[8px]"
        :error="errors.reason_for_moving"
        :options="[
          {value: 'verhuizen', label: $trans('StepLivingRequirements.MovingForWork')},
          {value: 'samenwonen', label: $trans('StepLivingRequirements.MovingForLivingTogether')},
          {value: 'gezinsuitbreiding', label: $trans('StepLivingRequirements.MovingForBabies')},
          {value: 'scheiding', label: $trans('StepLivingRequirements.MovingForBreakup')},
          {value: 'voor_het_eerst_op_mezelf', label: $trans('StepLivingRequirements.MovingForOutOfParentsBasement')},
          {value: 'groter', label: $trans('StepLivingRequirements.MovingForWantBigger')},
          {value: 'goedkoper', label: $trans('StepLivingRequirements.MovingForWantCheaper')},
          {value: 'andere_plaats', label: $trans('StepLivingRequirements.MovingForDifferentCity')},
          {value: 'specifieke_woonwensen', label: $trans('StepLivingRequirements.MovingForGarden')},
          {value: 'energiezuinigere_woning', label: $trans('StepLivingRequirements.MovingForEnergy')},
          {value: 'anders', label: $trans('Common.Other')},
        ]"
        @update:model-value="newValue => {
          $emit('update:modelValue', {
            ...modelValue,
            reason_for_moving: newValue
          })
        }"
      >
        <template #label>
          {{ $trans('StepLivingRequirements.TotalMonthlyReasonForMovingLabel') }}
        </template>
      </Select>
    </div>

    <TextInput
      :model-value="modelValue?.total_monthly_income"
      :error="errors.total_monthly_income"
      class=""
      @update:model-value="newValue => {
        $emit('update:modelValue', {
          ...modelValue,
          total_monthly_income: newValue
        });
      }"
    >
      <template #icon>
        €
      </template>
      <template #label>
        {{ $trans('StepLivingRequirements.TotalMonthlyIncomeLabel') }}
      </template>
    </TextInput>
    <ErrorMessage v-show="frontendIncomeError">
      {{ frontendIncomeError }}
    </ErrorMessage>

    <TextInput
      :model-value="modelValue?.pets"
      :error="errors.pets"
      class="mt-[16px]"
      :placeholder="$trans('StepLivingRequirements.None')"
      @update:model-value="newValue => {
        $emit('update:modelValue', {
          ...modelValue,
          pets: newValue
        });
      }"
    >
      <template #label>
        {{ $trans('StepLivingRequirements.Pets') }}
      </template>
    </TextInput>

    <ErrorMessage v-if="errors.general" class="mt-[2px]">
      {{ errors.general }}
    </ErrorMessage>
  </div>
</template>
