<script setup>
import { ref } from 'vue'
import { trans } from '@js/App/Plugins/Translations'
import ErrorMessage from '@js/Components/Forms/ErrorMessage/ErrorMessage.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import { useMainStore } from '@js/App/Stores/MainStore'
import { getApiErrorMessageFromResponse, getDefaultHeadersToSend } from '@js/App/Api/Infra/ApiClient'

const ALLOWED_TYPES = ['png', 'jpg', 'jpeg', 'webp', 'mov', 'mp4', 'avi', 'webm', '3gp']
const MB_IN_BYTES = 1048576

const errors = ref({})
const files = ref([])

const emit = defineEmits(['selected-a-file', 'images-uploaded'])

const fileRef = ref(null)
const setFiles = (inputfiles) => {
  errors.value = {}

  const _files = inputfiles?.length > 0
    ? Array.from(inputfiles)
    : Array.from(fileRef.value.files)

  if (_files.length > 5) {
    files.value = []
    errors.value = {
      files: trans('Errors.MaxFiles5')
    }
  }

  if (_files.length <= 0) {
    files.value = []
    errors.value = {
      files: trans('Errors.MinFiles')
    }
  }

  files.value = _files.map((file) => {
    for (const allowedType of ALLOWED_TYPES) {
      if (file?.type?.toLowerCase()?.includes(allowedType)) {
        return file
      }
    }
    return null
  })

  if (files.value.includes(null)) {
    files.value = []
    errors.value = {
      files: trans('Errors.AllowedFileType', { file_types: ALLOWED_TYPES.join(', ') })
    }
  }

  let totalSize = 0
  files.value.forEach((image) => {
    totalSize += image.size
  })

  if (totalSize > 1000 * MB_IN_BYTES) {
    files.value = []
    errors.value = {
      files: trans('Errors.MaxFileSize', { size: '1000 MB' })
    }
  } else {
    emit('selected-a-file')
  }
}

const clickedDropZone = () => {
  document.querySelector('#fileinput').click()
}

const onFileDrop = (ev) => {
  ev.preventDefault()

  let files = event.dataTransfer?.files
  if (!files || files.length <= 0) {
    files = event.target.files
  }

  setFiles(files)
}

const mainStore = useMainStore()
const submitFiles = async () => {
  if (files.value.length <= 0) {
    files.value = []
    errors.value = {
      files: trans('Errors.MinFiles')
    }
    return
  }

  try {
    mainStore.startLoading()
    errors.value = {}
    const formData = new FormData()
    for (const image of files.value) {
      formData.append('files[]', image)
    }
    const headers = getDefaultHeadersToSend()

    console.log('DOING FETCH IN OFFBOARDING')
    const response = await fetch(
      '/api/offboarding/user-review-files',
      {
        body: formData,
        headers,
        method: 'POST'
      }
    )

    if (response.status >= 400) {
      errors.value = await getApiErrorMessageFromResponse(response)
      return
    }

    const asJson = await response.json()

    emit('images-uploaded', asJson.files)
  } finally {
    mainStore.stopLoading()
  }
}

defineExpose({ submitFiles })

</script>
<template>
  <div>
    <div>
      <div class="hover:cursor-pointer hover:bg-purple-800/80 hover:border-purple-400/80 transition-colors w-full bg-purple-800 border border-dashed border-purple-400 px-[32px] rounded-md py-[32px] mb-3" @click="clickedDropZone" @drop.prevent="onFileDrop" @dragenter.prevent @dragover.prevent>
        <input id="fileinput" ref="fileRef" type="file" multiple @change="setFiles">

        <p class="m-0 p-0 text-center w-100 text-gray-200">
          <span class="pr-[12px]">{{ $trans('MediaUpload.UploadMessage') }}</span>
          <Icon name="upload" icon-width="inline relative top-[-2px]" />
        </p>
        <p v-show="files?.length > 0" class="m-0 p-0 text-center text-gray-200">
          {{ trans(files?.length === 1 ? "MediaUpload.FileSelected" : "MediaUpload.FilesSelected", { amount: files?.length }) }}
        </p>
      </div>
      <ErrorMessage v-show="errors.images || errors.files" class="mb-2">
        {{ errors.images || errors.files }}
      </ErrorMessage>
    </div>
  </div>
</template>
<style lang="scss" scoped>
input[type=file] {
  cursor: pointer;
  display: none;
  width:1px;
  height: 1px;
}
</style>
