<script setup>
import { onClickPricingCardPayButton } from '@js/App/Api/Payments'
import { getCurrency } from '@js/App/Utils/CountryAndLocale'
import Button from '@js/Components/Forms/Button/Button.vue'
import { onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { usePaymentStore } from '@js/App/Stores/PaymentStore'

onMounted(() => {
  const paymentStore = usePaymentStore()
  const { trialPrice, selectedPrice } = storeToRefs(paymentStore)

  selectedPrice.value = trialPrice.value
})
</script>
<template>
  <div class="bg-purple-900 text-white px-6 py-4 rounded-md lg:py-6 w-[600px] max-w-full">
    <div class="flex justify-center">
      <div class="flex items-center  lg:ml-[140px]">
        <div
          class="text-6xl text-[64px] font-medium xl:text-[100px]"
        >
          1
        </div>
        <div class="text-lg font-medium flex flex-col h-full justify-between pl-[6px] xl:text-2xl xl:relative xl:top-[6px]">
          <div>
            ,00
          </div>
          <div class="relative top-[-4px] xl:top-[-22px]">
            {{ getCurrency() }}
          </div>
        </div>

        <div class="hidden xl:flex items-center pl-0">
          <svg
            :class="{
              'text-success':true,
            }"
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="10"
            viewBox="0 0 12 10"
            fill="none"
          >
            <path d="M0 5L11.5 0V10L0 5Z" fill="currentColor" />
          </svg>
          <div
            class="text-base px-4 py-1 rounded-lg relative left-[-1px]"
            :class="{
              'bg-success': true,
            }"
          >
            {{ $trans('PricingCardStartTrial.UniqueOpportunity') }}
          </div>
        </div>
      </div>
    </div>

    <div class="text-lg flex justify-center xl:text-3xl xl:text-[32px]">
      {{ $trans('PricingCardStartTrial.ForOneWeek') }}
    </div>

    <div class="xl:hidden">
      <div class="flex justify-center">
        <svg
          :class="{
            'text-success': true,
          }"
          class="relative top-[1px]"
          xmlns="http://www.w3.org/2000/svg"
          width="35"
          height="7"
          viewBox="0 0 35 7"
          fill="none"
        >
          <path d="M17.5 -7.64949e-07L35 6.49999L-2.84124e-07 6.49999L17.5 -7.64949e-07Z" fill="currentColor" />
        </svg>
      </div>
      <div class="flex justify-center">
        <div
          class="text-sm  px-4 py-1 rounded-lg"
          :class="{
            'bg-success': true,
          }"
        >
          {{ $trans('PricingCardStartTrial.UniqueOpportunity') }}
        </div>
      </div>
    </div>

    <div class="flex justify-center mt-4">
      <Button
        class="w-full"
        :full-width-on-desktop="true"
        :full-width-on-mobile="true"
        :big="true"
        icon-right="arrow-right"
        @click="onClickPricingCardPayButton"
      >
        {{ $trans("PricingCardStartTrial.ButtonLabel") }}
      </Button>
    </div>
    <div class="text-white text-xs text-center mt-2 lg:text-sm">
      {{ $trans('PricingCardStartTrial.DisclaimerText') }}
    </div>
  </div>
</template>
