<script setup>
import NavMenu from '@js/Components/NavMenu/NavMenu.vue'
import SectionDivider from '@js/Components/Marketing/SectionDivider/SectionDivider.vue'
import TrustpilotBox from '@js/Components/Trustpilot/TrustpilotBox.vue'
import { formatNumber } from '@js/App/Plugins/Formatting'
import Container from '@js/Components/Container/Container.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { useUser } from '@js/App/Api/User/User'

const { user } = useUser()
</script>

<template>
  <div class="bg-purple-700 text-white mb-[32px] lg:mb-[40px]">
    <Container class="pb-8 pt-4 lg:pt-8 lg:pb-16">
      <NavMenu />
    </Container>

    <Container>
      <div class="mt-1 pb-[35px] lg:pb-[40px]">
        <div class="block lg:flex">
          <div class="lg:w-2/3 lg:mr-4">
            <div class="flex flex-col lg:block">
              <h4 class="text-white text-base lg:text-2xl lg:mb-2">
                {{ $trans('Reviews.SubTitle') }}
              </h4>
              <h1 class="text-white text-3xl font-medium lg:text-6xl lg:text-[64px]">
                {{ $trans('Reviews.Title', { amount: formatNumber(6000, 0) }) }}
              </h1>
            </div>
          </div>

          <div class="relative lg:w-1/3 flex lg:justify-end lg:ml-4 lg:mt-0 mt-4">
            <div>
              <TrustpilotBox :inverse="true" />
            </div>
          </div>
        </div>
        <div class="block mt-4 lg:mt-8 lg:max-w-[510px]">
          <div class="lg:flex lg:items-end">
            <Button
              v-if="user.hasActiveSubscription"
              tag="link"
              :href="$getLocalizedUrl('my-rentbird.match-history')"
              :big="true"
              :full-width-on-mobile="true"
              icon-right="arrow-right"
              class="lg:mr-2 mb-2 lg:mb-0"
            >
              <span class="whitespace-nowrap">{{ $trans('HomePageLoggedIn.MatchHistoryButton') }}</span>
            </Button>
            <Button
              v-if="user.hasActiveSubscription"
              tag="link"
              :href="$getLocalizedUrl('my-rentbird.create-search-profile')"
              :big="true"
              :outline="true"
              :full-width-on-mobile="true"
              icon-right="plus"
            >
              <span class="whitespace-nowrap">{{ $trans('HomePageLoggedIn.NewSearchProfileButton') }}</span>
            </Button>
            <Button
              v-if="!user.hasActiveSubscription"
              tag="link"
              :href="$getLocalizedUrl('upgrade')"
              :big="true"
              :full-width-on-mobile="true"
              icon-right="unlocked"
              class="lg:mr-2 mb-2 lg:mb-0"
            >
              <span class="whitespace-nowrap">{{ $trans('HomePageLoggedIn.ChooseSubscription') }}</span>
            </Button>
            <Button
              v-if="!user.hasActiveSubscription"
              tag="link"
              :href="$getLocalizedUrl('pricing')"
              :big="true"
              :outline="true"
              :full-width-on-mobile="true"
              icon-right="arrow-right"
            >
              <span class="whitespace-nowrap">{{ $trans('HomePageLoggedIn.ViewPrices') }}</span>
            </Button>
          </div>
        </div>
      </div>
    </Container>
    <SectionDivider />
  </div>
</template>
