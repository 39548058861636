<script setup>
import { ref } from 'vue'
import ModalInstallOurAppComponent from '@js/Components/MyRentbird/ModalInstallOurApp/ModalInstallOurAppComponent.vue'
import { useUser } from '@js/App/Api/User/User'

const hasBeenInMyRentbirdBefore = localStorage.getItem('has_been_in_my_rentbird_before') || false
localStorage.setItem('has_been_in_my_rentbird_before', true)

const { user } = useUser()

const showAppPopup = ref(!hasBeenInMyRentbirdBefore && user.value.notificationChannel !== 'mobile')
</script>

<template>
  <ModalInstallOurAppComponent v-if="showAppPopup" @close="showAppPopup = false" />
</template>
