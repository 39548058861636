export const TYPE_CTA = 'CTA'
export const TYPE_LISTINGS = 'LISTINGS'

export const parseContentIntoParts = (content) => {
  const splitted = content.replaceAll('[/BLOCK]', '[BLOCK]').split('[BLOCK]')

  return splitted.map((item) => {
    if (item === TYPE_CTA) {
      return {
        type: 'block',
        content: TYPE_CTA
      }
    }

    if (item?.includes(TYPE_LISTINGS)) {
      return {
        type: 'block',
        content: TYPE_LISTINGS,
        props: parseAllHousesProps(item)
      }
    }

    return {
      type: 'string',
      content: item
    }
  })
}

const parseAllHousesProps = (item) => {
  if (!item.includes('(') || !item.includes(')')) {
    return {}
  }

  const args = item.substring(item.indexOf('(') + 1, item.indexOf(')'))
  const argsAsArray = args.split(',')

  const props = {}

  for (const arg of argsAsArray) {
    const keyValue = arg.split('=')
    const key = keyValue[0]
    let value = keyValue[1]

    if (key === 'neighbourhoods') {
      value = keyValue[1]?.split('|').filter(item => item)
    } else if (key === 'extraWishes') {
      value = keyValue[1]?.split('|').filter(item => item)
    } else if (key === 'maxRent') {
      value = parseInt(value)
    } else if (key === 'minBedrooms') {
      value = parseInt(value)
    } else if (key === 'minSurface') {
      value = parseInt(value)
    } else if (key === 'showTitle') {
      value = value !== 'false'
    }

    props[key] = value
  }

  return props
}
