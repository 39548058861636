import { doGetRequest } from '@js/App/Api/Infra/ApiClient'
import { ref, watch } from 'vue'
import { COUNTRY_NL, getCountry } from '@js/App/Utils/CountryAndLocale'
import { isProduction } from '@js/App/Utils/Environment'

/**
 * Searches for a city (place)
 * @param searchQueryRef {Ref} The search query as a Vue ref.
 * @param shouldSearchRef {Ref<UnwrapRef<boolean>>} If true the search API will be called, otherwise it won't be called even on changes
 *   to the search query.
 * @returns {{places: Ref<UnwrapRef<*[]>>, hasMoreResults: Ref<UnwrapRef<boolean>>}}
 */
export const usePlacesSearch = (searchQueryRef, shouldSearchRef) => {
  const places = ref([])
  const hasMoreResults = ref(false)

  const getPlacesFromApi = async () => {
    const country = getCountry()
    const { defaultPlaces, isDefaultQuery } = getDefaultResponseIfEmptyQuery(country, searchQueryRef.value)

    if (isDefaultQuery) {
      places.value = defaultPlaces
      hasMoreResults.value = true
      return
    }

    const response = await doGetRequest('/api/city/search', {
      query: searchQueryRef.value,
      country
    }, isProduction())

    places.value = (response.json?.hits || [])
    hasMoreResults.value = response.json?.nbPages > 1
  }

  watch(shouldSearchRef, async () => {
    if (shouldSearchRef.value) {
      shouldSearchRef.value = false
      await getPlacesFromApi()
    }
  })

  return {
    places, hasMoreResults
  }
}

const getDefaultResponseIfEmptyQuery = (country, query) => {
  if (country !== COUNTRY_NL || query) {
    return { defaultPlaces: null, isDefaultQuery: false }
  }
  return {
    defaultPlaces: DEFAULT_RESPONSE_ON_EMPTY_QUERY,
    isDefaultQuery: true
  }
}

// This is basicaly the first API request response on an empty query. This speeds up the search bar, by "preloading"
// the first results.
const DEFAULT_RESPONSE_ON_EMPTY_QUERY = [
  {
    id: 'amsterdam',
    name: 'Amsterdam',
    rating: 1000,
    coordinates: [
      4.91,
      52.35
    ],
    zoom: 10,
    neighbourhoods: [
      {
        name: 'Centrum',
        ids: [
          'amsterdam-centrum'
        ]
      },
      {
        name: 'Oud West',
        ids: [
          'amsterdam-westerpark',
          'amsterdam-bos-en-lommer',
          'amsterdam-de-baarsjes'
        ]
      },
      {
        group: 'Oud West',
        name: 'Westerpark',
        ids: [
          'amsterdam-westerpark'
        ]
      },
      {
        group: 'Oud West',
        name: 'Bos en Lommer',
        ids: [
          'amsterdam-bos-en-lommer'
        ]
      },
      {
        group: 'Oud West',
        name: 'De Baarsjes',
        ids: [
          'amsterdam-de-baarsjes'
        ]
      },
      {
        name: 'Nieuw West',
        ids: [
          'amsterdam-geuzenveld',
          'amsterdam-osdorp',
          'amsterdam-sloten',
          'amsterdam-slotervaart',
          'amsterdam-westpoort'
        ]
      },
      {
        group: 'Nieuw West',
        name: 'Geuzenveld',
        ids: [
          'amsterdam-geuzenveld'
        ]
      },
      {
        group: 'Nieuw West',
        name: 'Westpoort',
        ids: [
          'amsterdam-westpoort'
        ]
      },
      {
        group: 'Nieuw West',
        name: 'Osdorp',
        ids: [
          'amsterdam-osdorp'
        ]
      },
      {
        group: 'Nieuw West',
        name: 'Sloten',
        ids: [
          'amsterdam-sloten'
        ]
      },
      {
        group: 'Nieuw West',
        name: 'Slotervaart',
        ids: [
          'amsterdam-slotervaart'
        ]
      },
      {
        name: 'Zuid',
        ids: [
          'amsterdam-oud-zuid',
          'amsterdam-buitenveldert',
          'amsterdam-de-pijp'
        ]
      },
      {
        group: 'Zuid',
        name: 'Oud Zuid',
        ids: [
          'amsterdam-oud-zuid'
        ]
      },
      {
        group: 'Zuid',
        name: 'Buitenveldert',
        ids: [
          'amsterdam-buitenveldert'
        ]
      },
      {
        group: 'Zuid',
        name: 'De Pijp',
        ids: [
          'amsterdam-de-pijp'
        ]
      },
      {
        name: 'Oost',
        ids: [
          'amsterdam-oud-oost',
          'amsterdam-indische-buurt',
          'amsterdam-ijburg',
          'amsterdam-watergraafsmeer'
        ]
      },
      {
        group: 'Oost',
        name: 'Oud Oost',
        ids: [
          'amsterdam-oud-oost'
        ]
      },
      {
        group: 'Oost',
        name: 'Indische Buurt',
        ids: [
          'amsterdam-indische-buurt'
        ]
      },
      {
        group: 'Oost',
        name: 'IJburg',
        ids: [
          'amsterdam-ijburg'
        ]
      },
      {
        group: 'Oost',
        name: 'Watergraafsmeer',
        ids: [
          'amsterdam-watergraafsmeer'
        ]
      },
      {
        name: 'Duivendrecht',
        ids: [
          'amsterdam-duivendrecht'
        ]
      },
      {
        name: 'Diemen',
        ids: [
          'amsterdam-diemen'
        ]
      },
      {
        name: 'Amstelveen',
        ids: [
          'amsterdam-amstelveen'
        ]
      },
      {
        name: 'Noord',
        ids: [
          'amsterdam-noord'
        ]
      },
      {
        name: 'Zuidoost',
        ids: [
          'amsterdam-zuidoost'
        ]
      }
    ],
    city_type: 'city',
    has_neighbourhoods: true,
    objectID: 'amsterdam',
    _highlightResult: {
      id: {
        value: 'amsterdam',
        matchLevel: 'none',
        matchedWords: []
      },
      name: {
        value: 'Amsterdam',
        matchLevel: 'none',
        matchedWords: []
      },
      rating: {
        value: '1000',
        matchLevel: 'none',
        matchedWords: []
      },
      coordinates: [
        {
          value: '4.91',
          matchLevel: 'none',
          matchedWords: []
        },
        {
          value: '52.35',
          matchLevel: 'none',
          matchedWords: []
        }
      ],
      zoom: {
        value: '10',
        matchLevel: 'none',
        matchedWords: []
      },
      neighbourhoods: [
        {
          name: {
            value: 'Centrum',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'amsterdam-centrum',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Oud West',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'amsterdam-westerpark',
              matchLevel: 'none',
              matchedWords: []
            },
            {
              value: 'amsterdam-bos-en-lommer',
              matchLevel: 'none',
              matchedWords: []
            },
            {
              value: 'amsterdam-de-baarsjes',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          group: {
            value: 'Oud West',
            matchLevel: 'none',
            matchedWords: []
          },
          name: {
            value: 'Westerpark',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'amsterdam-westerpark',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          group: {
            value: 'Oud West',
            matchLevel: 'none',
            matchedWords: []
          },
          name: {
            value: 'Bos en Lommer',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'amsterdam-bos-en-lommer',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          group: {
            value: 'Oud West',
            matchLevel: 'none',
            matchedWords: []
          },
          name: {
            value: 'De Baarsjes',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'amsterdam-de-baarsjes',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Nieuw West',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'amsterdam-geuzenveld',
              matchLevel: 'none',
              matchedWords: []
            },
            {
              value: 'amsterdam-osdorp',
              matchLevel: 'none',
              matchedWords: []
            },
            {
              value: 'amsterdam-sloten',
              matchLevel: 'none',
              matchedWords: []
            },
            {
              value: 'amsterdam-slotervaart',
              matchLevel: 'none',
              matchedWords: []
            },
            {
              value: 'amsterdam-westpoort',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          group: {
            value: 'Nieuw West',
            matchLevel: 'none',
            matchedWords: []
          },
          name: {
            value: 'Geuzenveld',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'amsterdam-geuzenveld',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          group: {
            value: 'Nieuw West',
            matchLevel: 'none',
            matchedWords: []
          },
          name: {
            value: 'Westpoort',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'amsterdam-westpoort',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          group: {
            value: 'Nieuw West',
            matchLevel: 'none',
            matchedWords: []
          },
          name: {
            value: 'Osdorp',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'amsterdam-osdorp',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          group: {
            value: 'Nieuw West',
            matchLevel: 'none',
            matchedWords: []
          },
          name: {
            value: 'Sloten',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'amsterdam-sloten',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          group: {
            value: 'Nieuw West',
            matchLevel: 'none',
            matchedWords: []
          },
          name: {
            value: 'Slotervaart',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'amsterdam-slotervaart',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Zuid',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'amsterdam-oud-zuid',
              matchLevel: 'none',
              matchedWords: []
            },
            {
              value: 'amsterdam-buitenveldert',
              matchLevel: 'none',
              matchedWords: []
            },
            {
              value: 'amsterdam-de-pijp',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          group: {
            value: 'Zuid',
            matchLevel: 'none',
            matchedWords: []
          },
          name: {
            value: 'Oud Zuid',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'amsterdam-oud-zuid',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          group: {
            value: 'Zuid',
            matchLevel: 'none',
            matchedWords: []
          },
          name: {
            value: 'Buitenveldert',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'amsterdam-buitenveldert',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          group: {
            value: 'Zuid',
            matchLevel: 'none',
            matchedWords: []
          },
          name: {
            value: 'De Pijp',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'amsterdam-de-pijp',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Oost',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'amsterdam-oud-oost',
              matchLevel: 'none',
              matchedWords: []
            },
            {
              value: 'amsterdam-indische-buurt',
              matchLevel: 'none',
              matchedWords: []
            },
            {
              value: 'amsterdam-ijburg',
              matchLevel: 'none',
              matchedWords: []
            },
            {
              value: 'amsterdam-watergraafsmeer',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          group: {
            value: 'Oost',
            matchLevel: 'none',
            matchedWords: []
          },
          name: {
            value: 'Oud Oost',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'amsterdam-oud-oost',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          group: {
            value: 'Oost',
            matchLevel: 'none',
            matchedWords: []
          },
          name: {
            value: 'Indische Buurt',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'amsterdam-indische-buurt',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          group: {
            value: 'Oost',
            matchLevel: 'none',
            matchedWords: []
          },
          name: {
            value: 'IJburg',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'amsterdam-ijburg',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          group: {
            value: 'Oost',
            matchLevel: 'none',
            matchedWords: []
          },
          name: {
            value: 'Watergraafsmeer',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'amsterdam-watergraafsmeer',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Duivendrecht',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'amsterdam-duivendrecht',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Diemen',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'amsterdam-diemen',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Amstelveen',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'amsterdam-amstelveen',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Noord',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'amsterdam-noord',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Zuidoost',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'amsterdam-zuidoost',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        }
      ],
      city_type: {
        value: 'city',
        matchLevel: 'none',
        matchedWords: []
      }
    }
  },
  {
    id: 'almere',
    name: 'Almere',
    rating: 990,
    coordinates: [
      5.212175395145778,
      52.37381255093248
    ],
    zoom: 10,
    neighbourhoods: [
      {
        name: 'Almere Poort',
        ids: [
          'almere-poort'
        ]
      },
      {
        name: 'Almere Stad',
        ids: [
          'almere-stad'
        ]
      },
      {
        name: 'Almere Haven',
        ids: [
          'almere-haven'
        ]
      },
      {
        name: 'Almere Buiten',
        ids: [
          'almere-buiten'
        ]
      },
      {
        name: 'Almere Hout',
        ids: [
          'almere-hout'
        ]
      }
    ],
    city_type: 'city',
    has_neighbourhoods: true,
    objectID: 'almere',
    _highlightResult: {
      id: {
        value: 'almere',
        matchLevel: 'none',
        matchedWords: []
      },
      name: {
        value: 'Almere',
        matchLevel: 'none',
        matchedWords: []
      },
      rating: {
        value: '990',
        matchLevel: 'none',
        matchedWords: []
      },
      coordinates: [
        {
          value: '5.212175395145778',
          matchLevel: 'none',
          matchedWords: []
        },
        {
          value: '52.37381255093248',
          matchLevel: 'none',
          matchedWords: []
        }
      ],
      zoom: {
        value: '10',
        matchLevel: 'none',
        matchedWords: []
      },
      neighbourhoods: [
        {
          name: {
            value: 'Almere Poort',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'almere-poort',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Almere Stad',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'almere-stad',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Almere Haven',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'almere-haven',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Almere Buiten',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'almere-buiten',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Almere Hout',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'almere-hout',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        }
      ],
      city_type: {
        value: 'city',
        matchLevel: 'none',
        matchedWords: []
      }
    }
  },
  {
    id: 'den-haag',
    name: 'Den Haag',
    rating: 980,
    zoom: 10,
    coordinates: [
      4.305725073242193,
      52.06958837063351
    ],
    neighbourhoods: [
      {
        name: 'Centrum',
        ids: [
          'den-haag-centrum'
        ]
      },
      {
        name: 'Scheveningen',
        ids: [
          'den-haag-scheveningen'
        ]
      },
      {
        name: 'Segbroek',
        ids: [
          'den-haag-segbroek'
        ]
      },
      {
        name: 'Escamp',
        ids: [
          'den-haag-escamp'
        ]
      },
      {
        name: 'Loosduinen',
        ids: [
          'den-haag-loosduinen'
        ]
      },
      {
        name: 'Laak',
        ids: [
          'den-haag-laak'
        ]
      },
      {
        name: 'Haagse Hout',
        ids: [
          'den-haag-haagse-hout'
        ]
      },
      {
        name: 'Leidschenveen-Ypenburg',
        ids: [
          'den-haag-leidschenveen-ypenburg'
        ]
      },
      {
        name: 'Rijswijk',
        ids: [
          'den-haag-rijswijk'
        ]
      }
    ],
    city_type: 'city',
    has_neighbourhoods: true,
    objectID: 'den-haag',
    _highlightResult: {
      id: {
        value: 'den-haag',
        matchLevel: 'none',
        matchedWords: []
      },
      name: {
        value: 'Den Haag',
        matchLevel: 'none',
        matchedWords: []
      },
      rating: {
        value: '980',
        matchLevel: 'none',
        matchedWords: []
      },
      zoom: {
        value: '10',
        matchLevel: 'none',
        matchedWords: []
      },
      coordinates: [
        {
          value: '4.305725073242193',
          matchLevel: 'none',
          matchedWords: []
        },
        {
          value: '52.06958837063351',
          matchLevel: 'none',
          matchedWords: []
        }
      ],
      neighbourhoods: [
        {
          name: {
            value: 'Centrum',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'den-haag-centrum',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Scheveningen',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'den-haag-scheveningen',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Segbroek',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'den-haag-segbroek',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Escamp',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'den-haag-escamp',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Loosduinen',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'den-haag-loosduinen',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Laak',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'den-haag-laak',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Haagse Hout',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'den-haag-haagse-hout',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Leidschenveen-Ypenburg',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'den-haag-leidschenveen-ypenburg',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Rijswijk',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'den-haag-rijswijk',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        }
      ],
      city_type: {
        value: 'city',
        matchLevel: 'none',
        matchedWords: []
      }
    }
  },
  {
    id: 'eindhoven',
    name: 'Eindhoven',
    rating: 970,
    coordinates: [
      5.478633507914763,
      51.44096807942071
    ],
    zoom: 10,
    neighbourhoods: [
      {
        name: 'Eindhoven Centrum',
        ids: [
          'eindhoven-centrum'
        ]
      },
      {
        name: 'Eindhoven Stratum',
        ids: [
          'eindhoven-stratum'
        ]
      },
      {
        name: 'Eindhoven Strijp',
        ids: [
          'eindhoven-strijp'
        ]
      },
      {
        name: 'Eindhoven Tongelre',
        ids: [
          'eindhoven-tongelre'
        ]
      },
      {
        name: 'Eindhoven Woensel Noord',
        ids: [
          'eindhoven-woensel-noord'
        ]
      },
      {
        name: 'Eindhoven Woensel Zuid',
        ids: [
          'eindhoven-woensel-zuid'
        ]
      },
      {
        name: 'Eindhoven Gestel',
        ids: [
          'eindhoven-gestel'
        ]
      },
      {
        name: 'Veldhoven',
        ids: [
          'eindhoven-veldhoven'
        ]
      }
    ],
    city_type: 'city',
    has_neighbourhoods: true,
    objectID: 'eindhoven',
    _highlightResult: {
      id: {
        value: 'eindhoven',
        matchLevel: 'none',
        matchedWords: []
      },
      name: {
        value: 'Eindhoven',
        matchLevel: 'none',
        matchedWords: []
      },
      rating: {
        value: '970',
        matchLevel: 'none',
        matchedWords: []
      },
      coordinates: [
        {
          value: '5.478633507914763',
          matchLevel: 'none',
          matchedWords: []
        },
        {
          value: '51.44096807942071',
          matchLevel: 'none',
          matchedWords: []
        }
      ],
      zoom: {
        value: '10',
        matchLevel: 'none',
        matchedWords: []
      },
      neighbourhoods: [
        {
          name: {
            value: 'Eindhoven Centrum',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'eindhoven-centrum',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Eindhoven Stratum',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'eindhoven-stratum',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Eindhoven Strijp',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'eindhoven-strijp',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Eindhoven Tongelre',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'eindhoven-tongelre',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Eindhoven Woensel Noord',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'eindhoven-woensel-noord',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Eindhoven Woensel Zuid',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'eindhoven-woensel-zuid',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Eindhoven Gestel',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'eindhoven-gestel',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Veldhoven',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'eindhoven-veldhoven',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        }
      ],
      city_type: {
        value: 'city',
        matchLevel: 'none',
        matchedWords: []
      }
    }
  },
  {
    id: 'groningen',
    name: 'Groningen',
    rating: 960,
    coordinates: [
      6.569399765905473,
      53.21600660832951
    ],
    zoom: 10,
    neighbourhoods: [
      {
        name: 'Binnenstad',
        ids: [
          'groningen-binnenstad'
        ]
      },
      {
        name: 'Herewegwijk en Helpman',
        ids: [
          'groningen-herewegwijk-en-helpman'
        ]
      },
      {
        name: 'Hoogkerk',
        ids: [
          'groningen-hoogkerk'
        ]
      },
      {
        name: 'Korrewegwijk',
        ids: [
          'groningen-korrewegwijk'
        ]
      },
      {
        name: 'Noorddijk',
        ids: [
          'groningen-noorddijk'
        ]
      },
      {
        name: 'Oosterparkwijk',
        ids: [
          'groningen-oosterparkwijk'
        ]
      },
      {
        name: 'Oosterpoortwijk',
        ids: [
          'groningen-oosterpoortwijk'
        ]
      },
      {
        name: 'Oranjewijk',
        ids: [
          'groningen-oranjewijk'
        ]
      },
      {
        name: 'Schilders en Zeehelden wijk',
        ids: [
          'groningen-schilders-en-zeeheldenwijk'
        ]
      },
      {
        name: 'Stadsparkwijk',
        ids: [
          'groningen-stadsparkwijk'
        ]
      }
    ],
    city_type: 'city',
    has_neighbourhoods: true,
    objectID: 'groningen',
    _highlightResult: {
      id: {
        value: 'groningen',
        matchLevel: 'none',
        matchedWords: []
      },
      name: {
        value: 'Groningen',
        matchLevel: 'none',
        matchedWords: []
      },
      rating: {
        value: '960',
        matchLevel: 'none',
        matchedWords: []
      },
      coordinates: [
        {
          value: '6.569399765905473',
          matchLevel: 'none',
          matchedWords: []
        },
        {
          value: '53.21600660832951',
          matchLevel: 'none',
          matchedWords: []
        }
      ],
      zoom: {
        value: '10',
        matchLevel: 'none',
        matchedWords: []
      },
      neighbourhoods: [
        {
          name: {
            value: 'Binnenstad',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'groningen-binnenstad',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Herewegwijk en Helpman',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'groningen-herewegwijk-en-helpman',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Hoogkerk',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'groningen-hoogkerk',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Korrewegwijk',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'groningen-korrewegwijk',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Noorddijk',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'groningen-noorddijk',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Oosterparkwijk',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'groningen-oosterparkwijk',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Oosterpoortwijk',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'groningen-oosterpoortwijk',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Oranjewijk',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'groningen-oranjewijk',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Schilders en Zeehelden wijk',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'groningen-schilders-en-zeeheldenwijk',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Stadsparkwijk',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'groningen-stadsparkwijk',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        }
      ],
      city_type: {
        value: 'city',
        matchLevel: 'none',
        matchedWords: []
      }
    }
  },
  {
    id: 'haarlem',
    name: 'Haarlem',
    rating: 950,
    coordinates: [
      4.647556999549312,
      52.3867920148346
    ],
    zoom: 10,
    neighbourhoods: [
      {
        name: 'Duinwijk',
        ids: [
          'haarlem-duinwijk'
        ]
      },
      {
        name: 'Oost',
        ids: [
          'haarlem-oost'
        ]
      },
      {
        name: 'Haarlemmerhoutkwartier',
        ids: [
          'haarlem-haarlemmerhoutkwartier'
        ]
      },
      {
        name: 'Oud Schoten en Spaarndam',
        ids: [
          'haarlem-oud-schoten-en-spaarndam'
        ]
      },
      {
        name: 'Oude Stad',
        ids: [
          'haarlem-oude-stad'
        ]
      },
      {
        name: 'Schalwijk',
        ids: [
          'haarlem-schalwijk'
        ]
      },
      {
        name: 'Spoorbaan Leiden',
        ids: [
          'haarlem-spoorbaan-leiden'
        ]
      },
      {
        name: 'Ter Kleef en Te Zaanen',
        ids: [
          'haarlem-ter-kleef-en-te-zaanen'
        ]
      },
      {
        name: 'Westoever Noord Buitenspaarne',
        ids: [
          'haarlem-westoever-noord-buitenspaarne'
        ]
      },
      {
        name: 'Overveen',
        ids: [
          'haarlem-overveen'
        ]
      },
      {
        name: 'Aerdenhout',
        ids: [
          'haarlem-aerdenhout'
        ]
      },
      {
        name: 'Heemstede',
        ids: [
          'haarlem-heemstede'
        ]
      }
    ],
    city_type: 'city',
    has_neighbourhoods: true,
    objectID: 'haarlem',
    _highlightResult: {
      id: {
        value: 'haarlem',
        matchLevel: 'none',
        matchedWords: []
      },
      name: {
        value: 'Haarlem',
        matchLevel: 'none',
        matchedWords: []
      },
      rating: {
        value: '950',
        matchLevel: 'none',
        matchedWords: []
      },
      coordinates: [
        {
          value: '4.647556999549312',
          matchLevel: 'none',
          matchedWords: []
        },
        {
          value: '52.3867920148346',
          matchLevel: 'none',
          matchedWords: []
        }
      ],
      zoom: {
        value: '10',
        matchLevel: 'none',
        matchedWords: []
      },
      neighbourhoods: [
        {
          name: {
            value: 'Duinwijk',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'haarlem-duinwijk',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Oost',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'haarlem-oost',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Haarlemmerhoutkwartier',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'haarlem-haarlemmerhoutkwartier',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Oud Schoten en Spaarndam',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'haarlem-oud-schoten-en-spaarndam',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Oude Stad',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'haarlem-oude-stad',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Schalwijk',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'haarlem-schalwijk',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Spoorbaan Leiden',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'haarlem-spoorbaan-leiden',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Ter Kleef en Te Zaanen',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'haarlem-ter-kleef-en-te-zaanen',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Westoever Noord Buitenspaarne',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'haarlem-westoever-noord-buitenspaarne',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Overveen',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'haarlem-overveen',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Aerdenhout',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'haarlem-aerdenhout',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Heemstede',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'haarlem-heemstede',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        }
      ],
      city_type: {
        value: 'city',
        matchLevel: 'none',
        matchedWords: []
      }
    }
  },
  {
    id: 'leiden',
    name: 'Leiden',
    rating: 940,
    coordinates: [
      4.496748971591744,
      52.1593735048839
    ],
    zoom: 10,
    neighbourhoods: [
      {
        name: 'Midden',
        ids: [
          'leiden-binnenstad-noord',
          'leiden-binnenstad-zuid',
          'leiden-stationsdistrict'
        ]
      },
      {
        name: 'Binnenstad noord',
        group: 'Midden',
        ids: [
          'leiden-binnenstad-noord'
        ]
      },
      {
        name: 'Binnenstad zuid',
        group: 'Midden',
        ids: [
          'leiden-binnenstad-zuid'
        ]
      },
      {
        name: 'Stations district',
        group: 'Midden',
        ids: [
          'leiden-stationsdistrict'
        ]
      },
      {
        name: 'Noord',
        ids: [
          'leiden-noord',
          'leiden-merenwijkdistrict'
        ]
      },
      {
        name: 'Leiden Noord',
        group: 'Noord',
        ids: [
          'leiden-noord'
        ]
      },
      {
        name: 'Merenwijk',
        group: 'Noord',
        ids: [
          'leiden-merenwijkdistrict'
        ]
      },
      {
        name: 'Zuid',
        ids: [
          'leiden-roodenburgerdistrict',
          'leiden-bos-en-gasthuisdistrict'
        ]
      },
      {
        group: 'Zuid',
        name: 'Roodenburger',
        ids: [
          'leiden-roodenburgerdistrict'
        ]
      },
      {
        group: 'Zuid',
        name: 'Gasthuis',
        ids: [
          'leiden-bos-en-gasthuisdistrict'
        ]
      },
      {
        name: 'West',
        ids: [
          'leiden-morsdistrict',
          'leiden-stevenhof'
        ]
      },
      {
        name: 'Mors',
        group: 'West',
        ids: [
          'leiden-morsdistrict'
        ]
      },
      {
        name: 'Stevenhof',
        group: 'West',
        ids: [
          'leiden-stevenhof'
        ]
      },
      {
        name: 'Boerhaave',
        ids: [
          'leiden-boerhaavedistrict'
        ]
      },
      {
        name: 'Leiderdorp',
        ids: [
          'leiden-leiderdorp'
        ]
      },
      {
        name: 'Voorschoten',
        ids: [
          'leiden-voorschoten'
        ]
      }
    ],
    city_type: 'city',
    has_neighbourhoods: true,
    objectID: 'leiden',
    _highlightResult: {
      id: {
        value: 'leiden',
        matchLevel: 'none',
        matchedWords: []
      },
      name: {
        value: 'Leiden',
        matchLevel: 'none',
        matchedWords: []
      },
      rating: {
        value: '940',
        matchLevel: 'none',
        matchedWords: []
      },
      coordinates: [
        {
          value: '4.496748971591744',
          matchLevel: 'none',
          matchedWords: []
        },
        {
          value: '52.1593735048839',
          matchLevel: 'none',
          matchedWords: []
        }
      ],
      zoom: {
        value: '10',
        matchLevel: 'none',
        matchedWords: []
      },
      neighbourhoods: [
        {
          name: {
            value: 'Midden',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'leiden-binnenstad-noord',
              matchLevel: 'none',
              matchedWords: []
            },
            {
              value: 'leiden-binnenstad-zuid',
              matchLevel: 'none',
              matchedWords: []
            },
            {
              value: 'leiden-stationsdistrict',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Binnenstad noord',
            matchLevel: 'none',
            matchedWords: []
          },
          group: {
            value: 'Midden',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'leiden-binnenstad-noord',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Binnenstad zuid',
            matchLevel: 'none',
            matchedWords: []
          },
          group: {
            value: 'Midden',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'leiden-binnenstad-zuid',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Stations district',
            matchLevel: 'none',
            matchedWords: []
          },
          group: {
            value: 'Midden',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'leiden-stationsdistrict',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Noord',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'leiden-noord',
              matchLevel: 'none',
              matchedWords: []
            },
            {
              value: 'leiden-merenwijkdistrict',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Leiden Noord',
            matchLevel: 'none',
            matchedWords: []
          },
          group: {
            value: 'Noord',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'leiden-noord',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Merenwijk',
            matchLevel: 'none',
            matchedWords: []
          },
          group: {
            value: 'Noord',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'leiden-merenwijkdistrict',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Zuid',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'leiden-roodenburgerdistrict',
              matchLevel: 'none',
              matchedWords: []
            },
            {
              value: 'leiden-bos-en-gasthuisdistrict',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          group: {
            value: 'Zuid',
            matchLevel: 'none',
            matchedWords: []
          },
          name: {
            value: 'Roodenburger',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'leiden-roodenburgerdistrict',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          group: {
            value: 'Zuid',
            matchLevel: 'none',
            matchedWords: []
          },
          name: {
            value: 'Gasthuis',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'leiden-bos-en-gasthuisdistrict',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'West',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'leiden-morsdistrict',
              matchLevel: 'none',
              matchedWords: []
            },
            {
              value: 'leiden-stevenhof',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Mors',
            matchLevel: 'none',
            matchedWords: []
          },
          group: {
            value: 'West',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'leiden-morsdistrict',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Stevenhof',
            matchLevel: 'none',
            matchedWords: []
          },
          group: {
            value: 'West',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'leiden-stevenhof',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Boerhaave',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'leiden-boerhaavedistrict',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Leiderdorp',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'leiden-leiderdorp',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Voorschoten',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'leiden-voorschoten',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        }
      ],
      city_type: {
        value: 'city',
        matchLevel: 'none',
        matchedWords: []
      }
    }
  },
  {
    id: 'rotterdam',
    name: 'Rotterdam',
    coordinates: [
      4.476186879555137,
      51.92342289597229
    ],
    zoom: 10,
    rating: 930,
    neighbourhoods: [
      {
        name: 'Noord',
        ids: [
          'rotterdam-noord'
        ]
      },
      {
        name: 'Prins Alexander',
        ids: [
          'rotterdam-prins-alexander'
        ]
      },
      {
        name: 'Overschie',
        ids: [
          'rotterdam-overschie'
        ]
      },
      {
        name: 'Kralingen Crooswijk',
        ids: [
          'rotterdam-kralingen-crooswijk'
        ]
      },
      {
        name: 'Ijsselmonde',
        ids: [
          'rotterdam-ijsselmonde'
        ]
      },
      {
        name: 'Hillegersberg Schiebroek',
        ids: [
          'rotterdam-hillegersberg-schiebroek'
        ]
      },
      {
        name: 'Feijenoord',
        ids: [
          'rotterdam-feijenoord'
        ]
      },
      {
        name: 'Delfshaven',
        ids: [
          'rotterdam-delfshaven'
        ]
      },
      {
        name: 'Charlois',
        ids: [
          'rotterdam-charlois'
        ]
      },
      {
        name: 'Centrum',
        ids: [
          'rotterdam-centrum'
        ]
      },
      {
        name: 'Pernis',
        ids: [
          'rotterdam-pernis'
        ]
      },
      {
        name: "'s-Gravenland",
        ids: [
          'rotterdam-sgravenland'
        ]
      }
    ],
    city_type: 'city',
    has_neighbourhoods: true,
    objectID: 'rotterdam',
    _highlightResult: {
      id: {
        value: 'rotterdam',
        matchLevel: 'none',
        matchedWords: []
      },
      name: {
        value: 'Rotterdam',
        matchLevel: 'none',
        matchedWords: []
      },
      coordinates: [
        {
          value: '4.476186879555137',
          matchLevel: 'none',
          matchedWords: []
        },
        {
          value: '51.92342289597229',
          matchLevel: 'none',
          matchedWords: []
        }
      ],
      zoom: {
        value: '10',
        matchLevel: 'none',
        matchedWords: []
      },
      rating: {
        value: '930',
        matchLevel: 'none',
        matchedWords: []
      },
      neighbourhoods: [
        {
          name: {
            value: 'Noord',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'rotterdam-noord',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Prins Alexander',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'rotterdam-prins-alexander',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Overschie',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'rotterdam-overschie',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Kralingen Crooswijk',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'rotterdam-kralingen-crooswijk',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Ijsselmonde',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'rotterdam-ijsselmonde',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Hillegersberg Schiebroek',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'rotterdam-hillegersberg-schiebroek',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Feijenoord',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'rotterdam-feijenoord',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Delfshaven',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'rotterdam-delfshaven',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Charlois',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'rotterdam-charlois',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Centrum',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'rotterdam-centrum',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Pernis',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'rotterdam-pernis',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: "'s-Gravenland",
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'rotterdam-sgravenland',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        }
      ],
      city_type: {
        value: 'city',
        matchLevel: 'none',
        matchedWords: []
      }
    }
  },
  {
    id: 'tilburg',
    name: 'Tilburg',
    rating: 910,
    coordinates: [
      5.063789909584784,
      51.53017094989818
    ],
    zoom: 10,
    neighbourhoods: [
      {
        name: 'Tilburg Centrum',
        ids: [
          'tilburg-centrum'
        ]
      },
      {
        name: 'Tilburg Oud Zuid',
        ids: [
          'tilburg-oud-zuid'
        ]
      },
      {
        name: 'Tilburg Oud Noord',
        ids: [
          'tilburg-oud-noord'
        ]
      },
      {
        name: 'Tilburg Zuid',
        ids: [
          'tilburg-zuid'
        ]
      },
      {
        name: 'Tilburg Noord',
        ids: [
          'tilburg-noord'
        ]
      },
      {
        name: 'Tilburg Oost',
        ids: [
          'tilburg-oost'
        ]
      },
      {
        name: 'Tilburg West',
        ids: [
          'tilburg-west'
        ]
      },
      {
        name: 'Reeshof',
        ids: [
          'tilburg-reeshof'
        ]
      }
    ],
    city_type: 'city',
    has_neighbourhoods: true,
    objectID: 'tilburg',
    _highlightResult: {
      id: {
        value: 'tilburg',
        matchLevel: 'none',
        matchedWords: []
      },
      name: {
        value: 'Tilburg',
        matchLevel: 'none',
        matchedWords: []
      },
      rating: {
        value: '910',
        matchLevel: 'none',
        matchedWords: []
      },
      coordinates: [
        {
          value: '5.063789909584784',
          matchLevel: 'none',
          matchedWords: []
        },
        {
          value: '51.53017094989818',
          matchLevel: 'none',
          matchedWords: []
        }
      ],
      zoom: {
        value: '10',
        matchLevel: 'none',
        matchedWords: []
      },
      neighbourhoods: [
        {
          name: {
            value: 'Tilburg Centrum',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'tilburg-centrum',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Tilburg Oud Zuid',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'tilburg-oud-zuid',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Tilburg Oud Noord',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'tilburg-oud-noord',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Tilburg Zuid',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'tilburg-zuid',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Tilburg Noord',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'tilburg-noord',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Tilburg Oost',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'tilburg-oost',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Tilburg West',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'tilburg-west',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Reeshof',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'tilburg-reeshof',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        }
      ],
      city_type: {
        value: 'city',
        matchLevel: 'none',
        matchedWords: []
      }
    }
  },
  {
    id: 'utrecht',
    name: 'Utrecht',
    rating: 900,
    coordinates: [
      5.119437367825199,
      52.08974804585338
    ],
    zoom: 10,
    neighbourhoods: [
      {
        name: 'Binnenstad',
        ids: [
          'utrecht-binnenstad'
        ]
      },
      {
        name: 'Noord oost',
        ids: [
          'utrecht-noord-oost'
        ]
      },
      {
        name: 'Oost',
        ids: [
          'utrecht-oost'
        ]
      },
      {
        name: 'Zuid',
        ids: [
          'utrecht-zuid'
        ]
      },
      {
        name: 'Zuid west',
        ids: [
          'utrecht-zuid-west'
        ]
      },
      {
        name: 'West',
        ids: [
          'utrecht-west'
        ]
      },
      {
        name: 'Noord west',
        ids: [
          'utrecht-noord-west'
        ]
      },
      {
        name: 'Overvecht',
        ids: [
          'utrecht-overvecht'
        ]
      },
      {
        name: 'Leidsche rijn',
        ids: [
          'utrecht-leidsche-rijn'
        ]
      },
      {
        name: 'Vleuten-de meern',
        ids: [
          'utrecht-vleuten-de-meern'
        ]
      },
      {
        name: 'Nieuwegein',
        ids: [
          'utrecht-nieuwegein'
        ]
      }
    ],
    city_type: 'city',
    has_neighbourhoods: true,
    objectID: 'utrecht',
    _highlightResult: {
      id: {
        value: 'utrecht',
        matchLevel: 'none',
        matchedWords: []
      },
      name: {
        value: 'Utrecht',
        matchLevel: 'none',
        matchedWords: []
      },
      rating: {
        value: '900',
        matchLevel: 'none',
        matchedWords: []
      },
      coordinates: [
        {
          value: '5.119437367825199',
          matchLevel: 'none',
          matchedWords: []
        },
        {
          value: '52.08974804585338',
          matchLevel: 'none',
          matchedWords: []
        }
      ],
      zoom: {
        value: '10',
        matchLevel: 'none',
        matchedWords: []
      },
      neighbourhoods: [
        {
          name: {
            value: 'Binnenstad',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'utrecht-binnenstad',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Noord oost',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'utrecht-noord-oost',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Oost',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'utrecht-oost',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Zuid',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'utrecht-zuid',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Zuid west',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'utrecht-zuid-west',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'West',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'utrecht-west',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Noord west',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'utrecht-noord-west',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Overvecht',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'utrecht-overvecht',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Leidsche rijn',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'utrecht-leidsche-rijn',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Vleuten-de meern',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'utrecht-vleuten-de-meern',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        },
        {
          name: {
            value: 'Nieuwegein',
            matchLevel: 'none',
            matchedWords: []
          },
          ids: [
            {
              value: 'utrecht-nieuwegein',
              matchLevel: 'none',
              matchedWords: []
            }
          ]
        }
      ],
      city_type: {
        value: 'city',
        matchLevel: 'none',
        matchedWords: []
      }
    }
  },
  {
    rating: 500,
    id: 'zwolle',
    name: 'Zwolle',
    coordinates: [
      6.094641016606943,
      52.50805766426572
    ],
    city_type: 'city',
    objectID: 'zwolle',
    _highlightResult: {
      rating: {
        value: '500',
        matchLevel: 'none',
        matchedWords: []
      },
      id: {
        value: 'zwolle',
        matchLevel: 'none',
        matchedWords: []
      },
      name: {
        value: 'Zwolle',
        matchLevel: 'none',
        matchedWords: []
      },
      coordinates: [
        {
          value: '6.094641016606943',
          matchLevel: 'none',
          matchedWords: []
        },
        {
          value: '52.50805766426572',
          matchLevel: 'none',
          matchedWords: []
        }
      ],
      city_type: {
        value: 'city',
        matchLevel: 'none',
        matchedWords: []
      }
    }
  },
  {
    rating: 500,
    id: 'maastricht',
    name: 'Maastricht',
    coordinates: [
      5.690464083109191,
      50.85097883735418
    ],
    city_type: 'city',
    objectID: 'maastricht',
    _highlightResult: {
      rating: {
        value: '500',
        matchLevel: 'none',
        matchedWords: []
      },
      id: {
        value: 'maastricht',
        matchLevel: 'none',
        matchedWords: []
      },
      name: {
        value: 'Maastricht',
        matchLevel: 'none',
        matchedWords: []
      },
      coordinates: [
        {
          value: '5.690464083109191',
          matchLevel: 'none',
          matchedWords: []
        },
        {
          value: '50.85097883735418',
          matchLevel: 'none',
          matchedWords: []
        }
      ],
      city_type: {
        value: 'city',
        matchLevel: 'none',
        matchedWords: []
      }
    }
  },
  {
    rating: 500,
    id: 'hilversum',
    name: 'Hilversum',
    coordinates: [
      5.176787221347695,
      52.22384827036254
    ],
    city_type: 'city',
    objectID: 'hilversum',
    _highlightResult: {
      rating: {
        value: '500',
        matchLevel: 'none',
        matchedWords: []
      },
      id: {
        value: 'hilversum',
        matchLevel: 'none',
        matchedWords: []
      },
      name: {
        value: 'Hilversum',
        matchLevel: 'none',
        matchedWords: []
      },
      coordinates: [
        {
          value: '5.176787221347695',
          matchLevel: 'none',
          matchedWords: []
        },
        {
          value: '52.22384827036254',
          matchLevel: 'none',
          matchedWords: []
        }
      ],
      city_type: {
        value: 'city',
        matchLevel: 'none',
        matchedWords: []
      }
    }
  },
  {
    rating: 500,
    id: 'delft',
    name: 'Delft',
    coordinates: [
      4.358382191890087,
      52.01157824579457
    ],
    city_type: 'city',
    objectID: 'delft',
    _highlightResult: {
      rating: {
        value: '500',
        matchLevel: 'none',
        matchedWords: []
      },
      id: {
        value: 'delft',
        matchLevel: 'none',
        matchedWords: []
      },
      name: {
        value: 'Delft',
        matchLevel: 'none',
        matchedWords: []
      },
      coordinates: [
        {
          value: '4.358382191890087',
          matchLevel: 'none',
          matchedWords: []
        },
        {
          value: '52.01157824579457',
          matchLevel: 'none',
          matchedWords: []
        }
      ],
      city_type: {
        value: 'city',
        matchLevel: 'none',
        matchedWords: []
      }
    }
  },
  {
    rating: 500,
    id: 'breda',
    name: 'Breda',
    coordinates: [
      4.77565996052158,
      51.58852210347646
    ],
    city_type: 'city',
    objectID: 'breda',
    _highlightResult: {
      rating: {
        value: '500',
        matchLevel: 'none',
        matchedWords: []
      },
      id: {
        value: 'breda',
        matchLevel: 'none',
        matchedWords: []
      },
      name: {
        value: 'Breda',
        matchLevel: 'none',
        matchedWords: []
      },
      coordinates: [
        {
          value: '4.77565996052158',
          matchLevel: 'none',
          matchedWords: []
        },
        {
          value: '51.58852210347646',
          matchLevel: 'none',
          matchedWords: []
        }
      ],
      city_type: {
        value: 'city',
        matchLevel: 'none',
        matchedWords: []
      }
    }
  },
  {
    rating: 500,
    id: 'arnhem',
    name: 'Arnhem',
    coordinates: [
      5.911334772262649,
      51.98472487443317
    ],
    city_type: 'city',
    objectID: 'arnhem',
    _highlightResult: {
      rating: {
        value: '500',
        matchLevel: 'none',
        matchedWords: []
      },
      id: {
        value: 'arnhem',
        matchLevel: 'none',
        matchedWords: []
      },
      name: {
        value: 'Arnhem',
        matchLevel: 'none',
        matchedWords: []
      },
      coordinates: [
        {
          value: '5.911334772262649',
          matchLevel: 'none',
          matchedWords: []
        },
        {
          value: '51.98472487443317',
          matchLevel: 'none',
          matchedWords: []
        }
      ],
      city_type: {
        value: 'city',
        matchLevel: 'none',
        matchedWords: []
      }
    }
  },
  {
    rating: 500,
    id: 'amersfoort',
    name: 'Amersfoort',
    coordinates: [
      5.389446669812287,
      52.1555976705603
    ],
    city_type: 'city',
    objectID: 'amersfoort',
    _highlightResult: {
      rating: {
        value: '500',
        matchLevel: 'none',
        matchedWords: []
      },
      id: {
        value: 'amersfoort',
        matchLevel: 'none',
        matchedWords: []
      },
      name: {
        value: 'Amersfoort',
        matchLevel: 'none',
        matchedWords: []
      },
      coordinates: [
        {
          value: '5.389446669812287',
          matchLevel: 'none',
          matchedWords: []
        },
        {
          value: '52.1555976705603',
          matchLevel: 'none',
          matchedWords: []
        }
      ],
      city_type: {
        value: 'city',
        matchLevel: 'none',
        matchedWords: []
      }
    }
  },
  {
    rating: 500,
    id: 'alkmaar',
    name: 'Alkmaar',
    coordinates: [
      4.749842685492467,
      52.63106656195717
    ],
    city_type: 'city',
    objectID: 'alkmaar',
    _highlightResult: {
      rating: {
        value: '500',
        matchLevel: 'none',
        matchedWords: []
      },
      id: {
        value: 'alkmaar',
        matchLevel: 'none',
        matchedWords: []
      },
      name: {
        value: 'Alkmaar',
        matchLevel: 'none',
        matchedWords: []
      },
      coordinates: [
        {
          value: '4.749842685492467',
          matchLevel: 'none',
          matchedWords: []
        },
        {
          value: '52.63106656195717',
          matchLevel: 'none',
          matchedWords: []
        }
      ],
      city_type: {
        value: 'city',
        matchLevel: 'none',
        matchedWords: []
      }
    }
  },
  {
    rating: 500,
    id: "'s-hertogenbosch",
    name: 'Den Bosch',
    coordinates: [
      5.302814074010484,
      51.68867840990802
    ],
    city_type: 'city',
    objectID: "'s-hertogenbosch",
    _highlightResult: {
      rating: {
        value: '500',
        matchLevel: 'none',
        matchedWords: []
      },
      id: {
        value: "'s-hertogenbosch",
        matchLevel: 'none',
        matchedWords: []
      },
      name: {
        value: 'Den Bosch',
        matchLevel: 'none',
        matchedWords: []
      },
      coordinates: [
        {
          value: '5.302814074010484',
          matchLevel: 'none',
          matchedWords: []
        },
        {
          value: '51.68867840990802',
          matchLevel: 'none',
          matchedWords: []
        }
      ],
      city_type: {
        value: 'city',
        matchLevel: 'none',
        matchedWords: []
      }
    }
  },
  {
    rating: 495,
    id: 'zwijndrecht',
    name: 'Zwijndrecht',
    coordinates: [
      4.640391,
      51.816529
    ],
    city_type: 'city',
    objectID: 'zwijndrecht',
    _highlightResult: {
      rating: {
        value: '495',
        matchLevel: 'none',
        matchedWords: []
      },
      id: {
        value: 'zwijndrecht',
        matchLevel: 'none',
        matchedWords: []
      },
      name: {
        value: 'Zwijndrecht',
        matchLevel: 'none',
        matchedWords: []
      },
      coordinates: [
        {
          value: '4.640391',
          matchLevel: 'none',
          matchedWords: []
        },
        {
          value: '51.816529',
          matchLevel: 'none',
          matchedWords: []
        }
      ],
      city_type: {
        value: 'city',
        matchLevel: 'none',
        matchedWords: []
      }
    }
  }
]
