<script setup>

import CitySelectUsingStore from '@js/Components/Forms/CitySelect/CitySelectUsingStore.vue'
import { ON_COMPLETE_REDIRECT_TO_UPGRADE_PAGE, showCreateSearchProfileWizard } from '@js/App/SearchProfile'
import Button from '@js/Components/Forms/Button/Button.vue'
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import ErrorMessage from '@js/Components/Forms/ErrorMessage/ErrorMessage.vue'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { trans } from '@js/App/Plugins/Translations'

defineProps({
  black: {
    type: Boolean,
    default: false
  },
  textInputMarginRight: {
    type: Boolean,
    default: true
  }
})

const searchProfileStore = useSearchProfileStore()
const { searchProfile } = storeToRefs(searchProfileStore)

const error = ref('')
const onClickedButton = () => {
  if (!searchProfile.value.city) {
    error.value = trans('LandingPageCitySelect.SelectCityErrorMessage')
    return
  }

  error.value = ''

  showCreateSearchProfileWizard(ON_COMPLETE_REDIRECT_TO_UPGRADE_PAGE)
}
</script>
<template>
  <div class="lg:flex lg:items-end">
    <CitySelectUsingStore
      dusk="landing-page-city-select"
      :text-input-margin-right="textInputMarginRight"
      :show-error-border="!!error"
      @update:city="error = ''"
    />
    <Button
      dusk="landing-page-city-select-submit-button"
      :big="true"
      :full-width-on-mobile="true"
      icon-right="arrow-right"
      class="mt-2 lg:mt-0"
      @click="onClickedButton"
    >
      <span class="whitespace-nowrap">{{ $trans('MainHeroSection.ButtonLabel') }}</span>
    </Button>
  </div>
  <ErrorMessage
    v-if="error"
    class="mt-1 w-full"
  >
    {{ error }}
  </ErrorMessage>
</template>
