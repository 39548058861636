<script setup>
import Checkbox from '@js/Components/Forms/Checkbox/Checkbox.vue'
import { useUser } from '@js/App/Api/User/User'
import { ref, watch } from 'vue'
import { useMainStore } from '@js/App/Stores/MainStore'
import { doPostRequest } from '@js/App/Api/Infra/ApiClient'
import { reloadPropsFromServer } from '@js/App/Api/Infra/ServerData'

const props = defineProps({
  saveOnValueChange: {
    type: Boolean,
    default: true
  }
})

const { user } = useUser()
const internalShowHouseDetailPage = ref(user.value.showHouseDetailPage)

const mainStore = useMainStore()
const errors = ref({})

watch(internalShowHouseDetailPage, async () => {
  if (!props.saveOnValueChange) {
    return
  }

  await saveShowHouseDetailPage()
})

const saveShowHouseDetailPage = async () => {
  mainStore.startLoading()
  errors.value = {}

  try {
    const response = await doPostRequest('/api/user/show-house-detail-page', {
      show_house_detail_page: internalShowHouseDetailPage.value
    })
    if (response.hasErrors) {
      errors.value = response.errors
      return false
    }

    reloadPropsFromServer()
    return true
  } finally {
    mainStore.stopLoading()
  }
}

defineExpose({ saveShowHouseDetailPage })

</script>
<template>
  <div>
    <Checkbox v-model="internalShowHouseDetailPage">
      <span class="text-base" v-html="$trans('ShowInbetweenPageInput.Label')" />
    </Checkbox>
  </div>
</template>
