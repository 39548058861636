<script setup>
import HowItWorksHeroSection from '@js/Components/Marketing/Heros/HowItWorksHeroSection/HowItWorksHeroSection.vue'
import ThreeStepsSection from '@js/Components/Marketing/ThreeExplainerStepsSection/ThreeExplainerStepsSection.vue'
import StartYourSearchSectionLoggedIn
  from '@js/Components/Marketing/StartYourSearchSection/StartYourSearchSectionLoggedIn.vue'
import FeaturesSection from '@js/Components/Marketing/FeaturesSection/FeaturesSection.vue'
import Container from '@js/Components/Container/Container.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import FaqQuestion from '@js/Components/FaqQuestion/FaqQuestion.vue'
import RentHackSection from '@js/Components/Marketing/RentHackSection/RentHackSection.vue'
import { useUser } from '@js/App/Api/User/User'
import UserReviewsSectionAlternative
  from '@js/Components/Marketing/UserReviewsSectionAlternative/UserReviewsSectionAlternative.vue'
import CallToActionWithPricingSectionUS
  from '@js/Components/Marketing/CallToActionWithPricingSectionUS/CallToActionWithPricingSectionUS.vue'
import FooterUS from '@js/Components/Marketing/Footer/US/FooterUS.vue'
import StartYourSearchSectionUS from '@js/Components/Marketing/StartYourSearchSection/US/StartYourSearchSectionUS.vue'

const { user } = useUser()

</script>
<template>
  <HowItWorksHeroSection>
    <template #subheader>
      <span v-html="$trans('HowItWorks.Title')" />
    </template>
    <template #header>
      <span v-html="$trans('HowItWorks.Subtitle')" />
    </template>
    <template #robotquote>
      <span v-html="$trans('HowItWorks.RobotQuote')" />
    </template>
  </HowItWorksHeroSection>
  <ThreeStepsSection />

  <StartYourSearchSectionLoggedIn v-if="user.isLoggedIn" class="mt-12 lg:mt-0" />
  <StartYourSearchSectionUS v-else class="mt-12 lg:mt-0" />

  <div class="py-10">
    <FeaturesSection />
  </div>

  <div class="py-16">
    <RentHackSection />
  </div>

  <UserReviewsSectionAlternative>
    <template #button>
      <Button
        class="mr-4"
        :outline="true"
        :big="true"
        icon-right="arrow-right"
        tag="link"
        :href="$getLocalizedUrl('reviews')"
      >
        {{ $trans('UserReviewsCarousel.ButtonLabel') }}
      </Button>
    </template>
  </UserReviewsSectionAlternative>

  <div class="pt-[60px] lg:pt-[105px] pb-[40px]">
    <Container>
      <p class="text-lg lg:text-2xl text-purple-500 lg:mb-1">
        {{ $trans('HowItWorksPage.FaqSubtitle') }}
      </p>
      <h4 class="text-3xl text-[28px] lg:text-5xl font-medium text-purple-900 mb-3 lg:mb-8">
        {{ $trans('HowItWorksPage.FaqTitle') }}
      </h4>
      <FaqQuestion class="mb-2 lg:mb-4">
        <template #title>
          {{ $trans('FaqQuestion.HowLongTitle') }}
        </template>
        <template #default>
          <span v-html="$trans('FaqQuestion.HowLongContent')" />
        </template>
      </FaqQuestion>

      <FaqQuestion class="mb-2 lg:mb-4">
        <template #title>
          {{ $trans('FaqQuestion.DoYouVerhuurTitle') }}
        </template>
        <template #default>
          <span v-html="$trans('FaqQuestion.DoYouVerhuurContent')" />
        </template>
      </FaqQuestion>

      <FaqQuestion class="lg:mb-4">
        <template #title>
          {{ $trans('FaqQuestion.WhyWouldIPayTitle') }}
        </template>
        <template #default>
          <span v-html="$trans('FaqQuestion.WhyWouldIPayContent')" />
        </template>
      </FaqQuestion>

      <Button
        class="mt-4 lg:mt-6"
        :big="true"
        :outline="true"
        :full-width-on-mobile="true"
        tag="link"
        :href="$getLocalizedUrl('faq')"
        icon-right="arrow-right"
      >
        {{ $trans('PricingPage.FaqButtonLabel') }}
      </Button>
    </Container>
  </div>
  <CallToActionWithPricingSectionUS />
  <FooterUS />
</template>
