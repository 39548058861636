import { doGetRequest } from '@js/App/Api/Infra/ApiClient'
import { onMounted, ref, watch } from 'vue'
import { useHasBeenInViewport } from '@js/App/Performance/HasBeenInViewport'

/**
 * An API query that exposes everything needed to display the latest houses in a city.
 * Used in the slideshow on the homepage.
 * @param elementRef The outer element ref, used to only load the houses in the city when the viewport is close.
 * @param cityRef The selected city value as a Vue ref.
 * @returns {{
 *  isLoading: Ref<UnwrapRef<boolean>>,
 *  hasErrors: Ref<UnwrapRef<boolean>>,
 *  houses: Ref<UnwrapRef<*[]>>,
 *  numberOfHouses: Ref<UnwrapRef<number>>,
 *  availablePlaces: Ref<UnwrapRef<*[]>>,
 *  errors: Ref<UnwrapRef<*[]>>
 * }}
 */
export const useHousesInCity = (elementRef, cityRef) => {
  const isLoading = ref(true)
  const hasErrors = ref(false)
  const errors = ref([])
  const houses = ref([])
  const numberOfHouses = ref(0)
  const availablePlaces = ref([])
  const { hasBeenInViewport } = useHasBeenInViewport(elementRef, 500)

  const getHousesInCityFromApi = async () => {
    isLoading.value = true
    const response = await doGetRequest('/api/houses-in-city-for-slideshow', {
      city: cityRef.value.id,
      include_funda: false
    })

    hasErrors.value = response.hasErrors || false
    errors.value = response.errors || []
    houses.value = response.json?.houses || []
    numberOfHouses.value = response.json?.total || 0
    availablePlaces.value = response.json?.availablePlaces || []

    isLoading.value = false
  }

  onMounted(() => {
    watch(hasBeenInViewport, async () => {
      await getHousesInCityFromApi()
    })

    watch(cityRef, async () => {
      await getHousesInCityFromApi()
    })
  })

  return {
    isLoading, hasErrors, errors, houses, numberOfHouses, availablePlaces
  }
}
