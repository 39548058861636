<script setup>
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import Container from '@js/Components/Container/Container.vue'
import TextInput from '@js/Components/Forms/TextInput/TextInput.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import PasswordInput from '@js/Components/Forms/PasswordInput/PasswordInput.vue'
import { router, usePage } from '@inertiajs/vue3'
import { reactive } from 'vue'

defineProps({ errors: Object })

const page = usePage()

const form = reactive({
  csrf: page.props.csrf,
  email: '',
  password: ''
})

const submit = async () => {
  await router.post('/control-panel', form, {
    onSuccess: () => {
      console.log('Success! reloading')
      window.location.reload()
    }
  })
}
</script>
<template>
  <Container>
    <div class="py-20">
      <MyRentbirdCard class="w-[600px] max-w-full mx-auto">
        <form class="relative block " method="post" @submit.prevent="submit">
          <h2 class="text-purple-900 font-medium text-3xl mb-2 lg:text-4xl">
            Control Panel Login
          </h2>
          <TextInput
            v-model="form.email"
            dusk="email"
            :error="errors.email"
            class="mb-4"
            placeholder="Email"
          >
            <template #label>
              <span class="text-purple-900">Email</span>
            </template>
          </TextInput>

          <PasswordInput
            v-model="form.password"
            dusk="password"
            :error="errors.password"
            class="mb-4"
            :show-create-password-hint="false"
            placeholder="password"
          >
            <template #label>
              <span class="text-purple-900">Password</span>
            </template>
          </PasswordInput>

          <Button
            dusk="submit-button"
            type="submit"
            :big="true"
            :full-width-on-mobile="true"
            :full-width-on-desktop="true"
            icon-right="arrow-right"
            class="mb-4"
          >
            Login
          </Button>
        </form>
      </MyRentbirdCard>
    </div>
  </Container>
</template>
<style lang="scss">
.ControlPanel-LoginPage {
  background: rgb(234,234,235);
}
</style>
