<script setup>
import TrustpilotSmall from '@js/Components/Trustpilot/TrustpilotSmall.vue'
import SectionDivider from '@js/Components/Marketing/SectionDivider/SectionDivider.vue'
import Container from '@js/Components/Container/Container.vue'
import { usePage } from '@inertiajs/vue3'
import RentbirdLogo from '@js/Components/Logos/RentbirdLogo/RentbirdLogo.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { useUser } from '@js/App/Api/User/User'

const page = usePage()
const { user } = useUser()
</script>
<template>
  <div class="bg-purple-700">
    <SectionDivider :reversed="true" />
    <Container>
      <div class="text-white pb-12 lg:pb-20 pt-12 lg:pt-10">
        <div class="lg:hidden flex justify-center mb-4 lg:mb-8">
          <RentbirdLogo :href="false" size="md" />
        </div>
        <div class="lg:flex lg:w-full items-center justify-between">
          <div class="mb-4 lg:mb-0 xl:pl-20 lg:pr-4 lg:w-1/2 xl:w-[848px]">
            <h4 class="text-3xl lg:text-5xl text-center lg:text-left font-medium" v-html="$trans('CallToActionSectionCityPage.Title', { city: page.props.city_name})" />
          </div>
          <div class=" lg:pl-4 lg:w-1/2 lg:flex justify-end">
            <div class="lg:flex">
              <Button
                v-if="user.hasActiveSubscription"
                tag="link"
                :href="$getLocalizedUrl('my-rentbird.match-history')"
                :big="true"
                :full-width-on-mobile="true"
                icon-right="arrow-right"
                class="lg:mr-2 mb-2 lg:mb-0"
              >
                <span class="whitespace-nowrap">{{ $trans('HomePageLoggedIn.MatchHistoryButton') }}</span>
              </Button>
              <Button
                v-if="user.hasActiveSubscription"
                tag="link"
                :href="$getLocalizedUrl('my-rentbird.create-search-profile')"
                :big="true"
                :outline="true"
                :full-width-on-mobile="true"
                icon-right="plus"
              >
                <span class="whitespace-nowrap">{{ $trans('HomePageLoggedIn.NewSearchProfileButton') }}</span>
              </Button>
              <Button
                v-if="!user.hasActiveSubscription"
                tag="link"
                :href="$getLocalizedUrl('upgrade')"
                :big="true"
                :full-width-on-mobile="true"
                icon-right="unlocked"
                class="lg:mr-2 mb-2 lg:mb-0"
              >
                <span class="whitespace-nowrap">{{ $trans('HomePageLoggedIn.ChooseSubscription') }}</span>
              </Button>
              <Button
                v-if="!user.hasActiveSubscription"
                tag="link"
                :href="$getLocalizedUrl('pricing')"
                :big="true"
                :outline="true"
                :full-width-on-mobile="true"
                icon-right="arrow-right"
              >
                <span class="whitespace-nowrap">{{ $trans('HomePageLoggedIn.ViewPrices') }}</span>
              </Button>

              <TrustpilotSmall class="mt-4 lg:mt-0 lg:hidden" />
            </div>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>
