<script setup>
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import PasswordInput from '@js/Components/Forms/PasswordInput/PasswordInput.vue'
import { ref } from 'vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { useMainStore } from '@js/App/Stores/MainStore'
import { doPostRequest } from '@js/App/Api/Infra/ApiClient'

const newPassword = ref('')

const showSavedMessage = ref(false)
const mainStore = useMainStore()
const errors = ref({})
const onEditPassword = async () => {
  mainStore.startLoading()
  errors.value = {}

  const response = await doPostRequest('/api/user/change-password', {
    password: newPassword.value
  })

  if (response.hasErrors) {
    errors.value = response.errors
  } else {
    showSavedMessage.value = true
  }

  mainStore.stopLoading()
}
</script>
<template>
  <MyRentbirdCard :can-expand-on-mobile="false">
    <template #title>
      {{ $trans('EditPasswordCard.Title') }}
    </template>
    <PasswordInput
      v-model="newPassword"
      :error="errors.password"
    >
      <template #label>
        {{ $trans('EditPasswordCard.Label') }}
      </template>
    </PasswordInput>

    <Button class="mt-[16px]" :icon-right="showSavedMessage ? 'check' : 'save'" :full-width-on-mobile="true" @click="onEditPassword">
      <span v-if="!showSavedMessage">{{ $trans('Common.SaveNewPassword') }}</span>
      <span v-else>{{ $trans('Common.Saved') }}</span>
    </Button>
  </MyRentbirdCard>
</template>
