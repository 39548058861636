<script setup>
import { useUser } from '@js/App/Api/User/User'
import { computed } from 'vue'
import { Link } from '@inertiajs/vue3'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'

const props = defineProps({
  step: Number
})

const { user } = useUser()
const isCompleted = computed(() => (user.value.verhuisgids_completed_steps || []).includes(props.step))
</script>
<template>
  <div class="text-lg text-purple-700 flex items-center justify-between">
    <div class="flex">
      <b class="w-[28px]">{{ step }}.</b>
      <Link
        :href="getLocalizedUrl('my-rentbird.verhuisgids', { step: step })"
        class="hover:underline "
        :class="{
          'line-through text-success hover:line-through': isCompleted,
          'hover:cursor-pointer hover:underline hover:text-blue-base transition-all': !isCompleted
        }"
      >
        <slot />
      </Link>
    </div>

    <svg
      v-if="isCompleted"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="28" height="28" rx="14" fill="#00B67A" />
      <path d="M22.5545 7.58301L10.7906 19.3469L5.44336 13.9997" stroke="white" stroke-width="2.13889" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg
      v-else
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <rect
        x="0.583333"
        y="0.583333"
        width="26.8333"
        height="26.8333"
        rx="13.4167"
        stroke="#8D8F90"
        stroke-width="1.16667"
      />
    </svg>
  </div>
</template>
