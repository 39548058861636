<script setup>
import RentbirdLogo from '@js/Components/Logos/RentbirdLogo/RentbirdLogo.vue'
import Container from '@js/Components/Container/Container.vue'
import MyRentbirdNavbarDropdown from '@js/Components/MyRentbird/MyRentbirdNavbar/MyRentbirdNavbarDropdown.vue'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'
import { useUser } from '@js/App/Api/User/User'
import Icon from '@js/Components/Icon/Icon.vue'
import NavMenuFoldout from '@js/Components/NavMenu/NavMenuFoldout.vue'
import { ref } from 'vue'
import { Link } from '@inertiajs/vue3'

defineProps({
  showAccountMenu: {
    type: Boolean,
    default: true
  },
  logoIsClickable: {
    type: Boolean,
    default: true
  }
})

const { isMobile } = useIsMobile()

const { user } = useUser()
const isLoggedIn = user.value.isLoggedIn

const isMenuOpen = ref(false)
</script>
<template>
  <div class="py-[24px] lg:py-[34px] bg-purple-700">
    <Container>
      <div class="flex justify-between items-center">
        <RentbirdLogo :href="logoIsClickable ? $getLocalizedUrl('welcome') : false" :size="isMobile ? 'xs' : 'md'" />
        <MyRentbirdNavbarDropdown v-if="showAccountMenu && isLoggedIn" />
        <div v-else class="flex items-center">
          <div class="hidden lg:block lg:mr-6 hover:text-pink-500 p-4 text-white text-base rounded-md transition-colors">
            <Link v-if="!user.isLoggedIn" :href="$getLocalizedUrl('login')" class="flex">
              <Icon name="user" class="relative mr-2" />
              <span>{{ $trans('NavMenu.Login') }}</span>
            </Link>
            <Link v-else :href="$getLocalizedUrl('my-rentbird.home')" class="flex">
              <span>{{ $trans('NavMenuFoldout.MyRentbird') }}</span>
              <Icon name="trending-up" class="relative ml-2" />
            </Link>
          </div>
          <div class="flex hover:text-pink-500 hover:cursor-pointer bg-purple-900 p-4 text-white text-base rounded-md transition-colors" @click="isMenuOpen = true">
            <Icon name="menu" class="relative mr-2" />

            <span>{{ $trans('NavMenu.Menu') }}</span>
          </div>
        </div>
        <NavMenuFoldout :is-open="isMenuOpen" @close="() => isMenuOpen = false" />
      </div>
    </Container>
  </div>
</template>
