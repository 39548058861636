export const shuffle = (array) => {
  let currentIndex = array.length; let randomIndex

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]]
  }

  return array
}

export const chunk = (array, numberOfChunks, balanced = true) => {
  if (numberOfChunks < 2) { return [array] }

  const len = array.length
  const out = []
  let i = 0
  let size

  if (len % numberOfChunks === 0) {
    size = Math.floor(len / numberOfChunks)
    while (i < len) {
      out.push(array.slice(i, i += size))
    }
  } else if (balanced) {
    while (i < len) {
      size = Math.ceil((len - i) / numberOfChunks--)
      out.push(array.slice(i, i += size))
    }
  } else {
    numberOfChunks--
    size = Math.floor(len / numberOfChunks)
    if (len % size === 0) { size-- }
    while (i < size * numberOfChunks) {
      out.push(array.slice(i, i += size))
    }
    out.push(array.slice(size * numberOfChunks))
  }

  return out
}

export const unique = (array) => {
  return array.filter((value, index) => {
    return array.indexOf(value) === index
  })
}

export const randomElement = array =>
  array[Math.floor(Math.random() * array.length)]

export const includesAny = (array, needles) => {
  for (const needle of needles) {
    if (array.includes(needle)) {
      return true
    }
  }
  return false
}
