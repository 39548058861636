<script setup>
import Select from '@js/Components/Forms/Select/Select.vue'
import { computed } from 'vue'
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import { storeToRefs } from 'pinia'

defineEmits(['click:neighbourhoods'])

defineProps({
  error: {
    type: String,
    default: ''
  }
})

const { searchProfile } = storeToRefs(useSearchProfileStore())
const selectedCityHasNeighbourhoods = computed(() => (searchProfile.value.city?.hasNeighbourhoods))
</script>
<template>
  <div>
    <div class="flex mt-4 justify-between">
      <label class="block font-medium text-base mb-1">
        {{ $trans('DistanceSelect.Label') }}
      </label>
      <div v-show="selectedCityHasNeighbourhoods" class="text-blue-300 hover:cursor-pointer hover:underline transition-all" @click="$emit('click:neighbourhoods')">
        {{ $trans('DistanceSelect.SwitchToNeighbourhoods') }}
      </div>
    </div>
    <Select
      v-model="searchProfile.distance"
      :error="error"
      :options="[2, 3, 5, 8, 10, 15, 20].map(item => ({label: `< ${item}km`, value: item}))"
    />
  </div>
</template>
