export const DEFAULT_CITY = ''
export const DEFAULT_DISTANCE = 10
export const DEFAULT_SORT_BY = 'created_at'
export const DEFAULT_SORT_ASCENDING = false
export const DEFAULT_MAX_RENT = ''
export const DEFAULT_MIN_BEDROOMS = 0
export const DEFAULT_MIN_SURFACE = 0
export const DEFAULT_FURNISHED = 'everything'
export const DEFAULT_EXTRA_WISHES = []
export const DEFAULT_NEIGHBOURHOODS = []
