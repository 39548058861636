import { trans } from '@js/App/Plugins/Translations'

export const getHardcodedReviews = () => {
  return [
    {
      name: 'Noa Hoekstra',
      formatted_title_text: trans('UserReviews.NoaHoekstraTitle'),
      stars: 5,
      trustpilot_id: '65c4c1a4dbf092ece2004583',
      image: '/img/people/user-review-noah.png',
      video: '/videos/user-review-noah.mov',
      date_formatted: trans('UserReviews.NoaHoekstraDate'),
      formatted_review_text: trans('UserReviews.NoaHoekstraContent')
    },
    {
      name: 'Aron de Jong',
      formatted_title_text: trans('UserReviews.AronTitle'),
      stars: 5,
      trustpilot_id: '6543bfc6aac2b75d6560f7f5',
      image: '/img/people/user-review-aron.png',
      date_formatted: trans('UserReviews.AronDate'),
      formatted_review_text: trans('UserReviews.AronContent')
    },
    {
      name: 'Aedan O’Shea',
      formatted_title_text: trans('UserReviews.AedanTitle'),
      stars: 5,
      trustpilot_id: '660d554086692bbf461b7a26',
      image: '/img/people/user-review-aedan.png',
      date_formatted: trans('UserReviews.AedanDate'),
      formatted_review_text: trans('UserReviews.AedanContent')
    },
    {
      name: 'Reza Abdoelrazak',
      formatted_title_text: trans('UserReviews.RezaTitle'),
      stars: 5,
      trustpilot_id: '661b8dca35126776ec646228',
      image: '/img/people/user-review-reza.jpg',
      date_formatted: trans('UserReviews.RezaDate'),
      formatted_review_text: trans('UserReviews.RezaContent')
    },
    {
      name: 'Emerence & Peter',
      formatted_title_text: trans('UserReviews.EmerenceTitle'),
      stars: 5,
      trustpilot_id: '6267beac7b2618e9fea623e8',
      image: '/img/people/user-review-emerence2.jpg',
      date_formatted: trans('UserReviews.EmerenceDate'),
      formatted_review_text: trans('UserReviews.EmerenceContent')
    },
    {
      name: 'Daniele Frenguelli',
      formatted_title_text: trans('UserReviews.DanieleTitle'),
      stars: 5,
      trustpilot_id: '6478c9fdfa52fbbc784740a5',
      image: '/img/people/user-review-daniele2.png',
      date_formatted: trans('UserReviews.DanieleDate'),
      formatted_review_text: trans('UserReviews.DanieleContent')
    },
    {
      name: 'Ema Miseikaite',
      formatted_title_text: trans('UserReviews.EmaTitle'),
      stars: 5,
      trustpilot_id: '654204f54952b9753624c871',
      image: '/img/people/user-review-ema.png',
      date_formatted: trans('UserReviews.EmaDate'),
      formatted_review_text: trans('UserReviews.EmaContent')
    }
  ]
}
