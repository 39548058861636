<script setup>
import Footer from '@js/Components/Marketing/Footer/Footer.vue'
import ReviewsHeroSection from '@js/Components/Marketing/Heros/ReviewsHeroSection/ReviewsHeroSection.vue'
import { usePage } from '@inertiajs/vue3'
import { computed, ref, toRaw } from 'vue'
import Container from '@js/Components/Container/Container.vue'
import { chunk } from '@js/App/Utils/Arrays'
import UserReviewCard from '@js/Components/Marketing/UserReviewsSection/UserReviewCard.vue'
import { doGetRequest } from '@js/App/Api/Infra/ApiClient'
import LoadingSpinner from '@js/Components/Forms/LoadingSpinner/LoadingSpinner.vue'
import { onReachBottomOfPage } from '@js/App/Utils/Scrolling'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'
import Button from '@js/Components/Forms/Button/Button.vue'
import ReviewsHeroSectionLoggedIn from '@js/Components/Marketing/Heros/ReviewsHeroSection/ReviewsHeroSectionLoggedIn.vue'
import { useUser } from '@js/App/Api/User/User'

const { user } = useUser()
const page = usePage()
const { isMobile } = useIsMobile()
const reviews = ref(page.props.reviews)

const reviewsFiltered = computed(() => {
  if (isMobile.value) {
    return toRaw(reviews.value).filter(item => item.formatted_review_text.length > 300)
  } else {
    return reviews.value
  }
})

const reviewsChunked = computed(() => chunk(reviewsFiltered.value, 3)
)

const isLoading = ref(false)
const loadingCount = ref(0)
const hasLoadedAllReviews = ref(false)

const loadMoreReviews = async () => {
  isLoading.value = true

  const response = await doGetRequest(`/api/reviews?skip=${reviews.value.length}`)
  isLoading.value = false

  const previousLength = reviews.value.length
  reviews.value = [
    ...reviews.value,
    ...response.json.reviews
  ]

  if (previousLength === reviews.value.length) {
    hasLoadedAllReviews.value = true
  }
}

onReachBottomOfPage(() => {
  if (isLoading.value) { return }
  if (hasLoadedAllReviews.value) { return }
  if (loadingCount.value >= 2) { return }

  loadMoreReviews()
  loadingCount.value++
})
</script>
<template>
  <ReviewsHeroSectionLoggedIn v-if="user.isLoggedIn" />
  <ReviewsHeroSection v-else />
  <Container>
    <div class="lg:flex pt-2">
      <div class="lg:w-1/3 lg:pr-4">
        <UserReviewCard
          v-for="review of reviewsChunked[0]"
          :key="review.trustpilot_id"
          class="mb-4 lg:mb-6 mx-auto"
          :name="review.name"
          :image="false"
          :trustpilot-url="`https://www.trustpilot.com/reviews/${review.trustpilot_id}`"
          :rating="review.stars"
          :date-text="review.date_formatted"
          :big-text-formatting="true"
        >
          <span v-html="review.formatted_review_text" />
        </UserReviewCard>
      </div>
      <div class="lg:w-1/3 lg:pl-2 lg:pr-2">
        <UserReviewCard
          v-for="review of reviewsChunked[1]"
          :key="review.trustpilot_id"
          class="mb-4 lg:mb-6 mx-auto"
          :name="review.name"
          :image="false"
          :trustpilot-url="`https://www.trustpilot.com/reviews/${review.trustpilot_id}`"
          :rating="review.stars"
          :date-text="review.date_formatted"
          :big-text-formatting="true"
        >
          <span v-html="review.formatted_review_text" />
        </UserReviewCard>
      </div>
      <div class="lg:w-1/3 lg:pl-4">
        <UserReviewCard
          v-for="review of reviewsChunked[2]"
          :key="review.trustpilot_id"
          class="mb-4 lg:mb-6 mx-auto"
          :name="review.name"
          :image="false"
          :trustpilot-url="`https://www.trustpilot.com/reviews/${review.trustpilot_id}`"
          :rating="review.stars"
          :date-text="review.date_formatted"
          :big-text-formatting="true"
        >
          <span v-html="review.formatted_review_text" />
        </UserReviewCard>
      </div>
    </div>

    <div v-if="isLoading" class="flex w-full justify-center py-4">
      <LoadingSpinner />
    </div>

    <div v-if="loadingCount===2 && isLoading===false && !hasLoadedAllReviews" class="text-center">
      <Button
        class="mr-4"
        :outline="true"
        :big="true"
        icon-right="caret-down"
        @click="loadMoreReviews"
      >
        {{ $trans('Reviews.ShowMoreReviews') }}
      </Button>
    </div>
  </Container>

  <Footer />
</template>
