<script setup>
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import { computed, onMounted, ref, watch } from 'vue'
import { getMapboxTokenAndStyleUrl } from '@js/App/Utils/Mapbox'
import { addScriptSrcToPage, addStyleSrcToPage, MAPBOX_CSS, MAPBOX_SRC } from '@js/App/Performance/LazyScripts'
import LoadingSpinner from '@js/Components/Forms/LoadingSpinner/LoadingSpinner.vue'
import { getNeighbourhoodsWithCoordinatesInCity } from '@js/App/Api/NeighbourhoodsOfCity'
import { useMainStore } from '@js/App/Stores/MainStore'
import { storeToRefs } from 'pinia'

defineProps({
  useLandingPageStyling: {
    type: Boolean,
    default: false
  }
})

const mainStore = useMainStore()
const searchProfileStore = useSearchProfileStore()

const mapRef = ref(null)
const map = ref(null)
const addedLayers = ref([])
const addedHoverLayers = ref([])
const numberOfItemsThatAreLoading = ref(0)
const neighbourhoodsWithCoordinates = ref([])
const mapIsLoadedAndReady = ref(false)
const fadeInIsLoading = ref(false)
const fadeOutIsLoading = ref(false)

// This keeps tracking of the current city the map is rendering. It is used for some performance improvements.
const currentCity = ref(null)

const { searchProfile, step } = storeToRefs(searchProfileStore)
const { isFullScreenInputActive } = storeToRefs(mainStore)

const city = computed(() => searchProfile.value.city)
const hoveredNeighbourhood = computed(() => searchProfile.value.hoveredNeighbourhood)
const selectedNeighbourhoods = computed(() => searchProfile.value.neighbourhoods)
const distance = computed(() => searchProfile.value.distance)
const locationSelector = computed(() => searchProfile.value.locationSelector)

const initMapbox = () => {
  const { token, style } = getMapboxTokenAndStyleUrl()

  window.mapboxgl.accessToken = token

  map.value = new window.mapboxgl.Map({
    container: mapRef.value,
    style,
    pitchWithRotate: false,
    zoom: city.value?.zoom || 7,
    center: city.value?.coordinates || [5.145587, 52.070079]
  })

  map.value.on('load', () => {
    mapIsLoadedAndReady.value = true
    numberOfItemsThatAreLoading.value -= 1

    redrawMap()
  })
}

watch(isFullScreenInputActive, () => {
  if (!isFullScreenInputActive.value) {
    redrawMap()
  }
})

const redrawMap = () => {
  if (isFullScreenInputActive.value) { return }
  if (!map.value) { return }

  const startTime = performance.now()

  updateMapCityIfNeeded()
  updateRadiusCircleIfNeeded()
  updateNeighbourhoodsIfNeeded()

  const endTime = performance.now()
  console.log(`[🎨 Performance] redrawMap took ${(endTime - startTime).toFixed(2)}ms`)
}

const removeAllNeighbourhoodLayers = () => {
  for (const layerId of addedLayers.value) {
    map.value.removeLayer(layerId)
  }
  addedLayers.value = []
}
const removeAllNeighbourhoodHoverLayers = () => {
  for (const layerId of addedHoverLayers.value) {
    map.value.removeLayer(layerId)
  }
  addedHoverLayers.value = []
}

const updateRadiusCircleIfNeeded = () => {
  if (map.value.getLayer('circle')) {
    map.value.removeLayer('circle')
  }

  if (locationSelector.value !== 'distance') {
    return
  }

  const createGeoJSONCircle = function (center, radiusInKm, points = 64) {
    if (!center) { return }
    const coords = {
      latitude: center[1],
      longitude: center[0]
    }

    const km = radiusInKm

    const ret = []
    const distanceX = km / (111.320 * Math.cos(coords.latitude * Math.PI / 180))
    const distanceY = km / 110.574

    let theta, x, y
    for (let i = 0; i < points; i++) {
      theta = (i / points) * (2 * Math.PI)
      x = distanceX * Math.cos(theta)
      y = distanceY * Math.sin(theta)

      ret.push([coords.longitude + x, coords.latitude + y])
    }
    ret.push(ret[0])

    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [{
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [ret]
          }
        }]
      }
    }
  }
  const id = `${city.value?.id}-${distance.value}`

  if (!map.value.getSource(id)) {
    if (city.value) {
      const geo = createGeoJSONCircle(city.value?.coordinates, distance.value)
      map.value.addSource(id, geo)
    }
  }

  if (map.value.getSource(id)) {
    map.value.addLayer({
      id: 'circle',
      type: 'fill',
      source: id,
      layout: {},
      paint: {
        'fill-color': 'rgb(0, 162, 206)',
        'fill-opacity': 0.3
      }
    })
  }
}

const neighbourhoodsToStringId = neighbourhoods => neighbourhoods.filter(item => (item.ids || []).length === 1)
  .map(item => item.ids[0])
  .sort()
  .join(',')

const updateNeighbourhoodsIfNeeded = () => {
  if (locationSelector.value === 'distance') {
    removeAllNeighbourhoodLayers()
    return
  }

  const currentNeighbourhoodsAsString = neighbourhoodsToStringId(selectedNeighbourhoods.value)
  numberOfItemsThatAreLoading.value++

  setTimeout(() => {
    if (neighbourhoodsToStringId(selectedNeighbourhoods.value) !== currentNeighbourhoodsAsString || locationSelector.value === 'distance') {
      numberOfItemsThatAreLoading.value--
      return
    }

    for (
      const neighbourhood of neighbourhoodsWithCoordinates.value.filter(
        item => item.ids.length === 1 &&
            selectedNeighbourhoods.value.includes(item.ids[0]) &&
            !addedLayers.value.includes(item.ids[0])
      )) {
      showNeighbourhood(neighbourhood)
    }

    const layersToRemove = addedLayers.value.filter(
      item => !selectedNeighbourhoods.value.includes(item)
    )

    for (const layer of layersToRemove) {
      if (map.value.getLayer(`${layer}`)) {
        map.value.removeLayer(`${layer}`)
      }
    }

    addedLayers.value = addedLayers.value.filter(
      item => !layersToRemove.includes(item)
    )

    numberOfItemsThatAreLoading.value--
  }, 500)
}

const showNeighbourhood = (neighbourhood) => {
  const id = neighbourhood.ids[0]

  if (!map.value.getSource(`source-${id}`)) {
    map.value.addSource(`source-${id}`, {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            neighbourhood.coordinates
          ]
        }
      }
    })
  }

  if (!map.value.getLayer(`${id}`)) {
    addedLayers.value.push(`${id}`)

    map.value.addLayer({
      id: `${id}`,
      type: 'fill',
      source: `source-${id}`,
      layout: {},
      paint: {
        'fill-color': 'rgb(0, 162, 206)',
        'fill-opacity': 0.3
      }
    })
  }
}

const showHoveredNeighbourhood = (neighbourhood) => {
  const id = neighbourhood.ids[0]

  if (!map.value.getSource(`source-${id}`)) {
    map.value.addSource(`source-${id}`, {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            neighbourhood.coordinates
          ]
        }
      }
    })
  }

  if (!map.value.getLayer(`${id}` + '-hover')) {
    addedHoverLayers.value.push(`${id}` + '-hover')

    map.value.addLayer({
      id: `${id}` + '-hover',
      type: 'fill',
      source: `source-${id}`,
      layout: {},
      paint: {
        'fill-color': 'rgb(0, 162, 206)',
        'fill-opacity': 0.5
      }
    })
  }
}
const createIdFromSelectedNeighbourhoods = arr => (arr?.value || arr)?.join(',')

const updateMapCityIfNeeded = () => {
  if (currentCity.value === city.value?.name) { return }
  if (!map.value) { return }
  if (!city.value?.coordinates) { return }

  currentCity.value = city.value.name

  map.value.setCenter(city.value?.coordinates)
  map.value.setZoom(city.value?.zoom || 10)
}

onMounted(async () => {
  numberOfItemsThatAreLoading.value += 1
  if (!window.mapboxgl) {
    addScriptSrcToPage(MAPBOX_SRC)
    addStyleSrcToPage(MAPBOX_CSS)
  }
  const waitForMapboxToBeLoadedInterval = setInterval(() => {
    if (window.mapboxgl) {
      clearInterval(waitForMapboxToBeLoadedInterval)
      initMapbox()
    }
  }, 100)

  if (!city.value?.hasNeighbourhoods) {
    neighbourhoodsWithCoordinates.value = []
    return
  }
  neighbourhoodsWithCoordinates.value = await getNeighbourhoodsWithCoordinatesInCity(city)

  if (mapIsLoadedAndReady.value) {
    redrawMap()
  }
})

watch(selectedNeighbourhoods, (newNeighbourhoods, oldNeighbourhoods) => {
  const newId = createIdFromSelectedNeighbourhoods(newNeighbourhoods)
  const oldId = createIdFromSelectedNeighbourhoods(oldNeighbourhoods)
  if (newId === oldId) {
    return
  }
  redrawMap()
})

watch(hoveredNeighbourhood, () => {
  removeAllNeighbourhoodHoverLayers()
  for (
    const neighbourhood of neighbourhoodsWithCoordinates.value.filter(
      item => item.ids.length === 1 &&
              hoveredNeighbourhood.value.includes(item.ids[0]) &&
              !addedHoverLayers.value.includes(item.ids[0])
    )) {
    showHoveredNeighbourhood(neighbourhood)
  }
}
)

watch(city, async () => {
  if (!city.value.hasNeighbourhoods) {
    neighbourhoodsWithCoordinates.value = []
    redrawMap()
    return
  }
  neighbourhoodsWithCoordinates.value = await getNeighbourhoodsWithCoordinatesInCity(city)
  redrawMap()
})

watch(distance, redrawMap)

watch(locationSelector, redrawMap)

watch(numberOfItemsThatAreLoading, () => {
  if (numberOfItemsThatAreLoading.value > 0) {
    fadeInIsLoading.value = true
    setTimeout(() => {
      fadeInIsLoading.value = false
    }, 1)
    return
  }

  if (numberOfItemsThatAreLoading.value <= 0) {
    fadeOutIsLoading.value = true
    setTimeout(() => {
      if (numberOfItemsThatAreLoading.value <= 0) {
        fadeOutIsLoading.value = false
      }
    }, 300)
  }
})

watch(step, () => {
  if (step.value !== 1) { return }
  setTimeout(() => {
    map.value.resize()
  }, 50)
})

</script>
<template>
  <div class="relative">
    <div
      ref="mapRef"
      class=""
      :class="{
        'mapbox-container': !useLandingPageStyling,
        'rounded-md h-full': useLandingPageStyling
      }"
    />

    <div
      v-show="numberOfItemsThatAreLoading > 0 || fadeInIsLoading || fadeOutIsLoading"
      class="absolute top-0 w-full flex duration-300 justify-center items-center bg-gray-200 bg-opacity-20 transition-all max-h-ful"
      :class="{
        'opacity-0': fadeInIsLoading || fadeOutIsLoading,
        'opacity-100': !fadeInIsLoading && !fadeOutIsLoading,
        'mapbox-container': !useLandingPageStyling,
        'h-full': useLandingPageStyling
      }"
    >
      <LoadingSpinner :show-text="false" :small="true" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .mapbox-container {
    height: 100%;

    @media screen and (min-width: 769px) {
      height: 349px;
      @media screen and ( min-height: 720px ) { height: 449px; }
    }

  }
</style>
