export const capitalizeFirstLetter = string => string ? string.charAt(0).toUpperCase() + string.slice(1) : ''

export const cutoffIfTooLong = (string, charLimit) => {
  if (string.length <= charLimit) { return string }
  const substring = string.substring(0, charLimit - 3)
  return substring + '...'
}

/**
 * Basic hashing of a string. This is not secure! Use only for things where a breakable hash is OK.
 * Don't generate passwords, user_ids or other fancy things with this. It is not secure.
 * @param string The string to hash.
 * @returns {number} Returns the hashed number
 */
// eslint-disable-next-line camelcase
export const hashString_NOT_SECURE = (string) => {
  let hash = 0
  let i; let chr
  if (string.length === 0) { return hash }
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i)
    hash = ((hash << 5) - hash) + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}
