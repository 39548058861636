<script setup>
import Footer from '@js/Components/Marketing/Footer/Footer.vue'
import MyRentbirdLayout from '@js/Components/MyRentbird/MyRentbirdLayout/MyRentbirdLayout.vue'
import { usePage } from '@inertiajs/vue3'
import BlogPostAuthor from '@js/Components/Blog/BlogPostAuthor.vue'
import ImageDirk from '@images/people/dirk.webp'
import BlogPostFooterAuthor from '@js/Components/Blog/BlogPostFooterAuthor.vue'
import BlogPostBreadCrumb from '@js/Components/Blog/BlogPostBreadCrumb.vue'
import CallToActionSection from '@js/Components/Marketing/CallToActionSection/CallToActionSection.vue'
import { parseContentIntoParts, TYPE_CTA, TYPE_LISTINGS } from '@js/App/Blog/BlogParsing'
import CallToActionSectionActivateAlertsLarge
  from '@js/Components/Marketing/CallToActionSection/CallToActionSectionActivateAlertsLarge.vue'
import AllHouses from '@js/Components/AllHouses/AllHouses.vue'

const page = usePage()
const post = page.props.post
const houses = page.props.houses
const cityFromServer = page.props.city

const minutedReadingTime = Math.ceil(post.content?.rendered.length / 1200)

const content = parseContentIntoParts(post.content?.rendered)
</script>
<template>
  <MyRentbirdLayout>
    <Head>
      <title>{{ post.yoast_head_json?.title }}</title>
      <meta name="description" :content="post.yoast_head_json?.description">
    </Head>

    <BlogPostBreadCrumb class="mb-[20px]" :title="post.title?.rendered" />

    <div class="bg-white px-[16px] py-[16px] rounded-md lg:px-[108px] lg:py-[80px]">
      <div class="lg:mx-auto blog-post lg:max-w-[874px]">
        <div
          class="flex items-center flex-wrap mb-[16px] lg:mb-[24px] lg:order-first"
        >
          <div
            v-for="tag of post.tags"
            :key="tag"
            class="bg-purple-500 rounded-full text-white text-sm lg:text-base lg:px-[15px] font-medium px-[10px] py-[4px] mr-[8px]"
          >
            {{ tag }}
          </div>
          <div
            v-if="post.tags?.length === 0"
            class="bg-purple-500 rounded-full text-white text-sm lg:text-base lg:px-[15px] font-medium px-[10px] py-[4px] mr-[8px]"
          >
            {{ $trans('BlogPostPage.Article') }}
          </div>
        </div>

        <h1 class="text-2xl text-black font-medium lg:text-4xl lg:mt-[10px] mb-[24px]">
          {{ post.title?.rendered }}
        </h1>

        <BlogPostAuthor
          class="mb-[24px] lg:mb-[40px]"
          firstname="Dirk"
          lastname="Hoekstra"
          :image="ImageDirk"
          :created-at="post.created_at_formatted"
          :reading-time="$trans('OnboardingTipsPage.MinutesReadingTime', { minutes: minutedReadingTime})"
        />

        <div class="text-base text-purple-900 mt-[24px] lg:text-lg">
          <div v-for="block in content" :key="block.content">
            <div v-if="block.type === 'string'" v-html="block.content" />
            <div v-if="block.content === TYPE_CTA" class="dont-style-images">
              <CallToActionSectionActivateAlertsLarge class="w-full mt-[24px] mb-[24px] lg:mt-[50px] lg:mb-[40px]" />
            </div>
            <div v-if="block.content === TYPE_LISTINGS" class=" mt-[24px] mb-[24px] lg:mt-[50px] lg:mb-[40px] lg:-mx-[40px] xl:-mx-[40px]">
              <AllHouses
                :initial-houses="houses"
                :city-from-server="cityFromServer"
                :show-title="block.props?.showTitle"
                :custom-title="block.props?.customTitle"
                :city="block.props?.city || ''"
                :distance="block.props?.distance || 10"
                :neighbourhoods="block.props?.neighbourhoods || []"
                :max-rent="block.props?.maxRent || null"
                :min-bedrooms="block.props?.minBedrooms || null"
                :min-surface="block.props?.minSurface || null"
                :furnished="block.props?.furnished || 'everything'"
                :extra-wishes="block.props?.extraWishes || []"
              />
            </div>
          </div>
        </div>

        <BlogPostFooterAuthor
          class="mt-[40px]"
          firstname="Dirk"
          lastname="Hoekstra"
          :image="ImageDirk"
          job-title=""
        />
      </div>
    </div>
  </MyRentbirdLayout>

  <CallToActionSection section-divider-color="rgb(234,234,235)" />
  <Footer />
</template>
<style lang="scss">
.BlogPostPage {
  background: rgb(234,234,235);

  img.blog-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  img.author-image {
    margin-top: 0;
    margin-bottom: 0;
  }

  h2 {
    margin-bottom: 4px;
  }

  li:first-child {
    margin-top: 8px;
  }
  li {
    margin-bottom: 8px;
  }
}

.blog-post p {
  margin-bottom: 16px;
  font-size:16px;
  line-height: 24px;

  @media screen and (min-width: 768px) {
    font-size: 18px;
    line-height: 28px;
  }
}

.blog-post ul, .blog-post ol {
  margin-bottom: 16px;
}

.blog-post h2 {
  margin-top: 40px;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 12px;

  @media screen and (min-width: 768px) {
    font-size: 30px;
    line-height: 36px;
  }
}
</style>
