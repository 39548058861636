<script setup>
import { useUser } from '@js/App/Api/User/User'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { usePaymentStore } from '@js/App/Stores/PaymentStore'
import PricingCardAlternativeAmount
  from '@js/Components/PricingCard/PricingCardAlternative/PricingCardAlternativeAmount.vue'
import Button from '@js/Components/Forms/Button/Button.vue'

const { userActivePayment } = useUser()
const { availablePrices } = storeToRefs(usePaymentStore())

const renewalPrice = computed(() => availablePrices.value?.find(item => item.plan === userActivePayment.value.renewal_plan))

</script>
<template>
  <div>
    <h4 class="text-xl lg:text-2xl font-medium mb-[8px] lg:mb-[16px]">
      {{ $trans('NextRenewalCard.Title') }}
    </h4>
    <div class="rounded bg-gray-50 p-[16px] lg:p-[24px]">
      <div class="lg:flex justify-between items-center">
        <div class="xl:mr-[-57px]">
          <div class="text-xl lg:text-3xl text-purple-900 font-medium mb-[8px]">
            {{ $trans(renewalPrice.numberOfMonths > 1 ? 'PricingCardCurrentSubscription.PerMonths' : 'PricingCardCurrentSubscription.PerMonth', {
              months: renewalPrice.numberOfMonths
            }) }}
          </div>
          <div class="mb-[8px]">
            <span class="text-base text-blue-base border border-blue-base rounded px-[8px] py-[6px]">
              Gepland
            </span>
          </div>
          <div class="text-gray-700 text-sm">
            {{ $trans('NextRenewalCard.SubscriptionStartsAt', { date: userActivePayment.subscription_ends_at_formatted}) }}
          </div>
        </div>
        <div class="mt-[12px] lg:mt-0 mb-[24px] lg:mb-0">
          <PricingCardAlternativeAmount
            :full-price="false"
            :inverse-colors="true"
            :discount="renewalPrice.numberOfMonths > 1 && (
              renewalPrice.numberOfMonths === 2 ? 32 : 44
            )"
            :price="renewalPrice.amount / renewalPrice.numberOfMonths"
          />
        </div>
        <div>
          <Button icon-right="edit" :outline="true" tag="link" :full-width-on-mobile="true" :href="$getLocalizedUrl('my-rentbird.change-subscription')">
            {{ $trans('Common.Edit') }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
