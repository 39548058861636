<script setup>
import { usePage } from '@inertiajs/vue3'
import TrustpilotWhiteImage from '@images/trustpilot-logo-white.svg'
import Image from '@js/Components/Image/Image.vue'
import TrustpilotLogo from '@images/trustpilot-logo.svg'

defineProps({
  black: {
    type: Boolean,
    default: true
  }
})

const page = usePage()

const trustPilotRating = page.props?.trustpilot?.rating
</script>
<template>
  <div>
    <span
      class="text-base font-medium pr-[3px]"
      :class="{
        'text-purple-600': black,
        'text-white': !black,
      }"
    >
      {{ trustPilotRating }}
    </span>
    <span
      class="text-base pr-3"
      :class="{
        'text-purple-600': black,
        'text-white': !black,
      }"
    >
      {{ $trans('TrustpilotRating.OutOf') }}
    </span>
    <Image class="inline w-[67px] relative top-[-2px]" :src="black ? TrustpilotLogo : TrustpilotWhiteImage" />
  </div>
</template>
