<script setup>
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import { useUser } from '@js/App/Api/User/User'
import { ref } from 'vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { copyTextToClipboard } from '@js/App/Utils/Clipboard'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import Icon from '@js/Components/Icon/Icon.vue'
import { router } from '@inertiajs/vue3'

const { user } = useUser()
const responseLetter = ref(user.value.responseLetterTemplate)
const showCopySuccessMessage = ref(false)
const hasActiveSubscription = ref(user.value.hasActiveSubscription)

const onEditButtonClick = () => {
  router.visit(getLocalizedUrl('my-rentbird.response-letter'))
}
const onCopyButtonClick = async () => {
  if (responseLetter.value) {
    await copyTextToClipboard(responseLetter.value)
    showCopySuccessMessage.value = true
  } else {
    router.visit(getLocalizedUrl('my-rentbird.response-letter'))
  }
}

</script>

<template>
  <MyRentbirdCard
    class="h-full"
    :inactive-on-free-account="!hasActiveSubscription"
  >
    <template #title>
      <div class="">
        {{ $trans('ResponseLetterCard.Title') }}
      </div>
    </template>

    <template #body-that-is-always-shown>
      <div v-if="!user.responseLetterTemplate" class="text-sm pb-2 text-danger lg:hidden">
        <div>
          <Icon name="cross" icon-width="inline w-[20px] h-[20px]" />
          {{ $trans("ResponseLetterCard.NoResponseLetter") }}
        </div>
      </div>
      <div v-else class="pb-2 text-sm text-success lg:hidden">
        <Icon name="check" icon-width="inline w-[20px] h-[20px]" />
        {{ $trans("ResponseLetterCard.ResponseLetterAdded") }}
      </div>
    </template>

    <template #title-desktop-right />

    <template #free-account-message>
      {{ $trans('MyRentbird.LetterCardFreeAccountMessage') }}
    </template>

    <template #default>
      <div v-if="!user.responseLetter" class="block pb-6">
        <p v-html="$trans('ResponseLetterCard.SubTitle')" />
      </div>

      <div v-if="user.responseLetter" class="w-full text-purple-700 rounded-t-md transition-colors">
        <div class="min-h-[250px] relative mb-2">
          <textarea v-model="user.responseLetter" disabled class=" absolute w-full h-full bg-white response-letter flex-grow border border-neutral-400 rounded-md p-4" />
          <div class="absolute rounded-md overlay h-full w-full pointer-events-none" />
        </div>
        <div v-if="user.responseLetter" class="text-end mb-4">
          <a
            class="text-sm text-blue-base hover:cursor-pointer hover:underline transition-all relative top-[1px]"
            @click="onEditButtonClick()"
          >{{ $trans('ResponseLetterCard.EditLetter') }}</a>
        </div>
        <Button
          :big="true"
          :full-width-on-mobile="true"
          :full-width-on-desktop="true"
          :icon-right="showCopySuccessMessage ? 'check' : 'copy'"
          @click="onCopyButtonClick"
        >
          <span class="whitespace-nowrap">
            {{ showCopySuccessMessage ? $trans('ResponseLetterCard.CopySuccess') : $trans('ResponseLetterCard.CopyLetter') }}
          </span>
        </Button>
      </div>
      <Button
        v-if="!user.responseLetter"
        :disabled="!hasActiveSubscription"
        :big="true"
        :full-width-on-mobile="true"
        :full-width-on-desktop="true"
        :icon-right="plus"
        @click="!hasActiveSubscription? '' : onEditButtonClick()"
      >
        <span class="whitespace-nowrap">
          {{ $trans('ResponseLetterCard.ComposeLetter') }}
        </span>
      </Button>
    </template>
  </MyRentbirdCard>
</template>

<style lang="scss" scoped>
.response-letter {
  resize: none !important;
  font-size: 15px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.response-letter::-webkit-scrollbar { width: 0 !important }

.overlay {
  padding: 0.5px;
  background-clip: content-box;
  background-image: linear-gradient(to bottom, rgba(255,  0,  0,  0), rgba(255,  255,  255,  1));
}
</style>
