<script setup>
import TextInputAlternative from '@js/Components/Forms/TextInput/TextInputAlternative.vue'
import { ref } from 'vue'
import SelectAlternative from '@js/Components/Forms/Select/SelectAlternative.vue'
import TextAreaAlternative from '@js/Components/Forms/TextArea/TextAreaAlternative.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { useMainStore } from '@js/App/Stores/MainStore'
import { doPostRequest } from '@js/App/Api/Infra/ApiClient'

const props = defineProps({
  images: {
    type: Array,
    default: () => ([])
  }
})

const city = ref('')
const searchWeeks = ref('')
const experienceText = ref('')

const mainStore = useMainStore()
const errors = ref({})
const submit = async () => {
  mainStore.startLoading()
  errors.value = {}

  try {
    const response = await doPostRequest('/api/offboarding/selfie-reward-request', {
      place: city.value,
      search_weeks: searchWeeks.value,
      experience_text: experienceText.value,
      images: props.images
    })

    if (response.hasErrors) {
      errors.value = response.errors
      return
    }

    emit('next-step')
  } finally {
    mainStore.stopLoading()
  }
}

const emit = defineEmits(['next-step'])
</script>
<template>
  <div>
    <TextInputAlternative
      v-model="city"
      class="mb-[16px]"
      :error="errors.place"
    >
      <template #label>
        {{ $trans('ReviewUsYourDetails.City') }}
      </template>
    </TextInputAlternative>

    <SelectAlternative
      v-model="searchWeeks"
      :error="errors.search_weeks"
      :options="[
        { value: 1, label: $trans('ReviewUsYourDetails.OneWeek')},
        { value: 2, label: $trans('ReviewUsYourDetails.Weeks', { weeks: 2})},
        { value: 3, label: $trans('ReviewUsYourDetails.Weeks', { weeks: 3})},
        { value: 4, label: $trans('ReviewUsYourDetails.OneMonth')},
        { value: 8, label: $trans('ReviewUsYourDetails.Months', { months: 2})},
        { value: 12, label: $trans('ReviewUsYourDetails.Months', { months: 3})},
        { value: 13, label: $trans('ReviewUsYourDetails.MoreThanMonths', { months: 3})},
      ]"
      class="mb-[16px]"
    >
      <template #label>
        {{ $trans('ReviewUsYourDetails.HowLongDidYouSearch') }}
      </template>
    </SelectAlternative>

    <TextAreaAlternative
      v-model="experienceText"
      class="mb-[16px]"
      :error="errors.experience_text"
    >
      <template #label>
        {{ $trans('ReviewUrYourDetails.YourExperience') }}
      </template>
    </TextAreaAlternative>

    <Button
      :full-width-on-mobile="true"
      icon-right="arrow-right"
      :big="true"
      @click="submit"
    >
      {{ $trans('Common.Submit') }}
    </Button>
  </div>
</template>
