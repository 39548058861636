<script setup>
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import { ref } from 'vue'
import TextInput from '@js/Components/Forms/TextInput/TextInput.vue'
import { saveUserPersonalData } from '@js/App/Api/User/UserPersonalData'
import DateOfBirthInput from '@js/Components/Forms/DateOfBirthInput/DateOfBirthInput.vue'
import Select from '@js/Components/Forms/Select/Select.vue'
import LanguageSwitcherDropdown from '@js/Components/Forms/LanguageSwitcherDropdown/LanguageSwitcherDropdown.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { doPostRequest } from '@js/App/Api/Infra/ApiClient'
import PhoneNumberInput from '@js/Components/Forms/PhoneNumberInput/PhoneNumberInput.vue'
import { useMainStore } from '@js/App/Stores/MainStore'
import { useUser } from '@js/App/Api/User/User'
import { reloadPropsFromServer } from '@js/App/Api/Infra/ServerData'
import { COUNTRY_NL, getCountry } from '@js/App/Utils/CountryAndLocale'

const { user, userPersonalData } = useUser()

const firstname = ref(user.value.firstNameFormatted)
const lastname = ref(user.value.lastNameFormatted)
const email = ref(user.value.email)
const phone = ref(user.value.phone)
const internalDateOfBirth = ref(userPersonalData.value.date_of_birth)
const internalGender = ref(userPersonalData.value.gender)

const mainStore = useMainStore()

const showSavedMessage = ref(false)

const onSaveDetails = async () => {
  errors.value = {}
  mainStore.startLoading()

  try {
    // Update the users name
    const response = await doPostRequest('/api/user/name', {
      firstname: firstname.value,
      lastname: lastname.value
    })
    if (response.hasErrors) {
      errors.value = {
        ...errors.value,
        ...response.errors
      }
    }

    // Update the users email address, if changed.
    if (email.value !== user.email) {
      const response = await doPostRequest('/api/user/change-email', {
        email: email.value
      })
      if (response.hasErrors) {
        errors.value = {
          ...errors.value,
          ...response.errors
        }
      }
    }

    // Update the users phone, if changed.
    if (phone.value !== user.phone) {
      const response = await doPostRequest('/api/user/phone/validate-and-update', {
        phone: phone.value,
        validation_code: 'NO_VERIFICATION_NEEDED'
      })
      if (response.hasErrors) {
        errors.value = {
          ...errors.value,
          ...response.errors
        }
      }
    }

    // Save the users personal data (DOB, and gender)
    const { errors: apiErrors } = await saveUserPersonalData({
      gender: internalGender.value,
      date_of_birth: internalDateOfBirth.value
    }, ['date_of_birth', 'gender'])
    errors.value = {
      ...errors.value,
      ...apiErrors
    }

    if (Object.keys(errors.value) <= 0) {
      showSavedMessage.value = true
      reloadPropsFromServer()
    }
  } finally {
    mainStore.stopLoading()
  }
}

const errors = ref({})

</script>
<template>
  <MyRentbirdCard :can-expand-on-mobile="false">
    <template #title>
      {{ $trans('EditAccountDetailsCard.Title') }}
    </template>
    <div>
      <div class="lg:flex">
        <div class="lg:w-1/2 lg:pr-[8px]">
          <TextInput v-model="firstname" class="mb-[12px]" :error="errors.firstname">
            <template #label>
              {{ $trans('Common.Firstname') }}
            </template>
          </TextInput>
        </div>
        <div class="lg:w-1/2 lg:pl-[8px]">
          <TextInput v-model="lastname" class="mb-[12px]" :error="errors.lastname">
            <template #label>
              {{ $trans('Common.Lastname') }}
            </template>
          </TextInput>
        </div>
      </div>

      <div class="lg:flex">
        <div class="lg:w-1/2 lg:pr-[8px]">
          <TextInput v-model="email" class="mb-[12px]" :error="errors.email">
            <template #label>
              {{ $trans('Common.Email') }}
            </template>
          </TextInput>
        </div>
        <div class="lg:w-1/2 lg:pl-[8px]">
          <PhoneNumberInput
            v-model="phone"
            :require-validation="false"
            class="mb-[12px]"
            :error="errors.phone"
          >
            <template #label>
              {{ $trans('Common.Phone') }}
            </template>
          </PhoneNumberInput>
        </div>
      </div>

      <div class="lg:flex">
        <div class="lg:w-1/3 lg:pr-[8px]">
          <DateOfBirthInput v-model="internalDateOfBirth" class="mb-[12px]" :error="errors.date_of_birth">
            <template #label>
              {{ $trans('Common.DateOfBirth') }}
            </template>
          </DateOfBirthInput>
        </div>
        <div class="lg:w-1/3 lg:px-[8px]">
          <Select
            v-model="internalGender"
            :error="errors.gender"
            class="mb-[12px]"
            :options="[{
              label: $trans('Common.Man'),
              value: 'man',
            }, {
              label: $trans('Common.Woman'),
              value: 'woman',
            }, {
              label: $trans('Common.Other'),
              value: 'other',
            }]"
          >
            <template #label>
              {{ $trans('Common.Gender') }}
            </template>
          </Select>
        </div>

        <div class="lg:w-1/3 lg:pl-[8px]">
          <LanguageSwitcherDropdown v-if="getCountry() === COUNTRY_NL" />
        </div>
      </div>

      <Button class="mt-[16px]" :icon-right="showSavedMessage ? 'check' : 'save'" :full-width-on-mobile="true" @click="onSaveDetails">
        <span v-if="!showSavedMessage">{{ $trans('Common.Save') }}</span>
        <span v-else>{{ $trans('Common.Saved') }}</span>
      </Button>
    </div>
  </MyRentbirdCard>
</template>
