<script setup>
import 'vue3-carousel/dist/carousel.css'
import { ref } from 'vue'
import { Carousel, Slide } from 'vue3-carousel'
import Button from '@js/Components/Forms/Button/Button.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import { useHasBeenInViewport } from '@js/App/Performance/HasBeenInViewport'
import Container from '@js/Components/Container/Container.vue'
import UserReviewCardAlternative
  from '@js/Components/Marketing/UserReviewsSectionAlternative/UserReviewCardAlternative.vue'

defineProps({
  reviews: {
    type: Array,
    default: () => ([])
  }
})

const myCarousel = ref(null)
const outerRef = ref(null)
const hasInteracted = ref(false)

const { hasBeenInViewport } = useHasBeenInViewport(outerRef, 500)

const breakpoints = {
  0: {
    itemsToShow: 1.1
  },
  400: {
    itemsToShow: 1.4
  },
  540: {
    itemsToShow: 1.8
  },
  680: {
    itemsToShow: 2.1
  },
  769: {
    itemsToShow: 1.49
  },
  800: {
    itemsToShow: 1.55
  },
  840: {
    itemsToShow: 1.6
  },
  880: {
    itemsToShow: 1.7
  },
  1000: {
    itemsToShow: 1.95
  },
  1200: {
    itemsToShow: 2.3
  },
  1240: {
    itemsToShow: 2.41
  },
  1280: {
    itemsToShow: 2.50
  },
  1320: {
    itemsToShow: 2.58
  },
  1360: {
    itemsToShow: 2.68
  },
  1400: {
    itemsToShow: 2.75
  },
  1440: {
    itemsToShow: 2.82
  },
  1480: {
    itemsToShow: 2.88
  },
  1520: {
    itemsToShow: 2.95
  },
  1560: {
    itemsToShow: 3.02
  },
  1600: {
    itemsToShow: 3.06
  },
  1650: {
    itemsToShow: 3.16
  },
  1700: {
    itemsToShow: 3.26
  },
  1750: {
    itemsToShow: 3.36
  },
  1800: {
    itemsToShow: 3.46
  },
  1850: {
    itemsToShow: 3.56
  },
  1900: {
    itemsToShow: 3.66
  },
  1950: {
    itemsToShow: 3.76
  },
  2000: {
    itemsToShow: 3.86
  },
  2050: {
    itemsToShow: 3.96
  },
  2100: {
    itemsToShow: 4.06
  },
  2150: {
    itemsToShow: 4.16
  },
  2200: {
    itemsToShow: 4.26
  },
  2250: {
    itemsToShow: 4.36
  },
  2300: {
    itemsToShow: 4.46
  },
  2350: {
    itemsToShow: 4.56
  },
  2400: {
    itemsToShow: 4.66
  },
  2450: {
    itemsToShow: 4.76
  },
  2500: {
    itemsToShow: 4.86
  }

}

const previousSlide = () => {
  hasInteracted.value = true
  myCarousel.value.prev()
}
const nextSlide = () => {
  hasInteracted.value = true
  myCarousel.value.next()
}

</script>
<template>
  <div ref="outerRef">
    <div v-if="hasBeenInViewport">
      <Carousel
        ref="myCarousel"
        :autoplay="hasInteracted ? 0 : 3000000"
        :wrap-around="true"
        :breakpoints="breakpoints"
        :pause-autoplay-on-hover="true"
        snap-align="center"
      >
        <Slide v-for="review of reviews" :key="review.id">
          <UserReviewCardAlternative
            :name="review.name"
            :title="review.formatted_title_text"
            :rating="review.stars"
            :trustpilot-url="`https://www.trustpilot.com/reviews/${review.trustpilot_id}`"
            :image="review.image"
            :video="review.video"
            :date-text="review.date_formatted"
          >
            <span v-html="review.formatted_review_text" />
          </UserReviewCardAlternative>
        </Slide>
      </Carousel>

      <Container class="mt-[10px] lg:mt-[40px]">
        <div class="flex justify-between">
          <slot name="button" />
          <Button
            v-if="!$slots.button"
            class="mr-4"
            :outline="true"
            :big="true"
            icon-right="arrow-right"
            tag="link"
            :href="$getLocalizedUrl('reviews')"
          >
            {{ $trans('UserReviewsCarousel.ButtonLabel') }}
          </Button>
          <div class="flex">
            <Button
              class="mr-2"
              :outline="true"
              :big="true"
              tag="button"
              @click="previousSlide"
            >
              <Icon name="arrow-outline-left" />
            </Button>
            <Button
              :outline="true"
              :big="true"
              tag="button"
              @click="nextSlide"
            >
              <Icon name="arrow-outline-right" />
            </Button>
          </div>
        </div>
      </Container>
    </div>
  </div>
</template>
<style lang="scss" scoped>
// These styles are within the vue3 Carousel slider component. That's why we can't use Tailwind here.
.carousel__slide {
  padding-left: 8px;
  padding-right: 8px;
  align-items: flex-start;

  @media screen and (min-width: 769px) {
    padding-left: 12px;
    padding-right: 12px;
  }
}
</style>
