<script setup>
import { usePage, Link, router } from '@inertiajs/vue3'
import { computed } from 'vue'
import HeaderWithLogoAndTrustpilot from '@js/Components/HeaderWithLogoAndTrustpilot/HeaderWithLogoAndTrustpilot.vue'
import Container from '@js/Components/Container/Container.vue'
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import VHGDownloadApp from '@js/Components/MyRentbird/Verhuisgids/VHGDownloadApp.vue'
import MyRentbirdLayout from '@js/Components/MyRentbird/MyRentbirdLayout/MyRentbirdLayout.vue'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import Icon from '@js/Components/Icon/Icon.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import VHGMarkAsComplete from '@js/Components/MyRentbird/Verhuisgids/Helpers/VHGMarkAsComplete.vue'
import { useUser } from '@js/App/Api/User/User'
import { useMainStore } from '@js/App/Stores/MainStore'
import { doPostRequest } from '@js/App/Api/Infra/ApiClient'
import { reloadPropsFromServer } from '@js/App/Api/Infra/ServerData'
import ProgressBar from '@js/Components/Forms/ProgressBar/ProgressBar.vue'
import VHGPushSettings from '@js/Components/MyRentbird/Verhuisgids/VHGPushSettings.vue'
import VHGExtraSearchProfile from '@js/Components/MyRentbird/Verhuisgids/VHGExtraSearchProfile.vue'
import VHGSearchBuddy from '@js/Components/MyRentbird/Verhuisgids/VHGSearchBuddy.vue'
import VHGResponseLetter from '@js/Components/MyRentbird/Verhuisgids/VHGResponseLetter.vue'
import VHGDocuments from '@js/Components/MyRentbird/Verhuisgids/VHGDocuments.vue'
import VHGFinances from '@js/Components/MyRentbird/Verhuisgids/VHGFinances.vue'
import VHGPopularVerhuurders from '@js/Components/MyRentbird/Verhuisgids/VHGPopularVerhuurders.vue'
import VHGFacebookGroups from '@js/Components/MyRentbird/Verhuisgids/VHGFacebookGroups.vue'
import VHGNieuwbouw from '@js/Components/MyRentbird/Verhuisgids/VHGNieuwbouw.vue'
import VHGNetwork from '@js/Components/MyRentbird/Verhuisgids/VHGNetwork.vue'
import VHGDontStink from '@js/Components/MyRentbird/Verhuisgids/VHGDontStink.vue'
import VHGPitch from '@js/Components/MyRentbird/Verhuisgids/VHGPitch.vue'

const page = usePage()
const step = computed(() => parseInt(page.props.step))

const { user } = useUser()

const isCurrentStepCompleted = computed(() => (user.value.verhuisgids_completed_steps || []).includes(step.value))
const mainStore = useMainStore()

const markCurrentStepAsCompleted = async () => {
  mainStore.startLoading()
  await doPostRequest('/api/user/verhuisgids', {
    step: step.value
  })
  mainStore.stopLoading()
  reloadPropsFromServer()
}

const onClickNext = async () => {
  mainStore.startLoading()
  await markCurrentStepAsCompleted()

  router.visit(
    step.value === 13
      ? getLocalizedUrl('my-rentbird.home')
      : getLocalizedUrl('my-rentbird.verhuisgids', { step: step.value + 1 })
  )

  mainStore.stopLoading()
}

</script>
<template>
  <MyRentbirdLayout>
    <Container class="pb-[40px]">
      <div class="w-full lg:flex justify-between items-center">
        <h1 class="text-[#151227] font-bold text-2xl lg:text-4xl mb-[12px] lg:mb-0">
          {{ $trans("Verhuisgids.Title") }}
        </h1>

        <div class="w-full lg:w-[200px]">
          <ProgressBar color="green" :progress="Math.round(step / 13 * 100)" :show-percentage="true" />
        </div>
      </div>

      <MyRentbirdCard class="mt-[16px] lg:mt-[20px]" :can-expand-on-mobile="false" :default-is-expanded="true">
        <VHGDownloadApp v-if="step === 1" />
        <VHGPushSettings v-if="step === 2" />
        <VHGExtraSearchProfile v-if="step === 3" />
        <VHGSearchBuddy v-if="step === 4" />
        <VHGResponseLetter v-if="step === 5" />
        <VHGDocuments v-if="step === 6" />
        <VHGFinances v-if="step === 7" />
        <VHGPopularVerhuurders v-if="step === 8" />
        <VHGFacebookGroups v-if="step === 9" />
        <VHGNieuwbouw v-if="step === 10" />
        <VHGNetwork v-if="step === 11" />
        <VHGDontStink v-if="step === 12" />
        <VHGPitch v-if="step === 13" />

        <div class="lg:flex w-full lg:justify-between items-center mt-[24px]">
          <Button
            v-if="step > 1"
            tag="link"
            class=" lg:mb-0 mr-2 hidden lg:block"
            :big="true"
            :outline="true"
            :href="getLocalizedUrl('my-rentbird.verhuisgids', { step: step - 1 })"
          >
            <Icon name="arrow-left" />
          </Button>
          <div v-else>
&nbsp;
          </div>

          <div class="block lg:flex w-full lg:w-auto items-center justify-center mt-[24px] lg:mt-0">
            <VHGMarkAsComplete
              :is-checked="isCurrentStepCompleted"
              class="lg:mr-[16px] mb-[16px] lg:mb-0"
              @click="markCurrentStepAsCompleted"
            />
            <div class="flex">
              <Button
                v-if="step > 1"
                tag="link"
                class=" lg:mb-0 mr-2 block lg:hidden"
                :big="true"
                :outline="true"
                :href="getLocalizedUrl('my-rentbird.verhuisgids', { step: step - 1 })"
              >
                <Icon name="arrow-left" />
              </Button>
              <Button
                tag="button"
                icon-right="arrow-right"
                :big="true"
                :full-width-on-mobile="true"
                class="flex-grow lg:flex-grow-0"
                @click="onClickNext"
              >
                {{ $trans('Common.Next') }}
              </Button>
            </div>
          </div>
        </div>
      </MyRentbirdCard>

      <Link class="text-base text-[#151227] flex mt-[24px] hover:cursor-pointer hover:text-pink-500 transition-all" :href="getLocalizedUrl('my-rentbird.home')">
        <Icon name="arrow-left" icon-width="w-[24px] mr-1" />
        {{ $trans('VerhuisgidsPage.BacktoMyRentbird') }}
      </Link>
    </Container>
  </MyRentbirdLayout>
</template>
<style>
.MyRentbird-VerhuisgidsPage {
  background: rgb(234,234,235);
}
</style>
