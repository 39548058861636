<script setup>

import BasicPageHeader from '@js/Components/Marketing/BasicPageHeader/BasicPageHeader.vue'
import TextInput from '@js/Components/Forms/TextInput/TextInput.vue'
import Container from '@js/Components/Container/Container.vue'
import { reactive } from 'vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { Link, router, usePage } from '@inertiajs/vue3'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import Image from '@js/Components/Image/Image.vue'
import CurlImage from '@images/icons/curl.svg'
import BirdRealisticImage from '@images/icons/bird-realistic.png'
import KeyImage from '@images/icons/key.png'
import Icon from '@js/Components/Icon/Icon.vue'

defineProps({ errors: Object })

const page = usePage()

const form = reactive({
  csrf: page.props.csrf,
  email: '',
  password: ''
})

const submit = async () => {
  await router.post('/password/email', form, {
    onSuccess: () => { window.location.href = getLocalizedUrl('password.link-sent') }
  })
}
</script>
<template>
  <BasicPageHeader :show-trustpilot-instead-of-login="true" :show-section-divider="false" />
  <div class="overflow-clip relative">
    <Container class="relative pt-8 lg:pt-12 pb-[200px] lg:pb-[300px]">
      <form class="relative block w-[600px] max-w-full mx-auto" method="post" action="password/email" @submit.prevent="submit">
        <Image :src="KeyImage" class="absolute left-[-166px] lg:left-[-178px] key-login top-[92px] w-[330px]" />
        <Image :src="BirdRealisticImage" class="absolute right-[-101px] lg:right-[-141px] login-bird top-[242px] w-[280px]" />
        <div class="relative rounded-md bg-purple-900 h-[500px] lg:h-[524px] px-6 py-8  lg:px-10 lg:py-10">
          <Image :src="CurlImage" class="absolute right-[-40px] lg:right-[-10px] top-[-30px] lg:top-[-40px]" />
          <h2 class="flex text-white font-medium text-4xl mb-2 lg:text-4xl">
            {{ $trans('PasswordRecovery.Title') }}
          </h2>
          <h4 class="text-white text-base mb-6 lg:text-lg">
            {{ $trans('PasswordRecovery.Subtitle') }}
          </h4>
          <TextInput
            v-model="form.email"
            :error="errors.email"
            class="mb-8"
            :placeholder="$trans('LoginPage.EmailPlaceholder')"
          >
            <template #label>
              <span class="text-white">{{ $trans('LoginPage.EmailLabel') }}</span>
            </template>
          </TextInput>

          <Button
            type="submit"
            :big="true"
            :full-width-on-mobile="true"
            :full-width-on-desktop="true"
            icon-right="arrow-right"
            class="mb-6"
          >
            {{ $trans('PasswordRecovery.ButtonLabel') }}
          </Button>

          <div class="text-base text-white lg:text-center">
            <Link :href="$getLocalizedUrl('login')">
              <span
                class="text-white hover:underline transition-colors hover:cursor-pointer"
              ><Icon name="arrow-left" class="inline" icon-width="inline relative w-[18px] lg:w-[24px]" />
                {{ $trans('PasswordRecovery.BackToLogin') }}
              </span>
            </Link>
          </div>
        </div>
      </form>
    </Container>
  </div>
</template>
<style lang="scss">
.Auth-PasswordRecoverPage {
  background: #28214B;
}
.key-login {
  transform: rotate(42deg);
}
.login-bird {
  transform: scaleX(-1) rotate(-15deg);
}
.Auth-LoginPage {
  background: #28214B;
}
</style>
