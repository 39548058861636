<script setup>
import Select from '@js/Components/Forms/Select/Select.vue'
import { computed } from 'vue'
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import { storeToRefs } from 'pinia'
import { COUNTRY_NL, getCountry } from '@js/App/Utils/CountryAndLocale'
import { convertKmToMi } from '@js/App/Utils/MetricSystem'

defineEmits(['click:neighbourhoods'])

defineProps({
  error: {
    type: String,
    default: ''
  },
  showSwitchToNeighbourhoodsIfRelevant: {
    type: Boolean,
    default: true
  }
})

const { searchProfile } = storeToRefs(useSearchProfileStore())
const selectedCityHasNeighbourhoods = computed(() => (searchProfile.value.city?.hasNeighbourhoods))

const options = computed(() => {
  if (getCountry() === COUNTRY_NL) {
    return [2, 3, 5, 8, 10, 15, 20, 30, 50].map(item => ({ label: `< ${item}km`, value: item }))
  }
  return [2, 3, 5, 8, 10, 15, 20, 30, 50].map(item => ({ label: `< ${convertKmToMi(item)}mi`, value: item }))
})
</script>
<template>
  <div>
    <div class="flex justify-between">
      <label class="block font-medium text-base mb-1">
        {{ $trans('DistanceSelect.Label') }}
      </label>
      <div
        v-show="selectedCityHasNeighbourhoods && showSwitchToNeighbourhoodsIfRelevant"
        dusk="distance-select-switch"
        class="text-blue-base hover:cursor-pointer hover:underline transition-all"
        @click="$emit('click:neighbourhoods')"
      >
        {{ $trans('DistanceSelect.SwitchToNeighbourhoods') }}
      </div>
    </div>
    <Select
      :model-value="searchProfile.distance"
      dusk="distance-select"
      :error="error"
      :options="options"
      @update:model-value="newValue => {
        console.log('DistanceSelect update:modelValue = ' + newValue)
        searchProfile.distance = newValue
      }"
    />
  </div>
</template>
