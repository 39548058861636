import { doPostRequest } from '@js/App/Api/Infra/ApiClient'
import { useMainStore } from '@js/App/Stores/MainStore'

export const saveUserPersonalData = async (personalData, fieldsToValidate = null) => {
  const mainStore = useMainStore()
  mainStore.startLoading()

  const response = await doPostRequest('/api/user/personal-data', {
    ...personalData,
    fields_to_update: fieldsToValidate || Object.keys(personalData)
  })

  mainStore.stopLoading()

  return {
    hasErrors: response.hasErrors,
    errors: response.errors || {}
  }
}
