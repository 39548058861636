<script setup>
import { computed } from 'vue'
import CheckboxDropdown from '@js/Components/Forms/CheckboxDropdown/CheckboxDropdown.vue'
import Icon from '@js/Components/Icon/Icon.vue'

defineEmits(['click:distance', 'update:modelValue', 'handleHover', 'handleUnhover'])

const props = defineProps({
  modelValue: Array,
  allNeighbourhoodsOfCity: Array,

  error: {
    type: String,
    default: ''
  },
  showTopBar: {
    type: Boolean,
    default: true
  },
  placeholder: {
    type: String,
    default: ''
  }
})

const isAllNeighbourhoodsSelected = computed(() => {
  for (const item of props.allNeighbourhoodsOfCity) {
    if (!isNeighbourhoodSelected(item)) {
      return false
    }
  }
  return true
})

const isNeighbourhoodSelected = (item) => {
  if ((item?.ids || []).length <= 0) { return false }

  for (const id of item.ids) {
    if (!props.modelValue.includes(id)) {
      return false
    }
  }

  return true
}
</script>
<template>
  <div>
    <div v-if="showTopBar" class="flex mt-4 justify-between">
      <label class="block font-medium text-base text-purple-700 mb-1">
        {{ $trans('NeighbourhoodSelect.Label') }}
      </label>
      <div dusk="neighbourhood-select-switch" class="text-blue-base hover:cursor-pointer hover:underline transition-all" @click="$emit('click:distance')">
        {{ $trans('NeighbourhoodSelect.SwitchToDistance') }}
      </div>
    </div>
    <CheckboxDropdown
      dusk="neighbourhood-select"
      :model-value="modelValue"
      :is-all-options-selected="isAllNeighbourhoodsSelected"
      :error="error"
      :options="allNeighbourhoodsOfCity?.map(item => ({
        label: item.name,
        value: item.ids
      }))"
      @update:model-value="newValue => {
        $emit('update:modelValue', [...new Set(newValue.flat())])
      }"
    >
      <template #itemsSelectedMessage>
        <span v-if="modelValue.length > 0 && !isAllNeighbourhoodsSelected">{{ $trans('NeighbourhoodSelect.ItemsSelectedMessage', { number: modelValue.length}) }}</span>
        <span v-else-if="modelValue.length === 0">{{ placeholder || $trans('NeighbourhoodSelect.NoItemsSelectedMessage') }}</span>
        <span v-else>{{ $trans('NeighbourhoodSelect.AllItemsSelectedMessage') }}</span>
      </template>
      <template #options>
        <div
          v-for="item of allNeighbourhoodsOfCity"
          :key="item.name"
          class="flex justify-between py-3 px-4 my-[2px] text-base text-purple-700 hover:cursor-pointer hover:text-white hover:bg-blue-base transition-all"
          :class="{
            'pl-4': !item.group,
            'pl-4 ml-4 border-l border-gray-201 border-dashed': item.group
          }"
          :dusk="`neighbourhood-select-option-${item.name}`"
          @click="() => {
            if (isNeighbourhoodSelected(item)) {
              $emit('update:modelValue', [...modelValue.filter(_item => !item.ids.includes(_item))])
            } else {
              $emit('update:modelValue', [...new Set([...modelValue, ...item.ids].flat())])
            }
          }"
          @mouseenter="$emit('handleHover')"
          @mouseleave="$emit('handleUnhover')"
        >
          <span>{{ item.name }}</span>
          <Icon v-if="!isNeighbourhoodSelected(item)" name="box" />
          <Icon v-else name="box-checked" />
        </div>
      </template>
    </CheckboxDropdown>
  </div>
</template>
