<script setup>
import Select from '@js/Components/Forms/Select/Select.vue'
import { storeToRefs } from 'pinia'
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import { getCurrency } from '@js/App/Utils/CountryAndLocale'

defineProps({
  error: {
    type: String,
    default: ''
  }
})

const { searchProfile } = storeToRefs(useSearchProfileStore())
</script>
<template>
  <Select
    v-model="searchProfile.minRent"
    dusk="min-rent"
    :error="error"
    class="mt-4"
    :options="[0,500,750,800,900,1000,1100,1200,1300,1400,1500,1600,1700,1800,1900,2000].map(item => ({
      value: item,
      label: `${getCurrency()}${$formatNumber(item, 0)}`
    }))"
  >
    <template #label>
      {{ $trans('SearchProfileWizard.MinRent') }}
    </template>
  </Select>
</template>
