import { isSSR } from '@js/App/Utils/Environment'
import { route } from 'ziggy-js'
import { usePage } from '@inertiajs/vue3'

export const ziggyRoute = (name, params = {}) => {
  if (!isSSR()) {
    return route(name, params)
  }

  const page = usePage()

  const props = page.props

  const Ziggy = {
    // Pull the Ziggy config off of the props.
    ...props.ziggy,
    // Build the location, since there is
    // no window.location in Node.
    location: new URL(props.ziggy.url)
  }

  return route(name, params, null, Ziggy)
}
