<script setup>
import OffboardingDarkCard from '@js/Components/MyRentbird/Offboarding/OffboardingDarkCard/OffboardingDarkCard.vue'
import { onMounted, ref } from 'vue'
import { doGetRequest } from '@js/App/Api/Infra/ApiClient'

const isLoading = ref(true)

const days = ref('')
const searchprofiles = ref('')
const matches = ref('')
const savedTime = ref('')

onMounted(async () => {
  const response = await doGetRequest('/api/offboarding/your-search-stats')
  const json = response.json

  days.value = json.days
  searchprofiles.value = json.search_profiles
  matches.value = json.matches
  savedTime.value = json.saved_time

  isLoading.value = false
})
</script>
<template>
  <div>
    <p class="text-white text-base mb-[8px]  lg:text-lg">
      {{ $trans('ConfirmCancel.AboutYourSearchTitle') }}
    </p>
    <OffboardingDarkCard class="mb-[40px]">
      <div class="flex mb-[4px]">
        <div class="w-1/2 text-base font-medium text-white lg:text-lg">
          {{ $trans('AboutYourSearchCard.YourSearch') }}
        </div>
        <div class="w-1/2 text-base lg:text-lg">
          🚀 {{ isLoading ? '..' : days }} {{ $trans(days > 1 ? 'Common.Days' : 'Common.Day').toLowerCase() }}
        </div>
      </div>
      <div class="flex mb-[4px]">
        <div class="w-1/2 text-base font-medium text-white lg:text-lg">
          {{ $trans('AboutYourSearchCard.SearchProfiles') }}
        </div>
        <div class="w-1/2 text-base lg:text-lg">
          🔍 {{ isLoading ? '..' : searchprofiles }} {{ $trans(searchprofiles > 1 ? 'Common.SearchProfiles' : 'Common.SearchProfile').toLowerCase() }}
        </div>
      </div>
      <div class="flex mb-[4px]">
        <div class="w-1/2 text-base font-medium text-white lg:text-lg">
          {{ $trans('AboutYourSearchCard.Matches') }}
        </div>
        <div class="w-1/2 text-base lg:text-lg">
          🏠 {{ isLoading ? '..' : matches }} {{ $trans(matches === 1 ? 'Common.House' : 'Common.Houses').toLowerCase() }}
        </div>
      </div>
      <div class="flex mb-[4px]">
        <div class="w-1/2 text-base font-medium text-white lg:text-lg">
          {{ $trans('AboutYourSearchCard.SavedTime') }}
        </div>
        <div class="w-1/2 text-base lg:text-lg">
          😍 {{ isLoading ? '..' : savedTime }} {{ $trans(savedTime === 1 ? 'Common.Hour' : 'Common.Hours').toLowerCase() }}
        </div>
      </div>
    </OffboardingDarkCard>
  </div>
</template>
