import { usePaymentStore } from '@js/App/Stores/PaymentStore'
import { storeToRefs } from 'pinia'

export const PLAN_1_WEEK_TRIAL = 'trial-price'
export const PLAN_1_MONTHLY_AFTER_TRIAL = '1-monthly-after-trial'
export const PLAN_1_MONTHLY = '1-monthly'
export const PLAN_2_MONTHLY = '2-monthly'
export const PLAN_3_MONTHLY = '3-monthly'
export const PLAN_1_MONTHLY_HIGH = '1-monthly-high'
export const PLAN_2_MONTHLY_HIGH = '2-monthly-high'
export const PLAN_3_MONTHLY_HIGH = '3-monthly-high'
export const PLAN_1_MONTHLY_SUPER_HIGH = '1-monthly-super-high'
export const PLAN_2_MONTHLY_SUPER_HIGH = '2-monthly-super-high'
export const PLAN_3_MONTHLY_SUPER_HIGH = '3-monthly-super-high'
export const PLAN_1_MONTHLY_FREE_WHATSAPPP = '1-monthly-free-whatsapp'
export const PLAN_2_MONTHLY_FREE_WHATSAPPP = '2-monthly-free-whatsapp'
export const PLAN_3_MONTHLY_FREE_WHATSAPPP = '3-monthly-free-whatsapp'
export const PLAN_ADDON_PAYMENT_WHATSAPP = 'whatsapp-price'

export const US_PLAN_1_MONTHLY = 'us-1-monthly'
export const US_PLAN_2_MONTHLY = 'us-2-monthly'
export const US_PLAN_3_MONTHLY = 'us-3-monthly'
export const US_PLAN_1_MONTHLY_HIGH = 'us-1-monthly-high'
export const US_PLAN_2_MONTHLY_HIGH = 'us-2-monthly-high'
export const US_PLAN_3_MONTHLY_HIGH = 'us-3-monthly-high'
export const US_PLAN_TRIAL = 'us-trial-price'

export const onlyHasTrialPriceAvailable = () => {
  const { availablePrices } = storeToRefs(usePaymentStore())
  return availablePrices.value?.length <= 0
}

export const hasNormalSubscriptionPricesAvailable = () => {
  return !onlyHasTrialPriceAvailable()
}
