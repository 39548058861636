<script setup>

import { ref } from 'vue'
import TextAreaAlternative from '@js/Components/Forms/TextArea/TextAreaAlternative.vue'
import { RATING_BAD, RATING_NEUTRAL, REASON_OTHER } from '@js/App/Api/User/Offboarding'
import Button from '@js/Components/Forms/Button/Button.vue'
import { useMainStore } from '@js/App/Stores/MainStore'
import { doPostRequest } from '@js/App/Api/Infra/ApiClient'

defineProps({
  reason: {
    type: String,
    default: ''
  },
  rating: {
    type: String,
    default: ''
  }
})

const otherReasonComments = ref('')
const improvementComments = ref('')

const mainStore = useMainStore()
const errors = ref({})
const submit = async () => {
  mainStore.startLoading()
  errors.value = {}

  try {
    const response = await doPostRequest('/api/offboarding/feedback-comments', {
      other_reason_comments: otherReasonComments.value,
      comments: improvementComments.value
    })

    if (response.hasErrors) {
      errors.value = response.errors
      return
    }

    emit('clicked:next')
  } finally {
    mainStore.stopLoading()
  }
}

const emit = defineEmits(['clicked:next'])
</script>
<template>
  <div>
    <TextAreaAlternative
      v-if="reason === REASON_OTHER"
      v-model="otherReasonComments"
      :error="errors.other_reason_comments"
      class="mb-[12px] lg:mb-[32px]"
    >
      <template #label>
        <div class="lg:text-3xl lg:mb-[24px]">
          {{ $trans('ReasonDetailsScreen.OtherReason') }}
        </div>
      </template>
    </TextAreaAlternative>

    <TextAreaAlternative
      v-if="rating === RATING_BAD || rating === RATING_NEUTRAL"
      v-model="improvementComments"
      :error="errors.comments"
      class="mb-[12px]"
    >
      <template #label>
        <div class="lg:text-3xl lg:mb-[24px]">
          {{ $trans('ReasonDetailsScreen.ImprovementComments') }}
        </div>
      </template>
    </TextAreaAlternative>

    <div class="lg:flex lg:w-full lg:justify-end">
      <Button
        :big="true"
        :full-width-on-mobile="true"
        icon-right="arrow-right"
        @click="submit"
      >
        {{ $trans('Offboarding.ContinueCancellation') }}
      </Button>
    </div>
  </div>
</template>
