<script setup>

</script>
<template>
  <div />
  <!--
    What shouldn't there be a checkout form here?!
    Yes: but Stripe integration is very slow. Waiting for the payment form to be loaded in the iFrame can takes up to
    5 seconds. That is why we preload the payment form. However: we cannot preload it on this page, as this is only
    called when the /checkout URL is visited. So we have some magic in the <Layout> component that preloads the
    Checkout form and only shows it if we are on the checkout form
  -->
</template>
