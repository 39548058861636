import { usePage } from '@inertiajs/vue3'
import { useLocale } from '@js/App/Utils/CountryAndLocale'
import { ref } from 'vue'

export const getLocalizedUrl = (name, objectContainingValuesToReplace = {}, queryParamsToAdd = {}, localeToUse = null) => {
  const inertiaPageObject = usePage()

  let localeRef = null
  if (localeToUse) {
    localeRef = ref(localeToUse)
  } else {
    const { locale } = useLocale()
    localeRef = locale
  }

  let baseUrl = inertiaPageObject.props.localizedUrls?.[localeRef.value]?.[name] || '#'

  for (const key of Object.keys(objectContainingValuesToReplace)) {
    // This replaces the {key} template in the url for the values
    baseUrl = baseUrl
      .replace(`{${key}}`, objectContainingValuesToReplace[key])
      .replace(`%7B${key}%7D`, objectContainingValuesToReplace[key])
  }

  let seperatorSymbol = baseUrl.includes('?') ? '&' : '?'
  for (const key of Object.keys(queryParamsToAdd)) {
    baseUrl += `${seperatorSymbol}${key}=${queryParamsToAdd[key]}`
    seperatorSymbol = '&'
  }

  return baseUrl
}

// Here we provide the global $trans function to be used in Vue templates.

export default {
  install: (app, options) => {
    // This globally injects a getLocalizedUrl() method for translations
    app.config.globalProperties.$getLocalizedUrl = (name, objectContainingValuesToReplace = {}, queryParamsToAdd = {}, localeToUse = null) => {
      return getLocalizedUrl(name, objectContainingValuesToReplace, queryParamsToAdd, localeToUse)
    }
  }
}
