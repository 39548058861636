<script setup>
import Button from '@js/Components/Forms/Button/Button.vue'
import CallToActionSectionActivateAlertsItem
  from '@js/Components/Marketing/CallToActionSection/CallToActionSectionActivateAlertsItem.vue'
import { showCreateSearchProfileWizard } from '@js/App/SearchProfile'
import { router } from '@inertiajs/vue3'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import { useUser } from '@js/App/Api/User/User'

const { user } = useUser()
const isLoggedIn = user.value.isLoggedIn
const hasActiveSubscription = user.hasActiveSubscription

const onClickedButton = () => {
  if (!isLoggedIn) {
    return showCreateSearchProfileWizard()
  }

  if (hasActiveSubscription) {
    router.visit(getLocalizedUrl('my-rentbird.home'))
  } else {
    router.visit(getLocalizedUrl('upgrade'))
  }
}
</script>

<template>
  <div class="bg-purple-50 w-full relative font-bold rounded-md p-6 lg:p-6">
    <div class="text-purple-900 ">
      <div class="lg:flex justify-between">
        <CallToActionSectionActivateAlertsItem class="mb-6 lg:mb-0">
          <div v-html="$trans('CallToActionSectionActivateAlertsSmall.Title')" />
        </CallToActionSectionActivateAlertsItem>

        <div class="relative">
          <Button
            :href="$getLocalizedUrl('upgrade')"
            :big="true"
            :full-width-on-desktop="true"
            :full-width-on-mobile="true"
            icon-right="bell-alert"
            @click="onClickedButton"
          >
            {{ $trans('CallToActionSectionActivateAlertsLarge.ActivateAlerts') }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
