import { isB2BUser, isProduction, isSSR } from '@js/App/Utils/Environment'
import { router } from '@inertiajs/vue3'
import { useUser } from '@js/App/Api/User/User'
import { getCountry } from '@js/App/Utils/CountryAndLocale'

export const setupPageViewTracking = () => {
  if (isSSR()) { return }

  let isFirstNavigate = true
  router.on('navigate', async () => {
    // For Google tracking we don't want to track the first virtual page view event, because that fires a regular
    // page view already
    if (!isFirstNavigate) {
      await trackPageView()
    }
    isFirstNavigate = false
  })
}

export const trackPurchase = async (amountInCents, invoiceId, planName, email, coupon, months, discount) => {
  if (isSSR()) { return }
  if (!isProduction()) { return }
  if (isB2BUser()) { return }
  if (!shouldTrackBasedOnLastTrackedAt('purchase')) {
    console.log('[Tracking 🕵️] Not tracking Purchase as it was already tracked in the last 5 minutes')
    return
  }

  await waitForDataLayerToBeLoaded()

  if (!window.dataLayer) {
    console.log('[Tracking 🕵️] Unable to track Purchase because window.dataLayer is undefined')
    return
  }

  console.log(`[Tracking 🕵️] Track Purchase! ${amountInCents / 100}EUR`)

  const payload = {
    currency: 'EUR',
    transaction_id: invoiceId,
    value: amountInCents / 100,
    affiliation: '',
    coupon,
    shipping: 0,
    tax: amountInCents / 100 / 1.21,
    email,
    items: [
      getTrackingPurchaseItem(planName, coupon, discount, amountInCents, months)
    ],
    rentbirdUserData: getCurrentUserTrackingDataAsObject(email)
  }

  window.gtag('event', 'purchase', payload)
}

export const trackPageView = async () => {
  if (isSSR()) { return }
  if (isB2BUser()) { return }
  if (!isProduction()) { return }

  await waitForDataLayerToBeLoaded()

  if (!window.dataLayer) {
    console.log('[Tracking 🕵️] Unable to trackPageView because window.dataLayer is undefined')
    return
  }

  console.log('[Tracking 🕵️] Pushing virtualPageview event to dataLayer url: ' + window.location.href)
  window.dataLayer.push({
    event: 'virtualPageview',
    pageUrl: window.location.href,
    pageTitle: document.title
  })
}

export const trackFunnelStep = async (step, selectedCity = null) => {
  if (isSSR()) { return }
  if (!isProduction()) { return }
  if (isB2BUser()) { return }
  if (!shouldTrackBasedOnLastTrackedAt(`FunnelStep${step}`)) {
    console.log(`[Tracking 🕵️] Not tracking funnel step ${step} as it was already tracked in the last 5 minutes`)
    return
  }

  const { user } = useUser()
  if (user.value.isLoggedIn) {
    console.log(`[Tracking 🕵️] Not tracking funnel step ${step} because user is already logged in`)
    return
  }

  await waitForDataLayerToBeLoaded()

  console.log(`[Tracking 🕵️] Tracking funnel step ${step}`)

  const payload = {
    event_category: 'User',
    event_label: 'FunnelStep',
    value: step,
    selectedCity,
    rentbirdUserData: getCurrentUserTrackingDataAsObject()
  }

  window.gtag('event', 'FunnelStep', payload)
}

export const trackClickBackInFunnel = () => {
  console.log('[Tracking 🕵️] Clicked back in funnel')
}

export const trackAddToCart = async (planName, coupon, discount, amountInCents, months) => {
  if (isSSR()) { return }
  if (isB2BUser()) { return }
  if (!isProduction()) { return }
  if (!shouldTrackBasedOnLastTrackedAt('AddToCart')) {
    console.log('[Tracking 🕵️] Not tracking AddToCart as it was already tracked in the last 5 minutes')
    return
  }

  console.log('[Tracking 🕵️] Add to cart')

  await waitForDataLayerToBeLoaded()

  const payload = {
    event_category: 'User',
    event_label: 'AddToCart',
    items: [
      getTrackingPurchaseItem(planName, coupon, discount, amountInCents, months)
    ],
    rentbirdUserData: getCurrentUserTrackingDataAsObject()
  }

  window.gtag('event', 'add_to_cart', payload)
}

export const trackInitiateCheckout = async () => {
  const { user } = useUser()
  if (isSSR()) { return }
  if (isB2BUser()) { return }
  if (!isProduction()) { return }

  if (!shouldTrackBasedOnLastTrackedAt('InitiateCheckout')) {
    console.log('[Tracking 🕵️] Not tracking InitiateCheckout as it was already tracked in the last 5 minutes')
    return
  }

  console.log('[Tracking 🕵️] InitiateCheckout, email=' + user.value.email)

  await waitForDataLayerToBeLoaded()

  const payload = {
    event_category: 'User',
    event_label: 'InitiateCheckout',
    email: user.value.email,
    rentbirdUserData: getCurrentUserTrackingDataAsObject()
  }

  window.gtag('event', 'InitiateCheckout', payload)

  // Also track this event as begin_checkout. Request from Stijn, I think it has something to do with GA4
  window.gtag('event', 'begin_checkout', payload)
}

export const trackCompleteRegistration = async (email) => {
  if (isSSR()) { return }
  if (isB2BUser()) { return }
  if (!isProduction()) { return }

  if (!shouldTrackBasedOnLastTrackedAt('CompleteRegistration')) {
    console.log('[Tracking 🕵️] Not tracking CompleteRegistration as it was already tracked in the last 5 minutes')
    return
  }

  await waitForDataLayerToBeLoaded()
  await waitForRentbirdUserDataToBeFilledIn() // Give the code time to go through the update cycle

  console.log(`[Tracking 🕵️] Tracking CompleteRegistration for email ${email}`)

  const payload = {
    event_category: 'User',
    event_label: 'CompleteRegistration',
    email,
    rentbirdUserData: getCurrentUserTrackingDataAsObject(email)
  }

  window.gtag('event', 'CompleteRegistration', payload)
}

export const trackSubscriptionSelection = (subscription) => {
  console.log(`[Tracking 🕵️] Tracking SelectSubscription: ${subscription}`)

  if (isSSR()) { return }
  if (isB2BUser()) { return }
  if (!isProduction()) { return }

  const payload = {
    event_category: 'User',
    event_label: 'SelectSubscription',
    value: subscription,
    rentbirdUserData: getCurrentUserTrackingDataAsObject()
  }
  window.gtag('event', 'SelectSubscription', payload)
}

export const trackCouponCodeUsed = (oldPrice, newPrice) => {
  console.log(`[Tracking 🕵️] Tracking CouponCodeUsed: ${oldPrice} - ${newPrice}`)

  if (isSSR()) { return }
  if (isB2BUser()) { return }
  if (!isProduction()) { return }

  const payload = {
    event_category: 'User',
    event_label: 'CouponCodeUsed',
    oldPrice,
    newPrice,
    rentbirdUserData: getCurrentUserTrackingDataAsObject()
  }
  window.gtag('event', 'CouponCodeUsed', payload)
}

export const trackCancelSubscriptionReason = async (cancelReason) => {
  if (isSSR()) { return }
  if (isB2BUser()) { return }
  if (!isProduction()) { return }

  await waitForDataLayerToBeLoaded()

  console.log('[Tracking 🕵️] Tracking cancel subscription reason')

  const payload = {
    event_category: 'User',
    event_label: 'CancelSubscriptionReason',
    value: cancelReason,
    rentbirdUserData: getCurrentUserTrackingDataAsObject()
  }

  window.gtag('event', 'CancelSubscriptionReason', payload)
}

const waitForDataLayerToBeLoaded = () => new Promise((resolve, reject) => {
  const interval = setInterval(() => {
    if (window.dataLayer) {
      clearInterval(interval)
      clearTimeout(timeout)
      resolve()
    }
  }, 100)

  const timeout = setTimeout(() => {
    clearInterval(interval)
    reject(new Error('[DEBUG] timeout exceeded while waiting for data layer'))
  }, 5000)
})

const waitForRentbirdUserDataToBeFilledIn = () => new Promise((resolve) => {
  const interval = setInterval(() => {
    const rentbirdUserData = getCurrentUserTrackingDataAsObject()
    if (rentbirdUserData.userFirstName) {
      clearInterval(interval)
      clearTimeout(timeout)
      resolve()
    }
  }, 100)

  const timeout = setTimeout(() => {
    clearInterval(interval)
    resolve()
  }, 5000)
})

const shouldTrackBasedOnLastTrackedAt = (eventName, minTimeBetweenEventsInSeconds = 300) => {
  const lastTrackedAddToCartAtAsString = localStorage.getItem(eventName)
  if (!lastTrackedAddToCartAtAsString) {
    localStorage.setItem(eventName, `${new Date().getTime()}`)
    return true
  }

  const lastTrackedAddToCartAt = parseInt(lastTrackedAddToCartAtAsString)
  const diffInS = ((new Date()).getTime() - lastTrackedAddToCartAt) / 1000

  if (diffInS < minTimeBetweenEventsInSeconds) {
    return false
  }

  localStorage.setItem(eventName, `${new Date().getTime()}`)
  return true
}

const getTrackingPurchaseItem = (planName, coupon, discount, amountInCents, months) => ({
  item_id: planName,
  item_name: planName,
  affiliation: '',
  coupon,
  currency: 'EUR',
  discount: discount / 100,
  index: 0,
  item_brand: 'Rentbird',
  item_category: 'Abonnement',
  item_category2: '',
  item_category3: '',
  item_category4: '',
  item_category5: '',
  item_list_id: 'abonnementen',
  item_list_name: 'Abonnementen',
  item_variant: planName,
  location_id: '',
  price: amountInCents / 100 / months,
  quantity: months
})

export const getCurrentUserTrackingDataAsObject = (updatedEmail = null) => {
  const { user } = useUser()

  return {
    userRandomId: user.value.serverId,
    userEmail: user.value.email,
    userPhone: user.value.phone,
    userFirstName: user.value.firstNameFormatted,
    userLastName: user.value.lastNameFormatted,
    userNotificationChannel: user.value.notificationChannel,
    userEnabledAutoRenew: user.value.enabledAutoRenew,
    userNumberOfSearchProfiles: user.value.numberOfSearchProfiles,
    userIsLoggedIn: user.value.isLoggedIn,
    userHasMadeAtLeastOnePayment: user.value.hasMadeAtLeastOnePayment,
    userFirstPaymentCreatedAt: user.value.firstPaymentCreatedAt,
    userCountry: getCountry(),
    userHasMadeFirstPaymentOnThisDayThatIsBeforeThisEvent: user.value.hasMadeFirstPaymentToday,
    userHasMobilePhoneLinked: user.value.hasMobilePhoneLinked
  }
}
