<script setup>
import OffboardingLayout from '@js/Components/MyRentbird/Offboarding/OffboardingLayout/OffboardingLayout.vue'
import { ref } from 'vue'
import { usePage } from '@inertiajs/vue3'
import {
  RATING_BAD, RATING_GOOD,
  RATING_NEUTRAL,
  REASON_FOUND_A_HOUSE_WITH_RENTBIRD,
  REASON_FOUND_A_HOUSE_TROUGH_WAITING_LIST,
  REASON_FOUND_A_HOUSE_TROUGH_AGENCY,
  REASON_FOUND_A_HOUSE_THROUGH_ANOTHER_CHANNEL

} from '@js/App/Api/User/Offboarding'
import Container from '@js/Components/Container/Container.vue'
import { useUser } from '@js/App/Api/User/User'
import { getQueryParameter } from '@js/App/Utils/ReadQueryParams'
import Button from '@js/Components/Forms/Button/Button.vue'
import KimTextBox from '@js/Components/MyRentbird/Offboarding/PersonTextBox/PersonTextBox.vue'
import { useMainStore } from '@js/App/Stores/MainStore'
import { doPostRequest } from '@js/App/Api/Infra/ApiClient'
import { reloadPropsFromServer } from '@js/App/Api/Infra/ServerData'
import OffboardingProgressBar
  from '@js/Components/MyRentbird/Offboarding/OffboardingProgressBar/OffboardingProgressBar.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import UserReviewCard from '@js/Components/Marketing/UserReviewsSection/UserReviewCard.vue'
import { randomElement } from '@js/App/Utils/Arrays'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'

const page = usePage()
const userReviews = page?.props?.trustpilot?.reviews || []
const reviewToShow1 = randomElement(userReviews)
const reviewToShow2 = randomElement(userReviews)
const userFeedback = page.props.user_feedback
const { isMobile } = useIsMobile()
const rating = userFeedback.rating
const foundAHouse = userFeedback.reason === REASON_FOUND_A_HOUSE_WITH_RENTBIRD || userFeedback.reason === REASON_FOUND_A_HOUSE_THROUGH_ANOTHER_CHANNEL || userFeedback.reason === REASON_FOUND_A_HOUSE_TROUGH_WAITING_LIST || userFeedback.reason === REASON_FOUND_A_HOUSE_TROUGH_AGENCY

const { user, userActivePayment } = useUser()

const step = ref(1)

const cancelledWithFullRefund = getQueryParameter('cancelWithFullRefund', false)

const mainStore = useMainStore()
const submitIWantAFreeMonthOfRentbird = async () => {
  mainStore.startLoading()
  try {
    await doPostRequest('/api/offboarding/request-free-month-of-rentbird', {})

    reloadPropsFromServer()

    step.value = 2
  } finally {
    mainStore.stopLoading()
  }
}

</script>
<template>
  <OffboardingLayout
    :show-back-button="false"
    :show-trust-pilot="true"
    :show-close-button="false"
  >
    <Container class="lg:pt-[70px]">
      <OffboardingProgressBar
        :step-number="5"
        class="mb-6"
      />
      <div class="flex flex-row gap-6">
        <div class="h-full text-white pb-10 mt-3 lg:w-full">
          <div v-if="step === 1">
            <h2
              class="text-white font-medium text-3xl mb-2 lg:mb-4 lg:mt-16 lg:text-5xl flex flex-row"
            >
              <Icon
                name="circle-pink-checked"
                icon-width="w-[59px] relative"
                class="mr-2"
              />
              {{ $trans('CancellationConfirmedPage.Title') }}
            </h2>
            <p class="text-white text-base lg:text-xl lg:mb-16">
              <span v-if="cancelledWithFullRefund">
                {{ $trans('CancellationConfirmedPage.MoneyIsUnderway') }}
              </span>
              <span v-else>
                <span v-if="user.numberOfSearchProfiles > 0">
                  {{ $trans("CancellationConfirmedPage.SubscriptionEndsAt", { date: userActivePayment.subscription_ends_at_formatted }) }}
                </span>
                <span v-else>
                  {{ $trans('CancellationConfirmedPage.SubscriptionStopsNow') }}
                </span>
              </span>
            </p>
          </div>
          <div :class="{ 'mt-[24px]': step === 1 }">
            <!-- User feedback should always be something. This button is here for an sanity check / edge case -->
            <div v-if="!userFeedback" class="lg:w-[600px]">
              <Button
                :big="true"
                :full-width-on-mobile="true"
                :full-width-on-desktop="false"
                tag="link"
                icon-right="arrow-up-left"
                :href="$getLocalizedUrl('my-rentbird.subscription')"
              >
                {{ $trans('CancellationConfirmedPage.BackToMyRentbird') }}
              </Button>
            </div>

            <!-- This is the case if the user requested money back within 14 days -->
            <div v-else-if="cancelledWithFullRefund">
              <KimTextBox>
                <template #title>
                  {{ $trans('CancellationConfirmed.EarnMoneyWithSelfieTitle') }}
                </template>
                <span v-html="$trans('CancellationConfirmedPage.FullRefund.Text')" />
              </KimTextBox>

              <Button
                class="mt-[24px]"
                :big="true"
                :full-width-on-mobile="true"
                :full-width-on-desktop="false"
                tag="link"
                icon-right="arrow-up-left"
                :href="$getLocalizedUrl('my-rentbird.subscription')"
              >
                {{ $trans('CancellationConfirmedPage.BackToMyRentbird') }}
              </Button>
            </div>

            <!-- We have valid user feedback -->
            <div v-else>
              <!----------------------------------------------------------------------------------------------------------
              Rating: BAD
              Reason: Found A House
            ----------------------------------------------------------------------------------------------------------->
              <div v-if="rating === RATING_BAD && foundAHouse">
                <KimTextBox>
                  <template #title>
                    {{ $trans('CancellationConfirmedPage.RatingBad.FoundAHouse.Title') }}
                  </template>
                  <span v-html="$trans('CancellationConfirmedPage.RatingBad.FoundAHouse.Text')" />
                </KimTextBox>

                <div class="lg:w-[600px]">
                  <Button
                    class="mt-[24px]"
                    :big="true"
                    :full-width-on-mobile="true"
                    :full-width-on-desktop="false"
                    tag="link"
                    icon-right="arrow-up-left"
                    :href="$getLocalizedUrl('my-rentbird.subscription')"
                  >
                    {{ $trans('CancellationConfirmedPage.BackToMyRentbird') }}
                  </Button>
                </div>
              </div>

              <!----------------------------------------------------------------------------------------------------------
              Rating: BAD
              Reason: Other
            ----------------------------------------------------------------------------------------------------------->
              <div v-if="rating === RATING_BAD && !foundAHouse">
                <div v-if="step === 1">
                  <KimTextBox>
                    <template #title>
                      {{ $trans('CancellationConfirmedPage.RatingBad.NotFoundAHouse.Title') }}
                    </template>
                    <span v-html="$trans('CancellationConfirmedPage.RatingBad.NotFoundAHouse.Text')" />
                  </KimTextBox>

                  <div class="lg:w-[600px]">
                    <Button
                      class="mt-[24px]"
                      :big="true"
                      :full-width-on-mobile="true"
                      :full-width-on-desktop="false"
                      icon-right="arrow-right"
                      @click="submitIWantAFreeMonthOfRentbird"
                    >
                      {{ $trans('CancellationConfirmedPage.IWantToTry') }}
                    </Button>
                    <Button
                      class="mt-[8px] lg:ml-[8px]"
                      :big="true"
                      :outline="true"
                      :full-width-on-mobile="true"
                      :full-width-on-desktop="false"
                      tag="link"
                      icon-right="cross"
                      :href="$getLocalizedUrl('my-rentbird.subscription')"
                    >
                      {{ $trans('CancellationConfirmedPage.SearchOnMyOwn') }}
                    </Button>
                  </div>
                </div>
              </div>

              <!----------------------------------------------------------------------------------------------------------
              Rating: NEUTRAL
              Reason: Found A House
            ----------------------------------------------------------------------------------------------------------->
              <div v-if="rating === RATING_NEUTRAL && foundAHouse">
                <KimTextBox>
                  <template #title>
                    {{ $trans('CancellationConfirmedPage.RatingNeutral.FoundAHouse.Title') }}
                  </template>
                  <span v-html="$trans('CancellationConfirmedPage.RatingNeutral.FoundAHouse.Text')" />
                </KimTextBox>

                <div class="lg:w-[600px]">
                  <Button
                    class="mt-[24px]"
                    :big="true"
                    :full-width-on-mobile="true"
                    :full-width-on-desktop="false"
                    tag="link"
                    icon-right="arrow-up-left"
                    :href="$getLocalizedUrl('my-rentbird.subscription')"
                  >
                    {{ $trans('CancellationConfirmedPage.BackToMyRentbird') }}
                  </Button>
                </div>
              </div>

              <!----------------------------------------------------------------------------------------------------------
              Rating: NEUTRAL
              Reason: Other
            ----------------------------------------------------------------------------------------------------------->
              <div v-if="rating === RATING_NEUTRAL && !foundAHouse">
                <KimTextBox>
                  <template #title>
                    {{ $trans('CancellationConfirmedPage.RatingNeutral.NotFoundAHouse.Title') }}
                  </template>
                  <span v-html="$trans('CancellationConfirmedPage.RatingNeutral.NotFoundAHouse.Text')" />
                </KimTextBox>

                <div class="lg:w-[600px]">
                  <Button
                    class="mt-[24px]"
                    :big="true"
                    :full-width-on-mobile="true"
                    :full-width-on-desktop="false"
                    tag="link"
                    icon-right="arrow-up-left"
                    :href="$getLocalizedUrl('my-rentbird.subscription')"
                  >
                    {{ $trans('CancellationConfirmedPage.BackToMyRentbird') }}
                  </Button>
                </div>
              </div>

              <!----------------------------------------------------------------------------------------------------------
             Rating: Good
             ..
           ----------------------------------------------------------------------------------------------------------->
              <div v-if="rating === RATING_GOOD" class="flex">
                <div class="w-full lg:w-1/2">
                  <KimTextBox>
                    <template #title>
                      {{ $trans('CancellationConfirmed.EarnMoneyWithSelfieTitle') }}
                    </template>
                    <span v-html="$trans('CancellationConfirmed.EarnMoneyWithSelfieText')" />
                  </KimTextBox>

                  <div class="mt-8 flex flex-col lg:w-2/3">
                    <Button
                      class="mt-6"
                      :big="true"
                      :full-width-on-mobile="true"
                      :full-width-on-desktop="false"
                      tag="link"
                      icon-right="arrow-right"
                      :href="$getLocalizedUrl('my-rentbird.review-us')"
                    >
                      {{ $trans('CancellationConfirmedPage.ReviewUsButtonLabel') }}
                    </Button>
                    <Button
                      class="mt-2"
                      :big="true"
                      :outline="true"
                      :full-width-on-mobile="true"
                      :full-width-on-desktop="false"
                      tag="link"
                      icon-right="cross"
                      :href="$getLocalizedUrl('my-rentbird.subscription')"
                    >
                      {{ $trans('CancellationConfirmedPage.BacktoMyRentbird') }}
                    </Button>
                  </div>
                </div>

                <div v-if="!isMobile" class="ml-6 pr-4 w-full lg:w-1/2 flex justify-center">
                  <UserReviewCard
                    class="mr-6 mt-6"
                    :name="reviewToShow1.name"
                    :image="reviewToShow1.image"
                    :trustpilot-url="`https://www.trustpilot.com/reviews/${reviewToShow1.trustpilot_id}`"
                    :rating="reviewToShow1.rating"
                    :alternative-styling="true"
                  >
                    <span v-html="reviewToShow1.formatted_review_text" />
                  </UserReviewCard>
                  <UserReviewCard
                    class="mt-20"
                    :name="reviewToShow2.name"
                    :image="reviewToShow2.image"
                    :trustpilot-url="`https://www.trustpilot.com/reviews/${reviewToShow2.trustpilot_id}`"
                    :rating="reviewToShow2.rating"
                    :alternative-styling="true"
                  >
                    <span v-html="reviewToShow2.formatted_review_text" />
                  </UserReviewCard>
                </div>
              </div>
            </div>

            <div v-if="step === 2">
              <KimTextBox>
                <template #title>
                  {{ $trans('CancellationConfirmedPage.RefundRequestedTitle') }}
                </template>
                <span v-html="$trans('CancellationConfirmedPage.RefundRequestedText')" />
              </KimTextBox>

              <div class="lg:w-[600px]">
                <Button
                  class="mt-[32px]"
                  :big="true"
                  :full-width-on-mobile="true"
                  :full-width-on-desktop="false"
                  tag="link"
                  icon-right="arrow-up-left"
                  :href="$getLocalizedUrl('my-rentbird.subscription')"
                >
                  {{ $trans('CancellationConfirmedPage.BackToMyRentbird') }}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </OffboardingLayout>
</template>
<style lang="scss">
.MyRentbird-CancellationConfirmedPage {
  background-color: rgb(40,33,75);
}
</style>
