<script setup>

import BasicPageHeader from '@js/Components/Marketing/BasicPageHeader/BasicPageHeader.vue'
import Container from '@js/Components/Container/Container.vue'
import { reactive } from 'vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { router, usePage } from '@inertiajs/vue3'
import Image from '@js/Components/Image/Image.vue'
import CurlImage from '@images/icons/curl.svg'
import BirdRealisticImage from '@images/icons/bird-realistic.png'
import KeyImage from '@images/icons/key.png'
import PasswordInput from '@js/Components/Forms/PasswordInput/PasswordInput.vue'

const page = usePage()

defineProps({ errors: Object })

const form = reactive({
  csrf: page.props.csrf,
  token: page.props.token,
  email: page.props.email,
  password: ''
})

const submit = async () => {
  await router.post('/password/reset', form, {
    headers: { Accept: 'application/json' }
  })
}

defineEmits(['update:modelValue', 'submit'])
</script>
<template>
  <BasicPageHeader :show-trustpilot-instead-of-login="true" :show-section-divider="false" />
  <div class="overflow-clip relative">
    <Container class="relative pt-8 lg:pt-12 pb-[200px] lg:pb-[300px]">
      <form class="relative block w-[600px] max-w-full mx-auto" method="post" @submit.prevent="submit">
        <Image :src="KeyImage" class="absolute left-[-166px] lg:left-[-178px] key-login top-[92px] w-[330px]" />
        <Image :src="BirdRealisticImage" class="absolute right-[-101px] lg:right-[-141px] login-bird top-[242px] w-[280px]" />
        <div class="relative rounded-md bg-purple-900 h-[500px] lg:h-[524px] px-6 py-8  lg:px-10 lg:py-10">
          <Image :src="CurlImage" class="absolute z-0 right-[-40px] lg:right-[-60px] top-[-35px] lg:top-[-40px]" />

          <h2 class="flex text-white font-medium text-4xl mb-2 lg:text-4xl">
            {{ $trans('PasswordRecovery.ResetPasswordTitle') }}
          </h2>
          <h4 class="text-white text-base mb-6 lg:text-lg">
            <span v-html="$trans('PasswordRecovery.ResetPasswordSubtitle')" />
          </h4>

          <PasswordInput
            id="password"
            v-model="form.password"
            autocomplete="new-password"
            type="password"
            class="mt-4 mb-8"
            :text-white="true"
            :placeholder="$trans('SearchProfileWizard.PasswordPlaceholder')"
            :error="errors.password || errors.email"
            @keyup.enter="$emit('submit')"
          >
            <template #label>
              {{ $trans('SearchProfileWizard.Password') }}
            </template>
          </PasswordInput>

          <Button
            :big="true"
            :full-width-on-mobile="true"
            :full-width-on-desktop="true"
            icon-right="arrow-right"
            :href="$getLocalizedUrl('reviews')"
            class="mb-6"
          >
            {{ $trans('PasswordRecovery.SaveButtonLabel') }}
          </Button>
        </div>
      </form>
    </Container>
  </div>
</template>
<style lang="scss">
.Auth-PasswordResetPage {
  background: #28214B;
}
.key-login {
  transform: rotate(42deg);
}
.login-bird {
  transform: scaleX(-1) rotate(-15deg);
}
.Auth-LoginPage {
  background: #28214B;
}
</style>
