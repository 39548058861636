<script setup>
import { storeToRefs } from 'pinia'
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import { computed } from 'vue'
import DeadFaceImage from '@images/icons/dead.png'
import ColdFaceImage from '@images/icons/cold-face.png'
import FireImage from '@images/icons/fire.png'
import ThumbsUpImage from '@images/icons/thumbs-up.png'
import Button from '@js/Components/Forms/Button/Button.vue'
import Image from '@js/Components/Image/Image.vue'
import Icon from '@js/Components/Icon/Icon.vue'

defineProps({
  showSuccessfullySavedMessage: {
    type: Boolean,
    default: false
  },
  showPreviousButton: {
    type: Boolean,
    default: false
  }
})

defineEmits([
  'click:previousStep',
  'click:nextStep'
])
const { matchesPerWeek } = storeToRefs(useSearchProfileStore())

const matchIcon = computed(() => {
  if (matchesPerWeek.value?.isLoading) { return matchIcon.value || FireImage }
  if (matchesPerWeek.value?.matchesPerWeek <= 2) { return DeadFaceImage }
  if (matchesPerWeek.value?.matchesPerWeek <= 5) { return ColdFaceImage }
  if (matchesPerWeek.value?.matchesPerWeek <= 10) { return ThumbsUpImage }

  return FireImage
})

const backgroundColor = computed(() => {
  if (matchesPerWeek.value?.isLoading) { return backgroundColor.value || 'bg-blue-50' }
  if (matchesPerWeek.value?.matchesPerWeek <= 2) { return 'bg-pink-50' }
  if (matchesPerWeek.value?.matchesPerWeek <= 5) { return 'bg-[#FFF2D9]' }
  if (matchesPerWeek.value?.matchesPerWeek <= 10) { return 'bg-[#E2FFF5]' }

  return 'bg-[#E2FFF5]'
})

</script>
<template>
  <div
    class="fixed bottom-0 left-0 right-0 px-[16px] py-[16px] lg:z-auto z-10"
    :class="{
      [`${backgroundColor}`]: true
    }"
  >
    <div class="flex justify-between items-center">
      <div>
        <div class="text-base text-gray-600">
          {{ $trans('SearchProfileStickyBottom.ExpectedMatches') }}
        </div>
        <div class="text-lg font-medium">
          {{ $trans('SearchProfileStickyBottom.NumberOfMatches', { matches: matchesPerWeek?.isLoading ? '..' : matchesPerWeek?.matchesPerWeek }) }}
          <Image :src="matchIcon" class="w-[17px] inline relative top-[-2px]" />
        </div>
      </div>
      <div class="w-1/2 flex">
        <Button
          v-if="showPreviousButton"
          class=" lg:mb-0 mr-2"
          :outline="true"
          dusk="previous"
          @click="$emit('click:previousStep')"
        >
          <Icon name="arrow-left" />
        </Button>
        <Button
          class=" lg:mb-0 flex-grow"
          :full-width-on-mobile="true"
          :full-width-on-desktop="true"
          dusk="next"
          :icon-right="'arrow-right'"
          @click="$emit('click:nextStep')"
        >
          <span>{{ $trans('Common.Next') }}</span>
        </Button>
      </div>
    </div>
    <slot />
  </div>
</template>
