import { doPostRequest } from '@js/App/Api/Infra/ApiClient'
import { usePaymentStore } from '@js/App/Stores/PaymentStore'
import { trackCouponCodeUsed } from '@js/App/Tracking/Tracking'

export const submitCouponCode = async (couponCode) => {
  const response = await doPostRequest('/api/user/use-coupon', {
    coupon: couponCode
  }, false)

  if (response.hasErrors) {
    return {
      errors: response.errors
    }
  }

  const newPrices = response.json?.prices

  const paymentStore = usePaymentStore()

  paymentStore.applyNewCouponPrices(newPrices, couponCode)

  trackCouponCodeUsed(
    paymentStore.selectedPriceBeforeCouponWasApplied.amount / 100,
    paymentStore.selectedPrice.amount / 100
  )

  return { errors: {} }
}
