<script setup>
import TrustpilotStars from '@js/Components/Trustpilot/TrustpilotStars.vue'
import { usePage } from '@inertiajs/vue3'
import TrustpilotImage from '@images/trustpilot-logo.svg'
import TrustpilotWhiteImage from '@images/trustpilot-logo-white.svg'
import Image from '@js/Components/Image/Image.vue'

defineProps({
  black: {
    type: Boolean,
    default: false
  },
  showNumberOfReviewsOnMobile: {
    type: Boolean,
    default: false
  },
  showNumberOfReviewsOnDesktop: {
    type: Boolean,
    default: true
  },
  showRatingText: {
    type: Boolean,
    default: true
  },
  layout: {
    // 'horizontal' or 'vertical'
    type: String,
    default: 'horizontal'
  }
})

const page = usePage()

const trustPilotRating = page.props?.trustpilot?.rating
const numberOfReviews = page.props?.trustpilot?.numberOfReviews
</script>

<template>
  <div
    class="trustpilot"
    :class="{
      'flex items-center flex-wrap': layout === 'horizontal',
      'inline-block': layout === 'vertical'
    }"
  >
    <Image
      v-if="!black"
      class="w-[97px]"
      :src="TrustpilotWhiteImage"
      :class="{
        'mr-4': layout === 'horizontal',
        'lg:mx-auto': layout === 'vertical',
      }"
    />
    <Image
      v-if="black"
      class="w-[97px]"
      :src="TrustpilotImage"
      :class="{
        'mr-4': layout === 'horizontal',
        'lg:mx-auto': layout === 'vertical',
      }"
    />
    <div class="flex" :class="{'lg:justify-center': layout === 'vertical'}">
      <TrustpilotStars
        class="w-[131px] mt-1"
        :rating="trustPilotRating"
        :class="{'mr-4': showRatingText}"
      />

      <span
        v-if="showRatingText"
        class="text-base font-medium relative top-[6px] lg:text-base lg:relative "
        :class="{
          'text-white': !black,
          'text-black': black,
          'lg:mr-4': layout === 'horizontal'
        }"
      >
        {{ $formatNumber(trustPilotRating, 1) }}
      </span>
    </div>

    <div
      class="text-center lg:text-left lg:w-auto text-sm relative top-[5px] lg:mt-0 pl-3"
      :class="{
        'text-white': !black,
        'text-black': black,
        'hidden': !showNumberOfReviewsOnMobile,
        'lg:block': showNumberOfReviewsOnDesktop
      }"
      v-html="$trans('TrustpilotSmall.BasedOn', {
        url: 'https://nl.trustpilot.com/review/rentbird.nl',
        number: numberOfReviews
      })"
    />
  </div>
</template>
