import { router } from '@inertiajs/vue3'
import { useMainStore } from '@js/App/Stores/MainStore'
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'

export const setupResetStoresOnNavigation = () => {
  router.on('navigate', () => {
    console.log('[DEBUG] Resetting some pinia stores')
    const mainStore = useMainStore()
    mainStore.$reset()

    const searchProfileStore = useSearchProfileStore()
    if (searchProfileStore.shouldResetOnNavigation) {
      searchProfileStore.$reset()
    }
  })
}
