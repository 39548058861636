export const copyTextToClipboard = (textToCopy) => {
  const textArea = document.createElement('textarea')
  textArea.value = textToCopy
  textArea.style.position = 'fixed'
  textArea.style.left = '-999999px'
  textArea.style.top = '-999999px'
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  return new Promise((resolve, reject) => {
    document.execCommand('copy') ? resolve() : reject(new Error('unable to copy'))
    textArea.remove()
  })
}
