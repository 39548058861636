<script setup>
import InlineCheckboxGroup from '@js/Components/Forms/InlineCheckboxGroup/InlineCheckboxGroup.vue'
import { onMounted, ref, watch } from 'vue'
import { doGetRequest, doPostRequest } from '@js/App/Api/Infra/ApiClient'
import { useMainStore } from '@js/App/Stores/MainStore'

defineProps({
  checkboxLocation: {
    type: String
  },
  classLabel: {
    type: String,
    default: ''
  }
})

const searchProfileAccountSettings = ref(null)
const mainStore = useMainStore()

onMounted(async () => {
  mainStore.startLoading()

  const r = await doGetRequest('/api/user/search-profiles/account-settings')
  const accountSettings = r.json?.account_settings

  searchProfileAccountSettings.value = {}
  searchProfileAccountSettings.value.isForPets = accountSettings.is_for_pets
  searchProfileAccountSettings.value.isForStudents = accountSettings.is_for_students
  searchProfileAccountSettings.value.sharingThreeOrMore = accountSettings.sharing_3_or_more

  mainStore.stopLoading()
})

const emit = defineEmits(['accountSettingsUpdated'])

const getDebounceKey = () => `${searchProfileAccountSettings.value.includeWoningcorporaties}${searchProfileAccountSettings.value.includePaidWebsites}${searchProfileAccountSettings.value.includeSocialeLootwoningen}`

watch(searchProfileAccountSettings, (newValue, oldValue) => {
  if (oldValue === null) { return }

  const valueNow = getDebounceKey()
  setTimeout(async () => {
    if (valueNow !== getDebounceKey()) {
      return
    }

    await doPostRequest('/api/user/search-profiles/account-settings', {
      is_for_pets: searchProfileAccountSettings.value.isForPets,
      is_for_students: searchProfileAccountSettings.value.isForStudents,
      sharing_3_or_more: searchProfileAccountSettings.value.sharingThreeOrMore
    })

    emit('accountSettingsUpdated')
  }, 500)
}, { deep: true })
</script>
<template>
  <div>
    <InlineCheckboxGroup
      v-if="searchProfileAccountSettings"
      :initial-is-expanded="true"
      :can-collapse="false"
      :checkbox-location="checkboxLocation"
      :class-label="classLabel"
      :model-value="[
        searchProfileAccountSettings.isForPets ? 'is_for_pets' : null,
        searchProfileAccountSettings.isForStudents ? 'is_for_students' : null,
        searchProfileAccountSettings.sharingThreeOrMore ? 'sharing_3_or_more' : null,
      ].filter(v => v)"
      :options="[
        {
          label: $trans('OnBoardingWizard.IsForPets'),
          value: 'is_for_pets',
        },
        {
          label: $trans('OnBoardingWizard.IsForStudents'),
          value: 'is_for_students',
        },
        {
          label: $trans('OnBoardingWizard.IsForSharing'),
          value: 'sharing_3_or_more',
          tooltip: $trans('OnBoardingWizard.IsForSharingTooltip')
        },
      ].filter(v => v)"
      @update:model-value="newValue => {
        searchProfileAccountSettings.isForPets = newValue.includes('is_for_pets')
        searchProfileAccountSettings.isForStudents = newValue.includes('is_for_students')
        searchProfileAccountSettings.sharingThreeOrMore = newValue.includes('sharing_3_or_more')
      }"
    >
      {{ $trans('OnboardingWizard.SuitableFor') }}
    </InlineCheckboxGroup>
    <div v-else class="w-full h-[161px] lg:h-[165px]" />
  </div>
</template>
