<script setup>
import Button from '@js/Components/Forms/Button/Button.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import PaginationButton from '@js/Components/Pagination/PaginationButton.vue'
import { computed } from 'vue'
import { isSSR } from '@js/App/Utils/Environment'

const props = defineProps({
  baseUrl: String,
  currentPage: Number,
  lastPage: Number,

  shouldNavigateOnPagination: {
    type: Boolean,
    default: true
  }
})

const baseUrlToUse = computed(() => {
  if (props.baseUrl) { return props.baseUrl }
  if (isSSR()) { return '/' }
  return window.location.href.replaceAll(/(&|\?)page=\d+/gm, '')
})

const getUrlToPage = (page) => {
  const seperationChar = baseUrlToUse.value?.includes('?') ? '&' : '?'

  return `${baseUrlToUse.value}${seperationChar}page=${page}`
}

const linksToShow = computed(() => {
  const currentPage = props.currentPage

  const onEachSide = 1

  const links = []
  for (let i = currentPage - onEachSide; i <= currentPage + onEachSide; i += 1) {
    if (i < 1) { continue }
    if (i > currentPage + onEachSide) { continue }
    if (i > props.lastPage) { continue }

    links.push({
      page: i,
      isCurrent: i === props.currentPage
    })
  }

  if (links[0].page > 1) {
    links.unshift({
      page: '...',
      isCurrent: false
    })
    links.unshift({
      page: 1,
      isCurrent: false
    })
  }

  if (links[links.length - 1].page < props.lastPage) {
    links.push({
      page: '...',
      isCurrent: false
    })
    links.push({
      page: props.lastPage,
      isCurrent: false
    })
  }

  return links
})

defineEmits(['setPage'])
</script>
<template>
  <div class="flex w-full justify-center lg:justify-between">
    <Button
      :tag="shouldNavigateOnPagination ? 'link' : 'button'"
      :outline="true"
      class="mr-[8px] hidden lg:block"
      :href="shouldNavigateOnPagination ? getUrlToPage(currentPage - 1) : ''"
      :disabled="currentPage === 1"
      @click="() => {
        if (currentPage === 1) {
          return
        }
        $emit('setPage', currentPage - 1)
      }"
    >
      <Icon name="arrow-outline-left" />
    </Button>

    <div class="flex space-x-2 lg:space-x-4">
      <component
        :is="link.page === '...' ? 'div' : PaginationButton"
        v-for="(link, index) of linksToShow"
        :key="index"
        :should-navigate-on-pagination="shouldNavigateOnPagination"
        :url="getUrlToPage(link.page)"
        :current="link.isCurrent"
        :class="{
          'w-[14px] h-[30px] text-sm lg:text-base lg:w-[20px] mr-[8px] lg:h-[50px] flex justify-center items-center': link.page === '...'
        }"
        @click="$emit('setPage', link.page)"
      >
        <span v-if="link.page === '...'">...</span>
        <span v-else>{{ link.page }}</span>
      </component>
    </div>

    <Button
      :outline="true"
      class="ml-[8px] hidden lg:block"
      :tag="shouldNavigateOnPagination ? 'link' : 'button'"
      :href="shouldNavigateOnPagination ? getUrlToPage(currentPage + 1) : ''"
      :disabled="lastPage <= currentPage"
      @click="() => {
        if (lastPage <= currentPage) {
          return
        }
        $emit('setPage', currentPage + 1)
      }"
    >
      <Icon name="arrow-outline-right" />
    </Button>
  </div>
</template>
