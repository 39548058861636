<script setup>
import Select from '@js/Components/Forms/Select/Select.vue'
import { ref, watch } from 'vue'
import { useB2BOrganization } from '@js/App/Api/B2B/B2B'
import { doPostRequest } from '@js/App/Api/Infra/ApiClient'
import { ziggyRoute } from '@js/App/Utils/ZiggyUrls'

const props = defineProps({
  houseRandomId: {
    type: String,
    default: ''
  },
  houseStatus: {
    type: String,
    default: ''
  }
})

const { organization } = useB2BOrganization()
const status = ref(props.houseStatus)

watch(status, async () => {
  await doPostRequest(ziggyRoute('b2b.house.update-status', {
    organization: organization.value,
    house: props.houseRandomId
  }), { house_status: status.value }, false)
})
</script>
<template>
  <div>
    <Select
      v-model="status"
      :options="[
        { value: 'Not started', label: `Not started` },
        { value: 'In progress', label: `In progress` },
        { value: 'Viewing', label: `Viewing` },
        { value: 'Archive', label: `Archive` },
      ]"
    />
  </div>
</template>
