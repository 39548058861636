<script setup>
import InlineCheckboxGroup from '@js/Components/Forms/InlineCheckboxGroup/InlineCheckboxGroup.vue'
import { storeToRefs } from 'pinia'
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import { ref } from 'vue'

defineProps({
  initialIsExpanded: {
    type: Boolean,
    default: false
  },
  canCollapse: {
    type: Boolean,
    default: true
  }
})

const child = ref(null)

defineExpose({
  setIsExpanded: exp => child.value?.setIsExpanded(exp)
})

defineEmits(['update:isExpanded'])

const { searchProfile } = storeToRefs(useSearchProfileStore())
</script>
<template>
  <InlineCheckboxGroup
    ref="child"
    dusk="living-requirements"
    :can-collapse="canCollapse"
    :initial-is-expanded="initialIsExpanded"
    checkbox-location="left"
    :model-value="[
      searchProfile.notifyWhenRequirementsNotClear ? 'notify_when_requirements_not_clear' : null,
    ].filter(v => v)"
    :options="[
      {
        label: $trans('SearchProfileWizard.RequirementsNotClear'),
        value: 'notify_when_requirements_not_clear',
        tooltip: $trans('HouseRequirementsNotClearCheckbox.Tooltip')
      },
    ]"
    @update:model-value="newValue => {
      searchProfile.notifyWhenRequirementsNotClear = newValue.includes('notify_when_requirements_not_clear')
    }"
  >
    {{ $trans('SearchProfileWizard.Filter') }}
  </InlineCheckboxGroup>
</template>
