<script setup>
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import { usePage, Link } from '@inertiajs/vue3'
import { computed } from 'vue'
import SearchProfileCard from '@js/Components/SearchProfileCard/SearchProfileCard.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import { getSearchProfileFromDto } from '@js/App/Stores/SearchProfileStore'
import NumberOfMatchesBoxAllSearchProfiles
  from '@js/Components/NumberOfMatchesBox/NumberOfMatchesBoxAllSearchProfiles.vue'
import { useUser } from '@js/App/Api/User/User'

const page = usePage()

const { user } = useUser()
const userSearchProfiles = (page.props.userSearchProfiles || []).map(searchProfileDto => getSearchProfileFromDto(searchProfileDto))

const canCreateNewSearchProfile = computed(() => (user.value.hasActiveSubscription ? user.value.maxNumberOfSearchProfiles : 1) - userSearchProfiles.length > 0)

</script>
<template>
  <MyRentbirdCard>
    <template #title>
      <div class="flex items-center">
        {{ $trans('SearchProfilesCard.Title') }}
        <Icon
          v-tippy="{
            content: userSearchProfiles.length >= 1 ? $trans('Common.Active') : $trans('Common.Inactive'),
            allowHTML: true,
          }"
          class="pl-[8px]"
          :name="userSearchProfiles.length >= 1 ? 'dot-green' : 'dot-red'"
        />
      </div>
    </template>
    <template #title-desktop-right>
      <span v-if="user.hasActiveSubscription">
        <Link
          class="text-base text-blue-base hover:cursor-pointer hover:underline transition-all relative top-[1px]"
          :href="$getLocalizedUrl('my-rentbird.preferences')"
        >{{ $trans('SearchProfilesOverviewCard.SearchSettings') }}</Link>
      </span>
      <span
        v-else
        class="text-gray-300 flex relative top-[7px]"
      >
        <Icon name="lock" class="mr-[4px]" /> {{ $trans('SearchProfilesOverviewCard.UpgradeRequired') }}
      </span>
    </template>
    <template #default>
      <div class="text-sm text-gray-500 lg:mb-[4px] lg:relative lg:top-[-4px]">
        <span v-if="canCreateNewSearchProfile">
          {{ $trans('SearchProfilesCard.AmountAvailable', { amount: user.maxNumberOfSearchProfiles - userSearchProfiles.length}) }}
        </span>
        <span v-else-if="user.hasActiveSubscription">
          {{ $trans('SearchProfilesCard.AllSearchesCreated') }}
        </span>
      </div>

      <NumberOfMatchesBoxAllSearchProfiles />

      <div class="lg:flex lg:flex-wrap lg:mt-[8px] lg:ml-[-4px] lg:mr-[-4px]">
        <div
          v-for="searchProfile of userSearchProfiles"
          :key="searchProfile.serverId"
          class="lg:w-1/2 lg:pr-[4px] lg:pl-[4px]"
        >
          <SearchProfileCard
            class="mt-[8px]"
            :search-profile="searchProfile"
            :show-edit-button="true"
            :rounded-bottom="true"
          />
        </div>
        <div
          v-show="canCreateNewSearchProfile"
          class="hidden lg:flex w-1/2 items-center justify-center  min-h-[140px]"
        >
          <Link
            class="text-base text-blue-base hover:cursor-pointer hover:underline transition-all"
            :href="$getLocalizedUrl('my-rentbird.create-search-profile')"
          >
            {{ $trans('SearchProfilesOverviewCard.NewSearch') }}
          </Link>
        </div>
        <div
          v-show="!canCreateNewSearchProfile && !user.hasActiveSubscription"
          class="hidden lg:flex w-1/2 items-center justify-center text-center px-[16px]"
        >
          <span class="text-sm text-gray-300 lg:h-[172px] lg:flex lg:items-center">
            {{ $trans('SearchProfilesOverviewCard.UpgradeNowPlz') }}
          </span>
        </div>
      </div>

      <div v-show="canCreateNewSearchProfile" class="w-full flex justify-end mt-[16px] lg:hidden">
        <Link
          class="text-sm lg:text-base text-blue-base hover:cursor-pointer hover:underline transition-all"
          :href="$getLocalizedUrl('my-rentbird.create-search-profile')"
        >
          {{ $trans('SearchProfilesOverviewCard.NewSearch') }}
        </Link>
      </div>
      <div v-show="!canCreateNewSearchProfile && !user.hasActiveSubscription" class="mt-[16px] text-center lg:hidden">
        <span class="text-sm text-gray-300">
          {{ $trans('SearchProfilesOverviewCard.UpgradeNowPlz') }}
        </span>
      </div>
    </template>
  </MyRentbirdCard>
</template>
