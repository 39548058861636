<script setup>
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import ProgressBar from '@js/Components/Forms/ProgressBar/ProgressBar.vue'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import { router } from '@inertiajs/vue3'
import InfoMessageBox from '@js/Components/InfoMessageBox/InfoMessageBox.vue'
import ButtonAppDownload from '@js/Components/Forms/Button/ButtonAppDownload.vue'
import Checkbox from '@js/Components/Forms/Checkbox/Checkbox.vue'
import { ref } from 'vue'

const iHaveInstalledTheApp = ref(false)

const onClickNext = () => {
  if (!iHaveInstalledTheApp.value) {
    return
  }
  router.get(getLocalizedUrl('my-rentbird.onboarding', { step: 10 }))
}

</script>
<template>
  <div>
    <div class="lg:flex justify-between items-center">
      <h1 class="text-[#151227] font-bold text-2xl lg:text-4xl">
        {{ $trans('StepDownloadApp.Title') }}
      </h1>
      <div class="lg:w-[20%]">
        <ProgressBar color="green" :progress="95" :show-percentage="true" />
      </div>
    </div>
    <MyRentbirdCard class="mt-[16px] lg:mt-[20px]" :can-expand-on-mobile="false" :default-is-expanded="true">
      <InfoMessageBox color="blue">
        <div>
          <h4 class="font-bold text-xl text-[#151227] mb-[4px]">
            {{ $trans('StepDownloadApp.BlueBoxTitle') }}
          </h4>
          <p class="text-[#151227]">
            {{ $trans('StepDownloadApp.BlueBoxContent') }}
          </p>
        </div>
      </InfoMessageBox>

      <div class="flex mt-[24px]">
        <ButtonAppDownload
          :is-android="true"
          class="mr-[8px]"
          :is-outline="true"
          href="https://play.google.com/store/apps/details?id=io.rentbird"
        />
        <ButtonAppDownload
          :is-android="false"
          :is-outline="true"
          href="https://apps.apple.com/us/app/rentbird-find-rental-houses/id6468817766"
        />
      </div>

      <Checkbox v-model="iHaveInstalledTheApp" class="mt-[24px]">
        {{ $trans("StepDownloadApp.IHaveInstalledTheApp") }}
      </Checkbox>

      <div class="lg:flex justify-end">
        <Button
          :disabled="!iHaveInstalledTheApp"
          tag="button"
          icon-right="arrow-right"
          :big="true"
          :full-width-on-mobile="true"
          class="mt-[20px] lg:mt-[32px]"
          @click="onClickNext"
        >
          {{ $trans('Common.Next') }}
        </Button>
      </div>
    </MyRentbirdCard>
  </div>
</template>
