import { trans } from '@js/App/Plugins/Translations'
import { useMainStore } from '@js/App/Stores/MainStore'
import { doPostRequest } from '@js/App/Api/Infra/ApiClient'
import { reloadPropsFromServer } from '@js/App/Api/Infra/ServerData'

export const REASON_FOUND_A_HOUSE_WITH_RENTBIRD = 'found_a_house_with_rentbird'

export const REASON_FOUND_A_HOUSE_TROUGH_AGENCY = 'found_a_house_trough_agency'
export const REASON_FOUND_A_HOUSE_TROUGH_WAITING_LIST = 'found_a_house_trough_waiting_lust'
export const REASON_FOUND_A_HOUSE_THROUGH_ANOTHER_CHANNEL = 'found_a_house_through_another_channel'
export const REASON_FOUND_STILL_SEARCHING_BUT_WANT_TO_CANCEL = 'dont_want_to_auto_renew'
export const REASON_OTHER = 'other_reason'

export const RATING_GOOD = 'good'
export const RATING_NEUTRAL = 'neutral'
export const RATING_BAD = 'bad'

export const STEP_REASON = 'reason'
export const STEP_RATING = 'rating'
export const STEP_CONGRATULATIONS_WITH_YOUR_NEW_HOME = 'congratz'
export const STEP_RENTBIRD_HOME_DEALS = 'home-deals'
export const STEP_RENTBIRD_HOME_DEALS_2 = 'home-deals-2'
export const STEP_RENTBIRD_HOME_DEALS_3 = 'home-deals-3'
export const STEP_RENTBIRD_HOME_DEALS_VERIFY_YOUR_DETAILS = 'home-deals-verify'
export const STEP_RENTBIRD_HOME_DEALS_CONFIRMED = 'home-deals-confirmed'
export const STEP_CONFIRM_CANCEL = 'confirm-cancel'
export const STEP_REASON_DETAILS = 'reason-details'

export const HOMEDEAL_ENERGY = 'Gas & Electricity'
export const HOMEDEAL_INTERNET = 'TV & Internet'
export const HOMEDEAL_WATER = 'Water'
export const HOMEDEAL_SIMONLY = 'Sim Only'
export const HOMEDEAL_INSURANCE = 'Insurances'

export const submitCancelSubscription = async (deleteAllSearchProfiles, userWantsToCancelWithFullRefund, lastPaymentIsAppleInAppPayment = false) => {
  const mainStore = useMainStore()
  mainStore.startLoading()

  try {
    if (userWantsToCancelWithFullRefund && !lastPaymentIsAppleInAppPayment) {
      return await doPostRequest('/api/user/request-refund')
    } else {
      return await doPostRequest('/api/offboarding/cancel-subscription', {
        delete_all_search_profiles: deleteAllSearchProfiles
      })
    }
  } finally {
    mainStore.stopLoading()
  }
}

export const submitHomedealApplication = async (contactInfo, selectedHomedeals) => {
  const mainStore = useMainStore()
  mainStore.startLoading()

  try {
    const response = await doPostRequest('/api/offboarding/homedeals', {
      postcode: contactInfo.postcode,
      housenumber: contactInfo.housenumber,
      phone: contactInfo.phone,
      email: contactInfo.email,
      selected_homedeals: selectedHomedeals
    })

    reloadPropsFromServer()
    return response
  } finally {
    mainStore.stopLoading()
  }
}

export const submitUserFeedback = async (rating, reason, message) => {
  const mainStore = useMainStore()
  mainStore.startLoading()

  try {
    const postData = {
      reason,
      message
    }
    if (rating) {
      postData.rating = rating
    }
    const response = await doPostRequest('/api/offboarding/feedback', postData)

    reloadPropsFromServer()
    return response
  } finally {
    mainStore.stopLoading()
  }
}

export const getStepAfterRating = (reason, rating, userHasAppliedForHomedealsAlready) => {
  if (rating === RATING_GOOD && reason === REASON_FOUND_STILL_SEARCHING_BUT_WANT_TO_CANCEL) {
    return STEP_CONFIRM_CANCEL
  }
  if (reason === REASON_OTHER || reason === REASON_FOUND_STILL_SEARCHING_BUT_WANT_TO_CANCEL) {
    return STEP_REASON_DETAILS
  }

  return STEP_CONFIRM_CANCEL
}

export const getHomedealServiceTitle = (value) => {
  if (value === HOMEDEAL_ENERGY) {
    return trans('HomeDealsSelectServices.EnergyTitle')
  }
  if (value === HOMEDEAL_INTERNET) {
    return trans('HomeDealsSelectServices.InternetTitle')
  }
  if (value === HOMEDEAL_WATER) {
    return trans('HomeDealsSelectServices.WaterTitle')
  }
  if (value === HOMEDEAL_SIMONLY) {
    return trans('HomeDealsSelectServices.SimOnlyTitle')
  }
  if (value === HOMEDEAL_INSURANCE) {
    return trans('HomeDealsSelectServices.InsuranceTitle')
  }
  return value
}
