<script setup>
import { useUser } from '@js/App/Api/User/User'
import { computed, ref } from 'vue'
import ProgressBar from '@js/Components/Forms/ProgressBar/ProgressBar.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import VerhuisgidsLink from '@js/Components/MyRentbird/Verhuisgids/Card/VerhuisgidsLink.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'

const { user } = useUser()
const completedSteps = computed(() => user.value.verhuisgids_completed_steps || [])

const isExpanded = ref(false)

const highestCompletedStep = computed(() => {
  let highest = 0
  for (const step of completedSteps.value) {
    if (step > highest) {
      highest = step
    }
  }
  return highest + 1
})
</script>
<template>
  <div
    class="relative bg-white rounded px-[16px] py-[16px] lg:px-[32px] lg:py-[32px]"
  >
    <!-- Title row -->
    <div class="flex justify-between">
      <h4
        class="text-lg lg:text-3xl font-medium hover:cursor-pointer hover:text-pink-500 transition-all"
        @click="isExpanded = !isExpanded"
      >
        {{ $trans('VerhuisgidsCard.Title') }}
      </h4>
      <div class="flex">
        <div class="w-[240px] mr-[24px] lg:block hidden ">
          <ProgressBar color="green" :progress="Math.round(completedSteps.length / 13 * 100)" :show-percentage="true" />
        </div>

        <Icon
          name="caret-down"
          icon-width="w-[28px] h-[28px]"
          class="hover:cursor-pointer hover:text-pink-500 transition-all"
          :class="{
            'rotate-180': isExpanded,
          }"
          @click="isExpanded = !isExpanded"
        />
      </div>
    </div>

    <div class="w-full lg:hidden mt-[8px]">
      <ProgressBar color="green" :progress="Math.round(completedSteps.length / 13 * 100)" :show-percentage="true" />
    </div>

    <!-- Body -->
    <p class="text-base lg:text-lg text-purple-900 mt-[12px]">
      {{ $trans('VerhuisgidsCard.Content') }}
    </p>

    <div v-show="isExpanded" class="pt-[16px]">
      <VerhuisgidsLink v-for="i in 13" :key="i" class="mb-[16px]" :step="i">
        {{ $trans(`VerhuisgidsCard.Link${i}`) }}
      </VerhuisgidsLink>

      <Button
        v-show="completedSteps.length < 13"
        :big="true"
        icon-right="arrow-right"
        tag="link"
        :href="getLocalizedUrl('my-rentbird.verhuisgids', { step: highestCompletedStep || 1})"
      >
        {{ $trans('VerhuisgidsCard.Continue') }}
      </Button>
    </div>
  </div>
</template>
