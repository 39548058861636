<script setup>
import { ref } from 'vue'
import TextInputWithSuggestionsDropdown
  from '@js/Components/Forms/TextInputWithSuggestionsDropdown/TextInputWithSuggestionsDropdown.vue'
import { getLocale } from '@js/App/Utils/CountryAndLocale'

const props = defineProps({
  modelValue: String,
  error: {
    type: String,
    default: ''
  }
})

const options = [
  { value: 'AL', label: { en: 'Albania', nl: 'Albanië' } },
  { value: 'AD', label: { en: 'Andorra', nl: 'Andorra' } },
  { value: 'AR', label: { en: 'Argentina', nl: 'Argentinië' } },
  { value: 'AM', label: { en: 'Armenia', nl: 'Armenië' } },
  { value: 'AU', label: { en: 'Australia', nl: 'Australië' } },
  { value: 'AT', label: { en: 'Austria', nl: 'Oostenrijk' } },
  { value: 'AZ', label: { en: 'Azerbaijan', nl: 'Azerbeidzjan' } },
  { value: 'BY', label: { en: 'Belarus', nl: 'Wit-Rusland' } },
  { value: 'BE', label: { en: 'Belgium', nl: 'België' } },
  { value: 'BA', label: { en: 'Bosnia And Herzegovina', nl: 'Bosnië en Herzegovina' } },
  { value: 'BG', label: { en: 'Bulgaria', nl: 'Bulgarije' } },
  { value: 'CA', label: { en: 'Canada', nl: 'Canada' } },
  { value: 'HR', label: { en: 'Croatia', nl: 'Kroatië' } },
  { value: 'CY', label: { en: 'Cyprus', nl: 'Cyprus' } },
  { value: 'CZ', label: { en: 'Czech Republic', nl: 'Tsjechië' } },
  { value: 'DK', label: { en: 'Denmark', nl: 'Denemarken' } },
  { value: 'EE', label: { en: 'Estonia', nl: 'Estland' } },
  { value: 'FI', label: { en: 'Finland', nl: 'Finland' } },
  { value: 'FR', label: { en: 'France', nl: 'Frankrijk' } },
  { value: 'DE', label: { en: 'Germany', nl: 'Duitsland' } },
  { value: 'GR', label: { en: 'Greece', nl: 'Griekenland' } },
  { value: 'HU', label: { en: 'Hungary', nl: 'Hongarije' } },
  { value: 'IS', label: { en: 'Iceland', nl: 'IJsland' } },
  { value: 'ID', label: { en: 'Indonesia', nl: 'Indonesië' } },
  { value: 'IE', label: { en: 'Ireland', nl: 'Ierland' } },
  { value: 'IT', label: { en: 'Italy', nl: 'Italië' } },
  { value: 'KZ', label: { en: 'Kazakhstan', nl: 'Kazachstan' } },
  { value: 'LV', label: { en: 'Latvia', nl: 'Letland' } },
  { value: 'LI', label: { en: 'Liechtenstein', nl: 'Liechtenstein' } },
  { value: 'LT', label: { en: 'Lithuania', nl: 'Litouwen' } },
  { value: 'LU', label: { en: 'Luxembourg', nl: 'Luxemburg' } },
  { value: 'MK', label: { en: 'Macedonia', nl: 'Macedonië' } },
  { value: 'MT', label: { en: 'Malta', nl: 'Malta' } },
  { value: 'MX', label: { en: 'Mexico', nl: 'Mexico' } },
  { value: 'MD', label: { en: 'Moldova', nl: 'Moldavië' } },
  { value: 'MC', label: { en: 'Monaco', nl: 'Monaco' } },
  { value: 'ME', label: { en: 'Montenegro', nl: 'Montenegro' } },
  { value: 'NL', label: { en: 'Netherlands', nl: 'Nederland' } },
  { value: 'NZ', label: { en: 'New Zealand', nl: 'Nieuw-Zeeland' } },
  { value: 'NO', label: { en: 'Norway', nl: 'Noorwegen' } },
  { value: 'PL', label: { en: 'Poland', nl: 'Polen' } },
  { value: 'PT', label: { en: 'Portugal', nl: 'Portugal' } },
  { value: 'RO', label: { en: 'Romania', nl: 'Roemenië' } },
  { value: 'RU', label: { en: 'Russian Federation', nl: 'Rusland' } },
  { value: 'SM', label: { en: 'San Marino', nl: 'San Marino' } },
  { value: 'RS', label: { en: 'Serbia', nl: 'Servië' } },
  { value: 'SK', label: { en: 'Slovakia', nl: 'Slowakije' } },
  { value: 'SI', label: { en: 'Slovenia', nl: 'Slovenië' } },
  { value: 'KR', label: { en: 'South Korea', nl: 'Zuid Korea' } },
  { value: 'ES', label: { en: 'Spain', nl: 'Spanje' } },
  { value: 'SE', label: { en: 'Sweden', nl: 'Zweden' } },
  { value: 'CH', label: { en: 'Switzerland', nl: 'Zwitserland' } },
  { value: 'ZA', label: { en: 'South Africa', nl: 'Zuid afrika' } },
  { value: 'US', label: { en: 'United States', nl: 'Verenigde Staten' } },
  { value: 'GB', label: { en: 'United Kingdom', nl: 'Verenigd Koninkrijk' } }
]

const searchQuery = ref(
  props.modelValue
    ? options.find(item => item.value === props.modelValue)?.label[getLocale()]
    : ''
)

const filteredOptions = ref([])

const getMatchingOptions = () => {
  return options.filter(
    (item) => {
      const q = searchQuery.value.toLowerCase()

      const countryLabels = [
        item.label.en.toLowerCase(),
        item.label.nl.toLowerCase(),
        item.value.toLowerCase()
      ]

      for (const label of countryLabels) {
        if (label.includes(q)) {
          return true
        }
      }

      return false
    }
  )
}

defineEmits(['update:modelValue'])
</script>
<template>
  <div>
    <label
      class="block font-medium text-base mb-1 text-purple-700"
    >
      {{ $trans('CountryOfResidenceSelect.Label') }}
    </label>
    <TextInputWithSuggestionsDropdown
      :error="error"
      :model-value="selectedCountry"
      :text-value="searchQuery"
      :should-scroll-into-view-on-mobile="false"
      :placeholder="$trans('CountryOfResidenceSelect.Placeholder')"
      :use-landing-page-styling="false"
      :options="filteredOptions.map(item => ({
        ...item,
        label: item.label[getLocale().toLowerCase()]
      }))"
      @update:model-value="newValue => {
        $emit('update:modelValue', newValue?.value)
      }"
      @update:clicked-option="newValue => {
        $emit('update:modelValue', newValue?.value)
        searchQuery = newValue?.label
        filteredOptions = []
      }"
      @update:text-value="newValue => {
        searchQuery = newValue
        filteredOptions = getMatchingOptions()
      }"
      @click:text-input="() => {
        searchQuery = ''
        filteredOptions = options
      }"
      @click:outside="() => {
        filteredOptions = []
      }"
    />
  </div>
</template>
