import { usePage } from '@inertiajs/vue3'
import { isProduction, isSSR } from '@js/App/Utils/Environment'
import { doPostRequest } from '@js/App/Api/Infra/ApiClient'
import { COUNTRY_NL, getCountry, getLocale, LOCALE_NL } from '@js/App/Utils/CountryAndLocale'

/**
 * This is a custom translation function that is adds as a Vue mixin.
 * This can be called from all Vue Components using $trans()
 * @param key The key
 * @param replace A value to replace
 * @param locale The locale to use (defaults to the current locale defined on the window object)
 * @returns {string} The translated string
 */
export const trans = (key, replace = {}, locale = null) => {
  const inertiaPageObject = usePage()
  if (!locale) { locale = getLocale() }

  let translation
  let translationFoundInJson = false

  const translationsObject = inertiaPageObject.props.translations

  if (translationsObject[locale]?.[key]) {
    translation = translationsObject[locale][key]
    translationFoundInJson = true
  } else {
    translation = key
  }

  if (replace) {
    for (const [key, value] of Object.entries(replace)) {
      translation = translation.replaceAll(':' + key, value)
    }
  }

  // Log if we can't find the translation for non-NL strings.
  if (getCountry() === COUNTRY_NL && locale !== LOCALE_NL && !translationFoundInJson) {
    console.log(`Frontend: unable to find translation for ${key}`)

    // We only want to notify about missing keys on production.
    // Also we don't want to notify of missing keys in the SSR side, as that would increaste the load time
    if (isProduction() && !isSSR()) {
      doPostRequest('/api/translations/missing-translation', {
        key
      })
    }
    // If not found recurse to find dutch translation.
    return trans(key, replace, 'nl')
  }

  return translation
}

// Here we provide the global $trans function to be used in Vue templates.
export default {
  install: (app, options) => {
    // This globally injects a trans() method for translations
    app.config.globalProperties.$trans = (key, replace, locale) => {
      return trans(key, replace, locale)
    }
  }
}
