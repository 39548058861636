<script setup>
import InfoMessageBox from '@js/Components/InfoMessageBox/InfoMessageBox.vue'
import HouseImage from '@images/icons/house.png'
import { useMissedMatchesOfLoggedInUsersLastSearchProfile } from '@js/App/Api/MissedMatches'
import BasicHouseCardAlternative from '@js/Components/HouseCards/BasicHouseCard/BasicHouseCardAlternative.vue'
import { ref } from 'vue'
import Icon from '@js/Components/Icon/Icon.vue'
import SearchProfileCard from '@js/Components/SearchProfileCard/SearchProfileCard.vue'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'

defineProps({
  searchProfile: {
    type: Object,
    default: () => ({})
  }
})

const { missedMatches, isLoading, houseToShow } = useMissedMatchesOfLoggedInUsersLastSearchProfile()

const gotErrorFromApi = ref(false)

const isExpanded = ref(false)
const { isMobile } = useIsMobile()

</script>
<template>
  <div v-show="!gotErrorFromApi" class="bg-white px-4 lg:px-6 py-4 lg:py-6 rounded-md">
    <div
      class="text-pink-900 flex justify-between hover:text-pink-500 lg:hover:text-pink-900 transition-colors"
      @click="() => {
        if (!isMobile) { return }
        isExpanded = !isExpanded
      }"
    >
      <h4 class="font-medium text-lg mb-2 lg:text-2xl">
        {{ $trans('UpgradePage.MatchesTitle') }}
      </h4>

      <Icon
        v-if="isMobile"
        name="caret-down"
        icon-width="w-[24px] h-[24px]"
        :class="{
          'rotate-180 relative top-[-12px]': isExpanded,
          'transition-transform': true
        }"
      />
    </div>
    <SearchProfileCard v-if="searchProfile && (isExpanded || !isMobile)" :search-profile="searchProfile" />
    <InfoMessageBox
      :image="HouseImage"
      color="blue"
      class="px-3 lg:px-6"
    >
      <div
        v-html="$trans('MissedMatchesMessageBox.Text', {
          matches: isLoading ? '..' : missedMatches
        })"
      />
    </InfoMessageBox>

    <div v-show="isExpanded || !isMobile">
      <p class="text-base text-purple-700 mb-0 mt-3 lg:text-lg">
        {{ $trans('MissedMatchesMessageBox.MostRecentHouse') }}
      </p>
      <BasicHouseCardAlternative
        v-if="!isLoading && !gotErrorFromApi"
        class="mt-2"
        :city="houseToShow?.city"
        :rent="houseToShow?.rent"
        :area="houseToShow?.area"
        :bedrooms="houseToShow?.bedrooms"
        :image="houseToShow?.image || houseToShow?.image_url"
        :rental-price="houseToShow?.rent"
        :name="houseToShow?.name"
        :rental-agent="houseToShow?.rental_agent_name"
        :found-at-formatted="$trans('Common.Today')"
      />
      <div v-show="isLoading" class="w-full h-[224px]" />
    </div>
  </div>
</template>
