<script setup>
import NavMenu from '@js/Components/NavMenu/NavMenu.vue'
import TrustpilotSmall from '@js/Components/Trustpilot/TrustpilotSmall.vue'
import RentbirdLogo from '@js/Components/Logos/RentbirdLogo/RentbirdLogo.vue'
import Container from '@js/Components/Container/Container.vue'
import CityMap from '@js/Components/SearchProfileWizards/SearchProfileWizard/CityMap.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { storeToRefs } from 'pinia'
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import { onMounted } from 'vue'
import { useUser } from '@js/App/Api/User/User'
import MobileHeroBar from '@js/Components/Marketing/Heros/MobileHeroBar/MobileHeroBar.vue'

defineProps({
  showNavigation: {
    type: Boolean,
    default: true
  }
})

const { user } = useUser()
const searchProfileStore = useSearchProfileStore()
const { searchProfile, allNeighbourhoodsOfCity } = storeToRefs(searchProfileStore)

const setSelectedNeighbourhoods = () => {
  if ((allNeighbourhoodsOfCity.value || []).length <= 0) {
    searchProfile.value.neighbourhoods = []
  }
  searchProfile.value.neighbourhoods = [
    ...new Set(
      allNeighbourhoodsOfCity.value.map(item => item.ids).flat()
    )
  ]
}

onMounted(() => {
  setTimeout(() => {
    setSelectedNeighbourhoods()
  }, 100)
})
</script>
<template>
  <div class="bg-purple-700 text-white mb-[140px]">
    <Container class="mb-[-140px] lg:mb-[-110px] pt-4 lg:pt-8 lg:pb-16">
      <NavMenu v-if="showNavigation" />
      <div v-else class="flex justify-center mb-8">
        <RentbirdLogo :href="false" />
      </div>

      <div
        class="block lg:flex pt-8"
      >
        <div class="lg:pr-8">
          <h6 class="mb-1 lg:mb-3 text-base lg:text-2xl">
            <slot name="subtitle" />
          </h6>
          <h1
            class="text-3xl font-medium mb-4 lg:text-4xl xl:text-6xl lg:mb-6"
          >
            <slot />
          </h1>

          <div class="lg:flex items-end">
            <Button
              v-if="user.hasActiveSubscription"
              tag="link"
              :href="$getLocalizedUrl('my-rentbird.match-history')"
              :big="true"
              :full-width-on-mobile="true"
              icon-right="arrow-right"
              class="lg:mr-2 mb-2 lg:mb-0"
            >
              <span class="whitespace-nowrap">{{ $trans('HomePageLoggedIn.MatchHistoryButton') }}</span>
            </Button>
            <Button
              v-if="user.hasActiveSubscription"
              tag="link"
              :href="$getLocalizedUrl('my-rentbird.create-search-profile')"
              :big="true"
              :outline="true"
              :full-width-on-mobile="true"
              icon-right="plus"
            >
              <span class="whitespace-nowrap">{{ $trans('HomePageLoggedIn.NewSearchProfileButton') }}</span>
            </Button>
            <Button
              v-if="!user.hasActiveSubscription"
              tag="link"
              :href="$getLocalizedUrl('upgrade')"
              :big="true"
              :full-width-on-mobile="true"
              icon-right="unlocked"
              class="lg:mr-2 mb-2 lg:mb-0"
            >
              <span class="whitespace-nowrap">{{ $trans('HomePageLoggedIn.ChooseSubscription') }}</span>
            </Button>
            <Button
              v-if="!user.hasActiveSubscription"
              tag="link"
              :href="$getLocalizedUrl('pricing')"
              :big="true"
              :outline="true"
              :full-width-on-mobile="true"
              icon-right="arrow-right"
            >
              <span class="whitespace-nowrap">{{ $trans('HomePageLoggedIn.ViewPrices') }}</span>
            </Button>
          </div>

          <div class="pt-6 flex justify-start lg:justify-start w-full">
            <TrustpilotSmall class="justify-center" :show-number-of-reviews-on-mobile="true" />
          </div>
        </div>
        <div class="block relative z-20 pt-4 lg:pt-0 lg:min-w-[300px] xl:min-w-[504px] h-[344px] lg:h-[510px]">
          <CityMap class="h-full" :use-landing-page-styling="true" />
        </div>
      </div>
    </Container>
    <MobileHeroBar />
  </div>
</template>
