<script setup>
import MyRentbirdLayout from '@js/Components/MyRentbird/MyRentbirdLayout/MyRentbirdLayout.vue'
import MyRentbirdBackButton from '@js/Components/MyRentbird/MyRentbirdBackButton/MyRentbirdBackButton.vue'
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import UserPaymentStatusLabel from '@js/Components/MyRentbird/UserPaymentStatusLabel/UserPaymentStatusLabel.vue'
import PricingCardAlternative from '@js/Components/PricingCard/PricingCardAlternative/PricingCardAlternative.vue'
import ThreeSmallUsps from '@js/Components/Marketing/ThreeSmallUsps/ThreeSmallUsps.vue'
import PaymentsTable from '@js/Components/MyRentbird/PaymentsTable/PaymentsTable.vue'
import { usePage } from '@inertiajs/vue3'
import { useUser } from '@js/App/Api/User/User'
import PricingCardCurrentSubscription
  from '@js/Components/PricingCard/PricingCardCurrentSubscription/PricingCardCurrentSubscription.vue'
import { computed } from 'vue'
import NextRenewalCard from '@js/Components/MyRentbird/NextRenewalCard/NextRenewalCard.vue'
import InfoMessageBox from '@js/Components/InfoMessageBox/InfoMessageBox.vue'
import ThumbsUpImage from '@images/icons/thumbs-up.png'

const page = usePage()
const payments = page.props.payments

const { user, userActivePayment } = useUser()

const hasDifferentRenewal = computed(() => {
  if (!userActivePayment.value) { return false }
  return userActivePayment.value.plan !== userActivePayment.value.renewal_plan
})
</script>
<template>
  <MyRentbirdLayout>
    <MyRentbirdBackButton />

    <MyRentbirdCard
      :can-expand-on-mobile="false"
      class="mt-[16px] lg:mt-[24px]"
    >
      <template #title-desktop-right>
        <UserPaymentStatusLabel
          :free-message="$trans('SubscriptionPage.Free')"
          :paid-message="$trans('SubscriptionPage.Active')"
        />
      </template>
      <template #title-mobile-right>
        <UserPaymentStatusLabel
          :free-message="$trans('SubscriptionPage.Free')"
          :paid-message="$trans('SubscriptionPage.Active')"
        />
      </template>
      <template #title>
        {{ $trans('SubscriptionPage.Title') }}
      </template>

      <InfoMessageBox v-if="user.hasRightToAFullRefund" color="bg-[#C9EFE3]" class="mb-[16px] lg:mb-[24px]" :image="ThumbsUpImage">
        <div
          class="text-base lg:text-lg text-black"
          v-html="$trans('SubscriptionPage.RightToAFullRefundMessage', {
            days: 14 - user.daysSinceFirstPayment,
            textdays: (14 - user.daysSinceFirstPayment === 1 ? $trans('Common.Day') : $trans('Common.Days')).toLowerCase(),
            url: $getLocalizedUrl('my-rentbird.offboarding', {}, { cancelWithFullRefund: true})
          })"
        />
      </InfoMessageBox>

      <div class="lg:flex">
        <PricingCardAlternative v-if="!user.hasActiveSubscription" class="mt-[8px] mb-[24px] lg:mr-[12px]" />
        <PricingCardCurrentSubscription v-if="user.hasActiveSubscription" class="mt-[8px] mb-[24px] lg:mr-[12px]" />

        <div class="lg:pl-[12px] lg:ml-[32px] lg:mr-[32px] lg:mt-[24px]">
          <h4 class="hidden lg:block text-2xl text-purple-900 font-medium mb-[12px]">
            {{ $trans('SubscriptionPage.AdvantagesTitle') }}
          </h4>
          <ThreeSmallUsps
            :large-text-on-desktop="true"
          />
        </div>
      </div>

      <div v-if="false && hasDifferentRenewal && user.enabledAutoRenew" class="mb-[32px] mt-[12px] lg:mt-[8px]">
        <NextRenewalCard />
      </div>

      <PaymentsTable v-if="payments.length > 0" class="mt-[24px]" />
    </MyRentbirdCard>
  </MyRentbirdLayout>
</template>
<style lang="scss">
.MyRentbird-SubscriptionPage {
  background: rgb(234,234,235);
}
</style>
