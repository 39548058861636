<script setup>
import RentbirdLogo from '@js/Components/Logos/RentbirdLogo/RentbirdLogo.vue'
import SectionDivider from '@js/Components/Marketing/SectionDivider/SectionDivider.vue'
import Container from '@js/Components/Container/Container.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import TrustpilotLogo from '@images/trustpilot-logo-white.svg'
import Image from '@js/Components/Image/Image.vue'
import TrustpilotStars from '@js/Components/Trustpilot/TrustpilotStars.vue'
import { usePage } from '@inertiajs/vue3'
import PricingCardOneDollarTrialUS
  from '@js/Components/PricingCard/PricingCardOneDollarTrialUS/PricingCardOneDollarTrialUS.vue'
import PricingCardAlternative from '@js/Components/PricingCard/PricingCardAlternative/PricingCardAlternative.vue'
import { hasNormalSubscriptionPricesAvailable, onlyHasTrialPriceAvailable } from '@js/App/Prices'

defineProps({
  sectionDividerColor: {
    type: String,
    default: 'white'
  }
})

const page = usePage()

</script>
<template>
  <div class="bg-purple-700">
    <SectionDivider :color="sectionDividerColor" :reversed="true" />
    <Container>
      <div class="text-white pb-12 lg:pb-20 pt-12 lg:pt-10">
        <div class="flex justify-center mb-4 lg:mb-[40px]">
          <RentbirdLogo :href="false" size="md" />
        </div>

        <div class="xl:flex xl:justify-center xl:items-center">
          <div class="mb-8 lg:block w-full lg:w-[620px] justify-center">
            <h4
              class="text-3xl text-[28px] lg:text-5xl font-medium  lg:text-left xl:w-[600px] mb-6 lg:mb-[28px]"
              v-html="$trans(onlyHasTrialPriceAvailable() ? 'CallToActionOneDollarTrialSection.TrialTitle' : 'CallToActionOneDollarTrialSection.Title')"
            />

            <div class="w-full max-w-[800px]">
              <div class="flex mt-4 lg:mt-[16px]">
                <Icon name="check-circle" class="mr-2 lg:mr-3 text-success" icon-width="lg:w-[24px] lg:h-[24px]" />
                <p class="text-base relative top-[-3px] lg:top-0 lg:text-xl">
                  {{ $trans('CallToActionOneDollarTrialSection.Item1') }}
                </p>
              </div>

              <div class="flex mt-4 lg:mt-[16px]">
                <Icon name="check-circle" class="mr-2 lg:mr-3 text-success" icon-width="lg:w-[24px] lg:h-[24px]" />
                <p class="text-base relative top-[-3px] lg:top-0 lg:text-xl">
                  {{ $trans('CallToActionOneDollarTrialSection.Item2') }}
                </p>
              </div>

              <div class="flex mt-4 lg:mt-[16px]">
                <Icon name="check-circle" class="mr-2 lg:mr-3 text-success" icon-width="lg:w-[24px] lg:h-[24px]" />
                <p class="text-base relative top-[-3px] lg:top-0 lg:text-xl">
                  {{ $trans('CallToActionOneDollarTrialSection.Item3') }}
                </p>
              </div>

              <div class="flex mt-3 lg:mt-5">
                <Icon name="check-circle" class="mr-2 lg:mr-3 text-success" icon-width="lg:w-[24px] lg:h-[24px]" />
                <p class="text-base relative top-[-3px] lg:top-0 leading-9 lg:text-xl">
                  <span class="pr-2">{{ $trans('CallToActionOneDollarTrialSection.Item4') }}</span>
                  <TrustpilotStars class="w-[132px] inline relative top-[-2px]" :rating="page.props.trustpilot.rating" />
                  <span class="font-medium pl-2">{{ $formatNumber(page.props.trustpilot.rating, 1) }}</span>
                  <span class="pl-2 pr-2">{{ $trans('Common.On') }}</span>
                  <Image class="inline relative top-[-2px]" :src="TrustpilotLogo" />
                </p>
              </div>
            </div>
          </div>
          <div class="w-full lg:w-[452px]">
            <PricingCardOneDollarTrialUS v-if="onlyHasTrialPriceAvailable()" />
            <PricingCardAlternative v-if="hasNormalSubscriptionPricesAvailable()" />
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>
