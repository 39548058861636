<template>
  <div class="relative">
    <div class="__PrivateStripeElementLoader" style="box-sizing: border-box !important; position: absolute !important; inset: 4px 0px 0px !important; opacity: 1; width: calc(100% + 8px) !important; transition: opacity 0.3s ease 0s !important; overflow: hidden !important; z-index: 1 !important; margin: -4px !important; pointer-events: none !important; height: 253.863px !important;">
      <div style="margin: 4px !important; position: relative !important;">
        <div style="">
          <div style="display: flex !important; column-gap: calc(8px) !important;">
            <div style="box-sizing: border-box !important; border-radius: 4px !important; height: 62.8938px !important; background-color: rgb(255, 255, 255) !important; position: relative !important; overflow: hidden !important; box-shadow: none !important; display: flex !important; flex-grow: 1 !important; flex-basis: 0px !important; flex-direction: column !important; align-items: flex-start !important; padding: 12px 16px !important; border-width: 1px !important; border-color: rgb(202, 202, 202) !important; border-style: solid !important;">
              <div style="box-sizing: border-box !important; width: 22px !important; height: 16.8px !important; background-color: rgba(0, 0, 0, 0.04) !important; border-radius: 4px !important; position: relative !important; overflow: hidden !important; will-change: transform !important;">
                <div style="position: absolute !important; top: 0px !important; left: 0px !important; height: 100% !important; width: 50% !important; transform: translateX(200%); will-change: transform !important; background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0)) !important; transition: transform 3000ms ease 0s;" />
              </div><div style="box-sizing: border-box !important; width: 40% !important; height: 18.3906px !important; margin-top: 4px !important; background-color: rgba(0, 0, 0, 0.04) !important; border-radius: 4px !important; position: relative !important; overflow: hidden !important; will-change: transform !important;">
                <div style="position: absolute !important; top: 0px !important; left: 0px !important; height: 100% !important; width: 50% !important; transform: translateX(200%); will-change: transform !important; background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0)) !important; transition: transform 3000ms ease 0s;" />
              </div>
            </div><div style="box-sizing: border-box !important; border-radius: 4px !important; height: 62.8938px !important; background-color: rgb(255, 255, 255) !important; position: relative !important; overflow: hidden !important; box-shadow: none !important; display: flex !important; flex-grow: 1 !important; flex-basis: 0px !important; flex-direction: column !important; align-items: flex-start !important; padding: 12px 16px !important; border-width: 1px !important; border-color: rgb(202, 202, 202) !important; border-style: solid !important;">
              <div style="box-sizing: border-box !important; width: 22px !important; height: 16.8px !important; background-color: rgba(0, 0, 0, 0.04) !important; border-radius: 4px !important; position: relative !important; overflow: hidden !important; will-change: transform !important;">
                <div style="position: absolute !important; top: 0px !important; left: 0px !important; height: 100% !important; width: 50% !important; transform: translateX(200%); will-change: transform !important; background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0)) !important; transition: transform 3000ms ease 0s;" />
              </div><div style="box-sizing: border-box !important; width: 40% !important; height: 18.3906px !important; margin-top: 4px !important; background-color: rgba(0, 0, 0, 0.04) !important; border-radius: 4px !important; position: relative !important; overflow: hidden !important; will-change: transform !important;">
                <div style="position: absolute !important; top: 0px !important; left: 0px !important; height: 100% !important; width: 50% !important; transform: translateX(200%); will-change: transform !important; background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0)) !important; transition: transform 3000ms ease 0s;" />
              </div>
            </div>
          </div>
        </div><div style="margin-top: 12px !important;">
          <div style="margin-bottom: 12px !important;">
            <div style="">
              <div style="height: 12.8204px !important; background-color: rgba(0, 0, 0, 0.04) !important; position: relative !important; overflow: hidden !important; will-change: transform !important; border-radius: 4px !important; width: 63.7657px !important; box-sizing: border-box !important; margin-bottom: 8.27345px !important; box-shadow: none !important;">
                <div style="position: absolute !important; top: 0px !important; left: 0px !important; height: 100% !important; width: 50% !important; transform: translateX(200%); will-change: transform !important; background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0)) !important; transition: transform 3000ms ease 0s;" />
              </div><div style="height: 52.3906px !important; padding: 18.1367px !important; box-sizing: border-box !important; background-color: rgb(255, 255, 255) !important; position: relative !important; overflow: hidden !important; border-radius: 4px !important; box-shadow: none !important; border-width: 1px !important; border-color: rgb(209, 209, 209) !important; border-style: solid !important;">
                <div style="height: 12.8204px !important; background-color: rgba(0, 0, 0, 0.04) !important; position: relative !important; overflow: hidden !important; will-change: transform !important; border-radius: 4px !important; width: 63.7657px !important; box-sizing: border-box !important;">
                  <div style="position: absolute !important; top: 0px !important; left: 0px !important; height: 100% !important; width: 50% !important; transform: translateX(200%); will-change: transform !important; background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0)) !important; transition: transform 3000ms ease 0s;" />
                </div>
              </div>
            </div>
          </div><div style="display: flex !important; margin-bottom: 12px !important; margin-left: -12px !important;">
            <div style="box-sizing: border-box !important; width: 50% !important; padding-left: 12px !important;">
              <div style="">
                <div style="height: 12.8204px !important; background-color: rgba(0, 0, 0, 0.04) !important; position: relative !important; overflow: hidden !important; will-change: transform !important; border-radius: 4px !important; width: 34.7813px !important; box-sizing: border-box !important; margin-bottom: 8.27345px !important; box-shadow: none !important;">
                  <div style="position: absolute !important; top: 0px !important; left: 0px !important; height: 100% !important; width: 50% !important; transform: translateX(200%); will-change: transform !important; background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0)) !important; transition: transform 3000ms ease 0s;" />
                </div><div style="height: 52.3906px !important; padding: 18.1367px !important; box-sizing: border-box !important; background-color: rgb(255, 255, 255) !important; position: relative !important; overflow: hidden !important; border-radius: 4px !important; box-shadow: none !important; border-width: 1px !important; border-color: rgb(209, 209, 209) !important; border-style: solid !important;">
                  <div style="height: 12.8204px !important; background-color: rgba(0, 0, 0, 0.04) !important; position: relative !important; overflow: hidden !important; will-change: transform !important; border-radius: 4px !important; width: 34.7813px !important; box-sizing: border-box !important;">
                    <div style="position: absolute !important; top: 0px !important; left: 0px !important; height: 100% !important; width: 50% !important; transform: translateX(200%); will-change: transform !important; background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0)) !important; transition: transform 3000ms ease 0s;" />
                  </div>
                </div>
              </div>
            </div><div style="box-sizing: border-box !important; width: 50% !important; padding-left: 12px !important;">
              <div style="">
                <div style="height: 12.8204px !important; background-color: rgba(0, 0, 0, 0.04) !important; position: relative !important; overflow: hidden !important; will-change: transform !important; border-radius: 4px !important; width: 23.1875px !important; box-sizing: border-box !important; margin-bottom: 8.27345px !important; box-shadow: none !important;">
                  <div style="position: absolute !important; top: 0px !important; left: 0px !important; height: 100% !important; width: 50% !important; transform: translateX(200%); will-change: transform !important; background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0)) !important; transition: transform 3000ms ease 0s;" />
                </div><div style="height: 52.3906px !important; padding: 18.1367px !important; box-sizing: border-box !important; background-color: rgb(255, 255, 255) !important; position: relative !important; overflow: hidden !important; border-radius: 4px !important; box-shadow: none !important; border-width: 1px !important; border-color: rgb(209, 209, 209) !important; border-style: solid !important;">
                  <div style="height: 12.8204px !important; background-color: rgba(0, 0, 0, 0.04) !important; position: relative !important; overflow: hidden !important; will-change: transform !important; border-radius: 4px !important; width: 23.1875px !important; box-sizing: border-box !important;">
                    <div style="position: absolute !important; top: 0px !important; left: 0px !important; height: 100% !important; width: 50% !important; transform: translateX(200%); will-change: transform !important; background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0)) !important; transition: transform 3000ms ease 0s;" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
