export const MAPBOX_SRC = 'https://api.mapbox.com/mapbox-gl-js/v2.14.1/mapbox-gl.js'
export const MAPBOX_CSS = 'https://api.mapbox.com/mapbox-gl-js/v2.14.1/mapbox-gl.css'
export const STRIPE_SRC = 'https://js.stripe.com/v3/'

export const addTrackingScriptsIfProduction = () => {
  // Wait 100ms before loading the tracking scripts that take up all the CPU. This way we are sure that Rentbird has fully loaded and is ready.
  setTimeout(() => {
    console.log('[DEBUG] Adding tracking scripts')
    addScriptSrcToPage(STRIPE_SRC)
  }, 100)
}

export const addScriptToPage = (scriptContentAsString) => {
  console.log('[DEBUG] Adding script to page')
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.innerHTML = scriptContentAsString

  document.head.appendChild(script)
}

export const addScriptSrcToPage = (scriptSrc, isModule = false, tags = {}) => {
  console.log(`[DEBUG] Adding script src to page: ${scriptSrc}`)
  const script = document.createElement('script')
  script.src = scriptSrc
  if (isModule) {
    script.type = 'module'
  }
  for (const key of Object.keys(tags)) {
    script[key] = tags[key]
  }
  document.head.appendChild(script)
}

export const addStyleSrcToPage = (styleSrc) => {
  console.log(`[DEBUG] Adding style src to page: ${styleSrc}`)
  const style = document.createElement('link')
  style.setAttribute('href', styleSrc)
  style.setAttribute('rel', 'stylesheet')
  document.head.appendChild(style)
}
