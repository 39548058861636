<script setup>
import TextInput from '@js/Components/Forms/TextInput/TextInput.vue'
import ImageCheckEmoji from '@images/icons/check-emoji.png'
import { storeToRefs } from 'pinia'
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import CitySelectUsingStore from '@js/Components/Forms/CitySelect/CitySelectUsingStore.vue'
import DistanceSelect from '@js/Components/Forms/DistanceSelect/DistanceSelect.vue'
import { trans } from '@js/App/Plugins/Translations'
import Select from '@js/Components/Forms/Select/Select.vue'
import { watch } from 'vue'
import InfoMessageBox from '@js/Components/InfoMessageBox/InfoMessageBox.vue'

defineProps({
  errors: {
    type: Object,
    default: () => ({})
  }
})

const { searchProfile } = storeToRefs(useSearchProfileStore())

watch(() => searchProfile.value.city, () => {
  searchProfile.value.locationSelector = 'distance'
})

</script>
<template>
  <div>
    <TextInput
      v-model="searchProfile.rentbirdPlusEmail"
      class="mb-[16px]"
      placeholder="birdie@email.com"
      :error="errors.email"
    >
      <template #label>
        {{ $trans('RentbirdPlusEmailInput.Label') }}
      </template>
    </TextInput>

    <CitySelectUsingStore :error="errors.city" :show-label="true" :black="true" />

    <DistanceSelect
      class="mt-4"
      :error="errors.distance"
      :show-switch-to-neighbourhoods-if-relevant="false"
    />

    <InfoMessageBox
      color="bg-[#E2FFF5]"
      class="text-[#00B67A] mt-[8px]"
      :image="ImageCheckEmoji"
    >
      <span v-html="$trans('StepCityAndNeighbourhood.ExtraWishesAboutLocation')" />
    </InfoMessageBox>

    <Select
      v-model="searchProfile.rentbirdPlusSearchHomeFrom"
      class="mt-[16px]"
      :error="errors.rentbird_plus_search_home_from"
      :options="[
        {
          value: 'now',
          label: trans('RentbirdPlusSelect.Now')
        },
        {
          value: 'within_one_month',
          label: trans('RentbirdPlusSelect.1Month')
        },{
          value: 'within_two_months',
          label: trans('RentbirdPlusSelect.2Months')
        },{
          value: 'within_three_months',
          label: trans('RentbirdPlusSelect.3Months')
        },
        {
          value: 'more_than_three_months',
          label: trans('RentbirdPlusSelect.MoreThan3Months')
        },
        {
          value: 'doesnt_matter',
          label: trans('RentbirdPlusSelect.DoesntMatter')
        }
      ]"
    >
      <template #label>
        {{ $trans('StepCityAndNeighbourhood.SearchHomeFromLabel') }}
      </template>
    </Select>
  </div>
</template>
