<!--THIS IS SHOWN WHEN A USER IS ALREADY LOGGED IN AND ABOUT TO LOGIN INTO THE APP-->
<!--MOBILE USERS WHEN NOT LOGGED IN USE THE /LoginPage.vue COMPONENT-->
<script setup>

import Container from '@js/Components/Container/Container.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import BasicPageHeader from '@js/Components/Marketing/BasicPageHeader/BasicPageHeader.vue'
import { onBeforeMount } from 'vue'
import { isSSR } from '@js/App/Utils/Environment'
import ButtonAppDownload from '@js/Components/Forms/Button/ButtonAppDownload.vue'

const props = defineProps({
  authUrl: String,
  firstname: String
})

onBeforeMount(() => {
  if (isSSR()) { return }
  window.addEventListener(
    'CookiebotOnDialogInit',
    function (e) {
      if (window.Cookiebot.consent.stamp === 0 && this.top.location !== this.location) {
        window.Cookiebot.submitCustomConsent(!1, !1, !1)
        window.addEventListener(
          'CookiebotOnLoad',
          function (e) {
            window.Cookiebot.deleteConsentCookie()
          },
          false
        )
      }
    },
    false
  )
})
</script>

<template>
  <div class="bg-purple-700 h-screen">
    <BasicPageHeader :show-navigation="false" :show-section-divider="false" />
    <div class="overflow-clip relative">
      <Container class="relative pt-8 text-white">
        <h1 class="text-4xl font-bold leading-snug">
          {{ $trans('MobileLogin.Title.start') }} <span class="p-2 bg-blue-400 rounded-md">{{ $trans('MobileLogin.Title.highlighted') }}</span>
        </h1>
        <p class="my-4 text-base" v-html="$trans('MobileLogin.Body', { name: firstname })" />
        <div class="flex space-x-4">
          <ButtonAppDownload
            :is-android="false"
            :is-outline="true"
            href="https://apps.apple.com/us/app/rentbird-find-rental-houses/id6468817766"
            :is-black="false"
          />

          <ButtonAppDownload
            :is-android="true"
            :is-outline="true"
            href="https://play.google.com/store/apps/details?id=io.rentbird&hl=en"
            :is-black="false"
          />
        </div>
        <Button
          :big="true"
          :full-width-on-mobile="true"
          :full-width-on-desktop="false"
          icon-right="arrow-right"
          :href="authUrl"
          class="my-4 mt-8"
          tag="a"
        >
          {{ $trans('MobileLogin.LoginButton') }}
        </Button>
      </Container>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
