<script setup>
import B2BLayout from '@js/Components/B2B/B2BLayout.vue'
import { usePage, router, Link } from '@inertiajs/vue3'
import Button from '@js/Components/Forms/Button/Button.vue'
import { useB2BOrganization } from '@js/App/Api/B2B/B2B'
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import { computed, ref, watch } from 'vue'
import { getQueryParameter } from '@js/App/Utils/ReadQueryParams'
import DataTable from '@js/Components/Tables/DataTable.vue'
import { ziggyRoute } from '@js/App/Utils/ZiggyUrls'

const page = usePage()

const users = computed(() => (page.props.users))

const { organization } = useB2BOrganization()

const searchQuery = ref(getQueryParameter('search_query', ''))
const sortBy = ref(getQueryParameter('sort_by', 'created_at'))
const sortDirection = ref(getQueryParameter('sort_direction', 'desc'))

const refetchTableWithSearchAndSortParameters = () => {
  let url = `/b2b/${organization.value}/`
  url += `?search_query=${encodeURIComponent(searchQuery.value)}`
  url += `&sort_by=${encodeURIComponent(sortBy.value)}`
  url += `&sort_direction=${sortDirection.value}`

  router.visit(url, {
    preserveState: true,
    preserveScroll: true
  })
}

watch(searchQuery, () => {
  const oldValue = searchQuery.value
  setTimeout(() => {
    if (oldValue !== searchQuery.value) { return }

    refetchTableWithSearchAndSortParameters()
  }, 500)
})
watch(sortBy, refetchTableWithSearchAndSortParameters)
watch(sortDirection, refetchTableWithSearchAndSortParameters)

</script>
<template>
  <B2BLayout
    :bread-crumbs="[
      {
        label: 'Users',
        url: ziggyRoute('b2b.home', { organization: organization})
      },
    ]"
  >
    <template #title>
      <div class="flex items-center">
        <Icon name="user" icon-width="w-[40px] mr-2 relative top-[-2px]" />
        <span>Users</span>
      </div>
    </template>
    <div class="flex justify-between">
      <Button
        tag="link"
        :href="ziggyRoute('b2b.user.register', { organization: organization })"
        :big="true"
        icon-left="plus"
      >
        Create new user
      </Button>
    </div>

    <MyRentbirdCard class="mt-6" :can-expand-on-mobile="false">
      <DataTable
        :search-query="searchQuery"
        :sort-by="sortBy"
        :sort-direction="sortDirection"
        :pagination="users"
        :headers="[
          {
            label: 'Name',
            value: 'name',
          },
          {
            label: 'Created at',
            value: 'created_at',
          },
          {
            label: 'Status',
            value: 'status',
            isSortable: false,
          },
          {
            label: 'Email',
            value: 'email',
          },
          {
            label: 'Expected matches per week',
            value: 'expected_matches_per_week',
          },
        ]"
        @update:search-query="newSearchQuery => { searchQuery = newSearchQuery }"
        @update:sort-by="newSortBy => { sortBy = newSortBy }"
        @update:sort-direction="newSortDirection => { sortDirection = newSortDirection }"
      >
        <template
          v-for="item of users.data"
          :key="item?.random_id"
        >
          <tr class="row">
            <td>
              <Link
                class="text-blue-base hover:cursor-pointer hover:underline"
                :href="ziggyRoute('b2b.user.details', { organization: organization, user: item.random_id})"
              >
                {{ item.firstname }} {{ item.lastname }}
              </Link>
            </td>
            <td>
              {{ item.created_at_formatted }}
            </td>
            <td>
              <div class="flex items-center">
                <Icon class="pr-2 relative top-[-1px]" :name="item.is_active ? 'dot-green' : 'dot-red'" />
                <span v-if="item.is_active">Receiving matches</span>
                <span v-else>Not receiving matches</span>
              </div>
            </td>
            <td>
              {{ item.email }}
            </td>
            <td>
              {{ item.expected_matches_per_week || 0 }}
            </td>
          </tr>
        </template>
      </DataTable>
    </MyRentbirdCard>
  </B2BLayout>
</template>
<style lang="scss">
.B2B-HomePage {
  background: rgb(234,234,235);
}
</style>
