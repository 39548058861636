<script setup>
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import { storeToRefs } from 'pinia'
import { useMainStore } from '@js/App/Stores/MainStore'

const setLocale = (newLocale) => {
  window.location.href = getLocalizedUrl('set-language', {
    locale: newLocale
  })
}

const { locale } = storeToRefs(useMainStore())

</script>
<template>
  <div class="pb-4 lg:pb-10">
    <div class="text-xs lg:text-base">
      {{ $trans('NavMenuLanguageSwitcher.SelectLanguage') }}
    </div>

    <div class="flex">
      <div
        role="button"
        tabindex="0"
        class="text-base font-bold lg:text-2xl hover:cursor-pointer transition-colors hover:text-pink-500"
        :class="{'text-pink-500': locale === 'nl'}"
        @click="() => locale !== 'nl' && setLocale('nl')"
      >
        {{ $trans('Locale.Nl' ) }}
      </div>
      <span class="text-base mx-2 font-bold lg:text-2xl">/</span>
      <div
        role="button"
        tabindex="0"
        class="text-base font-bold lg:text-2xl hover:cursor-pointer transition-colors hover:text-pink-500"
        :class="{'text-pink-500': locale === 'en'}"
        @click="() => locale !== 'en' && setLocale('en')"
      >
        {{ $trans('Locale.En' ) }}
      </div>
    </div>
  </div>
</template>
