<script setup>
import { storeToRefs } from 'pinia'
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import { computed } from 'vue'
import DeadFaceImage from '@images/icons/dead.png'
import ColdFaceImage from '@images/icons/cold-face.png'
import FireImage from '@images/icons/fire.png'
import ThumbsUpImage from '@images/icons/thumbs-up.png'
import Button from '@js/Components/Forms/Button/Button.vue'
import Image from '@js/Components/Image/Image.vue'
import LightImage from '@images/icons/light.png'

defineProps({
  showSuccessfullySavedMessage: {
    type: Boolean,
    default: false
  }
})

const { matchesPerWeek } = storeToRefs(useSearchProfileStore())

const matchIcon = computed(() => {
  if (matchesPerWeek.value?.isLoading) { return matchIcon.value || FireImage }
  if (matchesPerWeek.value?.matchesPerWeek <= 2) { return DeadFaceImage }
  if (matchesPerWeek.value?.matchesPerWeek <= 5) { return ColdFaceImage }
  if (matchesPerWeek.value?.matchesPerWeek <= 10) { return ThumbsUpImage }

  return FireImage
})

const backgroundColor = computed(() => {
  if (matchesPerWeek.value?.isLoading) { return backgroundColor.value || 'bg-blue-50' }
  if (matchesPerWeek.value?.matchesPerWeek <= 2) { return 'bg-pink-50' }
  if (matchesPerWeek.value?.matchesPerWeek <= 5) { return 'bg-[#FFF2D9]' }
  if (matchesPerWeek.value?.matchesPerWeek <= 10) { return 'bg-blue-50' }

  return 'bg-blue-50'
})

</script>
<template>
  <div
    class="fixed bottom-0 left-0 right-0 px-[16px] py-[16px] lg:z-auto z-10"
    :class="{
      [`${backgroundColor}`]: true
    }"
  >
    <div
      v-show="backgroundColor === 'bg-pink-50'"
      class="px-[16px] py-[8px] rounded-md bg-pink-100 flex items-start mb-[10px]"
    >
      <Image :src="LightImage" />
      <div class="text-base text-pink-900 pl-[8px]">
        {{ $trans('SearchProfileStickyBottom.WarningLabel') }}
      </div>
    </div>
    <div class="flex justify-between items-center">
      <div>
        <div class="text-base text-gray-600">
          {{ $trans('SearchProfileStickyBottom.ExpectedMatches') }}
        </div>
        <div class="text-lg font-medium">
          {{ $trans('SearchProfileStickyBottom.NumberOfMatches', { matches: matchesPerWeek?.isLoading ? '..' : matchesPerWeek?.matchesPerWeek }) }}
          <Image :src="matchIcon" class="w-[17px] inline relative top-[-2px]" />
        </div>
      </div>
      <div class="w-1/2">
        <Button :icon-right="showSuccessfullySavedMessage ? 'check' : 'save'" :full-width-on-mobile="true">
          <span v-if="!showSuccessfullySavedMessage">{{ $trans('Common.Save') }}</span>
          <span v-else>{{ $trans('Common.Saved') }}</span>
        </Button>
      </div>
    </div>
    <slot />
  </div>
</template>
