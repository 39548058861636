<script setup>
import CheckboxDropdown from '@js/Components/Forms/CheckboxDropdown/CheckboxDropdown.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import { storeToRefs } from 'pinia'
import { computed, watch } from 'vue'

defineProps({
  black: {
    type: Boolean,
    default: false
  }
})

const searchProfileStore = useSearchProfileStore()
const { searchProfile, allNeighbourhoodsOfCity } = storeToRefs(searchProfileStore)

const isAllNeighbourhoodsSelected = computed(() => {
  for (const item of allNeighbourhoodsOfCity.value) {
    if (!isNeighbourhoodSelected(item)) {
      return false
    }
  }
  return true
})

watch(allNeighbourhoodsOfCity, () => {
  setSelectedNeighbourhoods()
})

const setSelectedNeighbourhoods = () => {
  if ((allNeighbourhoodsOfCity.value || []).length <= 0) {
    searchProfile.value.neighbourhoods = []
  }

  searchProfile.value.neighbourhoods = [
    ...new Set(
      allNeighbourhoodsOfCity.value.map(item => item.ids).flat()
    )
  ]
}

const isNeighbourhoodSelected = (item) => {
  if ((item?.ids || []).length <= 0) { return false }

  for (const id of item.ids) {
    if (!searchProfile.value?.neighbourhoods.includes(id)) {
      return false
    }
  }

  return true
}

const selectedBoroughs = computed(() => {
  const selected = []

  for (const item of allNeighbourhoodsOfCity.value) {
    if (isNeighbourhoodSelected(item) && item.group) {
      if (!selected.includes(item.group)) {
        selected.push(item.group)
      }
    }
  }

  return selected
})
</script>
<template>
  <div>
    <label
      class="block font-medium text-base mb-1"
      :class="{
        'text-white': !black,
        'text-gray-900': black,
      }"
    >
      {{ $trans('NewYorkBoroughSelect.Label') }}
    </label>
    <CheckboxDropdown
      dusk="neighbourhood-select"
      :model-value="searchProfile.neighbourhoods"
      :is-all-options-selected="isAllNeighbourhoodsSelected"
      :use-landing-page-styling="true"
      :options="allNeighbourhoodsOfCity?.map(item => ({
        label: item.name,
        value: item.ids
      }))"
      @update:model-value="newValue => {
        searchProfile.neighbourhoods = [...new Set(newValue.flat())]
      }"
    >
      <template #itemsSelectedMessage>
        <span v-if="selectedBoroughs.length > 1 && !isAllNeighbourhoodsSelected">{{ $trans('NewYorkBoroughSelect.ItemsSelectedMessage', { number: selectedBoroughs.length}) }}</span>
        <span v-else-if="selectedBoroughs.length === 0">{{ $trans('NewYorkBoroughSelect.NoItemsSelectedMessage') }}</span>
        <span v-else-if="selectedBoroughs.length === 1">{{ selectedBoroughs[0] }}, New York</span>
        <span v-else>{{ $trans('NewYorkBoroughSelect.AllItemsSelectedMessage') }}</span>
      </template>
      <template #options>
        <div
          v-for="item of allNeighbourhoodsOfCity.filter(item => !item.group)"
          :key="item.name"
          class="flex justify-between py-3 px-4 my-[2px] text-base text-purple-700 hover:cursor-pointer hover:text-white hover:bg-blue-base transition-all"
          :class="{
            'pl-4': !item.group,
            'pl-4 ml-4 border-l border-gray-201 border-dashed': item.group
          }"
          :dusk="`neighbourhood-select-option-${item.name}`"
          @click="() => {
            if (isNeighbourhoodSelected(item)) {
              searchProfile.neighbourhoods = searchProfile.neighbourhoods.filter(neighbourhoodItem => !item.ids.includes(neighbourhoodItem))
            } else {
              searchProfile.neighbourhoods = [...new Set([...searchProfile.neighbourhoods, ...item.ids].flat())]
            }
          }"
        >
          <span>{{ item.name }}, New York</span>
          <Icon v-if="!isNeighbourhoodSelected(item)" name="box" />
          <Icon v-else name="box-checked" />
        </div>
      </template>
    </CheckboxDropdown>
  </div>
</template>
