<script setup>
import TextInputAlternative from '@js/Components/Forms/TextInput/TextInputAlternative.vue'
import { ref, watch } from 'vue'
import { doGetRequest } from '@js/App/Api/Infra/ApiClient'

defineProps({
  error: {
    type: String,
    default: ''
  }
})

const internalAddress = ref('')
const context = ref('')
const searchResults = ref([])
watch(internalAddress, () => {
  const oldValue = internalAddress.value
  setTimeout(async () => {
    if (internalAddress.value !== oldValue) { return }

    const response = await doGetRequest('/api/offboarding/homedeals/search-address', {
      context: context.value,
      q: internalAddress.value
    })
    searchResults.value = response.json?.results
  }, 300)
})

const textInputIsFocused = ref(false)
const onFocus = () => {
  textInputIsFocused.value = true
}

const emit = defineEmits(['update:postcode'])
const clickedSearchResult = async (searchResult) => {
  context.value = searchResult.context
  internalAddress.value = searchResult.value

  if (searchResult.precision === 'Address') {
    const response = await doGetRequest('/api/offboarding/homedeals/address-details', {
      context: context.value
    })

    let number = `${response.json.address.buildingNumber}`
    if (response.json.address.buildingNumberAddition) {
      number += ` ${response.json.address.buildingNumberAddition}`
    }
    emit('update:postcode', {
      postcode: response.json.address.postcode,
      housenumber: number
    })
  }
}

</script>
<template>
  <div class="relative">
    <TextInputAlternative
      v-model="internalAddress"
      placeholder="Kerkstraat 20A"
      @focus="onFocus"
    >
      <template #label>
        <slot name="label" />
      </template>
    </TextInputAlternative>
    <div v-if="textInputIsFocused && internalAddress.length > 0" class="absolute left-0 right-0 z-10 bg-white rounded-b-md text-purple-900 text-base">
      <div v-if="searchResults.length <= 0" class="px-[16px] py-[16px]">
        {{ $trans('StreetAddressInput.NoSearchResults') }}
      </div>
      <div
        v-for="searchResult of searchResults"
        :key="searchResult.street"
        class="hover:cursor-pointer hover:bg-blue-base hover:text-white transition-colors group px-[16px] py-[16px]"
        @click="clickedSearchResult(searchResult)"
      >
        <span v-if="searchResult.highlights?.length > 0">
          {{ searchResult.label.substring(0, searchResult.highlights[0][0]) }}<strong>{{ searchResult.label.substring(searchResult.highlights[0][0], searchResult.highlights[0][1]) }}</strong>{{ searchResult.label.substring(searchResult.highlights[0][1]) }}
        </span>
        <span v-else>
          {{ searchResult.label }}
        </span>

        <span class="text-sm pl-1">{{ searchResult.description }}</span>
      </div>
    </div>
  </div>
</template>
