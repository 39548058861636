<script setup>
import CitySelect from '@js/Components/Forms/CitySelect/CitySelect.vue'
import FiltersInputScreenTopBar from '@js/Components/Forms/FiltersInputAllHousesPage/FiltersInputScreenTopBar.vue'
import Select from '@js/Components/Forms/Select/Select.vue'
import { computed } from 'vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { DEFAULT_CITY, DEFAULT_DISTANCE } from '@js/App/Utils/FiltersInputAllHousesDefaultValues'
import NeighbourhoodSelect from '@js/Components/Forms/NeighbourhoodSelect/NeighbourhoodSelect.vue'

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({})
  },
  isCityPage: {
    type: Boolean,
    default: false
  }
})

const filtersAreDifferentFromDefault = computed(() => {
  return props.modelValue.city || (props.modelValue.distance && props.modelValue.distance !== 10)
})

defineEmits(['update:modelValue', 'click:back', 'click:close'])
</script>
<template>
  <div>
    <FiltersInputScreenTopBar
      :title="$trans('FiltersInput.Location')"
      @click:back="$emit('click:back')"
      @click:close="$emit('click:close')"
    />
    <div class="mb-[8px]">
      <label class="block font-medium text-base mb-1 text-purple-700">{{ $trans("FiltersInputLocationScreen.City") }}</label>
      <CitySelect
        :model-value="modelValue?.city"
        :show-label="false"
        :black="true"
        :use-landing-page-styling="false"
        @update:model-value="newCity => {
          $emit('update:modelValue', {
            ...modelValue,
            city: newCity
          })
        }"
      />
    </div>

    <div v-if="isCityPage && modelValue?.city?.neighbourhoods?.length" class="mb-[24px]">
      <label class="block font-medium text-base mb-1 text-purple-700">{{ $trans("FiltersInputLocationScreen.Neighbourhoods") }}</label>
      <NeighbourhoodSelect
        :model-value="modelValue?.neighbourhoods || []"
        :all-neighbourhoods-of-city="modelValue?.city?.neighbourhoods || []"
        class="w-full"
        :placeholder="$trans('FiltersInputAllHousesPage.Neighbourhoods')"
        :show-top-bar="false"
        @update:model-value="newNeighbourhoods => {
          if (newNeighbourhoods?.length === 0 && modelValue?.neighbourhoods?.length === 0) {
            return
          }
          $emit('update:modelValue', {
            ...modelValue,
            neighbourhoods: newNeighbourhoods
          })
        }"
      />
    </div>

    <Select
      v-if="!modelValue?.city?.neighbourhoods?.length"
      class="mb-[24px]"
      :model-value="modelValue.distance"
      :options="[2,3,5,8,10,15,20,30,50].map(item => ({
        label: `<${item}km`,
        value: item,
      }))"
      :plus-and-minus-buttons="true"
      @update:model-value="newDistance => {
        $emit('update:modelValue', {
          ...modelValue,
          distance: newDistance
        })
      }"
    >
      <template #label>
        {{ $trans('FiltersInputLocationScreen.Distance') }}
      </template>
    </Select>

    <div class="w-full flex justify-end mb-[40px]">
      <a
        class="text-base text-blue-300"
        :class="{
          'hover:underline hover:cursor-pointer': filtersAreDifferentFromDefault,
          'opacity-30': !filtersAreDifferentFromDefault
        }"
        @click.prevent="() => {
          $emit('update:modelValue', {
            ...modelValue,
            city: DEFAULT_CITY,
            distance: DEFAULT_DISTANCE,
          })
        }"
      >
        {{ $trans('FilterInputLocationScreen.ResetFilter') }}
      </a>
    </div>

    <Button
      :big="true"
      :outline="true"
      :full-width-on-mobile="true"
      icon-right="back"
      @click="$emit('click:back')"
    >
      {{ $trans('FiltersInputLocationScreen.ApplyAndGoBack') }}
    </Button>
  </div>
</template>
