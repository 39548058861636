<script setup>
import MainHeroSection from '@js/Components/Marketing/Heros/MainHeroSection/MainHeroSection.vue'
import FourExplainerStepsSection from '@js/Components/Marketing/FourExplainerStepsSection/FourExplainerStepsSection.vue'
import TheRentalHackSection from '@js/Components/Marketing/TheRentalHackSection/TheRentalHackSection.vue'
import SectionDivider from '@js/Components/Marketing/SectionDivider/SectionDivider.vue'
import CallToActionSection from '@js/Components/Marketing/CallToActionSection/CallToActionSection.vue'
import Footer from '@js/Components/Marketing/Footer/Footer.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { useUser } from '@js/App/Api/User/User'
import LoggedInMainHeroSection from '@js/Components/Marketing/Heros/MainHeroSection/MainHeroSectionLoggedIn.vue'
import CallToActionSectionLoggedIn from '@js/Components/Marketing/CallToActionSection/CallToActionSectionLoggedIn.vue'
import BeforeAndAfterRentbirdSection
  from '@js/Components/Marketing/BeforeAndAfterRentbirdSection/BeforeAndAfterRentbirdSection.vue'
import HousesInCityCarousel
  from '@js/Components/Marketing/TheRentalHackSection/HousesInCityCarousel/HousesInCityCarousel.vue'
import { getLocale, LOCALE_NL } from '@js/App/Utils/CountryAndLocale'
import ImageHandNL from '@images/hand-alert-nl.png'
import ImageHandEN from '@images/hand-alert-en.png'
import UserReviewsSectionAlternative
  from '@js/Components/Marketing/UserReviewsSectionAlternative/UserReviewsSectionAlternative.vue'

const { user } = useUser()
</script>
<template>
  <LoggedInMainHeroSection v-if="user.isLoggedIn">
    <span v-if="user.hasActiveSubscription" v-html="$trans('HomePageLoggedIn.Title',{firstName: user.firstNameFormatted})" />
    <span v-else v-html="$trans('HomePageLoggedIn.TitleNoSubscriptionYet',{firstName: user.firstNameFormatted})" />
  </LoggedInMainHeroSection>
  <MainHeroSection v-else>
    <span v-html="$trans('HomePage.Title')" />
  </MainHeroSection>

  <FourExplainerStepsSection
    class="mb-[80px] lg:mb-0"
    :image="getLocale() === LOCALE_NL ? ImageHandNL : ImageHandEN"
  />

  <UserReviewsSectionAlternative
    :use-hardcoded-reviews="true"
  >
    <template #button>
      <Button
        class="mr-4"
        :outline="true"
        :big="true"
        icon-right="arrow-right"
        tag="link"
        :href="$getLocalizedUrl('reviews')"
      >
        {{ $trans('UserReviewsCarousel.ButtonLabel') }}
      </Button>
    </template>
  </UserReviewsSectionAlternative>
  <BeforeAndAfterRentbirdSection class="mt-[80px] lg:mt-[160px] mb-[80px] lg:mb-[160px]">
    <template #title>
      {{ $trans("HomePage.BeforeAndAfterRentbirdSectionTitle") }}
    </template>
  </BeforeAndAfterRentbirdSection>
  <HousesInCityCarousel
    :selected-city="{
      id: 'amsterdam',
      name: 'Amsterdam'
    }"
    :show-city-dropdown="true"
  />
  <TheRentalHackSection class="mt-[80px] lg:mt-[160px] mb-[60px] lg:mb-[80px]" />
  <SectionDivider :reversed="true" />

  <CallToActionSectionLoggedIn v-if="user.isLoggedIn" />
  <CallToActionSection v-else />
  <Footer />
</template>
