import { useMainStore } from '@js/App/Stores/MainStore'
import { doPostRequest } from '@js/App/Api/Infra/ApiClient'
import { isEmpty } from '@js/App/Utils/IsEmpty'
import { trans } from '@js/App/Plugins/Translations'

export const saveReponseLetters = async (responseLetterTiny, responseLetterLarge) => {
  const mainStore = useMainStore()
  mainStore.startLoading()

  try {
    const response = await doPostRequest('/api/response-letters', {
      response_letter_tiny: responseLetterTiny,
      response_letter_large: responseLetterLarge
    })

    return {
      hasErrors: !isEmpty(response.errors),
      errors: response.errors
    }
  } finally {
    mainStore.stopLoading()
  }
}

export const generateResponseLetters = async () => {
  const mainStore = useMainStore()
  mainStore.startLoading(false, trans('LoadingSpinner.AiGenerateResponseLetter'))

  const response = await doPostRequest('/api/response-letters/generate')

  mainStore.stopLoading(true)

  return {
    hasErrors: !isEmpty(response.errors),
    errors: response.errors || {}
  }
}
