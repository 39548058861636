<script setup>
import NumberOfMatchesBox from '@js/Components/NumberOfMatchesBox/NumberOfMatchesBox.vue'
import { computed, ref } from 'vue'
import { getMatchesPerWeekOfAllSearchProfilesOfB2BUser } from '@js/App/Api/MatchesPerWeek'
import { usePage } from '@inertiajs/vue3'

const isLoading = ref(true)
const matchesPerWeek = ref(0)

const user = computed(() => usePage().props.b2b_user)

const recalculateAllMatchesPerWeek = async () => {
  isLoading.value = true
  const response = await getMatchesPerWeekOfAllSearchProfilesOfB2BUser(user.value.random_id)
  matchesPerWeek.value = response.matchesPerWeek
  isLoading.value = false
}

recalculateAllMatchesPerWeek()

defineExpose({ recalculateAllMatchesPerWeek })

</script>
<template>
  <NumberOfMatchesBox
    :is-loading="isLoading"
    :is-for-all-search-profiles="true"
    :matches-per-week="matchesPerWeek"
  />
</template>
