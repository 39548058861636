<script setup>
import Button from '@js/Components/Forms/Button/Button.vue'
import ThreeStepsAnimation from '@js/Components/Marketing/ThreeStepsAnimation/ThreeStepsAnimation.vue'
import Container from '@js/Components/Container/Container.vue'
import FaqQuestion from '@js/Components/FaqQuestion/FaqQuestion.vue'
import CityHeroSection from '@js/Components/Marketing/CityHeroSection/CityHeroSection.vue'
import { usePage } from '@inertiajs/vue3'
import Footer from '@js/Components/Marketing/Footer/Footer.vue'
import CallToActionSectionCityPage from '@js/Components/Marketing/CallToActionSection/CallToActionSectionCityPage.vue'
import { useUser } from '@js/App/Api/User/User'
import CityHeroSectionLoggedIn from '@js/Components/Marketing/CityHeroSection/CityHeroSectionLoggedIn.vue'
import CallToActionSectionCityPageLoggedIn
  from '@js/Components/Marketing/CallToActionSection/CallToActionSectionCityPageLoggedIn.vue'
import HousesInCityCarousel
  from '@js/Components/Marketing/TheRentalHackSection/HousesInCityCarousel/HousesInCityCarousel.vue'
import UserReviewsSectionAlternative
  from '@js/Components/Marketing/UserReviewsSectionAlternative/UserReviewsSectionAlternative.vue'

const page = usePage()
const { user } = useUser()
</script>
<template>
  <CityHeroSectionLoggedIn v-if="user.isLoggedIn" :show-navigation="true">
    <template #subtitle>
      <span v-html="$trans('CityLandingPage.Subtitle', { city: page.props.city_name })" />
    </template>
    <template #default>
      <span v-html="$trans('CityLandingPage.Title', { city: page.props.city_name })" />
    </template>
  </CityHeroSectionLoggedIn>
  <CityHeroSection v-else :show-navigation="true">
    <template #subtitle>
      <span v-html="$trans('CityLandingPage.Subtitle', { city: page.props.city_name })" />
    </template>
    <template #default>
      <span v-html="$trans('CityLandingPage.Title', { city: page.props.city_name })" />
    </template>
  </CityHeroSection>

  <Container class="mt-[194px] lg:mt-[70px]">
    <div class="lg:flex">
      <div class="lg:w-1/2">
        <h2 class="text-3xl lg:text-5xl font-medium text-purple-900 lg:mb-2">
          {{ $trans('CityLandingPage.IntroTitle', { city: page.props.city_name }) }}
        </h2>
        <h2 class="text-3xl lg:text-5xl font-medium text-pink-500">
          {{ $trans('CityLandingPage.NotWithRentbird', { city: page.props.city_name }) }}
        </h2>
      </div>
      <p class="lg:w-1/2 text-base lg:text-xl text-purple-900 mt-4 lg:mt-0" v-html="$trans('CityLandingPage.CityIntroText', { city: page.props.city_name})" />
    </div>
  </Container>

  <ThreeStepsAnimation class="mt-[10px]" />

  <UserReviewsSectionAlternative :use-hardcoded-reviews="true" />

  <HousesInCityCarousel
    class="mt-[80px] lg:mt-[100px] mb-[80px] lg:mb-[100px]"
    :show-city-dropdown="false"
    :selected-city="{
      id: page.props.selected_city.id,
      name: page.props.selected_city.name
    }"
  />

  <div class="pt-[0px] pb-[30px]">
    <Container>
      <p class="text-lg lg:text-2xl text-purple-500 lg:mb-1">
        {{ $trans('HowItWorksPage.FaqSubtitle') }}
      </p>
      <h4 class="text-3xl text-[28px] lg:text-5xl font-medium text-purple-900 mb-3 lg:mb-8">
        {{ $trans('HowItWorksPage.FaqTitle') }}
      </h4>
      <FaqQuestion class="mb-2 lg:mb-4">
        <template #title>
          {{ $trans('FaqQuestion.DoYouVerhuurTitle') }}
        </template>
        <template #default>
          <span v-html="$trans('FaqQuestion.DoYouVerhuurContent')" />
        </template>
      </FaqQuestion>

      <FaqQuestion class="mb-2 lg:mb-4">
        <template #title>
          {{ $trans('FaqQuestion.CommissionTitle') }}
        </template>
        <template #default>
          <span v-html="$trans('FaqQuestion.CommissionContent')" />
        </template>
      </FaqQuestion>

      <FaqQuestion class="mb-2 lg:mb-4">
        <template #title>
          {{ $trans('FaqQuestion.SocialeHuurTitle') }}
        </template>
        <template #default>
          <span v-html="$trans('FaqQuestion.SocialeHuurContent')" />
        </template>
      </FaqQuestion>

      <FaqQuestion class="mb-2 lg:mb-4">
        <template #title>
          {{ $trans('FaqQuestion.WhyWouldIPayTitle') }}
        </template>
        <template #default>
          <span v-html="$trans('FaqQuestion.WhyWouldIPayContent')" />
        </template>
      </FaqQuestion>

      <FaqQuestion class="mb-2 lg:mb-4">
        <template #title>
          {{ $trans('FaqQuestion.SharingTitle') }}
        </template>
        <template #default>
          <span v-html="$trans('FaqQuestion.SharingContent')" />
        </template>
      </FaqQuestion>

      <Button
        class="mt-4"
        tag="link"
        :href="$getLocalizedUrl('faq')"
        :outline="true"
        :full-width-on-mobile="true"
        icon-right="arrow-right"
        :big="true"
      >
        {{ $trans('CityLandingPage.FaqButtonLabel') }}
      </Button>
    </Container>
  </div>

  <CallToActionSectionCityPageLoggedIn v-if="user.isLoggedIn" />
  <CallToActionSectionCityPage v-else />

  <Footer />
</template>
