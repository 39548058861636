<script setup>
import DanielleTextbox from '@js/Components/MyRentbird/Offboarding/PersonTextBox/PersonTextBox.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { ref } from 'vue'
import { usePage } from '@inertiajs/vue3'

defineProps({
  platform: {
    type: String,
    default: ''
  }
})

const page = usePage()
const trustpilotInviteLink = page.props.trustpilot_invite_link

const hasClickedTrustpilotButton = ref(false)

const onClickTrustpilotButton = () => {
  hasClickedTrustpilotButton.value = true
  window.open(trustpilotInviteLink, '_blank').focus()
}

const onClickAndroidButton = () => {
  hasClickedTrustpilotButton.value = true
  window.open('https://play.google.com/store/apps/details?id=io.rentbird&hl=en', '_blank').focus()
}

const onClickAppleButton = () => {
  hasClickedTrustpilotButton.value = true
  window.open('https://apps.apple.com/nl/app/rentbird-find-rental-houses/id6468817766', '_blank').focus()
}

defineEmits(['clicked:next'])
</script>
<template>
  <div>
    <DanielleTextbox>
      <template #title>
        {{ $trans('ReviewUsPage.Title') }}
      </template>
      <span v-html="$trans(platform === 'trustpilot' ? 'ReviewUsPage.Text' : 'ReviewUsPage.AppStoreText')" />
    </DanielleTextbox>

    <div class="lg:w-[600px]">
      <Button
        v-if="platform === 'trustpilot'"
        class="mt-[32px]"
        :full-width-on-mobile="true"
        :full-width-on-desktop="true"
        :big="true"
        :outline="true"
        icon-right="external"
        @click="onClickTrustpilotButton"
      >
        {{ $trans('ReviewUsTrustpilotStep.ButtonLabel') }}
      </Button>

      <div
        v-if="platform === 'mobile'"
        class="w-full flex items-center mt-[32px]"
      >
        <Button
          class="mr-[4px] w-full"
          :big="true"
          :full-width-on-desktop="true"
          :full-width-on-mobile="true"
          icon-right="external"
          :outline="true"
          @click="onClickAndroidButton"
        >
          {{ $trans('ReviewUsAndroid.ButtonLabel') }}
        </Button>
        <Button
          class="ml-[4px] w-full"
          :full-width-on-desktop="true"
          :full-width-on-mobile="true"
          :big="true"
          :outline="true"
          icon-right="external"
          @click="onClickAppleButton"
        >
          {{ $trans('ReviewUsApple.ButtonLabel') }}
        </Button>
      </div>

      <Button

        class="mt-[8px]"
        :full-width-on-mobile="true"
        :full-width-on-desktop="true"
        :big="true"
        icon-right="check"
        :disabled="!hasClickedTrustpilotButton"
        @click="() => {
          if (!hasClickedTrustpilotButton) { return }
          $emit('clicked:next')
        }"
      >
        {{ $trans('ReviewUsTrustpilotStep.IPlacedTheReview') }}
      </Button>
    </div>
  </div>
</template>
