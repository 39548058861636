import { ref } from 'vue'
import { isSSR } from '@js/App/Utils/Environment'

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export const isWindows = () => window?.navigator?.userAgent?.includes('Win')

export const useIsMobile = () => {
  const isMobile = ref(checkIfIsMobile())

  if (!isSSR()) {
    window.addEventListener('resize', (event) => {
      isMobile.value = checkIfIsMobile()
    })
  }

  return { isMobile }
}

const checkIfIsMobile = () => {
  if (isSSR()) { return false }
  return window.innerWidth < 769
}
