<script setup>
import Select from '@js/Components/Forms/Select/Select.vue'
import { trans } from '@js/App/Plugins/Translations'
import ErrorMessage from '@js/Components/Forms/ErrorMessage/ErrorMessage.vue'
import { COUNTRY_US, getCountry } from '@js/App/Utils/CountryAndLocale'

defineProps({
  modelValue: Object,
  error: {
    type: String,
    default: ''
  }
})

defineEmits(['update:modelValue'])

const getMonthOptions = () => (
  [{
    label: trans('Common.January'),
    value: 1
  }, {
    label: trans('Common.February'),
    value: 2
  }, {
    label: trans('Common.March'),
    value: 3
  }, {
    label: trans('Common.April'),
    value: 4
  }, {
    label: trans('Common.May'),
    value: 5
  }, {
    label: trans('Common.June'),
    value: 6
  }, {
    label: trans('Common.July'),
    value: 7
  }, {
    label: trans('Common.August'),
    value: 8
  }, {
    label: trans('Common.September'),
    value: 9
  }, {
    label: trans('Common.October'),
    value: 10
  }, {
    label: trans('Common.November'),
    value: 11
  }, {
    label: trans('Common.December'),
    value: 12
  }
  ]
)

const getYearOptions = () => {
  const options = []
  for (let i = 2007; i >= 1920; i--) {
    options.push({
      value: i,
      label: i
    })
  }
  return options
}

</script>
<template>
  <div>
    <label
      v-if="$slots.label"
      class="block font-medium text-base mb-1 text-purple-700"
    >
      <slot name="label" />
    </label>
    <div class="flex">
      <div v-if="getCountry() !== COUNTRY_US" class="w-1/5 lg:w-1/3 pr-[4px]">
        <Select

          :model-value="modelValue?.day || null"
          :show-error-border="!!error"
          :options="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
            .map(item => ({
              label: `${item}`,
              value: item
            }))"
          :placeholder="$trans('Common.Day')"
          @update:model-value="newValue => $emit('update:modelValue', {
            ...modelValue,
            day: newValue
          })"
        />
      </div>

      <div class="w-2/5 lg:w-1/3 lg:min-w-[120px] pr-[4px]">
        <Select
          :model-value="modelValue?.month || null"
          :options="getMonthOptions()"
          :show-error-border="!!error"
          :placeholder="$trans('Common.Month')"
          @update:model-value="newValue => $emit('update:modelValue', {
            ...modelValue,
            month: newValue
          })"
        />
      </div>

      <div v-if="getCountry() === COUNTRY_US" class="w-1/5 lg:w-1/3 pr-[4px]">
        <Select
          :model-value="modelValue?.day || null"
          :show-error-border="!!error"
          :options="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
            .map(item => ({
              label: `${item}`,
              value: item
            }))"
          :placeholder="$trans('Common.Day')"
          @update:model-value="newValue => $emit('update:modelValue', {
            ...modelValue,
            day: newValue
          })"
        />
      </div>

      <div class="w-2/5 lg:w-1/3">
        <Select
          :model-value="modelValue?.year || null"
          :options="getYearOptions()"
          :show-error-border="!!error"
          :placeholder="$trans('Common.Year')"
          @update:model-value="newValue => $emit('update:modelValue', {
            ...modelValue,
            year: newValue
          })"
        />
      </div>
    </div>
    <ErrorMessage v-if="error" class="mt-[2px]">
      {{ error }}
    </ErrorMessage>
  </div>
</template>
