<script setup>
import { getPriceBeforeCommaAndAfterCommaAsArray } from '@js/App/Plugins/Formatting'
import { getCurrency, getDecimalSeperator } from '@js/App/Utils/CountryAndLocale'
import { computed } from 'vue'
import { useUser } from '@js/App/Api/User/User'

defineProps({
  fullPrice: {
    type: [Number, Boolean],
    default: 2900
  },
  discount: {
    type: [Number, Boolean],
    default: false
  },
  price: {
    type: Number,
    default: 2900
  },
  inverseColors: {
    type: Boolean,
    default: false
  }
})

const { userActivePayment } = useUser()

const isSpecialTrialPlan = computed(() => {
  const priceDto = userActivePayment?.value?.priceDto
  return priceDto?.numberOfMonths <= 0
})
</script>
<template>
  <div>
    <div class="flex justify-center">
      <div class="flex items-center">
        <div
          v-if="fullPrice"
          class="text-gray-200 line-through pr-2 text-2xl ml-[-47px] xl:text-4xl xl:text-[38px] xl:pr-3 xl:ml-[0px]"
        >
          {{ $formatPrice(fullPrice) }}
        </div>
        <div
          class="text-6xl text-[64px] font-medium xl:text-[100px]"
          :class="{
            'xl:ml-[57px]': !discount || !fullPrice
          }"
        >
          {{ getPriceBeforeCommaAndAfterCommaAsArray(price)[0] }}
        </div>
        <div class="text-lg font-medium flex flex-col h-full justify-between pl-[6px] xl:text-2xl xl:relative xl:top-[6px]">
          <div v-if="getPriceBeforeCommaAndAfterCommaAsArray(price)[1] !== '00'">
            {{ getDecimalSeperator() }}{{ getPriceBeforeCommaAndAfterCommaAsArray(price)[1] }}
          </div>
          <div v-else>
            &nbsp;
          </div>
          <div class="relative top-[-4px] xl:top-[-22px]">
            {{ getCurrency() }}
          </div>
        </div>

        <div
          class="hidden xl:flex items-center pl-0"
        >
          <svg
            :class="{
              'text-success': discount,
              'text-danger-soft': !discount,
            }"
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="10"
            viewBox="0 0 12 10"
            fill="none"
          >
            <path d="M0 5L11.5 0V10L0 5Z" fill="currentColor" />
          </svg>
          <div
            class="text-base px-4 py-1 rounded-lg relative left-[-1px]"
            :class="{
              'bg-success': discount,
              'bg-danger-soft': !discount,
              'text-white': inverseColors
            }"
          >
            {{ $trans('PricingCardSimple.Discount', { discount: discount || 0 }) }}
          </div>
        </div>
      </div>
    </div>

    <div class="text-lg flex justify-center xl:text-3xl xl:text-[32px]">
      {{ $trans(isSpecialTrialPlan ? 'PricingCardAlternative.Per7Days' : 'PricingCardAlternative.PerMonth') }}
    </div>

    <div class="xl:hidden">
      <div class="flex justify-center">
        <svg
          :class="{
            'text-success': discount,
            'text-danger-soft': !discount,
          }"
          class="relative top-[1px]"
          xmlns="http://www.w3.org/2000/svg"
          width="35"
          height="7"
          viewBox="0 0 35 7"
          fill="none"
        >
          <path d="M17.5 -7.64949e-07L35 6.49999L-2.84124e-07 6.49999L17.5 -7.64949e-07Z" fill="currentColor" />
        </svg>
      </div>
      <div class="flex justify-center">
        <div
          class="text-sm  px-4 py-1 rounded-lg"
          :class="{
            'bg-success': discount,
            'bg-danger-soft': !discount,
            'text-white': inverseColors
          }"
        >
          {{ $trans('PricingCardSimple.Discount', { discount: discount || 0 }) }}
        </div>
      </div>
    </div>
  </div>
</template>
