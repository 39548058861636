<script setup>
import { usePage } from '@inertiajs/vue3'
import TrustpilotImage from '@images/trustpilot-logo.svg'
import Image from '@js/Components/Image/Image.vue'
import TrustpilotStars from '@js/Components/Trustpilot/TrustpilotStars.vue'

const page = usePage()

const trustPilotRating = page.props?.trustpilot?.rating
const numberOfReviews = page.props?.trustpilot?.numberOfReviews
</script>
<template>
  <div class="flex flex-wrap justify-end trustpilot">
    <div class="flex items-center basis-full justify-end">
      <Image
        class="w-[118px] mr-[16px]"
        :src="TrustpilotImage"
      />
      <TrustpilotStars
        class="w-[131px] relative top-[3px] mr-[16px]"
        :rating="trustPilotRating"
      />
      <span
        class="text-base font-medium relative top-[4px] lg:text-base lg:relative text-black"
      >
        {{ $formatNumber(trustPilotRating, 1) }}
      </span>
    </div>

    <div
      class="text-center text-black basis-full flex justify-end mt-[18px] text-sm"
    >
      <span
        v-html="$trans('TrustpilotSmall.BasedOn', {
          url: 'https://nl.trustpilot.com/review/rentbird.nl',
          number: numberOfReviews
        })"
      />
    </div>
  </div>
</template>
