<script setup>
import HeaderWithLogoAndTrustpilot from '@js/Components/HeaderWithLogoAndTrustpilot/HeaderWithLogoAndTrustpilot.vue'
import LoadingSpinner from '@js/Components/Forms/LoadingSpinner/LoadingSpinner.vue'
import { onMounted, ref } from 'vue'
import { doGetRequest } from '@js/App/Api/Infra/ApiClient'
import Icon from '@js/Components/Icon/Icon.vue'
import { spawnFullPageConfetti } from '@js/App/Utils/Confetti'
import { trackPurchase } from '@js/App/Tracking/Tracking'
import { getQueryParameter } from '@js/App/Utils/ReadQueryParams'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import { useUser } from '@js/App/Api/User/User'
import { router } from '@inertiajs/vue3'

const paymentStatus = ref('pending')
const redirectStatus = getQueryParameter('redirect_status')

if (redirectStatus === 'failed') {
  // Wait 3 seconds to give the user a time to read the payment failed message.
  setTimeout(() => {
    const fullUrl = getLocalizedUrl('checkout.start', {}, {
      plan: '1-monthly',
      onClickBackUrl: encodeURIComponent(getLocalizedUrl('upgrade'))
    })
    router.visit(
      fullUrl
    )
  }, 3000)
}

const getPaymentStatus = async () => {
  const r = await doGetRequest('/api/payment-status', {}, false)
  return {
    status: r.json?.status,
    payment: r.json?.payment
  }
}

const onPaymentCompleted = async (payment) => {
  spawnFullPageConfetti()

  const { user } = useUser()

  await trackPurchase(
    payment.amount,
    payment.invoice_id,
    payment.plan,
    user.value.email,
    payment.coupon,
    payment.months,
    payment.discount
  )
}

onMounted(() => {
  const checkPaymentStatusInterval = setInterval(async () => {
    if (redirectStatus === 'failed') { return }

    const { status, payment } = await getPaymentStatus()
    paymentStatus.value = status
    if (paymentStatus.value === 'completed') {
      clearInterval(checkPaymentStatusInterval)
      clearTimeout(showTimeoutMessageTimeout)

      await onPaymentCompleted(payment)
    }
  }, 2000)

  const showTimeoutMessageTimeout = setTimeout(() => {
    paymentStatus.value = 'not-found'
    clearInterval(checkPaymentStatusInterval)
  }, 60 * 1000)
})

</script>
<template>
  <div>
    <HeaderWithLogoAndTrustpilot />
    <div class="container mx-auto px-4 py-6 lg:px-8 lg:py-8 lg:pt-16">
      <div v-if="redirectStatus === 'failed'">
        <h1 class="text-purple-700 font-medium text-2xl mb-2 lg:mb-4 lg:text-4xl text-center">
          {{ $trans('CheckoutCompleted.RedirectFailed') }}
        </h1>
        <div class="text-base lg:text-lg text-purple-900 text-center mb-6">
          {{ $trans('CheckoutCompleted.RedirectMessage') }}
        </div>
        <div class="flex w-full justify-center">
          <LoadingSpinner :show-text="false" />
        </div>
      </div>

      <div v-else-if="paymentStatus === 'pending'">
        <h1 class="text-purple-700 font-medium text-2xl mb-2 lg:mb-4 lg:text-4xl text-center">
          {{ $trans('CheckoutCompleted.WaitingForPayment') }}
        </h1>
        <div class="text-base lg:text-lg text-purple-900 text-center mb-6">
          {{ $trans('CheckoutCompleted.WaitingMessage1') }}
        </div>
        <div class="flex w-full justify-center">
          <div class="w-[34px]">
            <LoadingSpinner :show-text="false" />
          </div>
        </div>
      </div>

      <div v-show="paymentStatus === 'completed'">
        <h1 class="text-purple-700 font-medium text-2xl mb-2 lg:mb-4 lg:text-4xl text-center">
          {{ $trans('CheckoutCompleted.PaymentCompleted') }}
        </h1>
        <div class="text-center">
          <a class="text-blue-base hover:cursor-pointer hover:underline text-base lg:text-lg transition-all" :href="$getLocalizedUrl('my-rentbird.home')">
            {{ $trans('CheckoutCompleted.GotoMyRentbirdLink') }}
            <Icon name="arrow-right" class="inline" icon-width="inline relative w-[18px] lg:w-[24px] top-[-1px] left-[2px]" />
          </a>
        </div>
      </div>

      <div v-show="paymentStatus === 'not-found'">
        <h1 class="text-purple-700 font-medium text-2xl mb-2 lg:mb-4 lg:text-4xl text-center">
          {{ $trans('CheckoutCompleted.NoPaymentFound') }}
        </h1>
        <div class="text-center">
          <div class="text-base lg:text-lg text-purple-900 text-center mb-6">
            {{ $trans('CheckoutCompleted.NoPaymentFoundDetails') }}
          </div>
          <a class="text-blue-base hover:cursor-pointer hover:underline text-base lg:text-lg transition-all" :href="$getLocalizedUrl('contact')">
            {{ $trans('CheckoutCompleted.GotoSupportLink') }}
            <Icon name="arrow-right" class="inline" icon-width="inline relative w-[18px] lg:w-[24px] top-[-1px] left-[2px]" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
