<script setup>
import ImageOhNo from '@images/icons/ohno.png'
import ImageThumbsUp from '@images/icons/thumbs-up.png'
import { storeToRefs } from 'pinia'
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import MaxRentSelect from '@js/Components/Forms/MaxRentSelect/MaxRentSelect.vue'
import MinBedroomsSelect from '@js/Components/Forms/MinBedroomsSelect/MinBedroomsSelect.vue'
import MinSurfaceSelect from '@js/Components/Forms/MinSurfaceSelect/MinSurfaceSelect.vue'
import { computed } from 'vue'
import { isEligbleForRentbirdPlus } from '@js/App/Api/RentbirdPlus'
import InfoMessageBox from '@js/Components/InfoMessageBox/InfoMessageBox.vue'
import GoingToLiveWithSelect from '@js/Components/Forms/LivingRequirementsInput/GoingToLiveWithSelect.vue'
import Checkbox from '@js/Components/Forms/Checkbox/Checkbox.vue'

defineProps({
  errors: {
    type: Object,
    default: () => ({})
  }
})
const { searchProfile } = storeToRefs(useSearchProfileStore())

const showWarningToggle = computed(() => {
  return !isEligbleForRentbirdPlus()
})

</script>
<template>
  <div>
    <MaxRentSelect :error="errors.max_rent" class="mb-[8px] lg:mb-[16px]" />

    <InfoMessageBox
      v-if="showWarningToggle"
      color="bg-[#FFF2D9]"
      class="text-[#E79F35] mb-[8px] lg:mb-[16px]"
      :image="ImageOhNo"
    >
      <span v-html="$trans('StepHouseDetails.RentToLow')" />
    </InfoMessageBox>
    <InfoMessageBox
      v-else
      color="bg-[#E2FFF5]"
      class="text-[#00B67A] mb-[8px] lg:mb-[16px]"
      :image="ImageThumbsUp"
    >
      <span v-html="$trans('StepHouseDetails.RentIsGood')" />
    </InfoMessageBox>

    <MinBedroomsSelect :error="errors.min_bedrooms" class="mb-[8px] lg:mb-[16px]" />

    <MinSurfaceSelect :error="errors.min_surface" class="mb-[8px] lg:mb-[16px]" />

    <div class="lg:flex lg:items-center">
      <div class="lg:w-1/2 lg:pr-4">
        <GoingToLiveWithSelect
          class="mb-[8px] lg:mb-[16px] mt-4"
          :model-value="searchProfile?.rentbirdPlusGoingToLiveWith"
          :error="errors.going_to_live_with"
          @update:model-value="newValue => {
            searchProfile.rentbirdPlusGoingToLiveWith = newValue
          }"
        />
      </div>
      <div class="lg:w-1/2 lg:pl-4">
        <Checkbox
          class="mt-6"
          :model-value="searchProfile.isForPets"
          @update:model-value="newValue => {
            searchProfile.isForPets = newValue
          }"
        >
          <span class="font-medium">{{ $trans('StepHouseDetails.DoYouHavePets') }}</span>
        </Checkbox>
      </div>
    </div>
  </div>
</template>
