<script setup>

import TrustpilotSmall from '@js/Components/Trustpilot/TrustpilotSmall.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import CallToActionSectionActivateAlertsItem
  from '@js/Components/Marketing/CallToActionSection/CallToActionSectionActivateAlertsItem.vue'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'
import LightningSet from '@images/icons/lightning-set.svg'
import TwoLightnings from '@images/icons/two-lightnings.svg'
import ThreeStripes from '@images/icons/three-stripes.svg'
import BirdStandingBlue from '@images/icons/bird-standing-blue.svg'
import BirdStanding from '@images/icons/bird-standing.svg'
import TrustpilotRating from '@js/Components/Trustpilot/TrustpilotRating.vue'
import { useUser } from '@js/App/Api/User/User'
import { showCreateSearchProfileWizard } from '@js/App/SearchProfile'
import { router } from '@inertiajs/vue3'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import Image from '@js/Components/Image/Image.vue'

const { isMobile } = useIsMobile()
const { user } = useUser()
const isLoggedIn = user.value.isLoggedIn
const hasActiveSubscription = user.hasActiveSubscription

const onClickedButton = () => {
  if (!isLoggedIn) {
    return showCreateSearchProfileWizard()
  }

  if (hasActiveSubscription) {
    router.visit(getLocalizedUrl('my-rentbird.home'))
  } else {
    router.visit(getLocalizedUrl('upgrade'))
  }
}
</script>

<template>
  <div>
    <div class="bg-purple-50 w-full mx-auto relative font-bold rounded-t-md p-6 lg:p-10">
      <div class="text-purple-900 ">
        <div class="text-xl lg:text-3xl mb-2 lg:mb-6">
          {{ $trans('CallToActionSectionActivateAlertsLarge.Title') }}
        </div>
        <div class="lg:flex justify-between">
          <div class="mr-10">
            <CallToActionSectionActivateAlertsItem class="mb-2">
              <div v-if="isMobile">
                {{ $trans('CallToActionSectionActivateAlertsLarge.Subtitle1Mobile') }}
              </div>
              <div v-else>
                {{ $trans('CallToActionSectionActivateAlertsLarge.Subtitle1') }}
              </div>
            </CallToActionSectionActivateAlertsItem>
            <CallToActionSectionActivateAlertsItem class="mb-2">
              <div v-if="isMobile">
                {{ $trans('CallToActionSectionActivateAlertsLarge.Subtitle2Mobile') }}
              </div>
              <div v-else>
                {{ $trans('CallToActionSectionActivateAlertsLarge.Subtitle2') }}
              </div>
            </CallToActionSectionActivateAlertsItem>
            <CallToActionSectionActivateAlertsItem class="mb-6 lg:mb-2">
              <div v-if="isMobile">
                {{ $trans('CallToActionSectionActivateAlertsLarge.Subtitle3Mobile') }}
              </div>
              <div v-else>
                {{ $trans('CallToActionSectionActivateAlertsLarge.Subtitle3') }}
              </div>
            </CallToActionSectionActivateAlertsItem>
          </div>
          <div class="relative">
            <Button
              :big="true"
              :full-width-on-desktop="true"
              :full-width-on-mobile="true"
              icon-right="bell-alert"
              @click="onClickedButton"
            >
              {{ $trans('CallToActionSectionActivateAlertsLarge.ActivateAlerts') }}
            </Button>
            <Image :src="LightningSet" class="hidden absolute lg:block h-[121px] top-[-136px] left-[79%] " />
            <Image :src="TwoLightnings" class="lg:hidden absolute h-[77px] top-[-50px] left-full" />
            <Image :src="ThreeStripes" class="absolute rotate-[138deg] h-[55px] lg:h-[92px] top-[-45px] lg:top-[-77px] -left-10 lg:left-[-54px] " />
          </div>
        </div>
      </div>
    </div>
    <div class="bg-purple-700 p-4 w-full mx-auto rounded-b-md flex justify-center relative">
      <Image :src="BirdStandingBlue" class="hidden lg:block absolute h-[71px] top-[-51px] left-[85%]" />
      <Image :src="BirdStanding" class="hidden lg:block absolute h-[71px] top-[-51px] left-[88.7%] " />
      <TrustpilotSmall v-if="!isMobile" class="" />
      <TrustpilotRating v-else :black="false" />
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
