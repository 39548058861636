<script setup>
import { ref } from 'vue'

const props = defineProps({
  tabs: {
    type: Array,
    default: () => ([{
      label: 'Example label',
      value: 'slotname'
    }])
  }
})

const selectedTab = ref(props.tabs?.[0]?.value || null)
</script>
<template>
  <div>
    <div class="flex">
      <div
        v-for="tab of tabs"
        :key="tab.value"
        class="text-base py-[12px] flex-grow flex justify-center mb-[16px] hover:cursor-pointer hover:text-[#3B316E] border-b border-white hover:border-[#3B316E] transition-all hover:font-medium"
        :class="{
          'text-[#3B316E] border-b !border-[#3B316E] font-medium': selectedTab === tab.value
        }"
        @click="selectedTab = tab.value"
      >
        {{ tab.label }}
      </div>
    </div>
    <div
      v-for="tab of tabs"
      v-show="selectedTab === tab.value"
      :key="tab.value"
    >
      <slot :name="tab.value" />
    </div>
  </div>
</template>
