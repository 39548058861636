<script setup>
import VHGTitle from '@js/Components/MyRentbird/Verhuisgids/Helpers/VHGTitle.vue'
import { trans } from '@js/App/Plugins/Translations'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import Button from '@js/Components/Forms/Button/Button.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import { computed } from 'vue'
import { useUser } from '@js/App/Api/User/User'

const hasCreatedResponseLetter = computed(() => {
  const { user } = useUser()
  return user.value.hasCreatedResponseLetter
})

</script>
<template>
  <div>
    <VHGTitle :step="5">
      {{ $trans("VHGResponseLetter.Title") }}
    </VHGTitle>
    <p class="vhgpushsettings py-[24px] text-purple-900 text-sm lg:text-lg" v-html="trans('VHGResponseLetter.Content')" />

    <div v-if="hasCreatedResponseLetter" class="flex text-success">
      <Icon
        name="check-circle"
        icon-width="min-w-[20px] min-h-[20px] max-w-[20px] max-h-[20px] mr-[8px] relative top-[2px]"
      />
      {{ $trans('VHGResponseLetter.YouHaveCreatedResponseLetter') }}
    </div>

    <Button v-else :outline="true" tag="link" class="text-blue-base text-sm lg:text-lg hover:cursor-pointer hover:underline transition-all" :href="getLocalizedUrl('my-rentbird.response-letter')">
      {{ $trans("VHGExtraSearchProfile.ResponseLetter") }}
    </Button>
  </div>
</template>
<style>
.vhgpushsettings li {
  padding-bottom: 22px;
}
</style>
