import { usePage } from '@inertiajs/vue3'
import { computed } from 'vue'

export const useB2BOrganization = () => {
  const page = usePage()

  const organization = computed(() => page.props.organization)

  return {
    organization
  }
}
