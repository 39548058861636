const formatAsString = value => typeof value === 'string'
  ? value
  : JSON.stringify(value)

export const isInCache = key => !!localStorage.getItem(formatAsString(key))

export const getFromCache = key => JSON.parse(
  localStorage.getItem(formatAsString(key))
)

export const putInCache = (key, value) => {
  localStorage.setItem(
    formatAsString(key),
    formatAsString(value)
  )
}
