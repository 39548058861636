<script setup>
import TextInput from '@js/Components/Forms/TextInput/TextInput.vue'
import { computed, ref, watch } from 'vue'
import useClickedOutside from '@js/App/Utils/ClickedOutside'
import Icon from '@js/Components/Icon/Icon.vue'
import { isSafari, useIsMobile } from '@js/App/Utils/BrowserDetection'

const props = defineProps({
  modelValue: [Object, String],
  textValue: String,
  placeholder: String,
  options: Array,
  showHasMoreResultsText: Boolean,

  shouldScrollIntoViewOnMobile: {
    type: Boolean,
    default: true
  },
  useLandingPageStyling: {
    type: Boolean,
    default: false
  },
  error: {
    type: String,
    default: ''
  },
  showErrorBorder: {
    type: Boolean,
    default: false
  },
  blackBorder: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },

  dusk: {
    type: [String, null],
    default: null
  }
})

const emit = defineEmits([
  'update:modelValue',
  'update:textValue',
  'click:textInput',
  'click:outside',
  'update:clickedOption'
])

const selectRef = ref(null)
const textInputIsSelected = ref(false)
const fixedInputRef = ref(null)
const lastClickedOnTextInputAt = ref(null)

const iJustGotOpened = ref(false)
const iJustGotClosed = ref(false)

const { isMobile } = useIsMobile()

const showDropdown = computed(() => props.options?.length > 0 || textInputIsSelected.value)

const showFixedLayout = computed(() => {
  return isMobile.value && !props.useLandingPageStyling && (showDropdown.value || textInputIsSelected.value)
})

watch(fixedInputRef, () => {
  if (fixedInputRef.value) {
    setTimeout(() => { fixedInputRef.value.focus() }, 300)
  }
})

watch(showDropdown, () => {
  if (!isMobile.value) { return }
  if (props.useLandingPageStyling) { return }

  if (showDropdown.value) {
    iJustGotOpened.value = true
    setTimeout(() => {
      iJustGotOpened.value = false
    }, 1)
  } else {
    iJustGotClosed.value = true
    setTimeout(() => { iJustGotClosed.value = false }, 300)
  }
})

useClickedOutside(selectRef, () => {
  if (!showFixedLayout.value) {
    const elapsedMs = performance.now() - lastClickedOnTextInputAt.value
    if (elapsedMs <= 200) { return }
    emit('click:outside')
  }
})

const onClickTextInput = () => {
  if (props.disabled) { return }
  lastClickedOnTextInputAt.value = performance.now()
  emit('click:textInput')
  if (!isMobile.value) { return }
  if (!props.shouldScrollIntoViewOnMobile) { return }

  selectRef.value.scrollIntoView({
    behavior: isMobile.value && isSafari() ? 'auto' : 'smooth'
  })
}

</script>
<template>
  <div
    ref="selectRef"
    class="scroll-my-8"
    :class="{
      'relative': !showFixedLayout,
      'hover:cursor-not-allowed': disabled,
    }"
    :style="!useLandingPageStyling && showDropdown && `box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.075);`"
  >
    <TextInput
      :disabled="disabled"
      :black-border="blackBorder"
      :error="error"
      :show-error-border="showErrorBorder"
      :use-landing-page-styling="useLandingPageStyling"
      :model-value="textValue"
      :placeholder="placeholder"
      :disable-border="isMobile && showFixedLayout"
      :delay-focus="isMobile && !showFixedLayout"
      :dusk="dusk"
      :class="{
        'fixed top-0 left-0 w-full border-0': showFixedLayout,
      }"
      @click="onClickTextInput"
      @update:model-value="(newValue) => {
        $emit('update:textValue', newValue)
      }"
      @focus="() => { textInputIsSelected = true; onClickTextInput() }"
      @blur="textInputIsSelected = false"
    >
      <template
        v-if="$slots.icon || showFixedLayout"
        #icon
      >
        <slot
          v-if="$slots.icon && !showFixedLayout"
          name="icon"
        />
        <Icon
          v-else
          name="caret-down"
          icon-width="w-[24px] rotate-90 top-[-5px] relative"
          class="hover:cursor-pointer hover:text-pink-500"
          @click="e => {
            if (showDropdown) {
              e.stopPropagation()

              $emit('click:outside')
            }
          }"
        />
      </template>
    </TextInput>

    <div
      v-if="showDropdown || showFixedLayout || iJustGotOpened || iJustGotClosed"
      class="transition-transform transition-300"
      :dusk="`${dusk}-dropdown`"
      :class="{
        'top-[56px]': !showFixedLayout && !useLandingPageStyling,
        'fixed lg:relative inset-0 top-[54px] z-20 overflow-y-auto lg:overflow-y-visible': showFixedLayout || iJustGotOpened || iJustGotClosed,
        'translate-y-vscreen': iJustGotOpened,
        'scroll-down': iJustGotClosed
      }"
    >
      <div
        class="absolute w-full bg-white z-30 text-gray-900 overflow-y-auto px-4 transition-transform"
        :class="{
          'border-t-0 top-[-9px] lg:top-[54px]': !useLandingPageStyling,
          'top-[53px] rounded-b': useLandingPageStyling,
          'h-auto max-h-[300px]': !showFixedLayout && !iJustGotClosed && !iJustGotOpened,
          'min-h-full lg:min-h-auto lg:max-h-[300px] border-t': showFixedLayout || iJustGotOpened || iJustGotClosed,
          'border rounded-b border-gray-200': !showFixedLayout && !useLandingPageStyling

        }"
        :style="useLandingPageStyling ? 'box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);' : 'box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.075);'"
      >
        <div
          :class="{
            'border-t border-opacity-10 border-black': useLandingPageStyling || showFixedLayout,
            'mt-[2px] pt-1': showFixedLayout
          }"
        >
          <div
            v-for="option of options"
            :key="option.label"
            class="option-label px-8 py-3 text-base hover:bg-blue-base hover:rounded hover:text-white transition-colors hover:cursor-pointer"
            :class="{
              'text-purple-700': !useLandingPageStyling
            }"
            :dusk="`text-input-with-suggestions-dropdown-option-${option.label}`"
            @click="() => {
              $emit('update:clickedOption', option)
            }"
            v-html="option.label"
          />
          <div
            v-if="showHasMoreResultsText"
            class="px-8 py-3 text-sm italic opacity-40 "
          >
            {{ $trans('TextInputWithSuggestionsDropdown.MoreOptionsLabel') }}
          </div>
          <div
            v-if="options.length === 0"
            class="px-8 py-3 text-sm italic opacity-40 "
          >
            {{ $trans('TextInputWithSuggestionsDropdown.NoResultsLabel') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.option-label em {
  font-weight: 600;
  font-style: normal;
}
</style>
