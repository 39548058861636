<script setup>
import Select from '@js/Components/Forms/Select/Select.vue'
import { storeToRefs } from 'pinia'
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import { computed } from 'vue'
import { COUNTRY_NL, getCountry } from '@js/App/Utils/CountryAndLocale'
import { convertM2ToSquareFeet } from '@js/App/Utils/MetricSystem'

defineProps({
  plusAndMinusButtons: {
    type: Boolean,
    default: true
  },
  error: {
    type: String,
    default: ''
  }
})

const { searchProfile } = storeToRefs(useSearchProfileStore())

const options = computed(() => {
  if (getCountry() === COUNTRY_NL) {
    return [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(item => ({
      value: item,
      label: `${item}m²`
    }))
  }

  // TODO-dirk make this square meters decimal values.
  return [0, 9.2903, 18.5806, 27.8709, 37.1612, 46.4515, 69.6773, 92.903, 116.1288, 139.3546].map(item => ({
    value: item,
    label: `${convertM2ToSquareFeet(item)}sqft`
  }))
})
</script>
<template>
  <Select
    v-model="searchProfile.minSurface"
    dusk="min-surface"
    :error="error"
    class="mt-4"
    :plus-and-minus-buttons="plusAndMinusButtons"
    :options="options"
  >
    <template #label>
      {{ $trans('SearchProfileWizard.MinSurface') }}
    </template>
  </Select>
</template>
