<script setup>
import Select from '@js/Components/Forms/Select/Select.vue'
import { storeToRefs } from 'pinia'
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import Checkbox from '@js/Components/Forms/Checkbox/Checkbox.vue'

defineProps({
  showIncludeRoomsCheckbox: {
    type: Boolean,
    default: false
  },
  plusAndMinusButtons: {
    type: Boolean,
    default: true
  },
  error: {
    type: String,
    default: ''
  }
})

const { searchProfile } = storeToRefs(useSearchProfileStore())
</script>
<template>
  <div>
    <Select
      v-model="searchProfile.minBedrooms"
      dusk="min-bedrooms"
      :error="error"
      :plus-and-minus-buttons="plusAndMinusButtons"
      :options="[1,2,3,4,5,6].map(item => ({
        value: item,
        label: `${item} ${$trans('SearchProfileWizard.BedroomsOrMoreLabel')}`
      }))"
    >
      <template #label>
        <div
          :class="{
            'flex justify-between w-full': showIncludeRoomsCheckbox
          }"
        >
          <div>{{ $trans('SearchProfileWizard.MinBedrooms') }}</div>
          <Checkbox
            v-if="showIncludeRoomsCheckbox"
            :small="true"
            class="hidden lg:flex"
            :model-value="searchProfile.includeRooms"
            @update:model-value="newValue => {
              searchProfile.includeRooms = !searchProfile.includeRooms
            }"
          >
            <span class="text-base font-normal">{{ $trans('MinBedroomsSelect.CheckboxLabel') }}</span>
          </Checkbox>
        </div>
      </template>
    </Select>
    <Checkbox
      v-if="showIncludeRoomsCheckbox"
      :small="true"
      class="lg:hidden mt-[2px]"
      :model-value="searchProfile.includeRooms"
      @update:model-value="newValue => {
        searchProfile.includeRooms = !searchProfile.includeRooms
      }"
    >
      <span class="text-base font-normal">{{ $trans('MinBedroomsSelect.CheckboxLabel') }}</span>
    </Checkbox>
  </div>
</template>
