<script setup>
import Image from '@js/Components/Image/Image.vue'
import ImageTrustpilot45Stars from '@images/logos/trustpilot-4.5-stars.svg'
import ImageTrustpilot50Stars from '@images/logos/trustpilot-5-stars.svg'

defineProps({
  rating: [Number, String]
})

const getRatingAsNumber = (rating) => {
  if (typeof rating === 'string') {
    return parseFloat(rating)
  }
  return rating
}
</script>
<template>
  <Image v-if="rating > getRatingAsNumber(4.7)" :src="ImageTrustpilot50Stars" />
  <Image v-else-if="rating > getRatingAsNumber(4.3)" :src="ImageTrustpilot45Stars" />
  <Image v-else :src="ImageTrustpilot45Stars" />
</template>
