import { onUnmounted, ref, watch } from 'vue'
import { isSSR } from '@js/App/Utils/Environment'

export const useHasBeenInViewport = (element, bufferHeight = 250) => {
  // On SSR we want to generate all HTML, so every element will get the "hasBeenInViewport" set to true
  if (isSSR()) {
    return { hasBeenInViewport: true }
  }

  const hasBeenInViewport = ref(false)

  const checkIfElementHasBeenInViewport = () => {
    // If the element has already been in the viewport, we are done.
    if (hasBeenInViewport.value) {
      if (interval) {
        clearInterval(interval)
      }
      return
    }

    const $el = element.value
    if ($el) {
      const boundingBox = $el.getBoundingClientRect()
      const { top, left, right, bottom } = boundingBox

      if (top === 0 && left === 0 && right === 0 && bottom === 0) {
        // This happens when we check v-show=false elements. In this case
        // we don't want to count this as being in view.
        return
      }

      hasBeenInViewport.value =
          // We add bufferHeight here to trigger this just before the element actually hits the viewport.
          // This way The element can load its dependencies just outside of the viewport.
          top <= window.innerHeight + bufferHeight
    }
  }

  // On element change we trigger the check function.
  watch(element, checkIfElementHasBeenInViewport)

  // And also on a scroll change.
  window.addEventListener('scroll', checkIfElementHasBeenInViewport)

  // When v-show changes this is not triggered yet, so add a base interval.
  const interval = setInterval(() => {
    checkIfElementHasBeenInViewport()
  }, 250)

  // Cleanup our listener after we're done.
  onUnmounted(() => {
    window.removeEventListener('scroll', checkIfElementHasBeenInViewport)
    clearInterval(interval)
  })

  return {
    hasBeenInViewport
  }
}
