<script setup>
import { useUser } from '@js/App/Api/User/User'
import Icon from '@js/Components/Icon/Icon.vue'
import { getHomedealServiceTitle } from '@js/App/Api/User/Offboarding'
import { ref } from 'vue'
import CheckboxAlternative from '@js/Components/Forms/Checkbox/CheckboxAlternative.vue'
import Button from '@js/Components/Forms/Button/Button.vue'

defineProps({
  contactInfo: {
    type: Object
  },
  selectedHomedeals: {
    type: Array
  }
})

const { user } = useUser()

const checkedTheBox = ref(false)

defineEmits(['clicked:back', 'clicked:next', 'clicked:gotoServices'])
</script>
<template>
  <div>
    <h4 class="text-white text-xl font-medium mb-[16px] lg:text-3xl lg:mb-[24px]">
      {{ $trans('HomeDealsVerifyInfo.Title') }}
    </h4>

    <div class="lg:flex">
      <div class="lg:w-1/2 lg:mr-[6px]">
        <div class="text-sm text-white mb-[4px] lg:text-lg lg:mb-[8px]">
          {{ $trans('HomeDealsVerifyInfo.ContactInfo') }}
        </div>
        <div
          class="px-[16px] py-[16px] mb-[16px] lg:mb-0 group  bg-purple-900 rounded-md hover:cursor-pointer hover:bg-purple-900/70 text-base lg:text-lg"
          @click="$emit('clicked:back')"
        >
          <div class="flex items-center justify-between">
            <div>
              <p class="font-medium">
                {{ user.firstNameFormatted }} {{ user.lastNameFormatted }}
              </p>
              <p>
                {{ contactInfo.postcode }} {{ contactInfo.housenumber }}
              </p>
              <p>
                {{ contactInfo.phone }}
              </p>
              <p>
                {{ contactInfo.email }}
              </p>
            </div>
            <Icon name="edit" class="group-hover:text-pink-500 transition-colors" icon-width="w-[24px] h-[24px]" />
          </div>
        </div>
      </div>

      <div class="lg:w-1/2 lg:ml-[6px]">
        <div class="text-sm text-white mb-[4px] lg:text-lg lg:mb-[8px]">
          {{ $trans('HomeDealsVerifyInfo.HomeDealsInfo') }}
        </div>
        <div
          class="px-[16px] py-[16px] mb-[16px] lg:mb-0 group bg-purple-900 rounded-md hover:cursor-pointer hover:bg-purple-900/70 text-base lg:text-lg"
          @click="$emit('clicked:gotoServices')"
        >
          <div class="flex items-center justify-between">
            <div>
              <p v-for="homeDeal of selectedHomedeals" :key="homeDeal">
                {{ getHomedealServiceTitle(homeDeal) }}
              </p>
            </div>
            <Icon name="edit" class="group-hover:text-pink-500 transition-colors" icon-width="w-[24px] h-[24px]" />
          </div>
        </div>
      </div>
    </div>

    <CheckboxAlternative v-model="checkedTheBox" class="mb-[24px] lg:mb-[32px] lg:mt-[32px]">
      {{ $trans('HomeDealsVerifyInfo.Disclaimer') }}
    </CheckboxAlternative>

    <div class="lg:w-full lg:flex lg:justify-end">
      <div>
        <Button
          :disabled="!checkedTheBox"
          :big="true"
          :full-width-on-mobile="true"
          icon-right="check"
          @click="() => {
            if (checkedTheBox) {
              $emit('clicked:next')
            }
          }"
        >
          {{ $trans('HomeDealsVerifyInfo.ButtonLabel') }}
        </Button>
        <p class="text-xs text-gray-200 text-center mt-[8px]">
          {{ $trans('HomeDealsVerifyInfo.SmallDisclaimer') }}
        </p>
      </div>
    </div>
  </div>
</template>
