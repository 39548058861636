<script setup>
import ButtonAppDownload from '@js/Components/Forms/Button/ButtonAppDownload.vue'
import { isAndroidAppEnabled, isIphoneAppEnabled } from '@js/App/FeatureFlags'
import Icon from '@js/Components/Icon/Icon.vue'
import { onMounted, ref, watch } from 'vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { isSSR } from '@js/App/Utils/Environment'

const hasSeen = isSSR() ? false : localStorage.getItem('MobileAppBottomModalSeen') === 'true'

const open = ref(!hasSeen)
const originalOverflowY = ref(isSSR() ? '' : document.querySelector('body').style.overflowY)
const originalPosition = ref(isSSR() ? '' : document.querySelector('body').style.position)

// "borrowed" from stackoverflow
function getMobileOperatingSystem () {
  if (isSSR()) {
    return 'unknown'
  }

  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  if (/android/i.test(userAgent)) {
    return 'Android'
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS'
  }

  return 'unknown'
}

const os = getMobileOperatingSystem()

onMounted(() => {
  if (os !== 'unknown' && !isSSR() && !hasSeen) {
    document.querySelector('body').style.overflowY = 'hidden'
    document.querySelector('body').style.position = 'fixed'
  }
})

watch(open, (newOpen) => {
  if (os !== 'unknown' && !isSSR()) {
    if (newOpen) {
      document.querySelector('body').style.overflowY = 'hidden'
      document.querySelector('body').style.position = 'fixed'
    } else {
      document.querySelector('body').style.overflowY = originalOverflowY.value
      document.querySelector('body').style.position = originalPosition.value
    }
  }

  if (!newOpen && !isSSR()) {
    localStorage.setItem('MobileAppBottomModalSeen', 'true')
  }
})
</script>

<template>
  <div v-if="open" class="fixed w-screen h-screen top-0 left-0 lg:pointer-events-none">
    <div
      class="flex flex-col w-full bg-purple-800 fixed bottom-0 px-6 pb-10 rounded-t-lg lg:w-[30rem] lg:right-5 z-30 pointer-events-auto"
    >
      <div class="flex justify-between bg-red-500 cursor-pointer pt-6" @click="open = false">
        <div>
          <h3 class="text-white font-bold text-2xl">
            {{ $trans('MobileAppBottomModal.open.title') }}
          </h3>
          <p class="text-white text-base">
            {{ $trans('MobileAppBottomModal.open.body') }}
          </p>
        </div>
        <Icon name="chevron-down" />
      </div>
      <div v-if="os === 'unknown'" class="flex justify-between mt-6">
        <ButtonAppDownload
          v-if="isAndroidAppEnabled()"
          :is-android="true"
          class="mr-[16px]"
          target="_blank"
          href="https://play.google.com/store/apps/details?id=io.rentbird&hl=nl&pli=1"
        />
        <ButtonAppDownload
          v-if="isIphoneAppEnabled()"
          :is-android="false"
          target="_blank"
          href="https://apps.apple.com/us/app/rentbird-find-rental-houses/id6468817766"
        />
      </div>
      <div v-else>
        <Button
          tag="a"
          :href="os === 'iOS' ? 'https://apps.apple.com/us/app/rentbird-find-rental-houses/id6468817766' : 'https://play.google.com/store/apps/details?id=io.rentbird&hl=nl&pli=1'"
          :full-width-on-mobile="true"
          icon-right="trending-up"
          class="mt-[24px] lg:mr-[8px]"
        >
          {{ $trans('MobileAppBottomModal.download_button') }}
        </Button>
      </div>
    </div>
    <div class="bg-black z-20 opacity-50 w-full h-full top-0 left-0 lg:hidden" @click="open = false" />
  </div>
  <div v-else class="flex flex-col w-full bg-purple-800 fixed bottom-0 px-6 rounded-t-lg lg:w-[30rem] lg:right-5 z-30">
    <div class="flex justify-between bg-red-500 cursor-pointer py-4" @click="open = true">
      <h3 class="text-white font-bold text-2xl">
        {{ $trans('MobileAppBottomModal.closed.title') }}
      </h3>
      <Icon name="chevron-up" />
    </div>
  </div>
</template>
