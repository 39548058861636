<script setup>
import TrustpilotSmall from '@js/Components/Trustpilot/TrustpilotSmall.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import Container from '@js/Components/Container/Container.vue'
import { useUser } from '@js/App/Api/User/User'

const { user } = useUser()
</script>

<template>
  <div class="bg-[#fdecf3]">
    <Container>
      <div class="flex justify-center w-full">
        <div class="w-full mt-6 mb-4 mx-4 lg:mt-10 lg:mb-6 max-w-[744px] flex flex-col justify-center">
          <div class="block lg:flex justify-between py-2 items-center">
            <div class="text-lg font-bold mb-2 lg:mb-0">
              {{ $trans('HowItWorksLoggedIn.HowCanWeHelp') }}
            </div>
            <div class="lg:flex lg:items-end">
              <Button
                v-if="user.hasActiveSubscription"
                tag="link"
                :href="$getLocalizedUrl('my-rentbird.home')"
                :big="true"
                :full-width-on-mobile="true"
                icon-right="trending-up"
                class="lg:mr-2 mb-2 lg:mb-0"
              >
                <span class="whitespace-nowrap">{{ $trans('Footer.MyRentbird') }}</span>
              </Button>
              <Button
                v-if="!user.hasActiveSubscription"
                tag="link"
                :href="$getLocalizedUrl('upgrade')"
                :big="true"
                :full-width-on-mobile="true"
                icon-right="unlocked"
                class="lg:mr-2 mb-2 lg:mb-0"
              >
                <span class="whitespace-nowrap">{{ $trans('HomePageLoggedIn.ChooseSubscription') }}</span>
              </Button>
              <Button
                tag="link"
                :href="$getLocalizedUrl('my-rentbird.faq')"
                :big="true"
                :outline="true"
                :full-width-on-mobile="true"
                icon-right="arrow-right"
              >
                <span class="whitespace-nowrap">{{ $trans('ContactPageHeroSection.Faq') }}</span>
              </Button>
            </div>
          </div>
          <div class="py-2 mt-2 lg:mt-0">
            <TrustpilotSmall :black="true" />
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>

<style>

</style>
